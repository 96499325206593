import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ".././Host/MyListings.css";
import { Table, Badge } from "react-bootstrap";
import { FaCheckCircle, FaEye, FaTrash } from "react-icons/fa";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { CancelOutlined } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, Link, useParams } from "react-router-dom";
import { BaseURL } from "../../config/variables";
import {
  getHostAccountHistory,
  limit3,
  payHost,
  totalPages,
} from "../../utils/ownerActions/ownerRequests";
import {
  getBookedAccommodation,
  getBookedFlights,
  getBookedServices,
  getBookedTours,
  getBookedVehicles,
} from "../../utils/hostActions/hostRequests";
import Spinner from "../Alert/Spinner/Spinner";
import OwnerNavbar from "./OwnerNavbar";
import { hostDasboardCalculateNights } from "../../utils/utilities";

const AccountHistory = () => {
  const { id } = useParams();
  const [hostName, setHostName] = useState("");

  // Show all bookings for this host
  const [data, setData] = useState([]);
  const [flightData, setFlightData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [tourData, setTourData] = useState([]);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const [amount, setAmount] = useState("");
  const [payoutType, setPayoutType] = useState("");
  const [currentAPage, setCurrentAPage] = useState(1);
  const [currentVPage, setCurrentVPage] = useState(1);
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentTPage, setCurrentTPage] = useState(1);
  const [currentSPage, setCurrentSPage] = useState(1);

  const [startAIndex, setstartAIndex] = useState(0);
  const [startVIndex, setstartVIndex] = useState(0);
  const [startFIndex, setstartFIndex] = useState(0);
  const [startTIndex, setstartTIndex] = useState(0);
  const [startSIndex, setstartSIndex] = useState(0);

  const [accountHistory, setAccountHistory] = useState(null);

  const fetchAccountHistory = async () => {
    setLoading(true);

    const history = await getHostAccountHistory(id);
    if (history?.status) {
      setAccountHistory(history);
      // console.log({ history })
      setLoading(false);
    } else {
      handleError("Could not get account histroy.");
      setLoading(false);
    }
  };

  const fetchAccomm = async () => {
    setLoading(true);
    setstartAIndex((currentAPage - 1) * limit3);
    const accomm = await getBookedAccommodation(id, currentAPage, limit3);
    if (accomm?.status) {
      const data = accomm.accommodations;
      setData(data);
      data?.length > 0 ? setHostName(data[0]?.hostName) : setHostName("");

      // const totalAmount = data.reduce((sum, item) => sum + Number(item.amountPaid), 0)
      // setAccomTotalAmount(totalAmount)

      setLoading(false);
      // console.log({accomm})
    } else {
      handleError("Could not get accommodation lists.");
      setLoading(false);
    }
  };

  const fetchVehicles = async () => {
    setLoading(true);
    setstartVIndex((currentAPage - 1) * limit3);
    const vehicles = await await getBookedVehicles(id, currentVPage, limit3);
    if (vehicles?.status) {
      const data = vehicles.vehicles;
      setVehicleData(data);
      data?.length > 0 ? setHostName(data[0]?.hostName) : setHostName("");
      // const totalAmount = data?.reduce((sum, item) => sum + Number(item.amountPaid), 0)
      // setVehicleTotalAmount(totalAmount)
      setLoading(false);
    } else {
      handleError("Could not get vehicles lists.");
      setLoading(false);
    }
  };
  const fetchFlights = async () => {
    setLoading(true);
    setstartFIndex((currentAPage - 1) * limit3);
    const flights = await getBookedFlights(id, currentFPage, limit3);
    if (flights?.status) {
      const data = flights.flights;
      setFlightData(data);
      data?.length > 0 ? setHostName(data[0]?.hostName) : setHostName("");
      // const totalAmount = data?.reduce((sum, item) => sum + Number(item.amountPaid), 0)
      // setFlightTotalAmount(totalAmount)
      setLoading(false);
    } else {
      handleError("Could not get flight lists.");
      setLoading(false);
    }
  };
  const fetchTours = async () => {
    setLoading(true);
    setstartTIndex((currentAPage - 1) * limit3);
    const tours = await getBookedTours(id, currentTPage, limit3);
    if (tours?.status) {
      const data = tours.tours;
      setTourData(data);
      data?.length > 0 ? setHostName(data[0]?.hostName) : setHostName("");
      // const totalAmount = data?.reduce((sum, item) => sum + Number(item.amountPaid), 0)
      // setTourTotalAmount(totalAmount)
      setLoading(false);
    } else {
      handleError("Could not get tours lists.");
      setLoading(false);
    }
  };
  const fetchServices = async () => {
    setLoading(true);
    setstartSIndex((currentAPage - 1) * limit3);
    const services = await getBookedServices(id, currentSPage, limit3);
    if (services?.status) {
      const data = services.services;
      setServiceData(data);
      data?.length > 0 ? setHostName(data[0]?.hostName) : setHostName("");
      // const totalAmount = data?.reduce((sum, item) => sum + Number(item.amountPaid), 0)
      // setServicesTotalAmount(totalAmount)
      setLoading(false);
    } else {
      handleError("Could not get services lists.");
      setLoading(false);
    }
  };

  const handlepayHost = async (e, text) => {
    e.preventDefault();
    setLoading(true);

    const result = await payHost({ amount, payoutType }, id);

    if (result?.status) {
      toast.success("Host payment account updated succesfully.");
      await fetchAccountHistory();
      setLoading(false);
    } else {
      handleError("Could not pay this host.");
      setLoading(false);
    }

    setAmount("");
    setPayoutType("");
  };

  const handlePrevious = (text) => {
    if (text === "a") {
      currentAPage > 1
        ? setCurrentAPage(currentAPage - 1)
        : setCurrentAPage(currentAPage);
    } else if (text === "v") {
      currentVPage > 1
        ? setCurrentVPage(currentVPage - 1)
        : setCurrentVPage(currentVPage);
    } else if (text === "f") {
      currentFPage > 1
        ? setCurrentFPage(currentFPage - 1)
        : setCurrentFPage(currentFPage);
    } else if (text === "t") {
      currentTPage > 1
        ? setCurrentTPage(currentTPage - 1)
        : setCurrentTPage(currentTPage);
    } else if (text === "s") {
      currentSPage > 1
        ? setCurrentSPage(currentSPage - 1)
        : setCurrentSPage(currentSPage);
    }
  };

  const handleNextPage = (text) => {
    if (text === "a") {
      currentAPage < totalPages
        ? setCurrentAPage(currentAPage + 1)
        : setCurrentAPage(currentAPage);
    } else if (text === "v") {
      currentVPage < totalPages
        ? setCurrentVPage(currentVPage + 1)
        : setCurrentVPage(currentVPage);
    } else if (text === "f") {
      currentFPage < totalPages
        ? setCurrentFPage(currentFPage + 1)
        : setCurrentFPage(currentFPage);
    } else if (text === "t") {
      currentTPage < totalPages
        ? setCurrentTPage(currentTPage + 1)
        : setCurrentTPage(currentTPage);
    } else if (text === "s") {
      currentSPage < totalPages
        ? setCurrentSPage(currentSPage + 1)
        : setCurrentSPage(currentSPage);
    }
  };

  const goToPage = (number, text) => {
    if (text === "a") {
      setCurrentAPage(number);
    } else if (text === "v") {
      setCurrentVPage(number);
    } else if (text === "f") {
      setCurrentFPage(number);
    } else if (text === "t") {
      setCurrentTPage(number);
    } else if (text === "s") {
      setCurrentSPage(number);
    }
  };

  useEffect(() => {
    fetchAccomm();
  }, [id, currentAPage]);

  useEffect(() => {
    fetchVehicles();
  }, [id, currentVPage]);

  useEffect(() => {
    fetchFlights();
  }, [id, currentFPage]);

  useEffect(() => {
    fetchTours();
  }, [id, currentTPage]);

  useEffect(() => {
    fetchServices();
  }, [id, currentSPage]);

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    fetchAccountHistory();
  }, []);

  //   console.log(data);

  function onTimeChange(value) {
    let timeSplit = value.split(":"),
      hours,
      minutes,
      meridian;
    hours = timeSplit[0];
    minutes = timeSplit[1];
    if (hours > 12) {
      meridian = "PM";
      hours -= 12;
      return hours + ":" + minutes + " " + meridian;
    } else if (hours < 12) {
      meridian = "AM";
      if (hours == 0) {
        hours = 12;
      }
      return hours + ":" + minutes + " " + meridian;
    } else {
      meridian = "PM";
      return hours + ":" + minutes + " " + meridian;
    }
  }

  function formatDate(inputDate) {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
      // Months use 0 index.
      return (
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
      );
    }
  }

  function datediff(first, second) {
    let start = new Date(first);
    start.setHours(0, 0, 0, 0); // Sets hours, minutes, seconds, and milliseconds
    let end = new Date(second);
    end.setHours(0, 0, 0, 0);
    let diff = Math.round((end - start) / 86400000) + 1; // See note below re `+ 1`
    return diff; // 3
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });






    // useEffect(() => {
    //   if (checkInDate && checkInTime && checkOutDate) {
    //     setNumberOfNights(
    //       hostDasboardCalculateNights(checkInDate, checkInTime, checkOutDate)
    //     ); 
    //   }
    // }, [checkInDate, checkInTime, checkOutDate]);

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <ToastContainer />

      {/* Fund Wallet Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                How much was paid to this Host for {payoutType}?
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="input-group mb-3">
                <span className="input-group-text">₦</span>
                <input
                  type="text"
                  className="form-control"
                  aria-label="Amount (to the nearest naira)"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  required
                />
                <span className="input-group-text">.00</span>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handlepayHost}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="MyListings">
        <Container>
          <Row>
            <Col xs={12} md={3} lg={3}>
              <OwnerNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <Row>
                <Col xs={12} md={12} className="mb-3">
                  <h2>
                    Account history <br /> with {hostName}{" "}
                  </h2>
                  {/* <p>Find All booking on your Listings in one place. </p> */}
                </Col>

                <Col xs={12} md={12} className="mb-5">
                  <div className="table-panel">
                    <h3>Accommodation(s)</h3>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th> S/N </th>
                          <th> Reservations </th>
                          <th> Customer </th>
                          <th> Duration </th>
                          <th> Status </th>
                          <th> Amount (₦) </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.map((property, index) => {
                          return (
                            <tr key={index}>
                              <th>{index + 1 + startAIndex}.</th>
                              <td>{property.propertyName} </td>
                              <td>
                                <b>
                                  {property.firstName + " " + property.lastName}{" "}
                                  <br /> ({property.phoneNumber})
                                </b>
                                <br /> Comments: <br /> {property.bookingFor}
                              </td>
                              <td>
                                From: {formatDate(property.checkInDate)} <br />(
                                {onTimeChange(property.checkInTime)})<br />
                                To: {formatDate(property.checkOutDate)} <br />(
                                {onTimeChange(property.checkOutTime)})<br />
                                <b>Total Stay: </b>
                                {/* {property.duration} */}
                                {hostDasboardCalculateNights(property.checkInDate, property.checkInTime, property.checkOutDate)}
                              </td>
                              <td>
                                {property.confirmation === 0 && (
                                  <Badge bg="warning">Pending</Badge>
                                )}
                                {property.confirmation === 1 && (
                                  <Badge bg="success">Accepted</Badge>
                                )}
                                {property.confirmation === 2 && (
                                  <Badge bg="danger">Rejected</Badge>
                                )}
                              </td>
                              <td>
                                <div className="text-primary">
                                  ₦ {numberWithCommas(property.amountPaid)}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <div className="total-div">
                      <Table responsive>
                        <tbody>
                          <tr>
                            <th> Total Amount Made: </th>
                            <th>
                              ₦{" "}
                              {accountHistory?.totalAmounts
                                ?.accommodationsTotalAmount &&
                              accountHistory?.totalAmounts
                                ?.accommodationsTotalAmount > 100
                                ? accountHistory?.totalAmounts?.accommodationsTotalAmount.toLocaleString()
                                : accountHistory?.totalAmounts
                                    ?.accommodationsTotalAmount}{" "}
                            </th>
                          </tr>
                          <tr>
                            <th> Total Amount Due: </th>
                            <th>
                              ₦{" "}
                              {accountHistory?.hostPayoutsDue
                                ?.TotalAccommodationsDue &&
                              accountHistory?.hostPayoutsDue
                                ?.TotalAccommodationsDue > 100
                                ? accountHistory?.hostPayoutsDue?.TotalAccommodationsDue.toLocaleString()
                                : accountHistory?.hostPayoutsDue
                                    ?.TotalAccommodationsDue}{" "}
                            </th>
                          </tr>
                          <tr>
                            <th> Total Amount Paid: </th>
                            <th>
                              ₦{" "}
                              {accountHistory?.hostPayouts
                                ?.totalAccommodationsPayout &&
                              accountHistory?.hostPayouts
                                ?.totalAccommodationsPayout > 100
                                ? accountHistory?.hostPayouts?.totalAccommodationsPayout.toLocaleString()
                                : accountHistory?.hostPayouts
                                    ?.totalAccommodationsPayout}{" "}
                            </th>
                          </tr>
                          <tr>
                            <th> Maczuby Profit: </th>
                            <th>
                              ₦{" "}
                              {accountHistory?.maczubyPayouts
                                ?.total15PercentAccommodations &&
                              accountHistory?.maczubyPayouts
                                ?.total15PercentAccommodations > 100
                                ? accountHistory?.maczubyPayouts?.total15PercentAccommodations.toLocaleString()
                                : accountHistory?.maczubyPayouts
                                    ?.total15PercentAccommodations}{" "}
                            </th>
                          </tr>
                        </tbody>
                      </Table>

                      <button
                        className="pay-host-btn "
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={(e) => setPayoutType("accommodations")}
                      >
                        Pay Host
                      </button>
                    </div>

                    <ul
                      className="pagination "
                      style={{ alignItems: "right", justifyContent: "right" }}
                    >
                      <li
                        className="page-item"
                        onClick={() => handlePrevious("a")}
                      >
                        <Link className="page-link" to="" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </Link>
                      </li>
                      <li
                        className="page-item"
                        onClick={() => goToPage(1, "a")}
                      >
                        <Link className="page-link" to="">
                          1
                        </Link>
                      </li>
                      <li
                        className="page-item"
                        onClick={() => goToPage(2, "a")}
                      >
                        <Link className="page-link" to="">
                          2
                        </Link>
                      </li>
                      <li
                        className="page-item"
                        onClick={() => goToPage(3, "a")}
                      >
                        <Link className="page-link" to="">
                          3
                        </Link>
                      </li>

                      <li
                        className="page-item"
                        onClick={() => handleNextPage("a")}
                      >
                        <Link className="page-link" to="" aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>

                <Col xs={12} md={12} className="mb-5">
                  <div className="table-panel">
                    <h3 className="mt-5">Flight(s)</h3>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th> S/N </th>
                          <th> Reservations </th>
                          <th> Customer </th>
                          <th> Duration </th>
                          <th> Status </th>
                          <th> Amount </th>
                        </tr>
                      </thead>
                      <tbody>
                        {flightData?.map((property, index) => {
                          return (
                            <tr key={index}>
                              <th>{index + 1 + startFIndex}.</th>
                              <td>
                                {property.fromWhere} -{property.toWhere}
                              </td>
                              <td>
                                <b>
                                  {property.firstName + " " + property.lastName}{" "}
                                  <br /> ({property.phoneNumber})
                                </b>
                                <br /> Comments: <br /> {property.bookingFor}
                              </td>
                              <td>
                                From: {formatDate(property.checkInDate)} <br />(
                                {onTimeChange(property.checkInTime)})<br />
                                To: {formatDate(property.checkOutDate)} <br />(
                                {onTimeChange(property.checkOutTime)})<br />
                                <b>Total Stay: </b>
                                {/* {property.duration} */}
                                {hostDasboardCalculateNights(property.checkInDate, property.checkInTime, property.checkOutDate)}

                                <br />
                                <b>Passengers: </b>
                                {property.tickets}
                              </td>
                              <td>
                                {property.confirmation === 0 && (
                                  <Badge bg="warning">Pending</Badge>
                                )}
                                {property.confirmation === 1 && (
                                  <Badge bg="success">Accepted</Badge>
                                )}
                                {property.confirmation === 2 && (
                                  <Badge bg="danger">Rejected</Badge>
                                )}
                              </td>
                              <td>
                                <div className="text-primary">
                                  NGN{numberWithCommas(property.amountPaid)}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>

                    <div className="total-div">
                      <Table responsive>
                        <tbody>
                          <tr>
                            <th> Total Amount Made: </th>
                            <th>
                              ₦{" "}
                              {accountHistory?.totalAmounts
                                ?.flightsTotalAmount &&
                              accountHistory?.totalAmounts?.flightsTotalAmount >
                                100
                                ? accountHistory?.totalAmounts?.flightsTotalAmount.toLocaleString()
                                : accountHistory?.totalAmounts
                                    ?.flightsTotalAmount}{" "}
                            </th>
                          </tr>
                          <tr>
                            <th> Total Amount Due: </th>
                            <th>
                              ₦{" "}
                              {accountHistory?.hostPayoutsDue
                                ?.TotalFlightsDue &&
                              accountHistory?.hostPayoutsDue?.TotalFlightsDue >
                                100
                                ? accountHistory?.hostPayoutsDue?.TotalFlightsDue.toLocaleString()
                                : accountHistory?.hostPayoutsDue
                                    ?.TotalFlightsDue}{" "}
                            </th>
                          </tr>
                          <tr>
                            <th> Total Amount Paid: </th>
                            <th>
                              ₦{" "}
                              {accountHistory?.hostPayouts
                                ?.totalFlightsPayout &&
                              accountHistory?.hostPayouts?.totalFlightsPayout >
                                100
                                ? accountHistory?.hostPayouts?.totalFlightsPayout.toLocaleString()
                                : accountHistory?.hostPayouts
                                    ?.totalFlightsPayout}{" "}
                            </th>
                          </tr>
                          <tr>
                            <th> Maczuby Profit: </th>
                            <th>
                              ₦{" "}
                              {accountHistory?.maczubyPayouts
                                ?.total15PercentFlights &&
                              accountHistory?.maczubyPayouts
                                ?.total15PercentFlights > 100
                                ? accountHistory?.maczubyPayouts?.total15PercentFlights.toLocaleString()
                                : accountHistory?.maczubyPayouts
                                    ?.total15PercentFlights}
                            </th>
                          </tr>
                        </tbody>
                      </Table>

                      <button
                        className="pay-host-btn "
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={(e) => setPayoutType("flights")}
                      >
                        Pay Host
                      </button>
                    </div>

                    <ul
                      className="pagination "
                      style={{ alignItems: "right", justifyContent: "right" }}
                    >
                      <li
                        className="page-item"
                        onClick={() => handlePrevious("f")}
                      >
                        <Link className="page-link" to="" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </Link>
                      </li>
                      <li
                        className="page-item"
                        onClick={() => goToPage(1, "f")}
                      >
                        <Link className="page-link" to="">
                          1
                        </Link>
                      </li>
                      <li
                        className="page-item"
                        onClick={() => goToPage(2, "f")}
                      >
                        <Link className="page-link" to="">
                          2
                        </Link>
                      </li>
                      <li
                        className="page-item"
                        onClick={() => goToPage(3, "f")}
                      >
                        <Link className="page-link" to="">
                          3
                        </Link>
                      </li>

                      <li
                        className="page-item"
                        onClick={() => handleNextPage("f")}
                      >
                        <Link className="page-link" to="" aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>

                <Col xs={12} md={12} className="mb-5">
                  <div className="table-panel">
                    <h3 className="mt-5">Vehicle(s)</h3>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th> S/N </th>
                          <th> Reservations </th>
                          <th> Customer </th>
                          <th> Duration </th>
                          <th> Status </th>
                          <th> Amount </th>
                        </tr>
                      </thead>
                      <tbody>
                        {vehicleData?.map((property, index) => {
                          return (
                            <tr key={index}>
                              <th>{index + 1 + startVIndex}.</th>
                              <td>{property.vehicleName} </td>
                              <td>
                                <b>
                                  {property.firstName + " " + property.lastName}{" "}
                                  <br /> ({property.phoneNumber})
                                </b>
                                <br /> Comments: <br /> {property.bookingFor}
                              </td>
                              <td>
                                From: {formatDate(property.checkInDate)} <br />(
                                {onTimeChange(property.checkInTime)})<br />
                                To: {formatDate(property.checkOutDate)} <br />(
                                {onTimeChange(property.checkOutTime)})<br />
                                <b>Total Stay: </b>
                                {/* {property.duration} */}
                                {hostDasboardCalculateNights(property.checkInDate, property.checkInTime, property.checkOutDate)}

                              </td>
                              <td>
                                {property.confirmation === 0 && (
                                  <Badge bg="warning">Pending</Badge>
                                )}
                                {property.confirmation === 1 && (
                                  <Badge bg="success">Accepted</Badge>
                                )}
                                {property.confirmation === 2 && (
                                  <Badge bg="danger">Rejected</Badge>
                                )}
                              </td>
                              <td>
                                <div className="text-primary">
                                  NGN{numberWithCommas(property.amountPaid)}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>

                    <div className="total-div">
                      <Table responsive>
                        <tbody>
                          <tr>
                            <th> Total Amount Made: </th>
                            <th>
                              ₦{" "}
                              {accountHistory?.totalAmounts
                                ?.vehiclesTotalAmount &&
                              accountHistory?.totalAmounts
                                ?.vehiclesTotalAmount > 100
                                ? accountHistory?.totalAmounts?.vehiclesTotalAmount.toLocaleString()
                                : accountHistory?.totalAmounts
                                    ?.vehiclesTotalAmount}{" "}
                            </th>
                          </tr>
                          <tr>
                            <th> Total Amount Due: </th>
                            <th>
                              ₦{" "}
                              {accountHistory?.hostPayoutsDue
                                ?.TotalVehiclesDue &&
                              accountHistory?.hostPayoutsDue?.TotalVehiclesDue >
                                100
                                ? accountHistory?.hostPayoutsDue?.TotalVehiclesDue.toLocaleString()
                                : accountHistory?.hostPayoutsDue
                                    ?.TotalVehiclesDue}{" "}
                            </th>
                          </tr>
                          <tr>
                            <th> Total Amount Paid: </th>
                            <th>
                              ₦{" "}
                              {accountHistory?.hostPayouts
                                ?.totalVehiclesPayout &&
                              accountHistory?.hostPayouts?.totalVehiclesPayout >
                                100
                                ? accountHistory?.hostPayouts?.totalVehiclesPayout.toLocaleString()
                                : accountHistory?.hostPayouts
                                    ?.totalVehiclesPayout}{" "}
                            </th>
                          </tr>
                          <tr>
                            <th> Maczuby Profit: </th>
                            <th>
                              {" "}
                              ₦{" "}
                              {accountHistory?.maczubyPayouts
                                ?.total15PercentVehicles &&
                              accountHistory?.maczubyPayouts
                                ?.total15PercentVehicles > 100
                                ? accountHistory?.maczubyPayouts?.total15PercentVehicles.toLocaleString()
                                : accountHistory?.maczubyPayouts
                                    ?.total15PercentVehicles}{" "}
                            </th>
                          </tr>
                        </tbody>
                      </Table>

                      <button
                        className="pay-host-btn "
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={(e) => setPayoutType("vehicles")}
                      >
                        Pay Host
                      </button>
                    </div>

                    <ul
                      className="pagination "
                      style={{ alignItems: "right", justifyContent: "right" }}
                    >
                      <li
                        className="page-item"
                        onClick={() => handlePrevious("v")}
                      >
                        <Link className="page-link" to="" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </Link>
                      </li>
                      <li
                        className="page-item"
                        onClick={() => goToPage(1, "v")}
                      >
                        <Link className="page-link" to="">
                          1
                        </Link>
                      </li>
                      <li
                        className="page-item"
                        onClick={() => goToPage(2, "v")}
                      >
                        <Link className="page-link" to="">
                          2
                        </Link>
                      </li>
                      <li
                        className="page-item"
                        onClick={() => goToPage(3, "v")}
                      >
                        <Link className="page-link" to="">
                          3
                        </Link>
                      </li>

                      <li
                        className="page-item"
                        onClick={() => handleNextPage("v")}
                      >
                        <Link className="page-link" to="" aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>

                <Col xs={12} md={12} className="mb-5">
                  <div className="table-panel">
                    <h3 className="mt-5">Tour(s)</h3>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th> S/N </th>
                          <th> Reservations </th>
                          <th> Customer </th>
                          <th> Duration </th>
                          <th> Status </th>
                          <th> Amount </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tourData?.map((property, index) => {
                          return (
                            <tr key={index}>
                              <th>{index + 1 + startTIndex}.</th>
                              <td>{property.tourName} </td>
                              <td>
                                <b>
                                  {property.firstName + " " + property.lastName}{" "}
                                  <br /> ({property.phoneNumber})
                                </b>
                                <br /> Comments: <br /> {property.bookingFor}
                              </td>
                              <td>
                                From: {formatDate(property.checkInDate)} <br />(
                                {onTimeChange(property.checkInTime)})<br />
                                To: {formatDate(property.checkOutDate)} <br />(
                                {onTimeChange(property.checkOutTime)})<br />
                                <b>Total Stay: </b>
                                {/* {property.duration} */}
                                {hostDasboardCalculateNights(property.checkInDate, property.checkInTime, property.checkOutDate)}

                              </td>
                              <td>
                                {property.confirmation === 0 && (
                                  <Badge bg="warning">Pending</Badge>
                                )}
                                {property.confirmation === 1 && (
                                  <Badge bg="success">Accepted</Badge>
                                )}
                                {property.confirmation === 2 && (
                                  <Badge bg="danger">Rejected</Badge>
                                )}
                              </td>
                              <td>
                                <div className="text-primary">
                                  NGN{numberWithCommas(property.amountPaid)}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>

                    <div className="total-div">
                      <Table responsive>
                        <tbody>
                          <tr>
                            <th> Total Amount Made: </th>
                            <th>
                              ₦{" "}
                              {accountHistory?.totalAmounts?.toursTotalAmount &&
                              accountHistory?.totalAmounts?.toursTotalAmount >
                                100
                                ? accountHistory?.totalAmounts?.toursTotalAmount.toLocaleString()
                                : accountHistory?.totalAmounts
                                    ?.toursTotalAmount}{" "}
                            </th>
                          </tr>
                          <tr>
                            <th> Total Amount Due: </th>
                            <th>
                              ₦{" "}
                              {accountHistory?.hostPayoutsDue?.TotalToursDue &&
                              accountHistory?.hostPayoutsDue?.TotalToursDue >
                                100
                                ? accountHistory?.hostPayoutsDue?.TotalToursDue.toLocaleString()
                                : accountHistory?.hostPayoutsDue
                                    ?.TotalToursDue}{" "}
                            </th>
                          </tr>
                          <tr>
                            <th> Total Amount Paid: </th>
                            <th>
                              {" "}
                              ₦{" "}
                              {accountHistory?.hostPayouts?.totalToursPayout &&
                              accountHistory?.hostPayouts?.totalToursPayout >
                                100
                                ? accountHistory?.hostPayouts?.totalToursPayout.toLocaleString()
                                : accountHistory?.hostPayouts
                                    ?.totalToursPayout}{" "}
                            </th>
                          </tr>
                          <tr>
                            <th> Maczuby Profit: </th>
                            <th>
                              {" "}
                              ₦{" "}
                              {accountHistory?.maczubyPayouts
                                ?.total15PercentTours &&
                              accountHistory?.maczubyPayouts
                                ?.total15PercentTours > 100
                                ? accountHistory?.maczubyPayouts?.total15PercentTours.toLocaleString()
                                : accountHistory?.maczubyPayouts
                                    ?.total15PercentTours}{" "}
                            </th>
                          </tr>
                        </tbody>
                      </Table>

                      <button
                        className="pay-host-btn "
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={(e) => setPayoutType("tours")}
                      >
                        Pay Host
                      </button>
                    </div>

                    <ul
                      className="pagination "
                      style={{ alignItems: "right", justifyContent: "right" }}
                    >
                      <li
                        className="page-item"
                        onClick={() => handlePrevious("t")}
                      >
                        <Link className="page-link" to="" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </Link>
                      </li>
                      <li
                        className="page-item"
                        onClick={() => goToPage(1, "t")}
                      >
                        <Link className="page-link" to="">
                          1
                        </Link>
                      </li>
                      <li
                        className="page-item"
                        onClick={() => goToPage(2, "t")}
                      >
                        <Link className="page-link" to="">
                          2
                        </Link>
                      </li>
                      <li
                        className="page-item"
                        onClick={() => goToPage(3, "t")}
                      >
                        <Link className="page-link" to="">
                          3
                        </Link>
                      </li>

                      <li
                        className="page-item"
                        onClick={() => handleNextPage("t")}
                      >
                        <Link className="page-link" to="" aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>

                <Col xs={12} md={12} className="mb-5">
                  <div className="table-panel">
                    <h3 className="mt-5">Service(s)</h3>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th> S/N </th>
                          <th> Reservations </th>
                          <th> Customer </th>
                          <th> Duration </th>
                          <th> Status </th>
                          <th> Amount </th>
                        </tr>
                      </thead>
                      <tbody>
                        {serviceData?.map((property, index) => {
                          return (
                            <tr key={index}>
                              <th>{index + 1 + startSIndex}.</th>
                              <td>{property.serviceName} </td>
                              <td>
                                <b>
                                  {property.firstName + " " + property.lastName}{" "}
                                  <br /> ({property.phoneNumber})
                                </b>{" "}
                                <br /> Comments: <br /> {property.bookingFor}
                              </td>
                              <td>
                                From: {formatDate(property.checkInDate)} <br />(
                                {onTimeChange(property.checkInTime)})<br />
                                To: {formatDate(property.checkOutDate)} <br />(
                                {onTimeChange(property.checkOutTime)})<br />
                                <b>Total Stay: </b>
                                {/* {property.duration} */}
                                {hostDasboardCalculateNights(property.checkInDate, property.checkInTime, property.checkOutDate)}

                              </td>
                              <td>
                                {property.confirmation === 0 && (
                                  <Badge bg="warning">Pending</Badge>
                                )}
                                {property.confirmation === 1 && (
                                  <Badge bg="success">Accepted</Badge>
                                )}
                                {property.confirmation === 2 && (
                                  <Badge bg="danger">Rejected</Badge>
                                )}
                              </td>
                              <td>
                                <div className="text-primary">
                                  NGN{numberWithCommas(property.amountPaid)}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>

                    <div className="total-div">
                      <Table responsive>
                        <tbody>
                          <tr>
                            <th> Total Amount Made: </th>
                            <th>
                              ₦{" "}
                              {accountHistory?.totalAmounts
                                ?.servicesTotalAmount &&
                              accountHistory?.totalAmounts
                                ?.servicesTotalAmount > 100
                                ? accountHistory?.totalAmounts?.servicesTotalAmount.toLocaleString()
                                : accountHistory?.totalAmounts
                                    ?.servicesTotalAmount}{" "}
                            </th>
                          </tr>
                          <tr>
                            <th> Total Amount Due: </th>
                            <th>
                              ₦{" "}
                              {accountHistory?.hostPayoutsDue
                                ?.TotalServicesDue &&
                              accountHistory?.hostPayoutsDue?.TotalServicesDue >
                                100
                                ? accountHistory?.hostPayoutsDue?.TotalServicesDue.toLocaleString()
                                : accountHistory?.hostPayoutsDue
                                    ?.TotalServicesDue}{" "}
                            </th>
                          </tr>
                          <tr>
                            <th> Total Amount Paid: </th>
                            <th>
                              {" "}
                              ₦{" "}
                              {accountHistory?.hostPayouts
                                ?.totalServicesPayout &&
                              accountHistory?.hostPayouts?.totalServicesPayout >
                                100
                                ? accountHistory?.hostPayouts?.totalServicesPayout.toLocaleString()
                                : accountHistory?.hostPayouts
                                    ?.totalServicesPayout}{" "}
                            </th>
                          </tr>
                          <tr>
                            <th> Maczuby Profit: </th>
                            <th>
                              {" "}
                              ₦{" "}
                              {accountHistory?.maczubyPayouts
                                ?.total15PercentServices &&
                              accountHistory?.maczubyPayouts
                                ?.total15PercentServices > 100
                                ? accountHistory?.maczubyPayouts?.total15PercentServices.toLocaleString()
                                : accountHistory?.maczubyPayouts
                                    ?.total15PercentServices}{" "}
                            </th>
                          </tr>
                        </tbody>
                      </Table>

                      <button
                        className="pay-host-btn "
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={(e) => setPayoutType("services")}
                      >
                        Pay Host
                      </button>
                    </div>

                    <ul
                      className="pagination "
                      style={{ alignItems: "right", justifyContent: "right" }}
                    >
                      <li
                        className="page-item"
                        onClick={() => handlePrevious("s")}
                      >
                        <Link className="page-link" to="" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </Link>
                      </li>
                      <li
                        className="page-item"
                        onClick={() => goToPage(1, "s")}
                      >
                        <Link className="page-link" to="">
                          1
                        </Link>
                      </li>
                      <li
                        className="page-item"
                        onClick={() => goToPage(2, "s")}
                      >
                        <Link className="page-link" to="">
                          2
                        </Link>
                      </li>
                      <li
                        className="page-item"
                        onClick={() => goToPage(3, "s")}
                      >
                        <Link className="page-link" to="">
                          3
                        </Link>
                      </li>

                      <li
                        className="page-item"
                        onClick={() => handleNextPage("s")}
                      >
                        <Link className="page-link" to="" aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>

                <Col xs={12} md={12} className="mb-5">
                  <div className="table-panel">
                    <h3 className="">Total Sums</h3>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th> S/N </th>
                          <th> Total Amount made</th>
                          <th> Total Amount Due </th>
                          <th> Total Amount Paid </th>
                          <th> Total Maczuby Profit </th>
                          {/* <th> Amount </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th> 1. </th>
                          <th>
                            ₦{" "}
                            {accountHistory?.totalAmounts?.totalAmount > 100
                              ? accountHistory?.totalAmounts?.totalAmount.toLocaleString()
                              : accountHistory?.totalAmounts?.totalAmount}
                          </th>

                          <th>
                            {" "}
                            ₦{" "}
                            {accountHistory?.hostPayoutsDue
                              ?.totalPayoutsAmountDue > 100
                              ? accountHistory?.hostPayoutsDue?.totalPayoutsAmountDue.toLocaleString()
                              : accountHistory?.hostPayoutsDue
                                  ?.totalPayoutsAmountDue}{" "}
                          </th>

                          <th>
                            {" "}
                            ₦{" "}
                            {accountHistory?.hostPayouts?.totalPayoutsAmount >
                            100
                              ? accountHistory?.hostPayouts?.totalPayoutsAmount.toLocaleString()
                              : accountHistory?.hostPayouts
                                  ?.totalPayoutsAmount}{" "}
                          </th>

                          <th>
                            {" "}
                            ₦{" "}
                            {accountHistory?.maczubyPayouts
                              ?.TotalMaczubyAmount > 100
                              ? accountHistory?.maczubyPayouts?.TotalMaczubyAmount.toLocaleString()
                              : accountHistory?.maczubyPayouts
                                  ?.TotalMaczubyAmount}{" "}
                          </th>

                          {/* <th> Amount </th> */}
                        </tr>
                        {/* {serviceData?.map((property, index) => {
                          return (
                            <tr key={index}>
                              <th>{index + 1 + startSIndex }.</th>
                              <td>{property.serviceName} </td>
                              <td>
                                <b>
                                  {property.firstName + " " + property.lastName}{" "}
                                  <br /> ({property.phoneNumber})
                                </b>{" "}
                                <br /> Comments: <br /> {property.bookingFor}
                              </td>
                              <td>
                                From: {formatDate(property.checkInDate)} <br />(
                                {onTimeChange(property.checkInTime)})<br />
                                To: {formatDate(property.checkOutDate)} <br />(
                                {onTimeChange(property.checkOutTime)})<br />
                                <b>Total Stay: </b>
                                {property.duration}
                              </td>
                              <td>
                                {property.confirmation === 0 && (
                                  <Badge bg="warning">Pending</Badge>
                                )}
                                {property.confirmation === 1 && (
                                  <Badge bg="success">Accepted</Badge>
                                )}
                                {property.confirmation === 2 && (
                                  <Badge bg="danger">Rejected</Badge>
                                )}
                              </td>
                              <td>
                                <div className="text-primary">
                                  NGN{numberWithCommas(property.amountPaid)}
                                </div>
                              </td>
                            </tr>
                          );
                        })} */}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        {loading && <Spinner />}
      </div>
    </>
  );
};

export default AccountHistory;
