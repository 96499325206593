import React from 'react'
import Home from './LandingPage/Home';
import Trends from './LandingPage/Trends';
import Stats from './LandingPage/Stats';
import DownloadApp from './LandingPage/DownloadApp';
import FlightDeals from './LandingPage/FlightDeals';
import Subscribe from './LandingPage/Subscribe';
import NavbarMenu from './Layouts/Navbar';
const Homepage = () => {
  return (
    <div>
        <NavbarMenu  style={{backgroundColor: '#003B95' }} />
        <Home />
        <Stats />
        <Trends />
        <DownloadApp />
        <FlightDeals />
        <Subscribe />
    </div>
  )
}

export default Homepage