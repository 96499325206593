import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./UserDashboard.css";
import { PaymentOutlined, Payments } from "@mui/icons-material";
import NavbarMenu from "../Layouts/Navbar";
import UserNavbar from "./UserNavbar";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import { FaPenAlt } from "react-icons/fa";
import axios from "axios";
import { BaseURL } from "../../config/variables";
import { useGetUserID } from "../../Hooks/useGetUserID";
import pdfupload from "../../Assets/Img/pdfupload.png";
import draganddrop from "../../Assets/Img/draganddrop.png";
import Spinner from "../Alert/Spinner/Spinner";
import Toast from "../Alert/Toast";
import { uploadUserAvatar } from "../../utils/userActions/userRequests";
import { validateUserAvatar } from "../../utils/userActions/userValidations";

const UserProfileInfoEdit = () => {
  const navigate = useNavigate();
  const currentUserID = useGetUserID();

  const fileInputRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    userID: currentUserID,
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [userDocument, setUserDocument] = useState("");

  useEffect(() => {
    // scroll to top on page load

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    axios
      .get(`${BaseURL}/fetchuserdetails/${currentUserID}`)
      .then((res) => {
        if (res.data.status === "Success") {
          setData({
            ...data,
            firstName: res.data.Result[0].firstName,
            lastName: res.data.Result[0].lastName,
            username: res.data.Result[0].username,
            phoneNumber: res.data.Result[0].phoneNumber,
            emailAddress: res.data.Result[0].emailAddress,
            country: res.data.Result[0].country,
            stateProvince: res.data.Result[0].stateProvince,
            city: res.data.Result[0].city,
            zipCode: res.data.Result[0].zipCode,
            streetAddress: res.data.Result[0].streetAddress,
          });
          // console.log(res.data.Result[0]);
        } else {
          console.log("No such user found");
        }
      })
      .catch((err) => console.log(err));
  }, []); 

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    axios
      .put(`${BaseURL}/edituserdetails/${currentUserID}`, data)
      .then((res) => {
        if (res.data) {
          const { success, message } = res.data;

          if (success) {
            handleSuccess(message);
            setTimeout(() => {
              navigate("/user-profile");
            }, 1000);
            setLoading(false);
          } else {
            setLoading(false);
            handleError(message);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleSubmitAvatar = async (e) => {
    e.preventDefault();

    setLoading(true)
    const result = validateUserAvatar({ selectedFile});

    if (result.errLength) {
      setLoading(false);

      return toast.error(() => <Toast title="Error" body={result.errMsg} />);
    }

    const response = await uploadUserAvatar(selectedFile, currentUserID)

    if(response?.success){
      toast.success("Profile image uploaded successfully.")
      navigate("/user-profile")
      setLoading(false);
    }else {
      toast.error("Could not upload profile image.")
      setLoading(false);
    }
  };

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <div className="UserDashboard">
        {/* Do a mini navbar just below the main navbar */}
        <Container className="container">
          <Row>
            <Col xs={12} md={3} lg={3}>
              <UserNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <form className="form-panel mt-5"  onSubmit={handleSubmit} style={{
                  paddingBottom: "50px"
                }}>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <h2>Edit Your Profile</h2>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <label htmlFor="firstName">First Name:</label>
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, firstName: e.target.value })
                      }
                      value={data.firstName || ""}
                    />

                    <label htmlFor="lastName">Last Name:</label>
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, lastName: e.target.value })
                      }
                      value={data.lastName || ""}
                    />

                    <label htmlFor="username">Username</label>
                    <input
                      type="text"
                      name="username"
                      id="username"
                      disabled
                      className="form-control text-primary"
                      value={data.username || ""}
                    />

                    <label htmlFor="emailAddress">Default Email</label>
                    <input
                      type="text"
                      name="emailAddress"
                      id="emailAddress"
                      disabled
                      className="form-control text-primary"
                      value={data.emailAddress || ""}
                    />

                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, phoneNumber: e.target.value })
                      }
                      value={data.phoneNumber || ""}
                    />
                  </Col>

                  <Col xs={12} md={6} lg={6}>
                    <label htmlFor="country">Country</label>
                    <input
                      type="text"
                      name="country"
                      id="country"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, country: e.target.value })
                      }
                      value={data.country || ""}
                    />

                    <label htmlFor="stateProvince">State/Province</label>
                    <input
                      type="text"
                      name="stateProvince"
                      id="stateProvince"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, stateProvince: e.target.value })
                      }
                      value={data.stateProvince || ""}
                    />

                    <label htmlFor="city">City</label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, city: e.target.value })
                      }
                      value={data.city || ""}
                    />

                    <label htmlFor="zipCode">Zip Code</label>
                    <input
                      type="text"
                      name="zipCode"
                      id="zipCode"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, zipCode: e.target.value })
                      }
                      value={data.zipCode || ""}
                    />

                    <label htmlFor="streetAddress">Street Address</label>
                    <input
                      type="text"
                      name="streetAddress"
                      id="streetAddress"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, streetAddress: e.target.value })
                      }
                      value={data.streetAddress || ""}
                    />
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <button
                      type="submit"
                      className="btnMore mt-5"
                      style={{ width: "100%" }}
                    >
                      Edit Profile <FaPenAlt />
                    </button>
                  </Col>
                </Row>
              </form>

              <form
                className="form-panel"
                onSubmit={handleSubmitAvatar}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "100%",
                  marginTop: "80px"
                }}
              >
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <h2>Profile Image</h2>
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <div className="mt-5">
                      <label className="text-black fw-bold">
                        Upload Profile image
                      </label>

                      <div
                        style={{
                          border: "2px dashed #ccc",
                          padding: "1rem",
                          borderRadius: "10px",
                          height: "250px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                          overflow: "hidden",
                        }}
                        className="mt-4"
                        onDrop={handleFileDrop}
                        onDragOver={handleDragOver}
                      >
                        {selectedFile ? (
                          <div
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              position: "relative",
                            }}
                          >
                            {selectedFile.type.startsWith("image/") ? (
                              <img
                                src={URL.createObjectURL(selectedFile)}
                                alt="Selected File Preview"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            ) : (
                              <div>
                                <p>Selected File: {selectedFile.name}</p>
                                <a
                                  href={URL.createObjectURL(selectedFile)}
                                  download
                                >
                                  Download Document
                                </a>
                              </div>
                            )}
                            <button
                              className="btn btn-danger"
                              onClick={handleRemoveFile}
                              style={{
                                position: "absolute",
                                top: "100px",
                                left: "70%",
                                transform: "translateX(-50%)",
                                width: "150px",
                                // height: "0px",
                                fontSize: "20px",
                              }}
                            >
                              Remove
                            </button>
                          </div>
                        ) : userDocument ? (
                          <div
                            className="text-center"
                            onClick={() => fileInputRef.current.click()}
                            onDrop={handleFileDrop}
                            onDragOver={handleDragOver}
                          >
                            <img
                              src={pdfupload}
                              alt=""
                              style={{ width: "30%" }}
                            />
                            <p>
                              User Document
                              <br />
                            </p>
                            <input
                              type="file"
                              ref={fileInputRef}
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                              accept=".jpg, .jpeg, .png, .pdf"
                            />
                            <label
                              className="btn btn-primary mt-3"
                              htmlFor="fileInput"
                              style={{
                                background: "none",
                                border: "2px solid #003B95",
                                transition: "background 0.3s",
                                textTransform: "none",
                                boxShadow: "none",
                                fontSize: "17px",
                                color: "#003B95",
                                cursor: "pointer",
                              }}
                              onMouseEnter={(e) =>
                                (e.target.style.background = "black")
                              }
                              onMouseLeave={(e) =>
                                (e.target.style.background = "none")
                              }
                            >
                              Select File
                            </label>
                          </div>
                        ) : (
                          <div
                            className="text-center"
                            onClick={() => fileInputRef.current.click()}
                            onDrop={handleFileDrop}
                            onDragOver={handleDragOver}
                          >
                            <img src={draganddrop} alt="" />
                            <p>
                              Select a file or drag and drop here
                              <br />
                              <span
                                style={{ fontSize: "11px", color: "#C7C9D9" }}
                              >
                                JPG, PNG or PDF, file size no more than 10MB
                              </span>
                            </p>
                            <input
                              type="file"
                              ref={fileInputRef}
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                              accept=".jpg, .jpeg, .png, .pdf"
                            />
                            <label
                              className="btn btn-primary mt-3"
                              htmlFor="fileInput"
                              style={{
                                background: "none",
                                border: "2px solid #003B95",
                                transition: "background 0.3s",
                                textTransform: "none",
                                boxShadow: "none",
                                fontSize: "17px",
                                color: "#003B95",
                                cursor: "pointer",
                              }}
                              onMouseEnter={(e) =>
                                (e.target.style.background = "white")
                              }
                              onMouseLeave={(e) =>
                                (e.target.style.background = "none")
                              }
                            >
                              Select File
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>

                  
                  <Col xs={12} md={12} lg={12}>
                    <button
                      type="submit"
                      className="btnMore mt-5"
                      style={{ width: "100%" }}
                    >
                      Upload image <FaPenAlt />
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
        <ToastContainer />

        {loading && <Spinner />}
      </div>
    </>
  );
};

export default UserProfileInfoEdit;
