import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./AccomBooking.css";
import { FaSignInAlt, FaUser } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PaymentOutlined } from "@mui/icons-material";
import { useGetUserID } from "../Hooks/useGetUserID";
import NavbarMenu from "./Layouts/Navbar";
import axios from "axios";
import { BaseURL } from "../config/variables";
import Spinner from "./Alert/Spinner/Spinner";
import { getAvailableRooms } from "../utils/hostActions/hostRequests";
import { handleError } from "../utils/notifications/toasts";
import { validateUserRoomBooking } from "../utils/userActions/userValidations";
import { toast } from "react-toastify";
import Toast from "./Alert/Toast";
import {
  calculateNights,
  calculatePercentage,
  convertDateStringArray,
  convertDateStringToArray,
  convertDatesToStrings,
  datediff,
  findMatchingDates,
  formatCalendarDate,
} from "../utils/utilities";
import Calender from "./UserAccount/Calender";

const UserComponent = ({ NoOfGuests }) => {
  const userIcons = Array.from({ length: NoOfGuests }, (_, index) => (
    <FaUser key={index} />
  ));

  return <div>{userIcons}</div>;
};

const AccomBooking = () => {
  const navigate = useNavigate();

  const userID = useGetUserID();
  const session = sessionStorage.getItem("session");
  const { id } = useParams();
  const [checkInDate, setCheckInDate] = useState("");
  const [checkInTime, setCheckInTime] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [checkOutTime, setCheckOutTime] = useState("12:00");
  const [bookedDates, setBookedDates] = useState([]);
  const [bookingFor, setBookingFor] = useState("");

  const [data, setData] = useState({
    propertyID: "",
    hostID: "",
    propertyName: "",
    propertyType: "",
    stateProvince: "",
    country: "",
    propertyPic1: "",
    propertyPic2: "",
    propertyPic3: "",
    propertyPic4: "",
    propertyPic5: "",
    hostName: "",
    aboutHost: "",
    aboutProperty: "",
    aboutNeighborhood: "",
    roomID: "",
    roomNo: "",
    roomType: "",
    guestNo: "",
    bathroomNo: "",
    roomBedType: "",
    price: "",
    discount: "",
    smoking: "",
    pet: "",
    children: "",
    checkInFrom: "",
    checkInUntil: "",
    checkOutFrom: "",
    checkOutUntil: "",
    bar: "",
    wifi: "",
    grill: "",
    airConditioner: "",
    flatScreenTv: "",
    sauna: "",
    kitchen: "",
    garden: "",
    pool: "",
    breakfast: "",
    parking: "",
    jacuzzi: "",
    heating: "",
    washingMachine: "",
  });

  const [numberOfNights, setNumberOfNights] = useState(0);
  const [fivePercentVAT, setFivePercentVAT] = useState(0);
  const [tenPercentCityTax, setTenPercentCityTax] = useState(0);

  const [loading, setLoading] = useState(false);
  const [proceedToBook, setProceedToBook] = useState(false);
  const [availableRooms, setAvailableRooms] = useState([]);
  const [standardRooms, setStandardRooms] = useState([]);
  const [deluxeRooms, setDeluxeRooms] = useState([]);
  const [standardSuits, setStandardSuits] = useState([]);
  const [familySuites, setFamilySuites] = useState([]);
  const [standardRoom, setStandardRoom] = useState({
    roomID: "",
    roomNo: "",
    price: 0,
    NoOfGuests: 0,
  });
  const [deluxeRoom, setDeluxeRoom] = useState({
    roomID: "",
    roomNo: "",
    price: 0,
    NoOfGuests: 0,
  });
  const [standardSuite, setStandardSuite] = useState({
    roomID: "",
    roomNo: "",
    price: 0,
    NoOfGuests: 0,
  });
  const [familySuite, setFamilySuite] = useState({
    roomID: "",
    roomNo: "",
    price: 0,
    NoOfGuests: 0,
  });

  // Function to filter rooms by type
  function filterRoomsByType(roomType) {
    return availableRooms?.filter((room) => room.roomType === roomType);
  }

  const setRooms = async () => {
    const standardRoom = filterRoomsByType("Standard Room");
    const deluxeRoom = filterRoomsByType("Deluxe Room");
    const standardSuit = filterRoomsByType("Standard Suite");
    const familySuite = filterRoomsByType("Family Suite");

    setStandardRooms(standardRoom);
    setDeluxeRooms(deluxeRoom);
    setStandardSuits(standardSuit);
    setFamilySuites(familySuite);
  };

  const setSelectedDeluxeRoom = async () => {
    if (deluxeRoom.roomID === "Select Room") {
      setDeluxeRoom({ ...deluxeRoom, roomID: "", price: 0, NoOfGuests: 0 });
    } else {
      const selectedRoom = availableRooms?.filter(
        (room) => room.roomID === Number(deluxeRoom.roomID)
      );
      setStandardRoom({ ...standardRoom, roomID: "", price: 0, NoOfGuests: 0 });
      setStandardSuite({
        ...standardSuite,
        roomID: "",
        price: 0,
        NoOfGuests: 0,
      });
      setFamilySuite({ ...familySuite, roomID: "", price: 0, NoOfGuests: 0 });
      setData({
        ...data,
        roomID: Number(deluxeRoom.roomID),
        price: Number(selectedRoom[0]?.price),
        guestNo: selectedRoom[0]?.guestNo,
        roomNo: selectedRoom[0]?.roomNo,
        roomType: selectedRoom[0]?.roomType,
        discount: selectedRoom[0]?.discount,
      });
      setDeluxeRoom({
        ...deluxeRoom,
        roomNo: selectedRoom[0]?.roomNo,
        price: selectedRoom[0]?.price,
        NoOfGuests: selectedRoom[0]?.guestNo,
      });
    }
  };
  const setSelectedStandardRoom = async () => {
    if (standardRoom.roomID === "Select Room") {
      setStandardRoom({ ...standardRoom, roomID: "", price: 0, NoOfGuests: 0 });
    } else {
      const selectedRoom = availableRooms?.filter(
        (room) => room.roomID === Number(standardRoom.roomID)
      );
      setDeluxeRoom({ ...deluxeRoom, roomID: "", price: 0, NoOfGuests: 0 });
      setStandardSuite({
        ...standardSuite,
        roomID: "",
        price: 0,
        NoOfGuests: 0,
      });
      setFamilySuite({ ...familySuite, roomID: "", price: 0, NoOfGuests: 0 });
      setStandardRoom({
        ...standardRoom,
        roomNo: selectedRoom[0]?.roomNo,
        price: selectedRoom[0]?.price,
        NoOfGuests: selectedRoom[0]?.guestNo,
      });
      setData({
        ...data,
        roomID: Number(standardRoom.roomID),
        price: Number(selectedRoom[0]?.price),
        guestNo: selectedRoom[0]?.guestNo,
        roomNo: selectedRoom[0]?.roomNo,
        roomType: selectedRoom[0]?.roomType,
        discount: selectedRoom[0]?.discount,
      });
    }
  };
  const setSelectedStandardSuite = async () => {
    if (standardSuite.roomID === "Select Room") {
      setStandardSuite({
        ...standardSuite,
        roomID: "",
        price: 0,
        NoOfGuests: 0,
      });
    } else {
      const selectedRoom = availableRooms?.filter(
        (room) => room.roomID === Number(standardSuite.roomID)
      );
      setDeluxeRoom({ ...deluxeRoom, roomID: "", price: 0, NoOfGuests: 0 });
      setFamilySuite({ ...familySuite, roomID: "", price: 0, NoOfGuests: 0 });
      setStandardRoom({ ...standardRoom, roomID: "", price: 0, NoOfGuests: 0 });
      setStandardSuite({
        ...standardSuite,
        roomNo: selectedRoom[0]?.roomNo,
        price: selectedRoom[0]?.price,
        NoOfGuests: selectedRoom[0]?.guestNo,
      });
      setData({
        ...data,
        roomID: Number(standardSuite.roomID),
        price: Number(selectedRoom[0]?.price),
        guestNo: selectedRoom[0]?.guestNo,
        roomNo: selectedRoom[0]?.roomNo,
        roomType: selectedRoom[0]?.roomType,
        discount: selectedRoom[0]?.discount,
      });
    }
  };
  const setSelectedFamilySuite = async () => {
    if (familySuite.roomID === "Select Room") {
      setFamilySuite({ ...familySuite, roomID: "", price: 0, NoOfGuests: 0 });
    } else {
      const selectedRoom = availableRooms?.filter(
        (room) => room.roomID === Number(familySuite.roomID)
      );
      setDeluxeRoom({ ...deluxeRoom, roomID: "", price: 0, NoOfGuests: 0 });
      setStandardSuite({
        ...standardSuite,
        roomID: "",
        price: 0,
        NoOfGuests: 0,
      });
      setStandardRoom({ ...standardRoom, roomID: "", price: 0, NoOfGuests: 0 });
      setFamilySuite({
        ...familySuite,
        roomNo: selectedRoom[0]?.roomNo,
        price: selectedRoom[0]?.price,
        NoOfGuests: selectedRoom[0]?.guestNo,
      });
      setData({
        ...data,
        roomID: Number(familySuite.roomID),
        price: Number(selectedRoom[0]?.price),
        guestNo: selectedRoom[0]?.guestNo,
        roomNo: selectedRoom[0]?.roomNo,
        roomType: selectedRoom[0]?.roomType,
        discount: selectedRoom[0]?.discount,
      });
    }
  };

  const fetchproperty = async () => {
    setLoading(true);
    axios
      .get(`${BaseURL}/propertydetails/` + id)
      .then((res) => {
        // console.log({res})
        setData({
          ...data,
          propertyID: res?.data?.Result[0].propertyID,
          propertyName: res?.data?.Result[0].propertyName,
          propertyType: res?.data?.Result[0].propertyType,
          stateProvince: res?.data?.Result[0].stateProvince,
          country: res?.data?.Result[0].country,
          propertyPic1: res?.data?.Result[0].propertyPic1,
          propertyPic2: res?.data?.Result[0].propertyPic2,
          propertyPic3: res?.data?.Result[0].propertyPic3,
          propertyPic4: res?.data?.Result[0].propertyPic4,
          propertyPic5: res?.data?.Result[0].propertyPic5,
          hostName: res?.data?.Result[0].hostName,
          hostID: res?.data?.Result[0].hostID,
          aboutHost: res?.data?.Result[0].aboutHost,
          aboutProperty: res?.data?.Result[0].aboutProperty,
          aboutNeighborhood: res?.data?.Result[0].aboutNeighborhood,
          // roomNo: res.data.Result[0].roomNo,
          // guestNo: res.data.Result[0].guestNo,
          bathroomNo: res.data.Result[0].bathroomNo,
          roomBedType: res.data.Result[0].roomBedType,
          // price: res.data.Result[0].price,
          // discount: res.data.Result[0].discount,
          smoking: res.data.Result[0].smoking,
          pet: res.data.Result[0].pet,
          children: res.data.Result[0].children,
          checkInFrom: res.data.Result[0].checkInFrom,
          checkInUntil: res.data.Result[0].checkInUntil,
          checkOutFrom: res.data.Result[0].checkOutFrom,
          checkOutUntil: res.data.Result[0].checkOutUntil,
          bar: res.data.Result[0].bar,
          wifi: res.data.Result[0].wifi,
          grill: res.data.Result[0].grill,
          airConditioner: res.data.Result[0].airConditioner,
          flatScreenTv: res.data.Result[0].flatScreenTv,
          fitnessCenter: res.data.Result[0].fitnessCenter,
          kitchen: res.data.Result[0].kitchen,
          garden: res.data.Result[0].garden,
          pool: res.data.Result[0].pool,
          breakfast: res.data.Result[0].breakfast,
          parking: res.data.Result[0].parking,
          jacuzzi: res.data.Result[0].jacuzzi,
          heating: res.data.Result[0].heating,
          washingMachine: res.data.Result[0].washingMachine,
        });
        setLoading(false);
      })

      .catch((err) => {
        setLoading(false);
        console.log(err);
      });

    const available = await getAvailableRooms(id, 1, 10000);

    if (available?.status) {
      setAvailableRooms(available.rooms);
      setLoading(false);

      // console.log({ rooms: available.rooms})
    } else {
      handleError("Could not get available rooms");
      setLoading(false);
    }
  };

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    fetchproperty();
  }, []);

  useEffect(() => {
    setRooms();
  }, [availableRooms]);

  useEffect(() => {
    setSelectedStandardRoom();
  }, [standardRoom?.roomID]);
  useEffect(() => {
    setSelectedDeluxeRoom();
  }, [deluxeRoom?.roomID]);
  useEffect(() => {
    setSelectedStandardSuite();
  }, [standardSuite?.roomID]);
  useEffect(() => {
    setSelectedFamilySuite();
  }, [familySuite?.roomID]);

  const generateDateArray = () => {
    const startDate = new Date(checkInDate);
    const endDate = new Date(checkOutDate);
    const dateArray = [];

    while (startDate <= endDate) {
      dateArray.push(new Date(startDate));
      startDate.setDate(startDate.getDate() + 1);
    }

    setBookedDates(dateArray);

    return dateArray;
  };

  // console.log({bookedDates})

  const checkRoomAvailability = async () => {
    setLoading(true);

    // const bookedStringDates = convertDatesToStrings(bookedDates)
    // console.log({bookedStringDates})

    const bookedRoom = availableRooms?.filter(
      (each, i) => each.roomID === data?.roomID
    );

    if (
      bookedRoom[0].bookedDates === null ||
      bookedRoom[0].bookedDates === "null"
    ) {
      setLoading(false);
      setProceedToBook(true);
      console.log("exited");
      return;
    }

    const roomBookedDatesString = JSON.parse(bookedRoom[0].bookedDates);
    // console.log(bookedRoom[0].bookedDates)

    const roomBookedDates = convertDateStringArray(roomBookedDatesString);
    // const roomBookedDates = convertDateStringToArray(roomBookedDatesString[1])

    // console.log({roomBookedDates})

    const matchingDates = findMatchingDates(bookedDates, roomBookedDates);

    if (matchingDates.length > 0) {
      // console.log("Matching dates found:");
      // console.log({matchingDates})

      let error = [];
      matchingDates.forEach(
        (matchingDate) =>
          error.push(
            matchingDate.toLocaleDateString(undefined, {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
          )
        // error.push(matchingDate.toLocaleString())
      );

      setLoading(false);
      return toast.error(() => (
        <Toast title="Error: These dates have been booked" body={error} />
      ));
    } else {
      setLoading(false);
      setProceedToBook(true);
      // console.log("No matching dates found.");
    }
  };

  useEffect(() => {
    if (data?.roomNo && checkInDate && checkOutDate) {
      checkRoomAvailability();
    }
  }, [data?.roomNo, checkInDate, checkOutDate]);

  useEffect(() => {
    if (checkInDate && checkOutDate) {
      generateDateArray();
    }
  }, [checkInDate, checkOutDate]);

  function onTimeChange(value) {
    let timeSplit = value.split(":"),
      hours,
      minutes,
      meridian;
    hours = timeSplit[0];
    minutes = timeSplit[1];
    if (hours > 12) {
      meridian = "PM";
      hours -= 12;
      return hours + ":" + minutes + " " + meridian;
    } else if (hours < 12) {
      meridian = "AM";
      if (hours == 0) {
        hours = 12;
      }
      return hours + ":" + minutes + " " + meridian;
    } else {
      meridian = "PM";
      return hours + ":" + minutes + " " + meridian;
    }
  }
  function formatDate(inputDate) {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
      // Months use 0 index.
      return (
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
      );
    }
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleSubmit = async (text) => {
    setLoading(true);
    if (!proceedToBook) {
      setLoading(false);
      return toast.error("Sorry, you can't book already booked dates!");
    }

    localStorage.setItem("paymentMethod", text);

    const result = validateUserRoomBooking({
      ...data,
      checkInDate,
      checkInTime,
      checkOutDate,
      checkOutTime,
      bookingFor,
    });

    if (result.errLength) {
      setLoading(false);

      return toast.error(() => <Toast title="Error" body={result.errMsg} />);
    }

    localStorage.setItem(
      "bookingDetails",
      JSON.stringify({
        ...data,
        checkInDate,
        checkInTime,
        checkOutDate,
        checkOutTime,
        bookingFor,
        bookedDates,
      })
    );

    navigate(
      `/pay-now/?itemID=${
        data.roomID
      }&itemType=${"accommodation"}&duration=${Number(
        datediff(checkInDate, checkOutDate)
      )}&unitPrice=${Number(
        data.price
      )}&checkInDate=${checkInDate}&checkInTime=${checkInTime}&checkOutDate=${checkOutDate}&checkOutTime=${checkOutTime}&bookedDates=${bookedDates}&hostID=${
        data.hostID
      }&amount=${
        // data.discount === "No"
        //   ? Number(data.price) * Number(datediff(checkInDate, checkOutDate)) +
        //     Number(data.price) *
        //       Number(datediff(checkInDate, checkOutDate)) *
        //       0.15
        //   : Number(data.price) * Number(datediff(checkInDate, checkOutDate)) +
        //     Number(data.price) *
        //       Number(datediff(checkInDate, checkOutDate)) *
        //       0.15 -
        //     Number(data.price) *
        //       Number(datediff(checkInDate, checkOutDate)) *
        //       0.2
       
          fivePercentVAT +
            tenPercentCityTax +
            (Number(data.price) * numberOfNights)
       
      }&bookingFor=${bookingFor}`
    );

    setLoading(false)
  };

  // console.log({ checkInDate });

  useEffect(() => {
    if (checkInDate && checkInTime && checkOutDate) {
      setNumberOfNights(
        calculateNights(checkInDate, checkInTime, checkOutDate)
      ); 
    }
  }, [checkInDate, checkInTime, checkOutDate]);

  useEffect(() => {
    if (data.price) {
      setFivePercentVAT(calculatePercentage(Number(Number(data.price) * numberOfNights), 5));
      setTenPercentCityTax(calculatePercentage(Number(Number(data.price) * numberOfNights), 10));
    }
  }, [data.price, numberOfNights]);

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />

      <div className="AccomBooking">
        <Container className="container">
          <Row>
            <Col xs={12} md={12} lg={12}>
              <Row>
                <Col xs={12} md={4} lg={4}>
                  <div className="circle-box">
                    <p>Step 1</p>
                    <h5>Select A Property</h5>
                  </div>
                </Col>
                <Col xs={12} md={8} lg={8}>
                  <Row>
                    <Col xs={12} md={12}>
                      <h2>Your Selection</h2>
                      <p>Booking Information</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <img
                        src={data.propertyPic1}
                        alt={data.propertyName}
                        className="main-img img-fluid"
                      />
                    </Col>
                    <Col xs={12} md={6} className="booking-details">
                      <Row>
                        <Col xs={12} md={12}>
                          <h2 style={{ fontSize: "25px" }}>
                            {data.propertyName}
                          </h2>
                          <p>
                            {data.stateProvince}, <b>{data.country}</b>
                          </p>
                        </Col>

                        <Col xs={12} md={6}>
                          <span>
                            <b>Room Type: </b>
                          </span>
                        </Col>
                        <Col xs={12} md={6}>
                          <p>{data.roomType}</p>
                        </Col>
                        <Col xs={12} md={6}>
                          <span>
                            <b>Room Number: </b>
                          </span>
                        </Col>
                        <Col xs={12} md={6}>
                          <p>{data.roomNo}</p>
                        </Col>
                        <Col xs={12} md={6}>
                          <span>
                            <b>Guests Allowed: </b>
                          </span>
                        </Col>
                        <Col xs={12} md={6}>
                          <p>{data.guestNo} Adults</p>
                        </Col>
                        <Col xs={12} md={6}>
                          <span>
                            <b>Price: </b>
                          </span>
                        </Col>
                        <Col xs={12} md={6}>
                          <p>₦{numberWithCommas(data.price)}/night</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <div className="circle-box">
                    <p>Step 2</p>
                    <h5>Visitor Details</h5>
                  </div>
                </Col>

                <Col xs={12} md={8} lg={8}>
                  <Row>
                    <Col xs={12} md={12}>
                      <h2>Your Details</h2>
                      <p>Personal Information</p>
                    </Col>
                    <Col xs={12} md={12}>
                      <div className="login-center mb-3">
                        {!session || session === "" || session === null || session === undefined ? (
                          <Link
                            className="btnMore mb-5"
                            style={{
                              textDecoration: "none",
                              padding: "15px",
                              display: "block",
                            }}
                            to={`/login/?q=accom-booking&id=${id}`}
                          >
                            Sign In to continue! <FaSignInAlt />
                          </Link>
                        ) : (
                          <div className="alert alert-info">
                            You're logged In!
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={12}>
                      <h4>Booking Details:</h4>
                      <p>fill out your arrival date and time below</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <span>
                        <b>Check In: </b>
                      </span>
                    </Col>
                    <Col xs={12} md={6}>
                      <span>
                        <b>{checkInDate && formatCalendarDate(checkInDate)}</b>
                      </span>
                      &nbsp;
                      {checkInTime && "(" + onTimeChange(checkInTime) + ")"}
                    </Col>
                    <Col xs={12} md={6}>
                      <span>
                        <b>Check Out: </b>
                      </span>
                    </Col>
                    <Col xs={12} md={6}>
                      <span>
                        <b>{checkOutDate && formatCalendarDate(checkOutDate)}</b>
                      </span>
                      &nbsp;
                      {checkOutTime && "(" + onTimeChange(checkOutTime) + ")"}
                    </Col>
                    <Col xs={12} md={6}>
                      <span>
                        <b>Total length of stay: </b>
                      </span>
                    </Col>
                    <Col xs={12} md={6}>
                      <p>
                        {/* {checkOutDate &&
                          datediff(checkInDate, checkOutDate) + " night(s)"} */}
                        {numberOfNights < 1 ? "" : numberOfNights}{" "}
                        {numberOfNights > 0 && numberOfNights > 1
                          ? "Nights"
                          : numberOfNights > 0 && numberOfNights === 1
                          ? "Night"
                          : ""}
                      </p>
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                      <Calender />
                      <label htmlFor="checkInDate">Check In Date:</label>
                      {/* <label htmlFor="checkInDate"  type="button"  data-bs-toggle="modal" data-bs-target="#calenderModal">Check In Date:</label> */}
                      {/* <input
                        type="button"  data-bs-toggle="modal" data-bs-target="#calenderModal"
                        id="checkInDate"
                        placeholder="mm/dd/yyyy"
                        className="form-control"
                        onChange={(e) => setCheckInDate(e.target.value)}
                      /> */}
                      <input
                        type="date"
                        id="checkInDate"
                        placeholder="mm/dd/yyyy"
                        className="form-control"
                        onChange={(e) => setCheckInDate(e.target.value)}
                      />
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                      <label htmlFor="checkInTime">Check In Time:</label>
                      <input
                        type="time"
                        id="checkInTime"
                        placeholder="10:00AM"
                        className="form-control"
                        onChange={(e) => setCheckInTime(e.target.value)}
                      />
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                      <label htmlFor="checkOutDate">Check Out Date:</label>
                      <input
                        type="date"
                        id="checkOutDate"
                        placeholder="mm/dd/yyyy"
                        className="form-control"
                        onChange={(e) => setCheckOutDate(e.target.value)}
                      />
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                      <label htmlFor="checkOutTime">Check Out Time:</label>
                      {/* <input
                        type="time"
                        id="checkOutTime"
                        placeholder="10:00AM"
                        className="form-control"
                        onChange={(e) => setCheckOutTime(e.target.value)}
                      /> */}

                      <input
                        type="time"
                        id="checkOutTime"
                        className="form-control"
                        value="12:00"
                        readOnly
                      />
                    </Col>

                    <Col xs={12} md={12} lg={12} className="mb-5">
                      <label htmlFor="booking">
                        <b>For yourself/Someone else?</b>
                      </label>
                      <input
                        type="text"
                        id=""
                        placeholder="name of who you are booking for"
                        className="form-control"
                        onChange={(e) => setBookingFor(e.target.value)}
                      />
                    </Col>

                    {/* Rooms Table */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "30px",
                        marginBottom: "20px",
                      }}
                    >
                      <table
                        className="table table-bordered border-primary"
                        style={{ width: "98%" }}
                      >
                        <thead>
                          <tr className="">
                            <th>Room Type</th>
                            <th className="">Number of guests</th>
                            <th className="">Today's Price</th>
                            <th className="">Selected Rooms</th>
                          </tr>
                        </thead>

                        <tbody>
                          {standardRooms?.length > 0 && (
                            <tr>
                              <td>
                                <h5>Standard Room</h5>
                              </td>
                              <td>
                                {standardRoom.NoOfGuests > 0 ? (
                                  <UserComponent
                                    NoOfGuests={standardRoom.NoOfGuests}
                                  />
                                ) : (
                                  <FaUser />
                                )}
                              </td>
                              <td>
                                <h5>
                                  ₦{" "}
                                  {standardRoom.price > 100
                                    ? Number(
                                        standardRoom.price
                                      ).toLocaleString()
                                    : standardRoom.price}
                                </h5>
                                <span>Includes taxes and fees</span>
                              </td>
                              <td>
                                <select
                                  className="form-select"
                                  onChange={(e) =>
                                    setStandardRoom({
                                      ...standardRoom,
                                      roomID: e.target.value,
                                    })
                                  }
                                  value={standardRoom.roomID || 0}
                                >
                                  <option>Select Room</option>
                                  {standardRooms?.map((each, i) => (
                                    <option value={each?.roomID} key={i}>
                                      {each?.roomNo}
                                    </option>
                                  ))}
                                </select>
                              </td>
                            </tr>
                          )}

                          {deluxeRooms?.length > 0 && (
                            <tr>
                              <td>
                                <h5>Deluxe Room</h5>
                              </td>
                              <td>
                                {deluxeRoom.NoOfGuests > 0 ? (
                                  <UserComponent
                                    NoOfGuests={deluxeRoom.NoOfGuests}
                                  />
                                ) : (
                                  <FaUser />
                                )}
                              </td>
                              <td>
                                <h5>
                                  ₦{" "}
                                  {deluxeRoom.price > 100
                                    ? Number(deluxeRoom.price).toLocaleString()
                                    : deluxeRoom.price}
                                </h5>
                                <span>Includes taxes and fees</span>
                              </td>
                              <td>
                                <select
                                  className="form-select"
                                  onChange={(e) =>
                                    setDeluxeRoom({
                                      ...deluxeRoom,
                                      roomID: e.target.value,
                                    })
                                  }
                                  value={deluxeRoom.roomID || 0}
                                >
                                  <option>Select Room</option>
                                  {deluxeRooms?.map((each, i) => (
                                    <option value={each?.roomID} key={i}>
                                      {each?.roomNo}
                                    </option>
                                  ))}
                                </select>
                              </td>
                            </tr>
                          )}

                          {standardSuits?.length > 0 && (
                            <tr>
                              <td>
                                <h5>Standard Suite</h5>
                              </td>
                              <td>
                                {standardSuite.NoOfGuests > 0 ? (
                                  <UserComponent
                                    NoOfGuests={standardSuite.NoOfGuests}
                                  />
                                ) : (
                                  <FaUser />
                                )}
                              </td>
                              <td>
                                <h5>
                                  ₦{" "}
                                  {standardSuite.price > 100
                                    ? Number(
                                        standardSuite.price
                                      ).toLocaleString()
                                    : standardSuite.price}
                                </h5>
                                <span>Includes taxes and fees</span>
                              </td>
                              <td>
                                <select
                                  className="form-select"
                                  onChange={(e) =>
                                    setStandardSuite({
                                      ...standardSuite,
                                      roomID: e.target.value,
                                    })
                                  }
                                  value={standardSuite.roomID || 0}
                                >
                                  <option>Select Room</option>
                                  {standardSuits?.map((each, i) => (
                                    <option value={each?.roomID} key={i}>
                                      {each?.roomNo}
                                    </option>
                                  ))}
                                </select>
                              </td>
                            </tr>
                          )}

                          {familySuites?.length > 0 && (
                            <tr>
                              <td>
                                <h5>Family Suite</h5>
                              </td>
                              <td>
                                {familySuite.NoOfGuests > 0 ? (
                                  <UserComponent
                                    NoOfGuests={familySuite.NoOfGuests}
                                  />
                                ) : (
                                  <FaUser />
                                )}
                              </td>
                              <td>
                                <h5>
                                  ₦{" "}
                                  {familySuite.price > 100
                                    ? Number(familySuite.price).toLocaleString()
                                    : familySuite.price}
                                </h5>
                                <span>Includes taxes and fees</span>
                              </td>
                              <td>
                                <select
                                  className="form-select"
                                  onChange={(e) =>
                                    setFamilySuite({
                                      ...familySuite,
                                      roomID: e.target.value,
                                    })
                                  }
                                  value={familySuite.roomID || 0}
                                >
                                  <option>Select Room</option>
                                  {familySuites?.map((each, i) => (
                                    <option value={each?.roomID} key={i}>
                                      {each?.roomNo}
                                    </option>
                                  ))}
                                </select>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Row>
                </Col>

                {!session ? (
                  <div className="alert alert-warning">
                    Login to finish reservation
                  </div>
                ) : (
                  <>
                    <Col xs={12} md={4} lg={4}>
                      <div className="circle-box">
                        <p>Step 3</p>
                        <h5>Finish Reservation</h5>
                      </div>
                    </Col>

                    <Col xs={12} md={8} lg={8}>
                      <Row>
                        <form className="booking-form-1">
                          <Row>
                            <Col xs={12} md={6}>
                              <h4>Your price summary:</h4>
                              {data.price && numberOfNights && (
                                <p>
                                  Original price: ₦{Number(data.price).toLocaleString() ?? 0}/
                                  night
                                </p>
                              )}
                              <p>
                                {/* {data.discount &&
                                  `This property is offering a 20% discount: - ₦ +
                                    ${
                                      (
                                        Number(data.price) * 0.2
                                      ).toLocaleString() ?? 0
                                    }`} */}
                              </p>
                            </Col>
                            <Col xs={12} md={6}>
                              {checkOutDate && (
                                <>
                                  <h4>Price information </h4>
                                  <p>
                                    Includes ₦{Number(fivePercentVAT + tenPercentCityTax).toLocaleString() ?? 0}
                                    {/* {numberWithCommas(
                                      data.price *
                                        0.15 *
                                        Number(
                                          datediff(checkInDate, checkOutDate)
                                        )
                                    )} */}
                                    {" "}
                                    in taxes and fees:
                                    <br />5 % VAT: ₦{fivePercentVAT.toLocaleString() ?? 0}
                                    {/* {numberWithCommas(
                                      data.price *
                                        0.05 *
                                        Number(
                                          datediff(checkInDate, checkOutDate)
                                        )
                                    )} */}
                                    <br />
                                    10 % City tax : ₦{tenPercentCityTax.toLocaleString() ?? 0}
                                    {/* {numberWithCommas(
                                      data.price *
                                        0.1 *
                                        Number(
                                          datediff(checkInDate, checkOutDate)
                                        )
                                    )} */}
                                  </p>
                                </>
                              )}
                            </Col>
                            <Col xs={12} md={6}>
                              <p className="highlight">
                                This reservation fee is 100% refundable if
                                you’re not satisfied with the property rented,
                                the owner will not get your money.
                              </p>
                            </Col>
                            <Col xs={12} md={6}>
                              {checkOutDate && (
                                <div className="paybox">
                                  {data.price &&
                                    fivePercentVAT &&
                                    tenPercentCityTax && (
                                      <h4 className="price">
                                        {" "}
                                        ₦
                                        {/* {data.discount === "No"
                                          ? "₦" +
                                            numberWithCommas(
                                              Number(data.price) *
                                                Number(
                                                  datediff(
                                                    checkInDate,
                                                    checkOutDate
                                                  )
                                                ) +
                                                Number(data.price) *
                                                  Number(
                                                    datediff(
                                                      checkInDate,
                                                      checkOutDate
                                                    )
                                                  ) *
                                                  0.15
                                            )
                                          : "₦" +
                                            numberWithCommas(
                                              Number(data.price) *
                                                Number(
                                                  datediff(
                                                    checkInDate,
                                                    checkOutDate
                                                  )
                                                ) +
                                                Number(data.price) *
                                                  Number(
                                                    datediff(
                                                      checkInDate,
                                                      checkOutDate
                                                    )
                                                  ) *
                                                  0.15 -
                                                Number(data.price) *
                                                  Number(
                                                    datediff(
                                                      checkInDate,
                                                      checkOutDate
                                                    )
                                                  ) *
                                                  0.2
                                            )} */}
                                        {Number(
                                          fivePercentVAT +
                                            tenPercentCityTax +
                                            (Number(data.price) * numberOfNights)
                                        ).toLocaleString() ?? 0}
                                      </h4>
                                    )}
                                  {/* <Link
                                    to={`/pay-now/?itemID=${id}&itemType=${"accommodation"}&duration=${Number(
                                      datediff(checkInDate, checkOutDate)
                                    )}&unitPrice=${Number(
                                      data.price
                                    )}&checkInDate=${checkInDate}&checkInTime=${checkInTime}&checkOutDate=${checkOutDate}&checkOutTime=${checkOutTime}&hostID=${
                                      data.hostID
                                    }&amount=${
                                      data.discount === "No"
                                        ? Number(data.price) *
                                            Number(
                                              datediff(
                                                checkInDate,
                                                checkOutDate
                                              )
                                            ) +
                                          Number(data.price) *
                                            Number(
                                              datediff(
                                                checkInDate,
                                                checkOutDate
                                              )
                                            ) *
                                            0.15
                                        : Number(data.price) *
                                            Number(
                                              datediff(
                                                checkInDate,
                                                checkOutDate
                                              )
                                            ) +
                                          Number(data.price) *
                                            Number(
                                              datediff(
                                                checkInDate,
                                                checkOutDate
                                              )
                                            ) *
                                            0.15 -
                                          Number(data.price) *
                                            Number(
                                              datediff(
                                                checkInDate,
                                                checkOutDate
                                              )
                                            ) *
                                            0.2
                                    }&bookingFor=${bookingFor}`}
                                  > */}
                                  <div
                                    className="btnMore"
                                    onClick={() => handleSubmit("paystack")}
                                  >
                                    Pay with Paystack <PaymentOutlined />
                                  </div>
                                  <div
                                    className="btnMore mt-3"
                                    onClick={() => handleSubmit("wallet")}
                                  >
                                    Pay with Wallet <PaymentOutlined />
                                  </div>
                                  {/* </Link> */}
                                </div>
                              )}
                            </Col>
                          </Row>
                        </form>
                      </Row>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </Container>

        {loading && <Spinner />}
      </div>
    </>
  );
};

export default AccomBooking;
