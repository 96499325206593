import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HostNavbar from "./HostNavbar";
import { FaArrowRight, FaPenAlt } from "react-icons/fa";
import "./ListService.css";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BaseURL } from "../../config/variables";

const ListService = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const currentHostID = window.localStorage.getItem("hostID");

  const [data, setData] = useState({
    hostID: currentHostID,
  });

  useEffect(() => {
    // scroll to top on page load

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    axios
      .get(`${BaseURL}/fetchservicedetails/${id}`)
      .then((res) => {
        if (res.data.status === "Success") {
          setData({
            ...data,
            serviceName: res.data.Result[0].serviceName,
            serviceType: res.data.Result[0].serviceType,
            city: res.data.Result[0].city,
            zipCode: res.data.Result[0].zipCode,
            stateProvince: res.data.Result[0].stateProvince,
            country: res.data.Result[0].country,
            streetAddress: res.data.Result[0].streetAddress,
            aboutService: res.data.Result[0].aboutService,
            hostName: res.data.Result[0].hostName,
            aboutHost: res.data.Result[0].aboutHost,
            phoneNumber: res.data.Result[0].phoneNumber,
          });
          console.log(res.data.Result[0]);
        } else {
          console.log("No service details found");
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(data);
    axios
      .put(`${BaseURL}/${id}`, data)
      .then((res) => {
        if (res.data.success) {
          const { success, message } = res.data;
          console.log(res.data);
          if (success) {
            handleSuccess(message);
            setTimeout(() => {
              navigate("/list-service");
            }, 1000);
          } else {
            handleError(message);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <ToastContainer />

      <div className="ListService">
        <Container className="container">
          <Row>
            <Col xs={12} md={3} lg={3}>
              <HostNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <form className="form-panel" onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <h2>Edit Your Service Listing</h2>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <label htmlFor="serviceName">Service Name:</label>
                    <input
                      type="text"
                      name="serviceName"
                      id="serviceName"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, serviceName: e.target.value })
                      }
                      value={data.serviceName || ""}
                    />
                    <label htmlFor="serviceType">Type of Service: </label>
                    <select
                      name="serviceType"
                      id="serviceType"
                      className="form-select selectt"
                      onChange={(e) =>
                        setData({ ...data, serviceType: e.target.value })
                      }
                      value={data.serviceType || ""}
                    >
                      <option value={""}>Choose Service type</option>
                      <option value={"SPA"}>SPA</option>
                      <option value={"Photography"}>Photography</option>
                      <option value={"Gym Trainer"}>Gym Trainer</option>
                      <option value={"Private Tutor"}>Private Tutor</option>
                      <option value={"Life Coach"}>Life Coach</option>
                      <option value={"Assistant"}>Assistant</option>
                      <option value={"Companion"}>Companion</option>
                      <option value={"Other"}>Other Attractions</option>
                    </select>
                    <label htmlFor="country">Country</label>
                    <input
                      type="text"
                      name="country"
                      id="country"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, country: e.target.value })
                      }
                      value={data.country || ""}
                    />

                    <label htmlFor="stateProvince">State/Province</label>
                    <input
                      type="text"
                      name="stateProvince"
                      id="stateProvince"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, stateProvince: e.target.value })
                      }
                      value={data.stateProvince || ""}
                    />

                    <label htmlFor="city">City</label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, city: e.target.value })
                      }
                      value={data.city || ""}
                    />

                    <label htmlFor="zipCode">Zip Code</label>
                    <input
                      type="text"
                      name="zipCode"
                      id="zipCode"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, zipCode: e.target.value })
                      }
                      value={data.zipCode || ""}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <label htmlFor="streetAddress">Street Address</label>
                    <input
                      type="text"
                      name="streetAddress"
                      id="streetAddress"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, streetAddress: e.target.value })
                      }
                      value={data.streetAddress || ""}
                    />
                    <label htmlFor="aboutService">Describe Your Service</label>{" "}
                    <br />
                    <small>
                      <b>What should visitors expect?</b>
                    </small>
                    <textarea
                      name="aboutService"
                      id="aboutService"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, aboutService: e.target.value })
                      }
                      value={data.aboutService || ""}
                    ></textarea>
                    <label htmlFor="hostName">Host Name</label>
                    <input
                      type="text"
                      name="hostName"
                      id="hostName"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, hostName: e.target.value })
                      }
                      value={data.hostName || ""}
                    />
                    <label htmlFor="aboutHost">About Host</label>
                    <textarea
                      name="aboutHost"
                      id="aboutHost"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, aboutHost: e.target.value })
                      }
                      value={data.aboutHost || ""}
                    ></textarea>
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, phoneNumber: e.target.value })
                      }
                      value={data.phoneNumber || ""}
                    />
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <button type="submit" className="btnOutline mt-5">
                      Edit Service <FaPenAlt />
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ListService;
