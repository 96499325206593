//import logo from './logo.svg';
import './App.css';
import { ToastContainer } from "react-toastify"; 
import Homepage from './Components/Homepage';
import Footer from './Components/Layouts/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './Components/Auth/Login';
import Register from './Components/Auth/Register';
import { BrowserRouter, Routes, Route } from "react-router-dom"; 
import Accommodations from './Components/Accommodations';
import AccomPreview from './Components/AccomPreview';
import AccomBooking from './Components/AccomBooking';
import PaymentPage from './Components/PaymentPage';
import BookingSuccess from './Components/BookingSuccess';
import ServicesPage from './Components/ServicesPage';
import ServicePreview from './Components/ServicePreview';
import ServiceBooking from './Components/ServiceBooking';
import ToursPage from './Components/Tours/ToursPage';
import TourPreview from './Components/Tours/TourPreview';
import TourBooking from './Components/Tours/TourBooking';
import UserDashboard from './Components/UserAccount/UserDashboard';
import Ewallet from './Components/UserAccount/Ewallet';
import MyBookings from './Components/UserAccount/MyBookings';
import Refunds from './Components/UserAccount/Refunds';
import ApplyForRefund from './Components/UserAccount/ApplyForRefund';
import RefundThanks from './Components/UserAccount/RefundThanks';
import CarListing from './Components/Cars/CarListing';
import CarPreview from './Components/Cars/CarPreview';
import CarBooking from './Components/Cars/CarBooking';
import FlightsPage from './Components/Flights/FlightsPage';
import FlightsListing from './Components/Flights/FlightsListing';
import FlightBooking from './Components/Flights/FlightBooking';
import LandingPage from './Components/Host/LandingPage';
import AOS from 'aos';
import "aos/dist/aos.css"; 
import { useEffect } from 'react';
import HostLogin from './Components/Host/HostLogin';
import HostReg1 from './Components/Host/HostReg1';
import HostReg2 from './Components/Host/HostReg2';
import HostDashboard from './Components/Host/HostDashboard';
import ListAccommodation from './Components/Host/ListAccommodation';
import ListAccommodation01 from './Components/Host/ListAccommodation01';
import ListAccommodation01Edit from './Components/Host/ListAccommodation01Edit';
import ListAccommodation02 from './Components/Host/ListAccommodation02';
import ListAccommodation02Edit from './Components/Host/ListAccommodation02Edit';
import ListAccommodation03 from './Components/Host/ListAccommodation03';
import ListAccommodation03Edit from './Components/Host/ListAccommodation03Edit';
import ListAccommodation04 from './Components/Host/ListAccommodation04';
import MyListings from './Components/Host/MyListings';
import ListVehicle from './Components/Host/ListVehicle';
import ListVehicle01 from './Components/Host/ListVehicle01';
import ListVehicle01Edit from './Components/Host/ListVehicle01Edit';
import ListVehicle02 from './Components/Host/ListVehicle02';
import ListVehicle02Edit from './Components/Host/ListVehicle02Edit';
import ListVehicle03 from './Components/Host/ListVehicle03';
import ListTour from './Components/Host/ListTour';
import ListTour01 from './Components/Host/ListTour01';
import ListTour01Edit from './Components/Host/ListTour01Edit';
import ListTour02 from './Components/Host/ListTour02';
import ListTour02Edit from './Components/Host/ListTour02Edit';
import ListTour03 from './Components/Host/ListTour03';
import ListService from './Components/Host/ListService';
import ListService01 from './Components/Host/ListService01';
import ListService01Edit from './Components/Host/ListService01Edit';
import ListService02 from './Components/Host/ListService02';
import ListService02Edit from './Components/Host/ListService02Edit';
import ListService03 from './Components/Host/ListService03';
import ListFlight from './Components/Host/ListFlight';
import ListFlight01 from './Components/Host/ListFlight01';
import ListFlight01Edit from './Components/Host/ListFlight01Edit';
import ListFlight02 from './Components/Host/ListFlight02';
import Notifications from './Components/Host/Notifications';
import HostProfile from './Components/Host/HostProfile';
import HostProfileInfoEdit from './Components/Host/HostProfileInfoEdit';
import HostProfileChangePass from './Components/Host/HostProfileChangePass';
import HostForgotPass from './Components/Host/HostForgotPass';
import HostResetPass from './Components/Host/HostResetPass';
import UserProfile from './Components/UserAccount/UserProfile';
import UserProfileInfoEdit from './Components/UserAccount/UserProfileInfoEdit';
import UserProfileChangePass from './Components/UserAccount/UserProfileChangePass';
import UserForgotPass from './Components/Auth/UserForgotPass';
import UserResetPass from './Components/Auth/UserResetPass';
import BookedAccom from './Components/UserAccount/BookedAccom';
import BookedVehicle from './Components/UserAccount/BookedVehicle';
import BookedService from './Components/UserAccount/BookedService';
import BookedTour from './Components/UserAccount/BookedTour';
import MyListingConfirmation from './Components/Host/MyListingConfirmation';
import BookedFlight from './Components/UserAccount/BookedFlight';


import OwnerLogin from './Components/Owner/OwnerLogin';
import OwnerReg1 from './Components/Owner/OwnerReg1'; 
import OwnerDashboard from './Components/Owner/OwnerDashboard';
import OwnerProfile from './Components/Owner/OwnerProfile';
import OwnerProfileInfoEdit from './Components/Owner/OwnerProfileInfoEdit';
import OwnerProfileChangePass from './Components/Owner/OwnerProfileChangePass';
import OwnerForgotPass from './Components/Owner/OwnerForgotPass';
import OwnerResetPass from './Components/Owner/OwnerResetPass';
import ManageHosts from './Components/Owner/ManageHosts';
import HostPreview from './Components/Owner/HostPreview';
import HostConfirm from './Components/Owner/HostConfirm';
import UserFeedbacksPage from './Components/Owner/UserFeedbacks';
import ReportIssuesPage from './Components/UserAccount/ReportIssuses';
import ManageUserPage from './Components/Owner/manageUsers';
import GatewayPaymentsPage from './Components/Owner/GatewayPayments';
import ManageRefunds from './Components/Owner/ManageRefunds';
import AccountHistory from './Components/Owner/AccountHistory';
import PayoutHistory from './Components/Owner/PayoutHistory';
import KYCPage from './Components/UserAccount/UpdateKYC';
import HostKYCPage from './Components/Host/UpdateHostKYC';
import UserPreview from './Components/Owner/UserPreview';
import PrivacyPolicyPage from './Components/PrivacyPolicy';
import HostFeedbackPage from './Components/Host/HostFeedback';
import HostReportsPage from './Components/Owner/HostReports';
import HostAccountHistoryPage from './Components/Host/HostAccountHistory';

function App() {

  

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className='wrap'>  
    <BrowserRouter>

      <Routes>
   
            <Route exact path="/" element={<Homepage />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />

            <Route exact path="/host-account" element={<LandingPage />} />

            <Route exact path="/host-login" element={<HostLogin />} />

            <Route exact path="/host-reg-1" element={<HostReg1 />} />

            <Route exact path="/host-reg-2" element={<HostReg2 />} />

            <Route exact path="/host-dashboard" element={<HostDashboard />} />

            <Route exact path="/host-account-history" element={<HostAccountHistoryPage />} />

            <Route exact path="/my-listings" element={<MyListings />} />

            <Route exact path="/my-listing-confirmation" element={<MyListingConfirmation />} />

            <Route exact path="/list-accommodation" element={<ListAccommodation />} />

            <Route exact path="/list-accommodation-01" element={<ListAccommodation01 />} />

            <Route exact path="/list-accommodation-01-edit/:id" element={<ListAccommodation01Edit />} />

            <Route exact path="/list-accommodation-02" element={<ListAccommodation02 />} />

            <Route exact path="/list-accommodation-02-edit/:id" element={<ListAccommodation02Edit />} />

            <Route exact path="/list-accommodation-03" element={<ListAccommodation03 />} />

            <Route exact path="/list-accommodation-03-edit/:id" element={<ListAccommodation03Edit />} />

            <Route exact path="/list-accommodation-04" element={<ListAccommodation04 />} />
            
            <Route exact path="/list-vehicle" element={<ListVehicle />} />
            
            <Route exact path="/list-vehicle-01" element={<ListVehicle01 />} />
            
            <Route exact path="/list-vehicle-01-edit/:id" element={<ListVehicle01Edit />} />

            <Route exact path="/list-vehicle-02" element={<ListVehicle02 />} />
            
            <Route exact path="/list-vehicle-02-edit/:id" element={<ListVehicle02Edit />} />

            <Route exact path="/list-vehicle-03" element={<ListVehicle03 />} />
            
            <Route exact path="/list-tour" element={<ListTour />} />
            
            <Route exact path="/list-tour-01" element={<ListTour01 />} />

            <Route exact path="/list-tour-01-edit/:id" element={<ListTour01Edit />} />

            <Route exact path="/list-tour-02" element={<ListTour02 />} />

            <Route exact path="/list-tour-02-edit/:id" element={<ListTour02Edit />} />
            
            <Route exact path="/list-tour-03" element={<ListTour03 />} />
            
            <Route exact path="/list-service" element={<ListService />} />
            
            <Route exact path="/list-service-01" element={<ListService01 />} />

            <Route exact path="/list-service-01-edit/:id" element={<ListService01Edit />} />

            <Route exact path="/list-service-02" element={<ListService02 />} />
            
            <Route exact path="/list-service-02-edit/:id" element={<ListService02Edit />} />

            <Route exact path="/list-service-03" element={<ListService03 />} />
            
            <Route exact path="/list-flight" element={<ListFlight />} />
            
            <Route exact path="/list-flight-01" element={<ListFlight01 />} />
            
            <Route exact path="/list-flight-01-edit/:id" element={<ListFlight01Edit />} /> 

            <Route exact path="/list-flight-02" element={<ListFlight02 />} />
            
            <Route exact path="/notifications" element={<Notifications />} />

            <Route exact path="/host-profile" element={<HostProfile />} />
  
            <Route exact path="/host-profile-info-edit" element={<HostProfileInfoEdit />} />
            
            <Route exact path="/host-profile-change-pass" element={<HostProfileChangePass />} />

            <Route exact path="/host-forgot-pass" element={<HostForgotPass />} />

            <Route exact path="/host-reset-password" element={<HostResetPass />} />

            <Route path="/host-update-kyc" element={<HostKYCPage />} />

            <Route path="/host-feedback" element={<HostFeedbackPage />} />



            <Route path="/login" element={<Login />} />
            <Route path="/login/:id" element={<Login />} />
            <Route path="/register" element={<Register />} /> 

            <Route path="/accommodations" element={<Accommodations />} />
            <Route path="/accom-preview/:id" element={<AccomPreview />} /> 
            <Route path="/accom-booking/:id" element={<AccomBooking />} />

            <Route path="/flights" element={<FlightsPage />} />
            <Route path="/flight-search" element={<FlightsListing />} />
            <Route path="/flight-booking/:id" element={<FlightBooking />} />

            <Route path="/services" element={<ServicesPage />} />
            <Route path="/service-preview/:id" element={<ServicePreview />} />
            <Route path="/service-booking/:id" element={<ServiceBooking />} />

            <Route path="/tours" element={<ToursPage />} />
            <Route path="/tour-preview/:id" element={<TourPreview />} />
            <Route path="/tour-booking/:id" element={<TourBooking />} />

            <Route path="/cars" element={<CarListing />} />
            <Route path="/car-preview/:id" element={<CarPreview />} />
            <Route path="/car-booking/:id" element={<CarBooking />} />

            <Route path="/pay-now" element={<PaymentPage />} />
            <Route path="/booking-success" element={<BookingSuccess />} />

            <Route path="/user-dashboard" element={<UserDashboard />} />
            <Route path="/ewallet" element={<Ewallet />} />
            <Route path="/my-bookings" element={<MyBookings />} />
            <Route path="/booked-accom/:id" element={<BookedAccom />} />
            <Route path="/booked-flight/:id" element={<BookedFlight />} />
            <Route path="/booked-vehicle/:id" element={<BookedVehicle />} />
            <Route path="/booked-tour/:id" element={<BookedTour />} />
            <Route path="/booked-service/:id" element={<BookedService />} />
            <Route path="/request-refund" element={<Refunds />} />
            <Route path="/apply-refund" element={<ApplyForRefund />} />
            <Route path="/refund-thanks" element={<RefundThanks />} />
            <Route path="/user-profile" element={<UserProfile />} />
            <Route path="/user-profile-info-edit" element={<UserProfileInfoEdit />} />
            <Route path="/user-update-kyc" element={<KYCPage />} />
            <Route path="/user-profile-change-pass" element={<UserProfileChangePass />} />
            <Route path="/user-forgot-pass" element={<UserForgotPass />} />
            <Route path="/user-reset-password" element={<UserResetPass />} />
            <Route path="/report-issue" element={<ReportIssuesPage />} />
        
            {/* OWNER ROUTES */}
            
            <Route exact path="/Owner-login" element={<OwnerLogin />} />
            <Route exact path="/Owner-reg-1" element={<OwnerReg1 />} /> 
            <Route exact path="/Owner-dashboard" element={<OwnerDashboard />} />            
            <Route exact path="/Owner-profile" element={<OwnerProfile />} />  
            <Route exact path="/Owner-profile-info-edit" element={<OwnerProfileInfoEdit />} />            
            <Route exact path="/Owner-profile-change-pass" element={<OwnerProfileChangePass />} />
            <Route exact path="/Owner-forgot-pass" element={<OwnerForgotPass />} />
            <Route exact path="/Owner-reset-password" element={<OwnerResetPass />} />
            <Route exact path="/manage-hosts" element={<ManageHosts />} />
            <Route exact path="/host-preview/:id" element={<HostPreview />} />
            <Route exact path="/user-preview/:id" element={<UserPreview />} />
            <Route exact path="/host-confirm" element={<HostConfirm />} />
            <Route exact path="/user-feedback" element={<UserFeedbacksPage />} />
            <Route exact path="/host-reports" element={<HostReportsPage />} />
            <Route exact path="/manage-users" element={<ManageUserPage />} />
            <Route exact path="/manage-refunds" element={<ManageRefunds />} />
            <Route exact path="/gateway-payments" element={<GatewayPaymentsPage />} />
            <Route exact path="/account-history/:id" element={<AccountHistory />} />
            <Route exact path="/payout-history" element={<PayoutHistory />} />
        
      </Routes>   
      <Footer />
    
    </BrowserRouter>  

    <ToastContainer /> 
    </div>
  );
}

export default App;
