import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./ServiceBooking.css";
import SpaImg2 from "../Assets/Img/SpaImg2.jpg";
import { FaSignInAlt } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetUserID, useGetUserSession } from "../Hooks/useGetUserID";
import { PaymentOutlined } from "@mui/icons-material";
import NavbarMenu from "./Layouts/Navbar";
import axios from "axios";
import { BaseURL } from "../config/variables";
import { validateUserBasicBooking } from "../utils/userActions/userValidations";
import Toast from "./Alert/Toast";
import { toast } from "react-toastify";

const ServiceBooking = () => {

  const navigate = useNavigate();

  const userID = useGetUserID();
  const session = useGetUserSession();
  const { id } = useParams();
  const [checkInDate, setCheckInDate] = useState("");
  const [checkInTime, setCheckInTime] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [checkOutTime, setCheckOutTime] = useState("");
  const [bookingFor, setBookingFor] = useState("");

  const [data, setData] = useState({
    serviceID: "",
    hostID: "",
    serviceName: "",
    serviceType: "",
    city: "",
    zipCode: "",
    stateProvince: "",
    country: "",
    streetAddress: "",
    aboutService: "",
    hostName: "",
    aboutHost: "",
    phoneNumber: "",
    price: "",
    discount: "",
    checkInFrom: "",
    checkInUntil: "",
    checkOutFrom: "",
    checkOutUntil: "",
    servicePic1: "",
    servicePic2: "",
    servicePic3: "",
    servicePic4: "",
    servicePic5: "",
  });

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    axios
      .get(`${BaseURL}/servicedetails/` + id)
      .then((res) => {
        setData({
          ...data,
          serviceID: res.data.Result[0].serviceID,
          hostID: res.data.Result[0].hostID,
          serviceName: res.data.Result[0].serviceName,
          serviceType: res.data.Result[0].serviceType,
          city: res.data.Result[0].city,
          zipCode: res.data.Result[0].zipCode,
          stateProvince: res.data.Result[0].stateProvince,
          country: res.data.Result[0].country,
          streetAddress: res.data.Result[0].streetAddress,
          aboutService: res.data.Result[0].aboutService,
          hostName: res.data.Result[0].hostName,
          aboutHost: res.data.Result[0].aboutHost,
          phoneNumber: res.data.Result[0].phoneNumber,
          price: res.data.Result[0].price,
          discount: res.data.Result[0].discount,
          checkInFrom: res.data.Result[0].checkInFrom,
          checkInUntil: res.data.Result[0].checkInUntil,
          checkOutFrom: res.data.Result[0].checkOutFrom,
          checkOutUntil: res.data.Result[0].checkOutUntil,
          servicePic1: res.data.Result[0].servicePic1,
          servicePic2: res.data.Result[0].servicePic2,
          servicePic3: res.data.Result[0].servicePic3,
          servicePic4: res.data.Result[0].servicePic4,
          servicePic5: res.data.Result[0].servicePic5,
        });
      })
      .catch((err) => console.log(err));
  }, []);

  function onTimeChange(value) {
    let timeSplit = value.split(":"),
      hours,
      minutes,
      meridian;
    hours = timeSplit[0];
    minutes = timeSplit[1];
    if (hours > 12) {
      meridian = "PM";
      hours -= 12;
      return hours + ":" + minutes + " " + meridian;
    } else if (hours < 12) {
      meridian = "AM";
      if (hours == 0) {
        hours = 12;
      }
      return hours + ":" + minutes + " " + meridian;
    } else {
      meridian = "PM";
      return hours + ":" + minutes + " " + meridian;
    }
  }
  function formatDate(inputDate) {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
      // Months use 0 index.
      return (
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
      );
    }
  }

  function datediff(first, second) {
    let start = new Date(first);
    start.setHours(0, 0, 0, 0); // Sets hours, minutes, seconds, and milliseconds
    let end = new Date(second);
    end.setHours(0, 0, 0, 0);
    let diff = Math.round((end - start) / 86400000) + 1; // See note below re `+ 1`
    return diff; // 3
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }



  const handleSubmit = async(text)=>{
    localStorage.setItem("paymentMethod",  text)

    const result = validateUserBasicBooking({ checkInDate, checkInTime, checkOutDate, checkOutTime, bookingFor});
     
    if (result.errLength) {
      
      return toast.error(() => <Toast title="Error" body={result.errMsg} />);
    }

    localStorage.setItem("bookingDetails", JSON.stringify({ ...data, checkInDate, checkInTime, checkOutDate, checkOutTime, bookingFor}))

    navigate(`/pay-now/?itemID=${id}&itemType=${"service"}&duration=${Number(
      datediff(checkInDate, checkOutDate)
    )}&unitPrice=${Number(
      data.price
    )}&checkInDate=${checkInDate}&checkInTime=${checkInTime}&checkOutDate=${checkOutDate}&checkOutTime=${checkOutTime}&hostID=${
      data.hostID
    }&amount=${
      data.discount === "No"
        ? Number(data.price) *
            Number(
              datediff(
                checkInDate,
                checkOutDate
              )
            ) +
          Number(data.price) *
            Number(
              datediff(
                checkInDate,
                checkOutDate
              )
            ) *
            0.15
        : Number(data.price) *
            Number(
              datediff(
                checkInDate,
                checkOutDate
              )
            ) +
          Number(data.price) *
            Number(
              datediff(
                checkInDate,
                checkOutDate
              )
            ) *
            0.15 -
          Number(data.price) *
            Number(
              datediff(
                checkInDate,
                checkOutDate
              )
            ) *
            0.2
    }&bookingFor=${bookingFor}`) 
  }

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />

      <div className="ServiceBooking">
        <Container className="container">
          <Row>
            <Col xs={12} md={12} lg={12}>
              <Row>
                <Col xs={12} md={4} lg={4}>
                  <div className="circle-box">
                    <p>Step 1</p>
                    <h5>Select A Service</h5>
                  </div>
                </Col>

                <Col xs={12} md={8} lg={8}>
                  <Row>
                    <Col xs={12} md={12}>
                      <h2>Your Selection</h2>
                      <p>Booking Information</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <img
                        src={ data.servicePic1}
                        alt={data.serviceName}
                        className="main-img img-fluid"
                      />
                    </Col>
                    <Col xs={12} md={6} className="booking-details">
                      <Row>
                        <Col xs={12} md={12}>
                          <h2 style={{ fontSize: "25px" }}>
                            {data.serviceName}
                          </h2>
                          <p>
                            {data.stateProvince}, <b>{data.country}</b>
                          </p>
                        </Col>

                        <Col xs={12} md={6}>
                          <span>
                            <b>Service Type: </b>
                          </span>
                        </Col>
                        <Col xs={12} md={6}>
                          <p>{data.serviceType}</p>
                        </Col>
                        <Col xs={12} md={6}>
                          <span>
                            <b>Ticket Charges: </b>
                          </span>
                        </Col>
                        <Col xs={12} md={6}>
                          <p>
                            Service is charged by <b> ticket/per day</b>
                          </p>
                        </Col>
                        <Col xs={12} md={6}>
                          <span>
                            <b>Price: </b>
                          </span>
                        </Col>
                        <Col xs={12} md={6}>
                          <p>
                            ₦{numberWithCommas(data.price)}/Ticket <br />{" "}
                            <b>per day</b>
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col xs={12} md={4} lg={4}>
                  <div className="circle-box">
                    <p>Step 2</p>
                    <h5>Visitor Details</h5>
                  </div>
                </Col>

                <Col xs={12} md={8} lg={8}>
                  <Row>
                    <Col xs={12} md={12}>
                      <h2>Your Details</h2>
                      <p>Personal Information</p>
                    </Col>
                    <Col xs={12} md={12}>
                      <div className="login-center mb-3">
                        {!session ? (
                          <Link
                            className="btnMore mb-5"
                            style={{
                              textDecoration: "none",
                              padding: "15px",
                              display: "block",
                            }}
                            to={`/login/?q=tour-booking&id=${id}`}
                          >
                            Sign In to continue! <FaSignInAlt />
                          </Link>
                        ) : (
                          <div className="alert alert-info">
                            You're logged In!
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={12}>
                      <h4>Booking Details:</h4>
                      <p>fill out your arrival date and time below</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <span>
                        <b>Check In: </b>
                      </span>
                    </Col>
                    <Col xs={12} md={6}>
                      <span>
                        <b>{checkInDate && formatDate(checkInDate)}</b>
                      </span>
                      &nbsp;
                      {checkInTime && "(" + onTimeChange(checkInTime) + ")"}
                    </Col>
                    <Col xs={12} md={6}>
                      <span>
                        <b>Check Out: </b>
                      </span>
                    </Col>
                    <Col xs={12} md={6}>
                      <span>
                        <b>{checkOutDate && formatDate(checkOutDate)}</b>
                      </span>
                      &nbsp;
                      {checkOutTime && "(" + onTimeChange(checkOutTime) + ")"}
                    </Col>
                    <Col xs={12} md={6}>
                      <span>
                        <b>Total length of stay: </b>
                      </span>
                    </Col>
                    <Col xs={12} md={6}>
                      <p>
                        {checkOutDate &&
                          datediff(checkInDate, checkOutDate) +
                            " ticket/day(s)"}
                      </p>
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                      <label htmlFor="checkInDate">Check In Date:</label>
                      <input
                        type="date"
                        id="checkInDate"
                        placeholder="mm/dd/yyyy"
                        className="form-control"
                        onChange={(e) => setCheckInDate(e.target.value)}
                      />
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                      <label htmlFor="checkInTime">Check In Time:</label>
                      <input
                        type="time"
                        id="checkInTime"
                        placeholder="10:00AM"
                        className="form-control"
                        onChange={(e) => setCheckInTime(e.target.value)}
                      />
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                      <label htmlFor="checkOutDate">Check Out Date:</label>
                      <input
                        type="date"
                        id="checkOutDate"
                        placeholder="mm/dd/yyyy"
                        className="form-control"
                        onChange={(e) => setCheckOutDate(e.target.value)}
                      />
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                      <label htmlFor="checkOutTime">Check Out Time:</label>
                      <input
                        type="time"
                        id="checkOutTime"
                        placeholder="10:00AM"
                        className="form-control"
                        onChange={(e) => setCheckOutTime(e.target.value)}
                      />
                    </Col>

                    <Col xs={12} md={12} lg={12} className="mb-5">
                      <label htmlFor="booking">
                        <b>For yourself/Someone else?</b>
                      </label>
                      <input
                        type="text"
                        id=""
                        placeholder="name of who you are booking for"
                        className="form-control"
                        onChange={(e) => setBookingFor(e.target.value)}
                      />
                    </Col>
                  </Row>
                </Col>
                {!userID ? (
                  <div className="alert alert-warning">
                    Login to finish reservation
                  </div>
                ) : (
                  <>
                    <Col xs={12} md={4} lg={4}>
                      <div className="circle-box">
                        <p>Step 3</p>
                        <h5>Finish Reservation</h5>
                      </div>
                    </Col>

                    <Col xs={12} md={8} lg={8}>
                      <Row>
                        <form className="booking-form-1">
                          <Row>
                            <Col xs={12} md={6}>
                              <h4>Your price summary:</h4>
                              <p>
                                Original price: ₦
                                {numberWithCommas(data.price)}
                                /ticket <b>per day</b>
                              </p>
                              <p>
                                {data.discount !== "No" &&
                                  "This service provider is offering a 20% discount: - ₦" +
                                    numberWithCommas(data.price * 0.2)}
                              </p>
                            </Col>
                            <Col xs={12} md={6}>
                              {checkOutDate && (
                                <>
                                  <h4>Price information </h4>
                                  <p>
                                    Includes ₦
                                    {numberWithCommas(
                                      data.price *
                                        0.15 *
                                        Number(
                                          datediff(checkInDate, checkOutDate)
                                        )
                                    )}{" "}
                                    in taxes and fees:
                                    <br />5 % VAT: ₦
                                    {numberWithCommas(
                                      data.price *
                                        0.05 *
                                        Number(
                                          datediff(checkInDate, checkOutDate)
                                        )
                                    )}
                                    <br />
                                    10 % City tax : ₦
                                    {numberWithCommas(
                                      data.price *
                                        0.1 *
                                        Number(
                                          datediff(checkInDate, checkOutDate)
                                        )
                                    )}
                                  </p>
                                </>
                              )}
                            </Col>
                            <Col xs={12} md={6}>
                              <p className="highlight">
                                This reservation fee is 100% refundable if
                                you’re not satisfied with the property rented,
                                the owner will not get your money.
                              </p>
                            </Col>
                            <Col xs={12} md={6}>
                              {checkOutDate && (
                                <div className="paybox">
                                  <h4 className="price">
                                    {data.discount === "No"
                                      ? "₦" +
                                        numberWithCommas(
                                          Number(data.price) *
                                            Number(
                                              datediff(
                                                checkInDate,
                                                checkOutDate
                                              )
                                            ) +
                                            Number(data.price) *
                                              Number(
                                                datediff(
                                                  checkInDate,
                                                  checkOutDate
                                                )
                                              ) *
                                              0.15
                                        )
                                      : "₦" +
                                        numberWithCommas(
                                          Number(data.price) *
                                            Number(
                                              datediff(
                                                checkInDate,
                                                checkOutDate
                                              )
                                            ) +
                                            Number(data.price) *
                                              Number(
                                                datediff(
                                                  checkInDate,
                                                  checkOutDate
                                                )
                                              ) *
                                              0.15 -
                                            Number(data.price) *
                                              Number(
                                                datediff(
                                                  checkInDate,
                                                  checkOutDate
                                                )
                                              ) *
                                              0.2
                                        )}
                                  </h4>
                                  {/* <Link
                                    to={`/pay-now/?itemID=${id}&itemType=${"service"}&duration=${Number(
                                      datediff(checkInDate, checkOutDate)
                                    )}&unitPrice=${Number(
                                      data.price
                                    )}&checkInDate=${checkInDate}&checkInTime=${checkInTime}&checkOutDate=${checkOutDate}&checkOutTime=${checkOutTime}&hostID=${
                                      data.hostID
                                    }&amount=${
                                      data.discount === "No"
                                        ? Number(data.price) *
                                            Number(
                                              datediff(
                                                checkInDate,
                                                checkOutDate
                                              )
                                            ) +
                                          Number(data.price) *
                                            Number(
                                              datediff(
                                                checkInDate,
                                                checkOutDate
                                              )
                                            ) *
                                            0.15
                                        : Number(data.price) *
                                            Number(
                                              datediff(
                                                checkInDate,
                                                checkOutDate
                                              )
                                            ) +
                                          Number(data.price) *
                                            Number(
                                              datediff(
                                                checkInDate,
                                                checkOutDate
                                              )
                                            ) *
                                            0.15 -
                                          Number(data.price) *
                                            Number(
                                              datediff(
                                                checkInDate,
                                                checkOutDate
                                              )
                                            ) *
                                            0.2
                                    }&bookingFor=${bookingFor}`}
                                  > */}
                                    <div className="btnMore" onClick={() => handleSubmit("paystack")}>
                                      Pay with Paystack <PaymentOutlined />
                                    </div>
                                    <div className="btnMore mt-3" onClick={() => handleSubmit("wallet")}>
                                      Pay with Wallet <PaymentOutlined />
                                    </div>
                                  {/* </Link> */}
                                </div>
                              )}
                            </Col>
                          </Row>
                        </form>
                      </Row>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ServiceBooking;
