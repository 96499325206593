



export const validateUserAvatar = (details) => {
    const { selectedFile  } = details;
    const errors  = [];
  
    if(!selectedFile){
      errors.push("Please upload an image.")
    }     
  
    return {
      errMsg: errors,
      errLength: errors.length
    }
  }
export const validateUserKYC = (details) => {
    const { selectedFile, documentName  } = details;
    const errors  = [];
  
    if(!selectedFile){
      errors.push("Please upload a file.")
    } 
    if(!documentName){
      errors.push("Please choose document name.")
    } 
    
  
    return {
      errMsg: errors,
      errLength: errors.length
    }
  }


export const validateUserRoomBooking = (details) => {
    const { checkInDate, checkInTime, checkOutDate, checkOutTime, bookingFor, roomNo, price  } = details;
    const errors  = [];
  
    if(!checkInDate){
      errors.push("Please choose check in date.")
    } 
    if(!checkInTime){
      errors.push("Please choose check in time.")
    } 
    if(!checkOutDate){
      errors.push("Please choose check out date.")
    } 
    // if(!checkOutTime){
    //   errors.push("Please choose check out time.")
    // } 
    if(!bookingFor){
      errors.push("Please choose who you are booking for.")
    } 
    if(!roomNo){
      errors.push("Please select a room.")
    } 
    if(!price){
      errors.push("Please select a room.")
    } 
    
  
    return {
      errMsg: errors,
      errLength: errors.length
    }
  }

export const validateUserBasicBooking = (details) => {
    const { checkInDate, checkInTime, checkOutDate, checkOutTime, bookingFor  } = details;
    const errors  = [];
  
    if(!checkInDate){
      errors.push("Please choose check in or departure date.")
    } 
    if(!checkInTime){
      errors.push("Please choose check in or departure time.")
    } 
    if(!checkOutDate){
      errors.push("Please choose check out or departure date.")
    } 
    if(!checkOutTime){
      errors.push("Please choose check out or departure time.")
    } 
    if(!bookingFor){
      errors.push("Please choose who you are booking for.")
    } 
    
  
    return {
      errMsg: errors,
      errLength: errors.length
    }
  }