import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HostNavbar from "./HostNavbar";
import { FaArrowRight } from "react-icons/fa";
import "./ListTour.css";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BaseURL } from "../../config/variables";
import Spinner from "../Alert/Spinner/Spinner";

const ListTour = () => {
  const navigate = useNavigate();
  const currentHostID = window.localStorage.getItem("hostID");
  const [inputValue, setInputValue] = useState({
    hostID: currentHostID,
    tourName: "",
    tourType: "",
    country: "",
    stateProvince: "",
    city: "",
    zipCode: "",
    streetAddress: "",
    aboutTourCenter: "",
    hostName: "",
    aboutHost: "",
    phoneNumber: "",
    aboutNeighborhood: "",
  });
  const {
    hostID,
    tourName,
    tourType,    
    country,
    stateProvince,
    city,
    zipCode,
    streetAddress,
    aboutTourCenter,
    hostName,
    aboutHost,
    phoneNumber,
    aboutNeighborhood,
  } = inputValue;

  const [loading, setLoading ] = useState(false)

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const { data } = await axios.post(`${BaseURL}/addtour`, {
        ...inputValue,
      });
      const { success, message, tourID } = data;
      window.localStorage.setItem("tourID", tourID);
      // console.log(data.tourID);
      // alert("tour ID is: "+window.localStorage.getItem("tourID"));

      if (success) {
        handleSuccess(message);
        setTimeout(() => {
          navigate("/list-tour-02");
        }, 1000);
        setLoading(false)
      } else {
        handleError(message);
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
    setInputValue({
      ...inputValue,
    });
  };

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <ToastContainer />

      <div className="ListTour">
        <Container className="container">
          <Row>
            <Col xs={12} md={3} lg={3}>
              <HostNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <form className="form-panel" onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <h2>Tell Us About Your Tour Center</h2>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <label htmlFor="tourName">Tour Name:</label>
                    <input
                      type="text"
                      name="tourName"
                      id="tourName"
                      className="form-control"
                      value={tourName}
                      onChange={handleOnChange}
                    />
                    <label htmlFor="tourType">Type of Tour Center: </label>
                    <select
                      name="tourType"
                      id="tourType"
                      className="form-select selectt"
                      value={tourType}
                      onChange={handleOnChange}
                    >
                      <option value={""}>Choose type</option>
                      <option value={"Park"}>Park</option>
                      <option value={"Museum"}>Museum</option>
                      <option value={"Lake"}>Lake City</option>
                      <option value={"Resort"}>Resort</option>
                      <option value={"Garden"}>Garden</option>
                      <option value={"Other"}>Other Attractions</option>
                    </select>
                    
                    <label htmlFor="country">Country</label>
                    <input
                      type="text"
                      name="country"
                      id="country"
                      className="form-control"
                      value={country}
                      onChange={handleOnChange}
                    />
                    
                    <label htmlFor="stateProvince">State/Province</label>
                    <input
                      type="text"
                      name="stateProvince"
                      id="stateProvince"
                      className="form-control"
                      value={stateProvince}
                      onChange={handleOnChange}
                    />

                    <label htmlFor="city">City</label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      className="form-control"
                      value={city}
                      onChange={handleOnChange}
                    />

                    <label htmlFor="zipCode">Zip Code</label>
                    <input
                      type="text"
                      name="zipCode"
                      id="zipCode"
                      className="form-control"
                      value={zipCode}
                      onChange={handleOnChange}
                    />

                    <label htmlFor="streetAddress">Street Address</label>
                    <input
                      type="text"
                      name="streetAddress"
                      id="streetAddress"
                      className="form-control"
                      value={streetAddress}
                      onChange={handleOnChange}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <label htmlFor="aboutTourCenter">About Tour Center</label><br />
                    <small><b>What should visitors expect?</b></small>
                    <textarea
                      name="aboutTourCenter"
                      id="aboutTourCenter"
                      className="form-control"                      
                      value={aboutTourCenter}
                      onChange={handleOnChange}
                    ></textarea> 

                    <label htmlFor="hostName">Host Name</label>
                    <input
                      type="text"
                      name="hostName"
                      id="hostName"
                      className="form-control"
                      value={hostName}
                      onChange={handleOnChange}
                    />

                    <label htmlFor="aboutHost">About Host</label>
                    <textarea
                      name="aboutHost"
                      id="aboutHost"
                      className="form-control"
                      value={aboutHost}
                      onChange={handleOnChange}
                    ></textarea>

                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      className="form-control"
                      value={phoneNumber}
                      onChange={handleOnChange}
                    />

                    <label htmlFor="aboutNeighborhood">
                      About The Neighborhood
                    </label>
                    <textarea
                      name="aboutNeighborhood"
                      id="aboutNeighborhood"
                      className="form-control"
                      value={aboutNeighborhood}
                      onChange={handleOnChange}
                    ></textarea>
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <button type="submit" className="btnOutline mt-5">
                      Next <FaArrowRight />
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>

        { loading && <Spinner />}

      </div>
    </>
  );
};

export default ListTour;
