import React from 'react' 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./Trends.css"
import { ArrowRight, TrendingUp } from '@mui/icons-material';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Trends = () => {
  return (
    <div className='Trends'> 
        <Container className='container'>
          <Row style={{marginTop: '-85px'}}>
            <Col xs={12} md={12}>
                <h2 data-aos="flip-left">Trending Deals <TrendingUp style={{fontWeight: 'bold'}} /></h2>
                
            </Col>
            <Col xs={12} md={3}>
                <Link to="./accommodations">
                    <div className="box-1">
                        <span className="cat-title">
                            Apartments
                        </span>
                    </div>                  
                </Link>
            
            </Col>
            <Col xs={12} md={3}>
                <Link to="./accommodations">
                    <div className="box-2"> 
                        <span className="cat-title">
                            Hotels
                        </span>
                    
                    </div>
                </Link>
            
            </Col>
            <Col xs={12} md={3}>
                <Link to="./accommodations">
                    <div className="box-3">
                        <span className="cat-title">
                            Guest Houses
                        </span>
                        
                    </div>
                </Link>
            
            </Col>
            <Col xs={12} md={3}>
                <Link to="./accommodations">
                    <div className="box-4">
                        <span className="cat-title">
                            Villas
                        </span>
                        
                    </div>
                </Link>
            
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
                <Link to="./accommodations">
                    <div className="btnMore" data-aos="flip-down">
                        Browse Accommodations <FaArrowRight />
                    </div> 
                </Link>
                
            </Col>
          </Row>
        </Container>

        <Container className='container'>
          <Row>
            <Col xs={12} md={12}>
                <h2 data-aos="flip-right">Car Rentals <TrendingUp style={{fontWeight: 'bold'}} /></h2>
                
            </Col>
            <Col xs={12} md={3}>
                <div className="box-5">
                    <Link to="./cars">
                        <span className="cat-title">
                            SUVs
                        </span>
                    </Link>
                </div>
            
            </Col>
            <Col xs={12} md={3}>
                <div className="box-6"> 
                    <Link to="./cars">
                        <span className="cat-title">
                            Saloon Car
                        </span>
                    </Link>
                </div>
            
            </Col>
            <Col xs={12} md={3}>
                <div className="box-7">
                    <Link to="./cars">
                        <span className="cat-title">
                            Airport Taxi
                        </span>
                    </Link>
                </div>
            
            </Col>
            <Col xs={12} md={3}>
                <div className="box-8">
                    <Link to="./cars">
                        <span className="cat-title">
                            Luxury
                        </span>
                    </Link>
                </div>
            
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
                <Link to="./cars">
                    <div className="btnMore" data-aos="flip-up">
                        Browse Car Rentals <FaArrowRight />
                    </div> 
                </Link>
            </Col>
          </Row>
        </Container>

        <Container className='container'>
          <Row>
            <Col xs={12} md={12}>
                <h2 data-aos="flip-right">Other Services <TrendingUp style={{fontWeight: 'bold'}} /></h2>
                
            </Col>
            <Col xs={12} md={3}>
                <div className="box-9">
                    <Link to="./services">
                        <span className="cat-title">
                            Facial Treatments
                        </span>
                    </Link>
                </div>
            
            </Col>
            <Col xs={12} md={3}>
                <div className="box-10"> 
                    <Link to="./services">
                        <span className="cat-title">
                            Yoga Classes
                        </span>
                    </Link>
                </div>
            
            </Col>
            <Col xs={12} md={3}>
                <div className="box-11">
                    <Link to="./services">
                        <span className="cat-title">
                            Gym Trainer
                        </span>
                    </Link>
                </div>
            
            </Col>
            <Col xs={12} md={3}>
                <div className="box-12">
                    <Link to="./services">                  
                        <span className="cat-title">
                            SPA
                        </span>
                    </Link>
                </div>
            
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
                <Link to="./services">
                    <div className="btnMore" data-aos="flip-up">
                        Browse Other Services <FaArrowRight />
                    </div> 
                </Link>
            </Col>
          </Row>
        </Container>
    </div>
  )
}

export default Trends;