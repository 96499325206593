import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Ewallet.css'; 
import { FaWallet } from 'react-icons/fa';
import UserNavbar from './UserNavbar';
import NavbarMenu from '../Layouts/Navbar'; 
import { useGetUserID, useGetUserSession } from '../../Hooks/useGetUserID';
import { getUserWallet, getUserWalletTransactions } from '../../utils/userActions/paymentActions';
import Spinner from '../Alert/Spinner/Spinner';
import { handleError } from '../../utils/notifications/toasts';
import { limit, totalPages } from '../../utils/ownerActions/ownerRequests';
import { Link } from 'react-router-dom';


const Ewallet = () => {

    const session = useGetUserSession()
    const userId = useGetUserID()

    const [ balance, setBalance] = useState(0)
    const [ transactions, setTransactions] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [startIndex, setstartIndex] = useState(0)


    const getWallet = async()=>{

        const response = await getUserWallet(userId)
        setBalance(response?.wallet?.balance)
    }

    const getWalletTransactions = async()=>{
        setLoading(true);

        const response = await getUserWalletTransactions(userId, currentPage, limit)
        if (response?.status) {
            setTransactions(response.transactions)
            setLoading(false);
          
        } else {
          handleError("Could not get transactions list");
          setLoading(false);
        }
    }
  
    useEffect(() => {
      getWallet()
      getWalletTransactions()
  
    }, [session, currentPage]);

    const handlePrevious = () =>{
        currentPage > 1 ? setCurrentPage(currentPage - 1) : setCurrentPage(currentPage);
        
    }
    
    const handleNextPage = () =>{
        currentPage < totalPages ? setCurrentPage(currentPage + 1) : setCurrentPage(currentPage);
        
    }
    
    const goToPage = (number) =>{
        setCurrentPage(number) 
        
    }

// console.log({ transactions })

  return (
    <>
        <NavbarMenu  style={{backgroundColor: '#003B95' }} />
        
        <div className='Ewallet'>
            {/* Do a mini navbar just below the main navbar */}
            <Container className='container'>
                <Row>
                    <Col xs={12} md={3} lg={3}>
                        <UserNavbar />
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                        <Row>
                            <Col xs={12} md={12} className='mt-5'>
                                <div className="welcome-panel">
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <p>
                                                <FaWallet /> My Ewallet Balance:
                                            </p>   
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <h4>
                                            ₦{balance > 100 ?balance.toLocaleString() : balance }.00
                                            </h4>   
                                        </Col>                                                            
                                    </Row>
                                </div>
                            </Col>
                            <Col xs={12} md={12} className='mt-5'>

                                {
                                    transactions.length> 0 ? transactions.map((each, i)=>{
                                        
                                        return (
                                            
                                            <div className="ewallet-box mb-3" key={i}>
                                                <p>
                                                    { 
                                                        each.transactionType === "credit" ? "Added" : ""
                                                    } 
                                                    <b> ₦{each.amount > 100 ? each.amount.toLocaleString() : each.amount } </b> 
                                                     to your account on  
                                                    <b>  {""}
                                                        {
                                                            new Date(each.createdAt).toLocaleString('en-US', { timeZone: 'UTC' })
                                                        } 
                                                    </b>
                                                </p>
                                            </div>
                                        )
                                        // new Date("2023-10-25T04:31:14.000Z").toLocaleString('en-US', { timeZone: 'UTC' });  
                                    })
                                    

                                    :

                                    (

                                        <div className="ewallet-box mb-3">
                                            <p>
                                                <b>No Transaction History to show</b>
                                            </p>
                                        </div>
                                    )
                                }

                                {/* <div className="ewallet-box mb-3">
                                    <p>
                                        Added <b>NGN24,500.00</b> to your account on <b>09/06/2023</b>
                                    </p>
                                </div>
                                <div className="ewallet-box mb-3">
                                    <p>
                                        Added <b>NGN124,500.00</b> to your account on <b>11/06/2023</b>
                                    </p>
                                </div> */}
                            </Col>


                            <ul className="pagination "
                                style={{ alignItems: "right", justifyContent: "right"}}
                                >
                                    <li className="page-item" onClick={handlePrevious}>
                                    <Link className="page-link" to="" aria-label="Previous">
                                        <span aria-hidden="true">&laquo;</span>
                                    </Link>
                                    </li>
                                    <li className="page-item" onClick={()=> goToPage(1)}><Link className="page-link" to="">1</Link></li>
                                    <li className="page-item" onClick={()=> goToPage(2)}><Link className="page-link" to="">2</Link></li>
                                    <li className="page-item" onClick={()=> goToPage(3)}><Link className="page-link" to="">3</Link></li>

                                    <li className="page-item" onClick={handleNextPage}>
                                    <Link className="page-link" to="" aria-label="Next">
                                        <span aria-hidden="true">&raquo;</span>
                                    </Link>
                                    </li>
                                </ul>

                            
                        </Row>
                    </Col>
                </Row>
            </Container>

            { loading && <Spinner />}

        </div>
    </>
  )
}

export default Ewallet