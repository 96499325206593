import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HostNavbar from "./HostNavbar";
import {
  FaArrowLeft,
  FaCamera,
  FaCheck,
  FaInfoCircle,
  FaRegSave,
  FaUpload,
} from "react-icons/fa";
import "./ListVehicle.css";
import dropImg from "../../Assets/illustrations/ep-picture-filled.svg";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BaseURL } from "../../config/variables";
import { uploadImagesToCloud } from "../../utils/imageUtils/uploadImage";
import Spinner from "../Alert/Spinner/Spinner";

const ListVehicle02 = () => {
  const navigate = useNavigate();
  const currentvehicleID = window.localStorage.getItem("vehicleID");
  // alert (currentvehicleID);
  const [data, setData] = useState({
    vehicleID: currentvehicleID,
  });
  const [loading, setLoading ] = useState(false)


  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)

    // const formdata = new FormData();
    // formdata.append("vehicleID", data.vehicleID);
    // formdata.append("vehiclePic1", data.vehiclePic1);
    // formdata.append("vehiclePic2", data.vehiclePic2);
    // formdata.append("vehiclePic3", data.vehiclePic3);
    // formdata.append("vehiclePic4", data.vehiclePic4);
    // formdata.append("vehiclePic5", data.vehiclePic5);

    try {

      const filteredArrayOfImages = [data.vehiclePic1, data.vehiclePic2, data.vehiclePic3, data.vehiclePic4, data.vehiclePic5].filter(imageUrl => imageUrl !== "" && imageUrl !== null && imageUrl !== undefined);

      const uploadedImageURLs = await uploadImagesToCloud(filteredArrayOfImages)
      const imageURLs = []
      uploadedImageURLs.forEach((each, i)=>{
        imageURLs.push(each.url)
      })


      const  response  = await axios.post(
        `${BaseURL}/addvehicleimages`,
        {vehicleID: data.vehicleID, imageURLs}
      );
      const { success, message } = response.data;
      console.log(data);

      if (success) {
        handleSuccess(message);
        // setTimeout(() => {
          navigate("/list-vehicle");
        // }, 1000);
        setLoading(false)
        
        window.localStorage.removeItem("vehicleID");
      } else {
        setLoading(false)
        handleError(message);
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  };

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <ToastContainer />

      <div className="ListVehicle">
        <Container className="container">
          <Row>
            <Col xs={12} md={3} lg={3}>
              <HostNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <form
                className="form-panel"
                encType="multipart/form-data"
                onSubmit={handleSubmit}
              >
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <h2>Vehicle Photos:</h2>
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <div className="dropbox mt-5">
                      <p>
                        <strong>
                          Upload at least 5 photos of your vehicle.{" "}
                        </strong>
                        The more you upload , the more likely you are to get
                        bookings. You can add more later.{" "}
                      </p>
                      <div className="dropphotos">
                        <div className="picbox mt-5">
                          <img src={dropImg} alt="vehicle pic" />
                        </div>
                        <p>Click below to select your photos</p>
                        <div className="col-12 mb-3">
                          <label className="form-label" htmlFor="vehiclePic1">
                            Main Photo
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="vehiclePic1"
                            id="vehiclePic1"
                            // value={vehiclePic1}
                            onChange={e => setData({...data, vehiclePic1: e.target.files[0]})}
                          />
                          <label className="form-label" htmlFor="vehiclePic2">
                            Photo 2
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="vehiclePic2"
                            id="vehiclePic2"
                            // value={vehiclePic2}
                            onChange={e => setData({...data, vehiclePic2: e.target.files[0]})}
                          />
                          <label className="form-label" htmlFor="vehiclePic3">
                            Photo 3
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="vehiclePic3"
                            id="vehiclePic3"
                            // value={vehiclePic3}
                            onChange={e => setData({...data, vehiclePic3: e.target.files[0]})}
                          />
                          <label className="form-label" htmlFor="vehiclePic4">
                            Photo 4
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="vehiclePic4"
                            id="vehiclePic4"
                            // value={vehiclePic4}
                            onChange={e => setData({...data, vehiclePic4: e.target.files[0]})}
                          />
                          <label className="form-label" htmlFor="vehiclePic5">
                            Photo 5
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="vehiclePic5"
                            id="vehiclePic5"
                            // value={vehiclePic5}
                            onChange={e => setData({...data, vehiclePic5: e.target.files[0]})}
                          />
                        </div> 

                        {/* <button className="btn btn-outline mb-3">
                          <FaCamera /> Upload photos
                        </button> */}
                      </div>
                    </div>
                  </Col>

                  {/* <Col xs={12} md={12} lg={4} className="mt-3">
                    <button type="submit" className="btnOutline mt-5">
                      <FaArrowLeft />
                      Previous{" "}
                    </button>
                  </Col> */}
                  <Col xs={12} md={12} lg={4} className="mt-3">
                    <button className="btnOutline mt-5">
                      Publish <FaUpload />
                    </button>
                  </Col>
                  {/* <Col xs={12} md={12} lg={4} className="mt-3">
                    <button className="btnOutline mt-5">
                      Save Draft <FaRegSave />
                    </button>
                  </Col> */}
                </Row>
              </form>
            </Col>
          </Row>
        </Container>

        { loading && <Spinner />}

      </div>
    </>
  );
};

export default ListVehicle02;
