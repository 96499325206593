import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HostNavbar from "./HostNavbar";
import { FaUpload } from "react-icons/fa";
import "./ListService.css";
import dropImg from "../../Assets/illustrations/ep-picture-filled.svg";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BaseURL } from "../../config/variables";
import Spinner from "../Alert/Spinner/Spinner";
import { uploadImagesToCloud } from "../../utils/imageUtils/uploadImage";

const ListService03 = () => {
  const navigate = useNavigate();
  const currentServiceID = window.localStorage.getItem("serviceID");
  // alert (currentServiceID);
  const [data, setData] = useState({
    serviceID: currentServiceID,
  });
  const [loading, setLoading ] = useState(false)


  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    // const formdata = new FormData();
    // formdata.append("serviceID", data.serviceID);
    // formdata.append("servicePic1", data.servicePic1);
    // formdata.append("servicePic2", data.servicePic2);
    // formdata.append("servicePic3", data.servicePic3);
    // formdata.append("servicePic4", data.servicePic4);
    // formdata.append("servicePic5", data.servicePic5);

    try {
      const filteredArrayOfImages = [data.servicePic1, data.servicePic2, data.servicePic3, data.servicePic4, data.servicePic5].filter(imageUrl => imageUrl !== "" && imageUrl !== null && imageUrl !== undefined);

      const uploadedImageURLs = await uploadImagesToCloud(filteredArrayOfImages)
      const imageURLs = []
      uploadedImageURLs.forEach((each, i)=>{
        imageURLs.push(each.url)
      })

      const response = await axios.post(
        `${BaseURL}/addserviceimages`,
        {serviceID: data.serviceID, imageURLs}
      );
      const { success, message } = response.data; 
      // console.log(data);

      if (success) {
        handleSuccess(message);
        setTimeout(() => {
          navigate("/list-service");
        }, 1000);
        window.localStorage.removeItem("serviceID");
        setLoading(false)
      } else {
        setLoading(false)
        handleError(message);
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  };

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <ToastContainer />

      <div className="ListService">
        <Container className="container">
          <Row>
            <Col xs={12} md={3} lg={3}>
              <HostNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <form
                className="form-panel"
                encType="multipart/form-data"
                onSubmit={handleSubmit}
              >
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <h2>Service Display Photos:</h2>
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <div className="dropbox mt-5">
                      <p>
                        <strong>
                          Upload at least 5 photos of your services.{" "}
                        </strong>
                        The more you upload, the more likely you are to get
                        bookings. You can add more later.{" "}
                      </p>
                      <div className="dropphotos">
                        <div className="picbox mt-5">
                          <img src={dropImg} alt="service pic" />
                        </div>
                        <p>Click below to select your photos</p>
                        <div className="col-12 mb-3">
                          <label className="form-label" htmlFor="servicePic1">
                            Main Photo
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="servicePic1"
                            id="servicePic1"
                            // value={servicePic1}
                            onChange={(e) =>
                              setData({
                                ...data,
                                servicePic1: e.target.files[0],
                              })
                            }
                          />
                          <label className="form-label" htmlFor="servicePic2">
                            Photo 2
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="servicePic2"
                            id="servicePic2"
                            // value={servicePic2}
                            onChange={(e) =>
                              setData({
                                ...data,
                                servicePic2: e.target.files[0],
                              })
                            }
                          />
                          <label className="form-label" htmlFor="servicePic3">
                            Photo 3
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="servicePic3"
                            id="servicePic3"
                            // value={servicePic3}
                            onChange={(e) =>
                              setData({
                                ...data,
                                servicePic3: e.target.files[0],
                              })
                            }
                          />
                          <label className="form-label" htmlFor="servicePic4">
                            Photo 4
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="servicePic4"
                            id="servicePic4"
                            // value={servicePic4}
                            onChange={(e) =>
                              setData({
                                ...data,
                                servicePic4: e.target.files[0],
                              })
                            }
                          />
                          <label className="form-label" htmlFor="servicePic5">
                            Photo 5
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="servicePic5"
                            id="servicePic5"
                            // value={servicePic5}
                            onChange={(e) =>
                              setData({
                                ...data,
                                servicePic5: e.target.files[0],
                              })
                            }
                          />
                        </div>

                        {/* <button className="btn btn-outline mb-3">
                                <FaCamera /> Upload photos
                                </button> */}
                      </div>
                    </div>
                  </Col>

                  <Col xs={12} md={12} lg={4} className="mt-3">
                    <button type="submit" className="btnOutline mt-5">
                      Publish <FaUpload />
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
        { loading && <Spinner />}

      </div>
    </>
  );
};

export default ListService03;
