import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./AccomPreview.css";
import landlordImg from "../Assets/illustrations/avatar.png";
import {
  AirSharp,
  BathroomOutlined,
  BedOutlined,
  BookOnlineOutlined,
  BreakfastDining,
  CancelOutlined,
  CheckCircle,
  ChildCareTwoTone,
  FitnessCenterOutlined,
  HouseOutlined,
  KitchenOutlined,
  OutdoorGrillOutlined,
  PaymentOutlined,
  Person2TwoTone,
  PetsOutlined,
  RoomServiceOutlined,
  VerifiedUser,
  ShareSharp,
} from "@mui/icons-material";
import {
  FaAccessibleIcon,
  FaBath,
  FaDollarSign,
  FaHandsWash,
  FaHeart,
  FaParking,
  FaSignInAlt,
  FaSignOutAlt,
  FaSmokingBan,
  FaSwimmingPool,
  FaTaxi,
  FaTree,
  FaTv,
  FaWater,
  FaWifi,
  FaUser,
} from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import NavbarMenu from "./Layouts/Navbar";
import axios from "axios";
import { BaseURL } from "../config/variables";
import Spinner from "./Alert/Spinner/Spinner";






const AccomPreview = () => {
  const [data, setData] = useState({
    propertyID: "",
    propertyName: "",
    propertyType: "",
    stateProvince: "",
    country: "",
    propertyPic1: "",
    propertyPic2: "",
    propertyPic3: "",
    propertyPic4: "",
    propertyPic5: "",
    hostName: "",
    aboutHost: "",
    aboutProperty: "",
    aboutNeighborhood: "",
    roomNo: "",
    guestNo: "",
    bathroomNo: "",
    roomBedType: "",
    price: "",
    discount: "",
    smoking: "",
    pet: "",
    children: "",
    checkInFrom: "",
    checkInUntil: "",
    checkOutFrom: "",
    checkOutUntil: "",
    bar: "",
    wifi: "",
    grill: "",
    airConditioner: "",
    flatScreenTv: "",
    sauna: "",
    kitchen: "",
    garden: "",
    pool: "",
    breakfast: "",
    parking: "",
    jacuzzi: "",
    heating: "",
    washingMachine: "",
  });
  const [loading, setLoading] = useState(false);
  
 


 


 

  const fetchproperty = async () => {
    setLoading(true);
    axios
      .get(`${BaseURL}/propertydetails/` + id)
      .then((res) => {
        setData({
          ...data,
          propertyID: res.data.Result[0].propertyID,
          propertyName: res.data.Result[0].propertyName,
          propertyType: res.data.Result[0].propertyType,
          stateProvince: res.data.Result[0].stateProvince,
          country: res.data.Result[0].country,
          propertyPic1: res.data.Result[0].propertyPic1,
          propertyPic2: res.data.Result[0].propertyPic2,
          propertyPic3: res.data.Result[0].propertyPic3,
          propertyPic4: res.data.Result[0].propertyPic4,
          propertyPic5: res.data.Result[0].propertyPic5,
          hostName: res.data.Result[0].hostName,
          aboutHost: res.data.Result[0].aboutHost,
          aboutProperty: res.data.Result[0].aboutProperty,
          aboutNeighborhood: res.data.Result[0].aboutNeighborhood,
          roomNo: res.data.Result[0].roomNo,
          guestNo: res.data.Result[0].guestNo,
          bathroomNo: res.data.Result[0].bathroomNo,
          roomBedType: res.data.Result[0].roomBedType,
          price: res.data.Result[0].price,
          discount: res.data.Result[0].discount,
          smoking: res.data.Result[0].smoking,
          pet: res.data.Result[0].pet,
          children: res.data.Result[0].children,
          checkInFrom: res.data.Result[0].checkInFrom,
          checkInUntil: res.data.Result[0].checkInUntil,
          checkOutFrom: res.data.Result[0].checkOutFrom,
          checkOutUntil: res.data.Result[0].checkOutUntil,
          bar: res.data.Result[0].bar,
          wifi: res.data.Result[0].wifi,
          grill: res.data.Result[0].grill,
          airConditioner: res.data.Result[0].airConditioner,
          flatScreenTv: res.data.Result[0].flatScreenTv,
          fitnessCenter: res.data.Result[0].fitnessCenter,
          kitchen: res.data.Result[0].kitchen,
          garden: res.data.Result[0].garden,
          pool: res.data.Result[0].pool,
          breakfast: res.data.Result[0].breakfast,
          parking: res.data.Result[0].parking,
          jacuzzi: res.data.Result[0].jacuzzi,
          heating: res.data.Result[0].heating,
          washingMachine: res.data.Result[0].washingMachine,
        });
        setLoading(false);
      })

      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
 
  const { id } = useParams();
  useEffect(() => {
    // scroll to top on page load
    // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    fetchproperty();
  }, []);


  

  function onTimeChange(value) {
    let timeSplit = value.split(":"),
      hours,
      minutes,
      meridian;
    hours = timeSplit[0];
    minutes = timeSplit[1];
    if (hours > 12) {
      meridian = "PM";
      hours -= 12;
      return hours + ":" + minutes + " " + meridian;
    } else if (hours < 12) {
      meridian = "AM";
      if (hours == 0) {
        hours = 12;
      }
      return hours + ":" + minutes + " " + meridian;
    } else {
      meridian = "PM";
      return hours + ":" + minutes + " " + meridian;
    }
  }

  // console.log({ availableRooms })

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />

      <div className="AccomPreview">
        <Container className="container">
          <Row>
            <Col xs={12} md={8}>
              <h2>{data.propertyName}</h2>
              <p>
                {data.stateProvince}, {data.country}
              </p>
            </Col>
            <Col xs={12} md={4}>
              <span>
                <ShareSharp /> Share &nbsp;&nbsp;
              </span>
              <span>
                <FaHeart /> Save
              </span>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6} className="mt-5">
              <Row>
                <Col xs={12} md={12} className="mt-3">
                  <img
                    src={ data.propertyPic1}
                    alt={data.propertyName}
                    className="main-img img-fluid"
                  />
                </Col>
              </Row>
            </Col>
            {/* More pictures here */}
            <Col xs={12} md={6} className="mt-5 mb-5">
              <Row>
                <Col xs={12} md={6} className="mt-3">
                  <img
                    src={ data.propertyPic2}
                    alt={data.propertyName}
                    className="img-fluid"
                  />
                </Col>
                <Col xs={12} md={6} className="mt-3">
                  <img
                    src={ data.propertyPic3}
                    alt={data.propertyName}
                    className="third-img img-fluid"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} className="mt-3">
                  <img
                    src={ data.propertyPic4}
                    alt={data.propertyName}
                    className="img-fluid"
                  />
                </Col>
                <Col xs={12} md={6} className="mt-3">
                  <div className="featBoxImg">
                    <img
                      src={ data.propertyPic5}
                      alt={data.propertyName}
                      className="fourth-img img-fluid"
                    />
                    {/* <div className='seeMore'>
                                        See all photos
                                    </div> */}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={12} className="rules mb-5">
              <Row>
                <Col xs={12} md={12} className="mb-5">
                  <Row>
                    <Col xs={6} md={8}>
                      <h3>
                        <span className="rental-property">
                          {data.propertyType}
                        </span>{" "}
                        in a rental unit{" "}
                      </h3>
                    </Col>
                    <Col xs={6} md={4}>
                      <h3 className="mb-3">
                        <img
                          src={landlordImg}
                          className="landlordImg"
                          alt="landlord-img"
                        />{" "}
                        Hosted by{" "}
                        <span className="landlord" style={{ marginLeft: "" }}>
                          {data.hostName}.{" "}
                        </span>
                      </h3>
                      <p> {data.aboutHost}</p>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={12} className="mb-5">
                  <Row>
                    <Col xs={12} md={4}>
                      <div className="features">
                        <BedOutlined /> {data.roomBedType}
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <div className="features">
                        <BathroomOutlined /> {data.bathroomNo} Private Bathroom
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <div className="features">
                        <HouseOutlined /> Host is approved by Maczuby.com
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={12}>
                  <h4>About This Place</h4>
                  <p>
                    Located in {data.stateProvince + "/" + data.country},{" "}
                    {data.aboutProperty}, {data.aboutNeighborhood}.
                  </p>
                </Col>
                <Col xs={12} md={12} className="offers">
                  <h4>What this place offers</h4>
                  {data.pool === "Yes" && (
                    <span>
                      <FaSwimmingPool /> Swimming Pool
                    </span>
                  )}
                  {data.bar === "Yes" && (
                    <span>
                      <FaWifi /> Bar
                    </span>
                  )}
                  {data.wifi === "Yes" && (
                    <span>
                      <FaWifi /> Free Wifi
                    </span>
                  )}
                  {data.grill === "Yes" && (
                    <span>
                      <OutdoorGrillOutlined /> Grill
                    </span>
                  )}
                  {data.airConditioner === "Yes" && (
                    <span>
                      <AirSharp /> Air Conditioner
                    </span>
                  )}
                  {data.flatScreenTv === "Yes" && (
                    <span>
                      <FaTv /> Flat Screen TV
                    </span>
                  )}
                  {data.fitnessCenter === "Yes" && (
                    <span>
                      <FitnessCenterOutlined /> Fitness Center
                    </span>
                  )}
                  {data.kitchen === "Yes" && (
                    <span>
                      <KitchenOutlined /> kitchen
                    </span>
                  )}
                  {data.garden === "Yes" && (
                    <span>
                      <FaTree /> garden
                    </span>
                  )}
                  {data.breakfast === "Yes" && (
                    <span>
                      <BreakfastDining /> Breakfast
                    </span>
                  )}
                  {data.parking === "Yes" && (
                    <span>
                      <FaParking /> Free Parking
                    </span>
                  )}
                  {data.jacuzzi === "Yes" && (
                    <span>
                      <FaBath /> Hot Tub/Jacuzzi
                    </span>
                  )}
                  {data.heating === "Yes" && (
                    <span>
                      <FaWater /> Heating
                    </span>
                  )}
                  {data.washingMachine === "Yes" && (
                    <span>
                      <FaHandsWash /> Washing Machine
                    </span>
                  )}
                </Col>

                <Col xs={12} md={12}>
                  <h4>House Rules</h4>
                </Col>
                <Col xs={12} md={6}>
                  <span>
                    <FaSignInAlt /> Check In
                  </span>
                </Col>
                <Col xs={12} md={6}>
                  <p>
                    From <b>{onTimeChange(data.checkInFrom)}</b> to{" "}
                    <b>{onTimeChange(data.checkInUntil)}</b>
                  </p>
                </Col>
                <Col xs={12} md={6}>
                  <span>
                    <FaSignOutAlt /> Check Out
                  </span>
                </Col>
                <Col xs={12} md={6}>
                  <p>
                    From <b>{onTimeChange(data.checkOutFrom)} </b> to{" "}
                    <b>{onTimeChange(data.checkOutUntil)} </b>
                  </p>
                </Col>
                <Col xs={12} md={6}>
                  <span>
                    <CancelOutlined /> Cancellation/prepayment
                  </span>
                </Col>
                <Col xs={12} md={6}>
                  <p>
                    Cancellation and prepayment policies vary according to
                    accommodations type. Check what conditions might apply to
                    each option when making your selection.
                  </p>
                </Col>
                <Col xs={12} md={6}>
                  <span>
                    <ChildCareTwoTone /> Children & Beds
                  </span>
                </Col>
                <Col xs={12} md={6}>
                  {data.children === "Yes" ? (
                    <p>
                      <b>Child policies -</b> Children of all ages are welcome.
                      To see correct prices and occupancy info, add the number
                      and ages of children in your group to your search. &nbsp;
                      <br />
                    </p>
                  ) : (
                    <b>children are not allowed in this property</b>
                  )}
                </Col>
                <Col xs={12} md={6}>
                  <span>
                    <Person2TwoTone /> No age restriction
                  </span>
                </Col>
                <Col xs={12} md={6}>
                  <p>There's no age requirement for check-in</p>
                </Col>
                <Col xs={12} md={6}>
                  <span>
                    <PetsOutlined /> Pets
                  </span>
                </Col>
                <Col xs={12} md={6}>
                  <p>
                    Pets are {data.pet === "Yes" ? "allowed" : "not allowed"}.
                  </p>
                </Col>
                <Col xs={12} md={6}>
                  <span>
                    <FaSmokingBan /> Smoking
                  </span>
                </Col>
                <Col xs={12} md={6}>
                  <p>
                    Smoking{" "}
                    {data.smoking === "Yes" ? " is allowed" : " is not allowed"}
                    .
                  </p>
                </Col>
                <Col xs={12} md={6}>
                  <span>
                    <PaymentOutlined /> Accepted payment methods
                  </span>
                </Col>
                <Col xs={12} md={6}>
                  <p>
                    Cash is not accepted,{" "}
                    <b>Pay securely through Paystack or your e-wallet.</b>
                  </p>
                </Col>

               

                <Col xs={12} md={12}>
                  <div className="reserve-pay">
                    <Row>
                      <Col xs={12} md={4}>
                        <p className="text-success text-center mt-5">
                          <CheckCircle /> <br />
                          <b>
                            Property Owner reserves right to cancel <br />
                            if you're not eligible
                          </b>
                        </p>
                      </Col>
                      <Col xs={12} md={4}>
                        <h4 className="text-center">Repayment Guarantee</h4>
                        <p className="text-center">
                          You can request refund if you're not satisfied with
                          this property listing
                        </p>
                      </Col>
                      <Col xs={12} md={4}>
                        <Link to={`/accom-booking/${data.propertyID}`}>
                          <div className="btnMore mt-5">
                            Book Now <BookOnlineOutlined />
                          </div>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        {loading && <Spinner />}
      </div>
    </>
  );
};

export default AccomPreview;
