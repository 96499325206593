import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Notifications.css'; 
import HostNavbar from './HostNavbar';
import { Table, Badge } from 'react-bootstrap';
import { FaEnvelopeOpenText } from 'react-icons/fa';
import NavbarMenu from '../Layouts/Navbar';  

const Notifications = () => {
  return (
    <>
        <NavbarMenu  style={{backgroundColor: '#003B95' }} />
        
        <div className='Notifications'>
            <Container>
                <Row>
                <Col xs={12} md={3} lg={3}>
                        <HostNavbar />
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                        <Row>
                            <Col xs={12} md={12} className='mt-5 mb-3'>
                                <h2>My Notifications </h2>
                                <p>Find All your notifications in one place. </p>

                            </Col>

                            <Col xs={12} md={12}>
                                <div className="table-panel">
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th> # </th>
                                                <th> Message</th>
                                                <th> Category </th> 
                                                <th> Status </th>
                                                <th> Amount </th>
                                                <th> Action </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='bg-warning'>1.</td>
                                                <td><strong>Username</strong> booking Zubiano Multiplex Apartment (Two-bedroom) </td>
                                                <td>Accomodation</td> 
                                                <td><Badge bg='warning'>Pending</Badge></td>
                                                <td><div className="text-primary">NGN48,343.00</div></td>
                                                <td width={120}>
                                                    <div className="btn btn-primary"><FaEnvelopeOpenText /> </div> 
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='bg-danger'>2.</td>
                                                <td><strong>Username</strong> canceled booking for Flat Egea Petrol Manual - Car101  </td>
                                                <td>Car Rentals</td> 
                                                <td><Badge bg='danger'>Canceled</Badge></td>
                                                <td><div className="text-primary">NGN148,343.00</div></td>
                                                <td width={120}>
                                                    <div className="btn btn-default bg-secondary text-white"><FaEnvelopeOpenText /> </div> 
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='bg-info'>3.</td>
                                                <td><strong>Username</strong> booking for Abuja (ABV) - Calabar (CBQ) Two-way Trips </td>
                                                <td>Flight Tickets</td> 
                                                <td><Badge bg='primary'>Booked</Badge></td>
                                                <td><div className="text-primary">NGN688,343.00</div></td>
                                                <td width={120}>
                                                    <div className="btn btn-primary"><FaEnvelopeOpenText /> </div> 
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='bg-success'>4.</td>
                                                <td><strong>Username</strong> bokking for Abuja (ABV) - Calabar (CBQ) Two-way Trips </td>
                                                <td>Flight Tickets</td> 
                                                <td><Badge bg='success'>Completed</Badge></td>
                                                <td><div className="text-primary">NGN588,343.00</div></td>
                                                <td width={120}> 
                                                    <div className="btn btn-default bg-secondary text-white"><FaEnvelopeOpenText /> </div> 
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>

                                </div>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    </>
  )
}

export default Notifications