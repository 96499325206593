import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { DashboardOutlined } from '@mui/icons-material';
import './RefundThanks.css'
import { Link } from 'react-router-dom';
import ThanksImg from '../../Assets/Img/customer-satisfaction.png';
import NavbarMenu from '../Layouts/Navbar'; 

const RefundThanks = () => {
  return (
    <>
        <NavbarMenu  style={{backgroundColor: '#003B95' }} />
        
        <div className='RefundThanks'>
            <Container className='container'>
                <Row>
                    <Col xs={12} md={6}>
                        <div className="booking-success-panel">
                            <h2>
                                Thanks for contacting us
                            </h2>

                            <p className="lead mt-5 mb-3">
                                How We Work
                            </p>
                            <p>
                                Once we review your request and interview the host, <br/>
                                You’re sure to get your instant refund, <br/>
                                We’re glad you reached out to us. Cheers
                            </p>
                            <Link to={'/user-dashboard'}>
                                <div className="btnMore mt-5 mb-5">
                                    Go to Dashboard <DashboardOutlined />
                                </div>

                            </Link>
                        </div>
                        
                        
                    </Col>
                    
                    <Col xs={12} md={6}>
                        <img src={ThanksImg} alt="thanks-img" />
                    </Col>
                </Row>
            </Container>

        </div>
    </>
  )
}

export default RefundThanks