import React, { useEffect, useState } from "react";  
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OwnerNavbar from './OwnerNavbar'; 
import './ManageHosts.css';
import NavbarMenu from '../Layouts/Navbar';   
import axios from "axios";
import { Table, Badge } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { BaseURL } from "../../config/variables";
import { getAllPaystackTransactions, getUserReports, limit, totalPages } from "../../utils/ownerActions/ownerRequests";
import Spinner from "../Alert/Spinner/Spinner";
import { handleError, handleSuccess } from "../../utils/notifications/toasts";

const GatewayPaymentsPage = () => {
    // show all registered hosts     
    // const hostID = window.localStorage.getItem("hostID");
    const [data, setData] = useState([]); 
    const [msg, setMsg] = useState(""); 
    const [loading, setLoading] = useState(false)
    const [transactions, setTransactions] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [startIndex, setstartIndex] = useState(0)
    



    const fetchTransactions = async()=>{
        setLoading(true);
        setstartIndex((currentPage - 1) * limit)
        const response = await getAllPaystackTransactions(currentPage, limit)
        if (response?.status) {
            // setTransactions(response.transactions)
            // handleSuccess("issue reported successfully.")
            setTransactions(response.transactions)
            setLoading(false);
          
        } else {
          handleError("Could not send report.");
          setLoading(false);
        }
    }

    const handlePrevious = () =>{
        currentPage > 1 ? setCurrentPage(currentPage - 1) : setCurrentPage(currentPage);
        
    }

    const handleNextPage = () =>{
        currentPage < totalPages ? setCurrentPage(currentPage + 1) : setCurrentPage(currentPage);
        
    }

    const goToPage = (number) =>{
        setCurrentPage(number) 
        
    }




    useEffect(() => {
      // scroll to top on page load
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

    }, []);

    useEffect(() => {
        fetchTransactions()
    }, [currentPage]);


    
      

    // console.log({ complaints })
    

  return (
    <>
        <NavbarMenu  style={{backgroundColor: '#003B95' }} />
        
        <div className='ListAccommodation'>
            <Container>
                <Row>
                    <Col xs={12} md={3} lg={3}>
                        <OwnerNavbar />
                    </Col>
                    <Col xs={12} md={9} lg={9}>                               
                      <div className="table-panel">
                        <h2>List of Paystack Transactions </h2>
                        <p>
                          All paystack transactions by maczby users
                        </p>

                            {/* <table class="table table-hover">
                                <thead>
                                    <tr>
                                    <th scope="col">S/N</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Subject</th>
                                    <th scope="col">Message</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <th scope="row">1</th>
                                    <td>Mark</td>
                                    <td>Otto</td>
                                    <td>@mdo</td>
                                    <td>My message</td>
                                    </tr>
                                    
                                    
                                </tbody>
                            </table> */}
                         
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                    <th scope="col">S/N</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Reference ID</th>
                                    <th scope="col">Date</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        transactions.length > 0 ? transactions.map((each, i) =>{
                                            return(
    
                                                <tr key={i}>
                                                    <th scope="row">{ i + 1 + startIndex }</th>
                                                    <td>{each.customer.email}</td>
                                                    <td>{(Number(each.amount) /  100 ).toLocaleString()}</td>
                                                    <td>{each.gateway_response}</td>
                                                    <td>{each.reference}</td>
                                                    <td>{new Date(each.createdAt).toLocaleDateString( 'en-US', { day: 'numeric', month: 'numeric', year: 'numeric'} )}</td>
                                                </tr>
                                            )
                                        })

                                        :

                                        (

                                            <tr>
                                                <th scope="row">-</th>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>

                                        )
                                    }
                                    
                                    
                                </tbody>
                            </table>

                            {/* <nav aria-label="Page navigation example"> */}
                                <ul className="pagination "
                                style={{ alignItems: "right", justifyContent: "right"}}
                                >
                                    <li className="page-item" onClick={handlePrevious}>
                                    <Link className="page-link" to="" aria-label="Previous">
                                        <span aria-hidden="true">&laquo;</span>
                                    </Link>
                                    </li>
                                    <li className="page-item" onClick={()=> goToPage(1)}><Link className="page-link" to="">1</Link></li>
                                    <li className="page-item" onClick={()=> goToPage(2)}><Link className="page-link" to="">2</Link></li>
                                    <li className="page-item" onClick={()=> goToPage(3)}><Link className="page-link" to="">3</Link></li>

                                    <li className="page-item" onClick={handleNextPage}>
                                    <Link className="page-link" to="" aria-label="Next">
                                        <span aria-hidden="true">&raquo;</span>
                                    </Link>
                                    </li>
                                </ul>
                                {/* </nav> */}
                         
                        <Table responsive>
                            {/* <thead>
                                <tr>
                                    <th> S/N </th>
                                    <th> User Name </th>
                                    <th> Subject </th> 
                                    <th> message </th>
                                   
                                </tr>
                            </thead> */}
                           
                            <tbody>

                                
                              {/* {data.map((host, index) => {
                                  return <tr key={index}>
                                      <td>{index+1}.</td>
                                      <td>{host.firstName + " " + host.lastName}</td>
                                      <td>{host.approvalStatus === 0 ? <Badge bg='warning'>Pending Approval</Badge> : <Badge bg='success'>Approved</Badge>}</td>
                                      <td>
                                        <Link to={`/host-preview/${host.hostID}`} className="btn btn-primary"><FaEye /> Preview </Link> &nbsp;                                  
                                      </td> 
                                      <td>
                                          <Link to={`/host-accounts/${host.hostID}`} className="btn btn-success"><PaymentOutlined /> Payments </Link> &nbsp;
                                      </td>
                                      <td>
                                          <Link to={`/host-confirm/?hostID=${host.hostID}&confirm=1&email=${host.emailAddress}`} className="btn btn-warning"><FaCheckDouble /> </Link> &nbsp;
                                          <Link to={`/host-confirm/?hostID=${host.hostID}&confirm=2&email=${host.emailAddress}`} className="btn btn-danger"><RemoveCircleOutline /> </Link> &nbsp;
                                      </td>
                                  </tr>
                              })} */}
                               
                                 
                            </tbody>
                        </Table>
                         
                        {msg && msg}
                      </div>
                             
                    </Col>
                </Row>
            </Container>

            { loading && <Spinner />}
        </div>
    </>
  )
}

export default GatewayPaymentsPage