import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './HostDashboard.css'; 
import { PaymentOutlined, Payments, RoomServiceTwoTone } from '@mui/icons-material'; 
import HostNavbar from './HostNavbar';
import {Link} from 'react-router-dom';
import { FaArrowCircleRight, FaBriefcase, FaCarSide, FaHome, FaKey, FaMapSigns, FaPlaneDeparture, FaUserCheck } from 'react-icons/fa';
import NavbarMenu from '../Layouts/Navbar'; 

const HostProfile = () => {

  return (
    <>
        <NavbarMenu  style={{backgroundColor: '#003B95' }} />
        
        <div className='HostDashboard'>
            {/* Do a mini navbar just below the main navbar */}
            <Container className='container'>
                <Row>
                    <Col xs={12} md={3} lg={3}>
                        <HostNavbar />
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                        <Row>
                            <Col xs={12} md={12} className=''>
                                <h2 className='mb-3'>Account Settings</h2>
                                
                            </Col>
                        </Row>    
                        <Row>
                            <Col xs={12} md={12} lg={12} className=''>                            
                                <p>Manage your personal details below: </p>
                            </Col> 
                            <Col xs={12} md={12} lg={4} className='mb-5'>
                                <div className="listing-cta">
                                    <div className="icon-list">
                                        <FaBriefcase className='icon-big' />
                                    </div>
                                    <h4>Edit Profile</h4>
                                    <p>Update your profile with valid bio information, this is for ease of identification.</p>
                                    <Link to={"/host-profile-info-edit"} className="btn btn-primary">Update Profile <FaArrowCircleRight /></Link>
                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={4} className='mb-5'>
                                <div className="listing-cta">
                                    <div className="icon-list">
                                        <FaUserCheck className='icon-big' />
                                    </div>
                                    <h4>Verify Your Account</h4>
                                    <p>Upload your proof of identity here to get a verified host account!</p>
                                    <Link to="/host-update-kyc" className="btn btn-primary">Upload KYC <FaArrowCircleRight /></Link>
                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={4} className='mb-5'>
                                <div className="listing-cta">
                                    <div className="icon-list">
                                        <FaKey className='icon-big' />
                                    </div>
                                    <h4>Update Password</h4>
                                    <p>Changing your password can help you secure your account, keep it safe!</p>
                                    <Link to={"/host-profile-change-pass"} className="btn btn-primary">Change Password <FaArrowCircleRight /></Link>
                                </div>
                            </Col>
                             
                        </Row>
                         
                    </Col>
                </Row>
            </Container>

        </div>
    </>
  )
}

export default HostProfile