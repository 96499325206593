import React from 'react';
import './Tours.css';
import { Link } from 'react-router-dom';
import { FaDollarSign, FaSearchLocation } from 'react-icons/fa';
import { LocationOn } from '@mui/icons-material'; 

const Tours = (props) => {
    
  return (
    <div className='Tours'>
        <div className="apImg" style={{backgroundImage: `url(${props.src})`}}>
            <img src={props.src} alt={props.alt} className='img-fluid' />
        </div>
        <div className="apTitle">
            <p className="apCaption">
                <b>{props.caption}</b>
            </p>
            <p className="apLocation">
                <LocationOn />{props.location}
            </p> 
            <p className="apPrice">
               <b>₦{props.price}</b>
            </p>
            <small style={{display: "block", clear: "both"}}><b>#{props.hashtag.replace(/\s+/g, '')}</b></small>
        </div>
        <div className="apPreview">
            <Link to={"/tour-preview/"+props.link}><div className='btnApp'>Book</div></Link>
            <div className="clearfix"></div>
        </div>
    </div>
  )
}

export default Tours