import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaArrowRight } from "react-icons/fa";
import "./ListAccommodation.css";
import HostNavbar from "./HostNavbar";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BaseURL } from "../../config/variables";
import Spinner from "../Alert/Spinner/Spinner";

const ListAccommodation = () => {
  // get inputs
  // make axios request - retrieve propertyID from requestadd to localstorage
  // navigate to the next step and add other details with propertyID
  // after the last step, get and clear the propertyID from localstorage

  const navigate = useNavigate();
  const currentHostID = window.localStorage.getItem("hostID");

  const [loading, setLoading ] = useState(false)

  const [inputValue, setInputValue] = useState({
    hostID: currentHostID,
    propertyName: "",
    propertyType: "",
    city: "",
    zipCode: "",
    stateProvince: "",
    country: "",
    streetAddress: "",
    aboutProperty: "",
    hostName: "",
    aboutHost: "",
    phoneNumber: "",
    aboutNeighborhood: "",
  });
  const {
    hostID,
    propertyName,
    propertyType,
    city,
    zipCode,
    stateProvince,
    country,
    streetAddress,
    aboutProperty,
    hostName,
    aboutHost,
    phoneNumber,
    aboutNeighborhood,
  } = inputValue;

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const { data } = await axios.post(`${BaseURL}/addproperty`, {
        ...inputValue,
      });
      const { success, message, propertyID } = data;
      window.localStorage.setItem("propertyID", propertyID);
      console.log(data.propertyID);
      //alert("Property ID is: "+window.localStorage.getItem("propertyID"));

      if (success) {
        handleSuccess(message);
        
          navigate("/list-accommodation-02");
     
        setLoading(false)
      } else {
        setLoading(false)
        handleError(message);
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
    setInputValue({
      ...inputValue,
    });
  };

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <ToastContainer />

      <div className="ListAccommodation">
        <Container>
          <Row>
            <Col xs={12} md={3} lg={3}>
              <HostNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <form className="form-panel" onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <h2>Tell Us About Your Property</h2>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <label htmlFor="propertyName">Property Name:</label>
                    <input
                      type="text"
                      name="propertyName"
                      id="propertyName"
                      className="form-control"
                      value={propertyName}
                      onChange={handleOnChange}
                    />

                    <label htmlFor="propertyType">Type of Property: </label>
                    <select
                      name="propertyType"
                      id="propertyType"
                      className="form-select"
                      value={propertyType}
                      onChange={handleOnChange}
                      style={{ height: "43px"}}
                    >
                      <option value={""}>Choose type</option>
                      <option value={"Hotel"}>Hotel</option>
                      <option value={"Villa"}>Villa</option>
                      <option value={"Apartment"}>Apartment</option>
                      <option value={"Guest House"}>Guest House</option>
                      <option value={"Hostel"}>Hostel</option>
                      <option value={"Other"}>Other</option>
                    </select>

                    <label htmlFor="city">City</label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      className="form-control"
                      value={city}
                      onChange={handleOnChange}
                    />

                    <label htmlFor="zipCode">Zip Code</label>
                    <input
                      type="text"
                      name="zipCode"
                      id="zipCode"
                      className="form-control"
                      value={zipCode}
                      onChange={handleOnChange}
                    />

                    <label htmlFor="stateProvince">State/Province</label>
                    <input
                      type="text"
                      name="stateProvince"
                      id="stateProvince"
                      className="form-control"
                      value={stateProvince}
                      onChange={handleOnChange}
                    />

                    <label htmlFor="country">Country</label>
                    <input
                      type="text"
                      name="country"
                      id="country"
                      className="form-control"
                      value={country}
                      onChange={handleOnChange}
                    />

                    <label htmlFor="streetAddress">Street Address</label>
                    <input
                      type="text"
                      name="streetAddress"
                      id="streetAddress"
                      className="form-control"
                      value={streetAddress}
                      onChange={handleOnChange}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <label htmlFor="aboutProperty">About Property</label><br />
                    <small><b>What should visitors expect?</b></small>
                    <textarea
                      name="aboutProperty"
                      id="aboutProperty"
                      className="form-control"
                      value={aboutProperty}
                      onChange={handleOnChange}
                    ></textarea>

                    <label htmlFor="hostName">Host Name</label>
                    <input
                      type="text"
                      name="hostName"
                      id="hostName"
                      className="form-control"
                      value={hostName}
                      onChange={handleOnChange}
                    />

                    <label htmlFor="aboutHost">About Host</label>
                    <textarea
                      name="aboutHost"
                      id="aboutHost"
                      className="form-control"
                      value={aboutHost}
                      onChange={handleOnChange}
                    ></textarea>

                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      className="form-control"
                      value={phoneNumber}
                      onChange={handleOnChange}
                    />

                    <label htmlFor="aboutNeighborhood">
                      About The Neighborhood
                    </label>
                    <textarea
                      name="aboutNeighborhood"
                      id="aboutNeighborhood"
                      className="form-control"
                      value={aboutNeighborhood}
                      onChange={handleOnChange}
                    ></textarea>
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <button type="submit" className="btnMore mt-5">
                      Create Property <FaArrowRight />
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>

        { loading && <Spinner />}

      </div>
    </>
  );
};

export default ListAccommodation;
