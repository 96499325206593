import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HostNavbar from "./HostNavbar";
import { FaArrowRight } from "react-icons/fa";
import "./ListService.css";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BaseURL } from "../../config/variables";
import Spinner from "../Alert/Spinner/Spinner";

const ListService = () => {
  const navigate = useNavigate();
  const currentHostID = window.localStorage.getItem("hostID");
  const [inputValue, setInputValue] = useState({
    hostID: currentHostID,
    serviceName: "",
    serviceType: "",
    country: "",
    stateProvince: "",
    city: "",
    zipCode: "",
    streetAddress: "",
    aboutService: "",
    hostName: "",
    aboutHost: "",
    phoneNumber: "",
  });
  const {
    hostID,
    serviceName,
    serviceType,
    country,
    stateProvince,
    city,
    zipCode,
    streetAddress,
    aboutService,
    hostName,
    aboutHost,
    phoneNumber,
  } = inputValue;
  const [loading, setLoading ] = useState(false)


  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const { data } = await axios.post(`${BaseURL}/addservice`, {
        ...inputValue,
      });
      const { success, message, serviceID } = data;
      window.localStorage.setItem("serviceID", serviceID);
      console.log(data.serviceID);
      // alert("Service ID is: " + window.localStorage.getItem("serviceID"));

      if (success) {
        handleSuccess(message);
        setTimeout(() => {
          navigate("/list-service-02");
        }, 1000);
        setLoading(false)
      } else {
        setLoading(false)
        handleError(message);
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
    setInputValue({
      ...inputValue,
    });
  };

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <ToastContainer />

      <div className="ListService">
        <Container className="container">
          <Row>
            <Col xs={12} md={3} lg={3}>
              <HostNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <form className="form-panel" onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <h2>Tell Us About Your Service Listing</h2>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <label htmlFor="serviceName">Service Name:</label>
                    <input
                      type="text"
                      name="serviceName"
                      id="serviceName"
                      className="form-control"
                      value={serviceName}
                      onChange={handleOnChange}
                    />
                    <label htmlFor="serviceType">Type of Service: </label>
                    <select
                      name="serviceType"
                      id="serviceType"
                      className="form-select selectt"
                      value={serviceType}
                      onChange={handleOnChange}
                    >
                      <option value={""}>Choose Service type</option>
                      <option value={"SPA"}>SPA</option>
                      <option value={"Photography"}>Photography</option>
                      <option value={"Gym Trainer"}>Gym Trainer</option>
                      <option value={"Private Tutor"}>Private Tutor</option>
                      <option value={"Life Coach"}>Life Coach</option>
                      <option value={"Assistant"}>Assistant</option>
                      <option value={"Companion"}>Companion</option>
                      <option value={"Other"}>Other Attractions</option>
                    </select>

                    <label htmlFor="country">Country</label>
                    <input
                      type="text"
                      name="country"
                      id="country"
                      className="form-control"
                      value={country}
                      onChange={handleOnChange}
                    />

                    <label htmlFor="stateProvince">State/Province</label>
                    <input
                      type="text"
                      name="stateProvince"
                      id="stateProvince"
                      className="form-control"
                      value={stateProvince}
                      onChange={handleOnChange}
                    />

                    <label htmlFor="city">City</label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      className="form-control"
                      value={city}
                      onChange={handleOnChange}
                    />

                    <label htmlFor="zipCode">Zip Code</label>
                    <input
                      type="text"
                      name="zipCode"
                      id="zipCode"
                      className="form-control"
                      value={zipCode}
                      onChange={handleOnChange}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <label htmlFor="streetAddress">Street Address</label>
                    <input
                      type="text"
                      name="streetAddress"
                      id="streetAddress"
                      className="form-control"
                      value={streetAddress}
                      onChange={handleOnChange}
                    />
                    <label htmlFor="aboutService">Describe Your Service</label>{" "}
                    <br />
                    <small>
                      <b>What should visitors expect?</b>
                    </small>
                    <textarea
                      name="aboutService"
                      id="aboutService"
                      className="form-control"
                      value={aboutService}
                      onChange={handleOnChange}
                    ></textarea>
                    <label htmlFor="hostName">Host Name</label>
                    <input
                      type="text"
                      name="hostName"
                      id="hostName"
                      className="form-control"
                      value={hostName}
                      onChange={handleOnChange}
                    />
                    <label htmlFor="aboutHost">About Host</label>
                    <textarea
                      name="aboutHost"
                      id="aboutHost"
                      className="form-control"
                      value={aboutHost}
                      onChange={handleOnChange}
                    ></textarea>
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      className="form-control"
                      value={phoneNumber}
                      onChange={handleOnChange}
                    />
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <button type="submit" className="btnOutline mt-5">
                      Next <FaArrowRight />
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>

        { loading && <Spinner />}

      </div>
    </>
  );
};

export default ListService;
