import { toast } from "react-toastify";





export const handleSuccess = (err) =>{
    toast.success(err, {
      position: "bottom-right",
    });
}

export const handleError = (err) =>{
    toast.error(err, {
      position: "bottom-left",
    });
}