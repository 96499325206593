import React, {useEffect} from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { DashboardOutlined, Link } from '@mui/icons-material';
import './BookingSuccess.css'
import { NavLink } from 'react-router-dom';
import NavbarMenu from './Layouts/Navbar';

const BookingSuccess = () => {
    useEffect(() => {
        // scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, []);
  return (
    <>
        <NavbarMenu  style={{backgroundColor: '#003B95' }} />
        
        <div className='BookingSuccess'>
            <Container className='container'>
                <Row>
                    <Col xs={12} md={12}>
                        <div className="booking-success-panel">
                            <h2>
                                Booking is successful
                            </h2>

                            <p className="lead">
                                What to do next?
                            </p>
                            <p>
                                Your booking has been sent to the host, For urgent preparations upon your arrival, Kindly check your booking details in your dashboard,
                                You can also send an instant message via email or WhatsApp, Enjoy your stay!
                            </p>
                            <NavLink to={'/user-dashboard'}>
                                <div className="btnMore mt-5 mb-5">
                                    Go to Dashboard <DashboardOutlined />
                                </div>
                            </NavLink>
                            
                        </div>
                        
                        
                    </Col>
                    <Col>
                        <div className="success-img">

                        </div>
                    </Col>
                </Row>
            </Container>

        </div>
    </>
  )
}

export default BookingSuccess