import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './OwnerDashboard.css'; 
import { PaymentOutlined, Payments, RoomServiceTwoTone } from '@mui/icons-material'; 
import OwnerNavbar from './OwnerNavbar';
import {Link, useNavigate} from 'react-router-dom';
import { FaArrowCircleRight, FaUsers, FaHome, FaMapSigns, FaPlaneDeparture } from 'react-icons/fa';
import NavbarMenu from "../Layouts/Navbar";
import { useCookies } from "react-cookie";  
import axios from "axios";
import { ToastContainer, toast } from "react-toastify"; 
import { BaseURL } from '../../config/variables';
import { handleError } from '../../utils/notifications/toasts';
import Spinner from '../Alert/Spinner/Spinner';
 
const OwnerDashboard = () => {
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies([]);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false)


  const verifyLoggedInUser = async () => {

    try {

        const token = sessionStorage.getItem("session")

        if(token){
            const owner = localStorage.getItem("owner")
            setUsername(owner); 
            setLoading(false)

            return toast(`Hello ${owner.toUpperCase()}`, {
                position: "top-right",
            })

        } else {
            handleError("Please login!") 
            localStorage.clear();
            await navigate("/owner-login");
        }        
        
    } catch (error) {
        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
    }}
  

  useEffect(() => {
    verifyLoggedInUser()
  }, []);

  return (
    <>
        <NavbarMenu  style={{backgroundColor: '#003B95' }} />

        <div className='HostDashboard'>
            {/* Do a mini navbar just below the main navbar */}
            <Container className='container'>
                <Row>
                    <Col xs={12} md={3} lg={3}>
                        <OwnerNavbar />
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                        <Row>
                            <Col xs={12} md={12} className='mb-5'>
                                <h2 className='mb-3'>Home | Maczuby Headquarters</h2>
                                <div className="welcome-panel">
                                    <h3 className='mb-3'>
                                        Hi { username?.charAt(0)?.toUpperCase() + username?.slice(1) },
                                    </h3>
                                    <p>
                                        Welcome to Maczuby Owner Account, We’re Africa’s leading hospitality and rental services company, 
                                        providing secured transaction between service providers and their customers.
                                    </p> 

                                </div>
                            </Col>
                        </Row>    
                        <Row>
                            <Col xs={12} md={12} lg={12} className='mb-5'>                                
                                To get started, select the category of property you want to manage on maczuby.com
                            </Col>  
                            <Col xs={12} md={12} lg={6} className='mb-5'>
                                <div className="listing-cta">
                                    <div className="icon-list">
                                        <FaUsers className='icon-big' />
                                    </div>
                                    <h4>Hosts</h4>
                                    <p>Manage all registered hosts and partners on Maczuby.com</p>
                                    <Link to="/manage-hosts">
                                    <div className="btn btn-primary">Manage Hosts <FaArrowCircleRight /></div>
                                    </Link>
                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={6} className='mb-5'>
                                <div className="listing-cta">
                                    <div className="icon-list">
                                        <FaUsers className='icon-big' />
                                    </div>
                                    <h4>Users</h4>
                                    <p>Manage all registered users and customers on Maczuby.com</p>
                                    <Link to="/manage-users">
                                    <div className="btn btn-primary">Manage Users <FaArrowCircleRight /></div>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                        {/* <Row>    
                            <Col xs={12} md={6} className='mb-5'>
                                <div className="ewallet-panel">
                                    <p>Total Earnings:</p>
                                    <h3 className='mt-3'>NGN 120,000.00</h3> 
                                    <div className="btn btn-success"> <Payments /> View Accounts</div>
                                </div>
                            </Col>
                            <Col xs={12} md={6} className='mb-5'>
                                <div className="ewallet-panel">
                                    <p>Total Refunds: </p>
                                    <h3 className='mt-3'>NGN 30,500.00</h3>
                                    <div className="btn btn-danger"><PaymentOutlined /> View Refunds</div>
                                </div>
                            </Col>
                        </Row> */}
                    </Col>
                </Row>
            </Container>

            { loading && <Spinner />}

        </div>
        <ToastContainer />

    </>
    
  )
}

export default OwnerDashboard