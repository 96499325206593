import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./HostConfirm.css";
import OwnerNavbar from "./OwnerNavbar";
import { Table, Badge } from "react-bootstrap";
import { FaCheckCircle, FaEye, FaTrash } from "react-icons/fa";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { CancelOutlined } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { BaseURL } from "../../config/variables";

const HostConfirm = () => {
  // Show all bookings for this host
  // const hostID = window.localStorage.getItem("hostID");
  const navigate = useNavigate();

  const [query] = useSearchParams();
  const confirm = query.get("confirm");
  const email = query.get("email");
  const hostID = query.get("hostID");

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  }, []);

  
  if ((confirm > 0 && confirm < 2) && (hostID !== "")) {
    // alert(confirm);
    axios
      .put(`${BaseURL}/confirmhost/${hostID}/${confirm}/${email}/`)
      .then((res) => {
        if (res.data) {
          const { success, message } = res.data;

          if (success) {
            handleSuccess(message);
            setTimeout(() => {
              navigate("/manage-hosts");
            }, 2000);
          } else {
            handleError(message);
          }
        }
      })
      .catch((err) => console.log(err));
  } else if ((confirm > 1) && (hostID !== "")) {
    axios
      .put(`${BaseURL}/rejecthost/${hostID}/${confirm}/${email}/`)
      .then((res) => {
        if (res.data) {
          const { success, message } = res.data;

          if (success) {
            handleSuccess(message);
            setTimeout(() => {
              navigate("/manage-hosts");
            }, 2000);
          } else {
            handleError(message);
          }
        }
      })
      .catch((err) => console.log(err));
  }

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <ToastContainer />

      <div className="MyListings">
        <Container>
          <Row>
            <Col xs={12} md={3} lg={3}>
              <OwnerNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <Row>
                <Col xs={12} md={12} className="mb-3">
                  {confirm == 1 && (
                    <>
                      <h2 className="text-success">Host Account Confirmed </h2>

                      <p>
                        Host was approved successfully, host can now accept bookings <b>Host will be notified...</b>
                      </p>
                      {/* <p>
                        Users can request a refund if order fulfillment is poor!
                      </p> */}
                    </>
                  )}

                  {confirm == 2 && (
                    <>
                      <h2 className="text-danger">Host Account Rejected </h2>

                      <p>
                        Host account was rejected successfully, <b>We'll notify the host and ban the account!</b>
                      </p>
                      <p>
                        Thanks for your apt response!
                      </p>
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HostConfirm;
