import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./FlightsPage.css";
import DubaiImg from "../../Assets/Img/dubai.png";
import ManchesterImg from "../../Assets/Img/manchester.png";
import LasVegasImg from "../../Assets/Img/lasvegas.png";
import apImg from "../../Assets/illustrations/no-data-found.gif"; 
import {
  ArrowRight,
  RestorePage,
  SearchRounded,
  TrendingUpOutlined,
} from "@mui/icons-material";
import DownloadApp from "../LandingPage/DownloadApp";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { BaseURL } from "../../config/variables";
import { handleError } from "../../utils/notifications/toasts";
import Spinner from "../Alert/Spinner/Spinner";

const FlightsPage = () => {

  const [countAdult, setCountAdult] = useState(1);
  const [countChildren, setChildrenCount] = useState(0);
  const navigate = useNavigate();

  const subtractAdult = () => {
    if (countAdult === 0) {
      setCountAdult(countAdult + 1);
    } else {
      setCountAdult(countAdult - 1);
    }
  };

  const addAdult = () => {
    setCountAdult(countAdult + 1);
  };

  const handleAdultCount = (e) => {
    setCountAdult(e.target.value);
  };

  const subChildren = () => {
    if (countChildren === 0) {
      setChildrenCount(countChildren + 1);
    } else {
      setChildrenCount(countChildren - 1);
    }
  };

  const addChildren = () => {
    setChildrenCount(countChildren + 1);
  };

  const handleChildrenCount = (e) => {
    setChildrenCount(e.target.value);
  };

  const [data, setData] = useState([]);
  const [resetData, setResetData] = useState('');
  const [msg, setMsg] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchFrom, setSearchFrom] = useState("");
  const [searchTo, setSearchTo] = useState("");
  const [searchTrip, setSearchTrip] = useState(0);
  const [searchCategory, setSearchCategory] = useState(0);
  const [loading, setLoading] = useState(false)

   
  const handleFilter = (e) => {
    e.preventDefault();
    if (
      searchFrom.length > 2 &&
      searchTo.length > 2 &&
      searchCategory.length > 0 &&
      searchTrip.length > 0
    ) {
        axios
        .get(
          `${BaseURL}/flightquery/?searchFrom=${searchFrom}&searchTo=${searchTo}&searchCategory=${searchCategory}&searchTrip=${searchTrip}`
        )
        .then((res) => {
          if (res.data.status === "Success") {
            setData(res.data.Result);
            setFilteredData(res.data.Result);
            console.log(res.data.Result);
          } else {
            console.log(res.data.Error);
            //console.log("No flight found");
            setMsg("No flight found");
          }
        })
        .catch((err) => console.log(err));
    } 
    
  }



  const fetchFlightList = async ()=>{
    setLoading(true)
    
    try {
      const res = await axios.get(`${BaseURL}/flightlist/`)
  
      if (res.data.status === "Success") {
        setData(res.data.Result);
        setFilteredData(res.data.Result); 

        setLoading(false)
      } else {
        setMsg("No flight found");
        setLoading(false)
      }
      
    } catch (error) {
      setLoading(false)
      handleError("Could not get all list")
    }
  }
    

  useEffect(() => {
    
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

      fetchFlightList()
    
  }, []);

  let url = `${BaseURL}/images/`;

  function onTimeChange(value) {
    let timeSplit = value.split(":"),
      hours,
      minutes,
      meridian;
    hours = timeSplit[0];
    minutes = timeSplit[1];
    if (hours > 12) {
      meridian = "PM";
      hours -= 12;
      return hours + ":" + minutes + " " + meridian;
    } else if (hours < 12) {
      meridian = "AM";
      if (hours == 0) {
        hours = 12;
      }
      return hours + ":" + minutes + " " + meridian;
    } else {
      meridian = "PM";
      return hours + ":" + minutes + " " + meridian;
    }
  } 

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />

      <div className="FlightsPage">
        <div className="main-hero-wrap">
          <div className="flights-hero-wrap">
            <Container>
              <Row>
                <Col xs={12} md={12}>
                  <h2>BOOK A FLIGHT</h2>
                  <h4 className="mb-5">Enjoy the Experience</h4>

                  {/* <div className="flight-search-panel">
                                    <form action='/flight-search'>
                                        <Row>
                                            <Col xs={12} md={3}>
                                                <select id="schedules" className="form-select">
                                                    <option value={'Schedules'}>Schedules</option>
                                                </select>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <select id="oneway" className="form-select">
                                                    <option value={'One way'}>One-Way</option>
                                                    <option value={'Round Trip'}>Round Trip</option>
                                                    <option value={'Multi City'}>Multi-City</option>
                                                </select>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <select id="passengers" className="form-select">
                                                    <option value={'Passengers'}>Passengers</option>
                                                </select> 
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <select id="category" className="form-select">
                                                    <option value={'Economy'}>Economy</option>
                                                    <option value={'Premium Economy'}>Premium Economy</option>
                                                    <option value={'Business'}>Business</option>
                                                    <option value={'First'}>First</option>
                                                    <option value={'Multiple'}>Multiple</option>
                                                </select>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={3}>
                                                <label htmlFor="fromwhere">From Where?</label>
                                                <input type="text" placeholder='e.g. Murtala M. Airport' className="form-control" />
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <label htmlFor="towhere">To Where?</label>
                                                <input type="text" placeholder="e.g. Dubai Int'l Airport" className="form-control" />
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <label htmlFor="departure">Leaving On?</label>
                                                <input type="date" className="form-control" />
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <label htmlFor="departure">Leaving On?</label>
                                                <input type="date" className="form-control" />
                                            </Col>
                                            <Col xs={12} md={12}>
                                                <button className="btn btn-warning mt-5" style={{display:'block', width: '150px', margin: '0 auto'}}><SearchTwoTone /> Search</button>
                                            </Col>
                                        </Row>
                                    </form>
                                    
                                </div> */}
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <Container className="container-2">
          <Row>
            <Col xs={12} md={12} lg={12}>
              <h2>
                Flight Deals <TrendingUpOutlined />{" "}
              </h2>
            </Col>
            <Col xs={12} md={12} lg={4}>
              <div className="flight-search-panel">
                {/* <p>WHAT ARE YOU TRAVELLING FOR?</p> */}
                <form onSubmit={handleFilter}>
                  {/* <label htmlFor="travelPurpose"><input type="checkbox" name="travelPurpose" id="travelPurpose" /> Business {" "}</label> {" "}
                                      <label htmlFor="travelPurpose">{" "}<input type="checkbox" name="travelPurpose" id="travelPurpose" /> Leisure</label>
                                      <br></br> */}

                  <Row>
                    <Col xs={12} md={12} lg={12}>
                      <label htmlFor="fromWhere">From:</label>
                      <input
                        type="text"
                        name="fromWhere"
                        id="fromWhere"
                        placeholder="Abuja"
                        className="form-control mb-3"
                        onChange={(e) => setSearchFrom(e.target.value)}
                      />
                    </Col>
                    <Col xs={12} md={12} lg={12}>
                      <label htmlFor="toWhere">Destination:</label>
                      <input
                        type="text"
                        name="toWhere"
                        id="toWhere"
                        placeholder="Dubai"
                        className="form-control mb-3"
                        onChange={(e) => setSearchTo(e.target.value)}
                      />
                    </Col>
                    {/* <Col xs={12} md={3}>
                        <label htmlFor="departure">Departure</label>
                        <input type="date" className="form-control" />
                      </Col>
                      <Col xs={12} md={3}>
                        <label htmlFor="arrival">Arrival</label>
                        <input type="date" className="form-control" />
                      </Col> */}
                    <Col xs={12} md={12} lg={12}>
                      <label htmlFor="ticketType">Flight Ticket:</label>
                      <select
                        id="ticketType"
                        className="form-select"
                        onChange={(e) => setSearchCategory(e.target.value)}
                      >
                        <option value={""}>Category</option>
                        <option value={"Economy"}>Economy</option>
                        <option value={"Premium Economy"}>
                          Premium Economy
                        </option>
                        <option value={"Business"}>Business</option>
                        <option value={"First"}>First</option>
                        <option value={"Multiple"}>Multiple</option>
                      </select>
                    </Col>
                    <Col xs={12} md={12} lg={12}>
                      <label htmlFor="tripType">Trip Type:</label>
                      <select
                        id="tripType"
                        className="form-select"
                        onChange={(e) => setSearchTrip(e.target.value)}
                      >
                        <option value={""}> Choose Trip</option>
                        <option value={"One Way Trip"}>One-Way Trip</option>
                        <option value={"Round Trip"}>Round Trip</option>
                        <option value={"Multi City"}>Multi-City</option>
                      </select>
                    </Col>
                    {/* <Col xs={12} md={3}>
                        <div className="number_ct">
                          <label htmlFor="adult">Adult (s)</label>
                          <div className="number">
                            <span className="minus" onClick={subtractAdult}>
                              -
                            </span>
                            <input
                              type="text"
                              value={countAdult}
                              onChange={handleAdultCount}
                            />
                            <span className="plus" onClick={addAdult}>
                              +
                            </span>
                          </div>
                        </div>

                        <div className="number_ct">
                          <label htmlFor="children">Children</label>
                          <div className="number">
                            <span className="minus" onClick={subChildren}>
                              -
                            </span>
                            <input
                              type="text"
                              value={countChildren}
                              onChange={handleChildrenCount}
                            />
                            <span className="plus" onClick={addChildren}>
                              +
                            </span>
                          </div>
                        </div>
                      </Col> */}
                      <Col xs={12} md={3}>
                        <button
                          className="btnMore"
                          style={{ marginTop: "40px" }}
                          type="submit" 
                        >
                          Find Deals <SearchRounded />
                        </button>

                      </Col>
                  </Row>
                </form>
              </div>
            </Col>
            <Col xs={12} md={12} lg={8}>
              <Row>
                {filteredData.length > 0 ? (
                  filteredData.map((flight, index) => {
                    return (
                      <Col xs={12} md={6} lg={6} key={index}>
                        <Link to={`/flight-booking/${flight.flightID}`} style={{textDecoration: "none"}}>
                          <div
                            className="travel-deals"
                            style={{
                              backgroundImage: `url(${ flight.flightPic2})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                            }}
                          >
                            <div className="box-overlay">
                              <div className="destination">
                                {flight.fromWhere} <ArrowRight /> {flight.toWhere}
                              </div>
                              <div className="duration">
                                {onTimeChange(flight.departure)} -{" "}
                                {onTimeChange(flight.arrival)}
                              </div>

                              <div className="price">
                                <h5>Reserve Now</h5>
                                <p>₦{numberWithCommas(flight.price)}/ticket</p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </Col>
                    );
                  })
                ) : (
                  <div className="msgBox">
                    <img src={apImg} alt="no data found" />
                  </div>
                )}
              </Row>
            </Col>

            {/* <Col xs={12} md={4}>
              <div
                className="travel-deals"
                style={{
                  backgroundImage: `url(${DubaiImg})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="box-overlay">
                  <div className="destination">
                    Abuja <ArrowRight /> Dubai
                  </div>
                  <div className="duration">Jun 16, 2023 - Jul 17, 2023</div>

                  <div className="price">
                    <h5>Pay Now</h5>
                    <p>N500,250</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div
                className="travel-deals"
                style={{
                  backgroundImage: `url(${ManchesterImg})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="box-overlay">
                  <div className="destination">
                    Lagos <ArrowRight /> Manchester
                  </div>
                  <div className="duration">Jun 16, 2023 - Jul 17, 2023</div>

                  <div className="price">
                    <h5>Pay Now</h5>
                    <p>N608,000</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div
                className="travel-deals"
                style={{
                  backgroundImage: `url(${LasVegasImg})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="box-overlay">
                  <div className="destination">
                    Ghana <ArrowRight /> Las Vegas
                  </div>
                  <div className="duration">Jun 16, 2023 - Jul 17, 2023</div>

                  <div className="price">
                    <h5>Pay Now</h5>
                    <p>N560,000</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div
                className="travel-deals"
                style={{
                  backgroundImage: `url(${DubaiImg})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="box-overlay">
                  <div className="destination">
                    Abuja <ArrowRight /> Dubai
                  </div>
                  <div className="duration">Jun 16, 2023 - Jul 17, 2023</div>

                  <div className="price">
                    <h5>Pay Now</h5>
                    <p>N500,250</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div
                className="travel-deals"
                style={{
                  backgroundImage: `url(${ManchesterImg})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="box-overlay">
                  <div className="destination">
                    Lagos <ArrowRight /> Manchester
                  </div>
                  <div className="duration">Jun 16, 2023 - Jul 17, 2023</div>

                  <div className="price">
                    <h5>Pay Now</h5>
                    <p>N608,000</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div
                className="travel-deals"
                style={{
                  backgroundImage: `url(${LasVegasImg})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="box-overlay">
                  <div className="destination">
                    Ghana <ArrowRight /> Las Vegas
                  </div>
                  <div className="duration">Jun 16, 2023 - Jul 17, 2023</div>

                  <div className="price">
                    <h5>Pay Now</h5>
                    <p>N560,000</p>
                  </div>
                </div>
              </div>
            </Col> */}
          </Row>
        </Container>
        <DownloadApp />


        { loading && <Spinner />}
      </div>
    </>
  );
};

export default FlightsPage;
