import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HostNavbar from "./HostNavbar";
import { FaArrowRight } from "react-icons/fa";
import "./ListFlight.css";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { Table, Badge } from "react-bootstrap";
import { FaEye, FaPenAlt, FaAddressBook } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BaseURL } from "../../config/variables";
import { limit, totalPages } from "../../utils/ownerActions/ownerRequests";
import { handleError } from "../../utils/notifications/toasts";
import { getHostListing } from "../../utils/hostActions/hostRequests";
import Spinner from "../Alert/Spinner/Spinner";

const ListFlight = () => {
  // show all Service
  const hostID = window.localStorage.getItem("hostID");
  const [data, setData] = useState([]);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setstartIndex] = useState(0);

  const fetchflights = async () => {
    setLoading(true);
    setstartIndex((currentPage - 1) * limit);
    const response = await getHostListing(
      "flightlist",
      hostID,
      currentPage,
      limit
    );
    // console.log({response})
    if (response?.status) {
      setData(response.Result);
      setLoading(false);
    } else {
      handleError("Could not get data.");
      setLoading(false);
    }
  };

  const handlePrevious = () => {
    currentPage > 1
      ? setCurrentPage(currentPage - 1)
      : setCurrentPage(currentPage);
  };

  const handleNextPage = () => {
    currentPage < totalPages
      ? setCurrentPage(currentPage + 1)
      : setCurrentPage(currentPage);
  };

  const goToPage = (number) => {
    setCurrentPage(number);
  };

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    fetchflights();
  }, [currentPage]);

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  // console.log(data);

  const handleDelete = (id) => {
    axios
      .delete(`${BaseURL}/deleteflight/` + id)
      .then((res) => {
        if (res.data.Status === "Success") {
          window.location.reload(true);
        } else {
          console.log("Error deleting flight listing!");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />

      <div className="ListFlight">
        <Container className="container">
          <Row>
            <Col xs={12} md={3} lg={3}>
              <HostNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <div className="table-panel">
                <h2>My Flight Listings </h2>
                <p>
                  Find All your Listings in one place. Ensure to set features
                  and rules for your flight listing to make it easy for visitors
                  to book quicker
                </p>
                <p>
                  <Link
                    to={"/list-flight-01"}
                    className="btnMore"
                    style={{ width: "150px", textDecoration: "none" }}
                  >
                    <FaPenAlt /> Add New
                  </Link>
                </p>
                <Table responsive>
                  <thead>
                    <tr>
                      <th> S/N </th>
                      <th> Flight </th>
                      <th> Details </th>
                      <th> Status </th>
                      <th> Features </th>
                      <th> Bookings </th>
                      <th> Action </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((flight, index) => {
                      return (
                        <tr key={index}>
                          <th>{index + 1 + startIndex}.</th>
                          <td>
                            <b>From: </b>
                            {flight.fromWhere} <br /> <b>To: </b>{" "}
                            {flight.toWhere}
                          </td>
                          <td>
                            <b>Departure: </b>
                            {flight.departure}
                            <br /> <b> Arrival: </b>
                            {flight.arrival}
                          </td>
                          <td>
                            {/* {flight.approvalStatus === 0 ? (
                                        <Badge bg="warning">Pending Approval</Badge>
                                        ) : (
                                        <Badge bg="success">Approved</Badge>
                                        )} */}
                            <Badge bg="success">Approved</Badge>
                          </td>
                          <td>
                            <Link
                              to={`/list-flight-01-edit/${flight.flightID}`}
                              className="text-primary"
                            >
                              <FaPenAlt /> Details
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={`/${flight.flightID}`}
                              className="btn btn-success"
                            >
                              <FaAddressBook /> Bookings
                            </Link>
                          </td>
                          <td width={120}>
                            <Link
                              to={`/service-preview/${flight.flightID}`}
                              target="_blank"
                              className="btn btn-primary"
                            >
                              <FaEye /> Preview{" "}
                            </Link>{" "}
                            &nbsp;
                            {/* <button onClick={e => handleDelete(flight.flightID)} className="btn btn-danger"><FaTrash /> </button>  */}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>

                <ul
                  className="pagination "
                  style={{ alignItems: "right", justifyContent: "right" }}
                >
                  <li className="page-item" onClick={handlePrevious}>
                    <Link className="page-link" to="" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </Link>
                  </li>
                  <li className="page-item" onClick={() => goToPage(1)}>
                    <Link className="page-link" to="">
                      1
                    </Link>
                  </li>
                  <li className="page-item" onClick={() => goToPage(2)}>
                    <Link className="page-link" to="">
                      2
                    </Link>
                  </li>
                  <li className="page-item" onClick={() => goToPage(3)}>
                    <Link className="page-link" to="">
                      3
                    </Link>
                  </li>

                  <li className="page-item" onClick={handleNextPage}>
                    <Link className="page-link" to="" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </Link>
                  </li>
                </ul>

                {msg && msg}
              </div>
            </Col>
          </Row>
        </Container>

        {loading && <Spinner />}
      </div>
    </>
  );
};

export default ListFlight;
