import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaArrowRight, FaPenAlt } from "react-icons/fa";
import "./OwnerAccount.css";
import OwnerNavbar from "./OwnerNavbar";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BaseURL } from "../../config/variables";

const OwnerProfileInfoEdit = () => {
  // const { id } = useParams();
  const navigate = useNavigate();
  const currentOwnerID = window.localStorage.getItem("ownerID");

  const [data, setData] = useState({
    ownerID: currentOwnerID,  
  });

  useEffect(() => {
    // scroll to top on page load

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    axios
      .get(`${BaseURL}/fetchownerdetails/${currentOwnerID}`)
      .then((res) => {
        if (res.data.status === "Success") {
          setData({
            ...data,
            firstName: res.data.Result[0].firstName,
            lastName: res.data.Result[0].lastName,
            username: res.data.Result[0].username,
            phoneNumber: res.data.Result[0].phoneNumber,
            emailAddress: res.data.Result[0].emailAddress,
            country: res.data.Result[0].country,
            stateProvince: res.data.Result[0].stateProvince,
            city: res.data.Result[0].city,
            zipCode: res.data.Result[0].zipCode,
            streetAddress: res.data.Result[0].streetAddress,
          });
          // console.log(res.data.Result[0]);
        } else {
          console.log("No such Owner found");
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(data)
    axios
      .put(`${BaseURL}/editownerdetails/${currentOwnerID}`, data)
      .then((res) => {
        if (res.data.success) {
          const { success, message } = res.data;
          
          if (success) {
            handleSuccess(message);
            setTimeout(() => {
              navigate("/owner-profile");
            }, 1000);
          } else {
            handleError(message);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <ToastContainer />

      <div className="ListAccommodation">
        <Container>
          <Row>
            <Col xs={12} md={3} lg={3}>
              <OwnerNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <form className="form-panel" onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <h2>Edit Your Owner Profile</h2>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <label htmlFor="firstName">First Name:</label>
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, firstName: e.target.value })
                      }
                      value={data.firstName || ""}
                    />

                    <label htmlFor="lastName">Last Name:</label>
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, lastName: e.target.value })
                      }
                      value={data.lastName || ""}
                    />

                    
                    <label htmlFor="username">Username</label>
                    <input
                      type="text"
                      name="username"
                      id="username"
                      disabled
                      className="form-control text-primary"
                      value={data.username || ""}
                    />

                    <label htmlFor="emailAddress">Default Email</label>
                    <input
                      type="text"
                      name="emailAddress"
                      id="emailAddress"
                      disabled
                      className="form-control text-primary"
                      value={data.emailAddress || ""}
                    />

                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, phoneNumber: e.target.value })
                      }
                      value={data.phoneNumber || ""}
                    />

                  </Col>

                  <Col xs={12} md={6} lg={6}>

                    <label htmlFor="country">Country</label>
                    <input
                      type="text"
                      name="country"
                      id="country"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, country: e.target.value })
                      }
                      value={data.country || ""}
                    />

                    <label htmlFor="stateProvince">State/Province</label>
                    <input
                      type="text"
                      name="stateProvince"
                      id="stateProvince"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, stateProvince: e.target.value })
                      }
                      value={data.stateProvince || ""}
                    />

                    <label htmlFor="city">City</label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, city: e.target.value })
                      }
                      value={data.city || ""}
                    />

                    <label htmlFor="zipCode">Zip Code</label>
                    <input
                      type="text"
                      name="zipCode"
                      id="zipCode"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, zipCode: e.target.value })
                      }
                      value={data.zipCode || ""}
                    />

                    <label htmlFor="streetAddress">Street Address</label>
                    <input
                      type="text"
                      name="streetAddress"
                      id="streetAddress"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, streetAddress: e.target.value })
                      }
                      value={data.streetAddress || ""}
                    />

                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <button type="submit" className="btnMore mt-5">
                      Edit Profile <FaPenAlt />
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default OwnerProfileInfoEdit;
