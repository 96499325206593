import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HostNavbar from "./HostNavbar";
import { FaArrowRight, FaPenAlt } from "react-icons/fa";
import "./ListVehicle.css";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BaseURL } from "../../config/variables";

const ListVehicle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const currentHostID = window.localStorage.getItem("hostID");

  const [data, setData] = useState({
    hostID: currentHostID,
  });

  useEffect(() => {
    // scroll to top on page load

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    axios
      .get(`${BaseURL}/fetchvehicledetails/${id}`)
      .then((res) => {
        if (res.data.status === "Success") {
          setData({
            ...data,
            vehicleName: res.data.Result[0].vehicleName,
            vehicleModel:  res.data.Result[0].vehicleModel,
            vehicleNo:  res.data.Result[0].vehicleNo,
            city:  res.data.Result[0].city,
            zipCode:  res.data.Result[0].zipCode,
            stateProvince:  res.data.Result[0].stateProvince,
            country:  res.data.Result[0].country,
            streetAddress:  res.data.Result[0].streetAddress,
            aboutVehicle:  res.data.Result[0].aboutVehicle,
            hostName:  res.data.Result[0].hostName,
            aboutHost:  res.data.Result[0].aboutHost,
            phoneNumber:  res.data.Result[0].phoneNumber, 
          });
          // console.log(res.data.Result[0]);
        } else {
          console.log("No properties found");
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(data)
    axios
      .put(`${BaseURL}/editvehicledetails/${id}`, data)
      .then((res) => {
        if (res.data.success) {
          const { success, message } = res.data;

          if (success) {
            handleSuccess(message);
            setTimeout(() => {
              navigate("/list-vehicle");
            }, 1000);
          } else {
            handleError(message);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <ToastContainer />

      <div className="ListVehicle">
        <Container className="container">
          <Row>
            <Col xs={12} md={3} lg={3}>
              <HostNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <form className="form-panel" onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <h2>Edit Vehicle Details</h2>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <label htmlFor="vehicleName">Vehicle Name:</label>
                    <input
                      type="text"
                      name="vehicleName"
                      id="vehicleName"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, vehicleName: e.target.value })
                      }
                      value={data.vehicleName || ""}
                    />
                    <label htmlFor="vehicleModel">Vehicle Model:</label>
                    <input
                      type="text"
                      name="vehicleModel"
                      id="vehicleModel"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, vehicleModel: e.target.value })
                      }
                      value={data.vehicleModel || ""}
                    />
                    <label htmlFor="vehicleNo">Vehicle No:</label>
                    <input
                      type="text"
                      name="vehicleNo"
                      id="vehicleNo"
                      placeholder="e.g. Car 101"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, vehicleNo: e.target.value })
                      }
                      value={data.vehicleNo || ""}
                    />
                    <label htmlFor="country">Country</label>
                    <input
                      type="text"
                      name="country"
                      id="country"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, country: e.target.value })
                      }
                      value={data.country || ""}
                    />
                    <label htmlFor="stateProvince">State/Province</label>
                    <input
                      type="text"
                      name="stateProvince"
                      id="stateProvince"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, stateProvince: e.target.value })
                      }
                      value={data.stateProvince || ""}
                    />
                    <label htmlFor="city">City</label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, city: e.target.value })
                      }
                      value={data.city || ""}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <label htmlFor="zipCode">Zip Code</label>
                    <input
                      type="text"
                      name="zipCode"
                      id="zipCode"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, zipCode: e.target.value })
                      }
                      value={data.zipCode || ""}
                    />
                    <label htmlFor="streetAddress">Street Address</label>
                    <input
                      type="text"
                      name="streetAddress"
                      id="streetAddress"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, streetAddress: e.target.value })
                      }
                      value={data.streetAddress || ""}
                    />
                    <label htmlFor="aboutVehicle">
                      Brief Vehicle (Description)
                    </label>
                    <textarea
                      name="aboutVehicle"
                      id="aboutVehicle"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, aboutVehicle: e.target.value })
                      }
                      value={data.aboutVehicle || ""}
                    ></textarea>

                    <label htmlFor="hostName">Host Name</label>
                    <input
                      type="text"
                      name="hostName"
                      id="hostName"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, hostName: e.target.value })
                      }
                      value={data.hostName || ""}
                    />

                    <label htmlFor="aboutHost">About Host</label>
                    <textarea
                      name="aboutHost"
                      id="aboutHost"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, aboutHost: e.target.value })
                      }
                      value={data.aboutHost || ""}
                    ></textarea>

                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, phoneNumber: e.target.value })
                      }
                      value={data.phoneNumber || ""}
                    />
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <button type="submit" className="btnOutline mt-5">
                      Edit Details <FaPenAlt />
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ListVehicle;
