import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./CarListing.css";
import {
  CarRental,
  CarRentalOutlined,
  GasMeter,
  LocationCityOutlined,
  LocationOnOutlined,
  SearchOutlined,
  TrendingUp,
} from "@mui/icons-material";
import { FaCarAlt, FaGasPump } from "react-icons/fa";
import apImg from "../../Assets/illustrations/no-data-found.gif";
import Cars from "./Cars";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { BaseURL } from "../../config/variables";
import Spinner from "../Alert/Spinner/Spinner";
import { toast } from "react-toastify";

const CarListing = () => {
  const [data, setData] = useState([]);
  const [msg, setMsg] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false)
  

  const handleCity = (e) => { 
    const searchCity = e.target.value; 
    if (searchCity !== "") {
      const newFilter = data.filter((value) => {
        return (
          value.country.toLowerCase().includes(searchCity.toLowerCase()) ||
          value.stateProvince.toLowerCase().includes(searchCity.toLowerCase())
        );
      });

      setFilteredData(newFilter);
    } else {
      setFilteredData(data);
    }    
  };

  const handleClass = (e) => { 
    const searchClass = e.target.value; 
    if (searchClass !== "") {
      const newFilter = data.filter((value) => {
        return (           
          value.vehicleClass === searchClass
        );
      });

      setFilteredData(newFilter);
    } else {
      setFilteredData(data);
    }
  };

  const handleFuel = (e) => { 
    const searchFuel = e.target.value; 
    // alert(searchFuel);
    if (searchFuel.length > 0) {
      const newFilter = data.filter((value) => {
        return (           
          value.fuelType === searchFuel
        );
      });

      setFilteredData(newFilter);
    } else {
      setFilteredData(data);
    }   
  };

  const handleTransmission = (e) => { 
    const searchTransmission = e.target.value; 
    if (searchTransmission !== "") {
      const newFilter = data.filter((value) => {
        return (             
            value.transmission === searchTransmission
        );
      });

      setFilteredData(newFilter);
    } else {
      setFilteredData(data);
    } 
  };

  const handleError = (err) =>{
    toast.error(err, {
      position: "bottom-left",
    });
    setLoading(false)
  }


  const fetchVehiclesList = async ()=>{
    setLoading(true)
    
    try {
      const res = await axios.get(`${BaseURL}/vehiclelist/`)
  
      if (res.data.status === "Success") {
        setData(res.data.Result);
        setFilteredData(res.data.Result); 
        // console.log(res.data.Result);
        setLoading(false)
      } else {
        // console.log("No vehicles found");
        setMsg("No vehicles found");
        setLoading(false)
      }
      
    } catch (error) {
      handleError("Could not get all list")
    }
  }




  useEffect(() => {
    // scroll to top on page load
    

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    // axios
    //   .get(`${BaseURL}/api/vehiclelist/`)
    //   .then((res) => {
    //     if (res.data.status === "Success") {
    //       setData(res.data.Result);
    //       setFilteredData(res.data.Result);
    //       // console.log(res.data.Result);
    //     } else {
    //       console.log("No vehicles found");
    //       setMsg("No vehicles found");
    //     }
    //   })
    //   .catch((err) => console.log(err));
      fetchVehiclesList()
      
  }, []);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

   

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />

      <div className="CarListing">
        <Container className="container">
          <Row>
            <Col xs={12} md={12} lg={12}>
              <h2 style={{ textAlign: "" }}>
                <CarRental /> Car Listing
              </h2>
            </Col>
            <Col xs={12} md={12} lg={4}>
              
              <form className="search-panel">
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <h4>
                      Search Car Deals <TrendingUp />
                    </h4>
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <label htmlFor="destination">
                      Pickup / Drop-off Location{" "}
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text" id="destination">
                        <LocationOnOutlined />{" "}
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Dubai"
                        aria-label="Destination"
                        aria-describedby="destination"
                        onChange={handleCity}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <label htmlFor="vehicleclass">Vehicle Class</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text" id="vehicleclass">
                        <FaCarAlt />{" "}
                      </span>
                      <select
                        className="form-select"
                        aria-label="Vehicleclass"
                        aria-describedby="vehicleclass"
                        onChange={handleClass} 
                      >
                        <option value={""}>Choose type</option>
                        <option value={"Economy"}>Economy</option>
                        <option value={"Luxury"}>Luxury</option>
                        <option value={"Medium"}>Medium</option>
                        <option value={"7+ Seats"}>7+ Seats</option>
                        <option value={"SUV"}>SUV</option>
                        <option value={"Other"}>Other</option>
                      </select>
                    </div>
                  </Col>
                  {/* <Col xs={12} md={12} lg={12}>
                    <label htmlFor="fueltype">Fuel Type</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text" id="fueltype">
                        <FaGasPump />{" "}
                      </span>
                      <select
                        className="form-select"
                        aria-label="Fueltype"
                        aria-describedby="fueltype"
                        onChange={handleFuel} 
                      >
                        <option value={""}>Choose type</option>
                        <option value={"Petrol"}>Petrol</option>
                        <option value={"Diesel"}>Diesel</option>
                      </select>
                    </div>
                  </Col> */}
                  <Col xs={12} md={12} lg={12}>
                    <label htmlFor="transmission">Transmission</label>
                    <div className="input-group mb-3">
                      <span className="input-group-text" id="transmission">
                        <GasMeter />{" "}
                      </span>
                      <select
                        className="form-select"
                        aria-label="transmission"
                        aria-describedby="transmission"
                        onChange={handleTransmission} 
                      >
                        <option value={""}>Choose type</option>
                        <option value={"Manual"}>Manual</option>
                        <option value={"Automatic"}>Automatic</option>
                      </select>
                    </div>
                  </Col>


                  {/* <Col xs={12} md={12} lg={3}>
                    <label htmlFor="pickupdate">Pick up date</label>
                    <div className="mb-3">
                      <input
                        type="date"
                        className="form-control"
                        aria-label="Pickupdate"
                        aria-describedby="pickupdate"
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={12} lg={3}>
                    <label htmlFor="pickuptime">Pick up time</label>
                    <div className="mb-3">
                      <input
                        type="time"
                        className="form-control"
                        aria-label="Pickuptime"
                        aria-describedby="pickuptime"
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={12} lg={3}>
                    <label htmlFor="dropoffdate">Drop off date</label>
                    <div className="mb-3">
                      <input
                        type="date"
                        className="form-control"
                        aria-label="Dropoffdate"
                        aria-describedby="dropoffdate"
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={12} lg={3}>
                    <label htmlFor="dropofftime">Drop off time</label>
                    <div className="mb-3">
                      <input
                        type="time"
                        className="form-control"
                        aria-label="Dropofftime"
                        aria-describedby="dropofftime"
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={12} lg={3}>
                    <button
                      type="submit"
                      onClick={handleFilter}
                      className="btnMore mt-5"
                    >
                      Find Deals <SearchOutlined />
                    </button>
                  </Col> */}
                </Row>
              </form>
            </Col>
            <Col xs={12} md={12} lg={8}>
              <Row>
                <Col xs={12} md={12} lg={12} className="allCars">
                  {filteredData.length > 0 ? (
                    filteredData.map((vehicle, index) => {
                      return (
                        <Col xs={12} md={12} lg={12} key={index}>
                          <Cars
                            carTitle={vehicle.vehicleName}
                            img={
                              vehicle.vehiclePic1
                            }
                            vehicleClass={vehicle.vehicleClass}
                            status={vehicle.approvalStatus}
                            fuel={vehicle.fuelType}
                            transmission={vehicle.transmission}
                            persons={vehicle.seats}
                            days={vehicle.availability}
                            amount={numberWithCommas(vehicle.price*vehicle.availability)}
                            daily={numberWithCommas((vehicle.price*vehicle.availability) / vehicle.availability)}
                            link={vehicle.vehicleID}
                          />
                        </Col>
                      );
                    })
                  ) : (
                    <div className="msgBox">
                      <img src={apImg} alt="no data found" />
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        { loading && <Spinner />}
      </div>
    </>
  );
};

export default CarListing;
