import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Login.css";
import { FaArrowRight, FaSignInAlt } from "react-icons/fa";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import NavbarMenu from "../Layouts/Navbar";
import { Cookies, useCookies } from "react-cookie";
import { useGetHostID } from "../../Hooks/useGetHostID";
import { BaseURL } from "../../config/variables";
//import { useGetUserID } from "../../Hooks/useGetUserID";

const UserResetPass = () => {
  const navigate = useNavigate();

  const [cookies, removeCookie] = useCookies([]);
  //const userID = useGetUserID();
  const hostID = useGetHostID();

  if (hostID) {
    window.localStorage.removeItem("hostID");
    //navigate("/host-login");
    removeCookie("token", "");
    window.location.reload();
  }

  //   if(userID){
  //     navigate("/login");
  //   window.location.reload();
  //  }
  const [query] = useSearchParams();
  const q = query.get("q");
  const id = query.get("id");
  const pv = query.get("verify");
  const urlBack = "/" + q + "/" + id;
  const verify = pv;

  const [inputValue, setInputValue] = useState({
    emailAddress: verify,
    password: "",
  });

  const { emailAddress, password } = inputValue;

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    if (verify !== "") {
      axios
        .put(`${BaseURL}/login/${verify}`)
        .then((res) => {
          if (res.data.success) {
            const { success, message } = res.data;
            // verify = '';
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-left",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${BaseURL}/userresetpass`,
        {
          ...inputValue,
        },
        { withCredentials: true }
      );

      console.log(data);
      const { success, message, userID } = data;

      if (success) {
        handleSuccess(message);
        setTimeout(() => {
          navigate("/login");
          window.location.reload();
        }, 1000);
      } else {
        handleError(message);
      }
    } catch (error) {
      console.log(error);
    }

    setInputValue({
      ...inputValue,
    });
  };

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />

      <div className="LoginPage">
        <Container className="container">
          <ToastContainer />
          <Row className="justify-items-center">
            <Col xs={12} md={12}>
              <div className="login-panel">
                {verify && (
                  <div className="alert alert-success">
                    Email address was found!
                  </div>
                )}
                <h3>Reset  - User Password</h3>
                <p>Enter your new password to restore your host account!</p>
                <form onSubmit={handleSubmit}>
                  {/* <label htmlFor="Email Address">Email Address</label> */}
                  <input
                    type="hidden"
                    name="emailAddress"
                    disabled
                    id="emailAddress"
                    className="form-control mb-3"
                    value={verify}
                    onChange={handleOnChange}
                  />
                  <p>
                    <b>Email Address: </b>
                    {emailAddress}
                  </p>
                  <h5>Create password</h5>
                  <p>
                    Use a minimum of 8 characters, including uppercase, lower
                    case letters, numbers and special characters.
                  </p>
                  <label htmlFor="password">New Password</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="form-control mb-3"
                    value={password}
                    onChange={handleOnChange}
                  />

                  <button
                    type="submit"
                    className="btnMore"
                    style={{ padding: "20px 30px", width: "100%" }}
                  >
                    Reset Password <FaArrowRight />
                  </button>
                  <hr />
                  <p>
                    Already a User?{" "}
                    <Link style={{ textDecoration: "none" }} to={"/login"}>
                      Sign In <FaSignInAlt />
                    </Link>
                  </p>
                </form>
                <hr /> 
                <div className="policy">
                  <p>
                    New Host?{" "}
                    <Link to={"/register"}>
                      Sign Up <FaSignInAlt />
                    </Link>
                  </p>
                  <hr />
                  <p>
                    By sign in or creating an account you agree with our &nbsp;
                    <Link to="/">Terms & Conditions</Link> and{" "}
                    <Link to="/">Privacy Policy</Link>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserResetPass;
