import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HostNavbar from "./HostNavbar";
import {
  FaArrowLeft,
  FaArrowRight,
  FaCamera,
  FaCheck,
  FaDollarSign,
  FaInfoCircle,
  FaPenAlt,
  FaRegSave,
  FaUpload,
} from "react-icons/fa";
import "./ListVehicle.css";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BaseURL } from "../../config/variables";

const ListVehicle02 = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const currentvehicleID = window.localStorage.getItem("vehicleID");

  const [data, setData] = useState({
    vehicleID: currentvehicleID,
  });

  useEffect(() => {
    // scroll to top on page load

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    axios
      .get(`${BaseURL}/fetchvehiclefeatures/${id}`)
      .then((res) => {
        if (res.data.status === "Success") {
          setData({
            ...data,
            vehicleClass: res.data.Result[0].vehicleClass,
            fuelType: res.data.Result[0].fuelType,
            transmission: res.data.Result[0].transmission,
            engineSize: res.data.Result[0].engineSize,
            enginePower: res.data.Result[0].enginePower,
            fuelConsumption: res.data.Result[0].fuelConsumption,
            seats: res.data.Result[0].seats,
            doors: res.data.Result[0].doors,
            airConditioner: res.data.Result[0].airConditioner,
            availability: res.data.Result[0].availability,
            pickUpFrom: res.data.Result[0].pickUpFrom,
            pickUpUntil: res.data.Result[0].pickUpUntil,
            dropOffFrom: res.data.Result[0].dropOffFrom,
            dropOffUntil: res.data.Result[0].dropOffUntil,
            price: res.data.Result[0].price,
            discount: res.data.Result[0].discount,
            agreeCancel: res.data.Result[0].agreeCancel,
          });
          console.log(res.data.Result[0]);
        } else {
          console.log("No properties found");
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(data)
    axios
      .put(`${BaseURL}/editvehiclefeatures/${id}`, data)
      .then((res) => {
        if (res.data.success) {
          const { success, message } = res.data;

          if (success) {
            handleSuccess(message);
            setTimeout(() => {
              navigate("/list-vehicle");
            }, 1000);
          } else {
            handleError(message);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <ToastContainer />

      <div className="ListVehicle">
        <Container className="container">
          <Row>
            <Col xs={12} md={3} lg={3}>
              <HostNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <form className="form-panel" onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <h2>Vehicle Features:</h2>
                  </Col>
                  <Col xs={12} md={12} lg={6}>
                    <label htmlFor="vehicleClass">Vehicle Class: </label>
                    <select
                      name="vehicleClass"
                      id="vehicleClass"
                      className="form-select selectt"
                      onChange={(e) =>
                        setData({ ...data, vehicleClass: e.target.value })
                      }
                      value={data.vehicleClass || ""}
                    >
                      <option value={""}>Choose type</option>
                      <option value={"Economy"}>Economy</option>
                      <option value={"Luxury"}>Luxury</option>
                      <option value={"Medium"}>Medium</option>
                      <option value={"7+ Seats"}>7+ Seats</option>
                      <option value={"SUV"}>SUV</option>
                      <option value={"Other"}>Other</option>
                    </select>

                    <label htmlFor="fuelType">Fuel Type: </label>
                    <select
                      name="fuelType"
                      id="fuelType"
                      className="form-select selectt"
                      onChange={(e) =>
                        setData({ ...data, fuelType: e.target.value })
                      }
                      value={data.fuelType || ""}
                    >
                      <option value={""}>Choose type</option>
                      <option value={"Diesel "}>Diesel </option>
                      <option value={"Petrol"}>Petrol</option>
                    </select>
                    <label htmlFor="transmission">Transmission: </label>
                    <select
                      name="transmission"
                      id="transmission"
                      className="form-select selectt"
                      onChange={(e) =>
                        setData({ ...data, transmission: e.target.value })
                      }
                      value={data.transmission || ""}
                    >
                      <option value={""}>Choose type</option>
                      <option value={"Manual"}>Manual</option>
                      <option value={"Automatic"}>Automatic</option>
                    </select>

                    <label htmlFor="engineSize">Base Engine Size</label>
                    <input
                      type="number"
                      name="engineSize"
                      id="engineSize"
                      placeholder="e.g. 1400"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, engineSize: e.target.value })
                      }
                      value={data.engineSize || ""}
                    />

                    <label htmlFor="enginePower">Engine Power</label>
                    <input
                      type="number"
                      name="enginePower"
                      id="enginePower"
                      placeholder="e.g. 95"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, enginePower: e.target.value })
                      }
                      value={data.enginePower || ""}
                    />
                  </Col>
                  <Col xs={12} md={12} lg={6}>
                    <label htmlFor="fuelConsumption">Fuel Consumption</label>
                    <input
                      type="text"
                      name="fuelConsumption"
                      id="fuelConsumption"
                      placeholder="e.g. 5.3"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, fuelConsumption: e.target.value })
                      }
                      value={data.fuelConsumption || ""}
                    />

                    <label htmlFor="seats">No of seats</label>
                    <input
                      type="number"
                      name="seats"
                      id="seats"
                      placeholder="e.g. 5"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, seats: e.target.value })
                      }
                      value={data.seats || ""}
                    />

                    <label htmlFor="doors">No of Doors</label>
                    <input
                      type="number"
                      name="doors"
                      id="doors"
                      placeholder="e.g. 4"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, doors: e.target.value })
                      }
                      value={data.doors || ""}
                    />

                    <label htmlFor="airConditioner">Air Conditioner?</label>
                    <select
                      name="airConditioner"
                      id="airConditioner"
                      className="form-select selectt"
                      onChange={(e) =>
                        setData({ ...data, airConditioner: e.target.value })
                      }
                      value={data.airConditioner || ""}
                    >
                      <option value={""}>Choose Y/N</option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </select>
                    <label htmlFor="availability">Availability (Days)</label>
                    <input
                      type="text"
                      name="availability"
                      id="availability"
                      placeholder="e.g. 6 Days"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, availability: e.target.value })
                      }
                      value={data.availability || ""}
                    />
                  </Col>
                  <Col xs={12} md={12} lg={12} className="mt-5">
                    <h4>Set Vehicle Rules</h4>
                    <Row>
                      <Col xs={12} md={12} lg={6}>
                        <Row className="mt-5">
                          <p className="lead">
                            <strong>Pick Up</strong>
                          </p>
                          <Col xs={12} md={12} lg={6}>
                            <label htmlFor="pickUpFrom">From: </label>
                            <input
                              type="time"
                              name="pickUpFrom"
                              id="pickUpFrom"
                              className="form-control"
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  pickUpFrom: e.target.value,
                                })
                              }
                              value={data.pickUpFrom || ""}
                            />
                          </Col>
                          <Col xs={12} md={12} lg={6}>
                            <label htmlFor="pickUpUntil">Until: </label>
                            <input
                              type="time"
                              name="pickUpUntil"
                              id="pickUpUntil"
                              className="form-control"
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  pickUpUntil: e.target.value,
                                })
                              }
                              value={data.pickUpUntil || ""}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={12} lg={6}>
                        <Row className="mt-5">
                          <p className="lead">
                            <strong>Drop Off</strong>
                          </p>

                          <Col xs={12} md={12} lg={6}>
                            <label htmlFor="dropOffFrom">From: </label>
                            <input
                              type="time"
                              name="dropOffFrom"
                              id="dropOffFrom"
                              className="form-control"
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  dropOffFrom: e.target.value,
                                })
                              }
                              value={data.dropOffFrom || ""}
                            />
                          </Col>
                          <Col xs={12} md={12} lg={6}>
                            <label htmlFor="dropOffUntil">Until: </label>
                            <input
                              type="time"
                              name="dropOffUntil"
                              id="dropOffUntil"
                              className="form-control"
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  dropOffUntil: e.target.value,
                                })
                              }
                              value={data.dropOffUntil || ""}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={12} lg={6} className="mt-5">
                    <h4>How much do you want to charge per day?</h4>
                    <label htmlFor="price">
                      Price guests pay (₦
                      ):{" "}
                      <small>
                        Amount in dollars will be paid in your exchange rate
                      </small>{" "}
                    </label>
                    <input
                      type="number"
                      name="price"
                      id="price"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, price: e.target.value })
                      }
                      value={data.price || ""}
                    />
                    <small>Including taxes, commission, and fees</small>

                    <h4 className="mt-5">
                      {" "}
                      Get guest’s attention with a 20% discount:{" "}
                    </h4>
                    <select
                      name="discount"
                      id="discount"
                      className="form-select selectt"
                      onChange={(e) =>
                        setData({ ...data, discount: e.target.value })
                      }
                      value={data.discount || ""}
                    >
                      <option value={""}>Choose Y/N?</option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </select>
                    <p>
                      Give 20% off your first 3 bookings for 90 days, whichever
                      comes first.
                    </p>
                  </Col>

                  <Col xs={12} md={12} lg={6} className="mt-5">
                    <h4>Cancellation policy</h4>
                    <p>
                      This policy is set at the property level - any changes
                      made will apply to all units.{" "}
                    </p>
                    <p>
                      <FaCheck className="icon" /> Guests can cancel their
                      bookings for free up to 1 day before their arrival
                    </p>
                    <p>
                      <FaCheck className="icon" /> Guests who cancel can within
                      24 hours will have their cancellation fee waived{" "}
                    </p>

                    <h4>
                      Price & cancellation policy{" "}
                      <FaInfoCircle className="icon" />
                    </h4>
                    <p>
                      <FaCheck className="icon" /> Guests will pay 10% less than
                      the standard rate for a non-refundable{" "}
                    </p>
                    <p>
                      <FaCheck className="icon" /> Guests can’t cancel their
                      bookings for free anytime{" "}
                    </p>
                    <h4>Agree to cancellation policy</h4>
                    <select
                      name="agreeCancel"
                      id="agreeCancel"
                      className="form-select selectt"
                      onChange={(e) =>
                        setData({ ...data, agreeCancel: e.target.value })
                      }
                      value={data.agreeCancel || ""}
                    >
                      <option value={""}>Choose Y/N?</option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </select>
                  </Col>

                  {/* <Col xs={12} md={12} lg={4} className='mt-3'>
                                <button className='btnOutline mt-5'><FaArrowLeft />Previous </button>
                                
                            </Col> */}
                  <Col xs={12} md={12} lg={12} className="mt-3">
                    <button type="submit" className="btnOutline mt-5">
                      Edit Features <FaPenAlt />
                    </button>
                  </Col>
                  {/* <Col xs={12} md={12} lg={4} className='mt-3'>
                                <button className='btnOutline mt-5'>Save Draft <FaRegSave /></button>
                                
                            </Col> */}
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ListVehicle02;
