import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ArrowRight, SearchTwoTone, TrendingUpOutlined } from '@mui/icons-material';
import AirlineIcon from '../../Assets/Img/canada.svg';
import './FlightsListing.css';
import { FaEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import NavbarMenu from '../Layouts/Navbar'; 

const FlightsListing = () => {
  return (
    <>
        <NavbarMenu  style={{backgroundColor: '#003B95' }} />
        
        <div className='FlightsListing'>
            <div className="main-hero-wrap"> 
                <Container>
                    <Row>
                        <Col xs={12} md={12}> 
                            <div className="flight-search-panel">
                                <form> 
                                    <Row>
                                        <Col xs={12} md={3}>
                                            <label htmlFor="fromwhere">From Where?</label>
                                            <input type="text" placeholder='e.g. Murtala M. Airport' className="form-control" />
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <label htmlFor="towhere">To Where?</label>
                                            <input type="text" placeholder="e.g. Dubai Int'l Airport" className="form-control" />
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <label htmlFor="departure">Leaving On?</label>
                                            <input type="date" className="form-control" />
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <label htmlFor="departure">Leaving On?</label>
                                            <input type="date" className="form-control" />
                                        </Col>
                                        {/* <Col xs={12} md={12}>
                                            <button className="btn btn-warning mt-5" style={{display:'block', width: '150px', margin: '0 auto'}}><SearchTwoTone /> Search</button>
                                        </Col> */}
                                    </Row>
                                </form>
                                
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={4} className='mt-5'>
                            {/* Filter Search */}
                            <h2 className='text-center'>Abuja - Dubai</h2>
                            
                            <div className="filter-search">
                                <p className='lead'>Price</p>
                                <label class="form-label" style={{textAlign: 'justify', display:'block'}}>N500,000 <span>N800,000</span></label>
                                <input type="range" class="form-range" min="500000" max="800000" />

                                <p className='lead mt-5'>Stops</p> 
                                <button className="btn btn-default bg-btn">0 <br/> <small>N500,000</small></button>
                                <button className="btn btn-default bg-btn">1 <br/>  <small>N740,000</small></button>
                                <button className="btn btn-default bg-btn">1+ <br/>  <small>N860,000</small></button>

                                <p className='lead mt-5'>Airlines</p> 
                                <label className='form-label' style={{textAlign: 'justify', display:'block'}}><input type="radio" name='airline' className="airlines" /> &nbsp; Multiple <span>N899,990 Above</span></label>
                                <label className='form-label' style={{textAlign: 'justify', display:'block'}}><input type="radio" name='airline' className="airlines" /> &nbsp; Qatar Airways <span>N899,990 Above</span></label>
                                <label className='form-label' style={{textAlign: 'justify', display:'block'}}><input type="radio" name='airline' className="airlines" /> &nbsp; Ethiopian Airline <span>N899,990 Above</span></label>

                                <div className="clearfix"></div>
                            </div>
    
                        </Col>
                        <Col xs={12} md={8}>
                            {/* Search Results */}
                            <div className="search-results mt-5">
                                <Row>
                                    <Col xs={12} md={12}>
                                        <div className="tickets">
                                            
                                            <Row>
                                                <Col xs={12} md={9}>
                                                    <Row>
                                                        <Col xs={12} md={4}><img src={AirlineIcon} alt="Airline-icon" className='roundedCircle' />&nbsp; Canada Airlines</Col>
                                                        <Col xs={12} md={8} className='duration'>
                                                            <div className="from"><b>20:45</b> (ABV) Abuja</div>
                                                            <div className="time">30h - 45m <br /> 1 Stop</div>
                                                            <div className="to"><b>07:45</b> (DOH) DOHA</div>
                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} md={4}><img src={AirlineIcon} alt="Airline-icon" className='roundedCircle' />&nbsp; Canada Airlines</Col>
                                                        <Col xs={12} md={8} className='duration'>
                                                            <div className="from"><b>20:45</b> (ABV) Abuja</div>
                                                            <div className="time">30h - 45m <br /> 1 Stop</div>
                                                            <div className="to"><b>07:45</b> (DOH) DOHA</div>
                                                        </Col>

                                                    </Row>
                                                
                                                </Col>
                                                
                                                <Col xs={12} md={3} className='cta-btn'>
                                                    <h5>N506,768</h5>
                                                    <Link to={'/flight-booking'}><div className="btn btn-primary"> Book Now <FaEye /> </div></Link>
                                                </Col>
                                            </Row>
                                        
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <div className="tickets">
                                            
                                            <Row>
                                                <Col xs={12} md={9}>
                                                    <Row>
                                                        <Col xs={12} md={4}><img src={AirlineIcon} alt="Airline-icon" className='roundedCircle' />&nbsp; Canada Airlines</Col>
                                                        <Col xs={12} md={8} className='duration'>
                                                            <div className="from"><b>20:45</b> (ABV) Abuja</div>
                                                            <div className="time">30h - 45m <br /> 1 Stop</div>
                                                            <div className="to"><b>07:45</b> (DOH) DOHA</div>
                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} md={4}><img src={AirlineIcon} alt="Airline-icon" className='roundedCircle' />&nbsp; Canada Airlines</Col>
                                                        <Col xs={12} md={8} className='duration'>
                                                            <div className="from"><b>20:45</b> (ABV) Abuja</div>
                                                            <div className="time">30h - 45m <br /> 1 Stop</div>
                                                            <div className="to"><b>07:45</b> (DOH) DOHA</div>
                                                        </Col>

                                                    </Row>
                                                
                                                </Col>
                                                
                                                <Col xs={12} md={3} className='cta-btn'>
                                                    <h5>N506,768</h5>
                                                    <Link to={'/flight-booking'}><div className="btn btn-primary"> Book Now <FaEye /> </div></Link>
                                                </Col>
                                            </Row>
                                        
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <div className="tickets">
                                            
                                            <Row>
                                                <Col xs={12} md={9}>
                                                    <Row>
                                                        <Col xs={12} md={4}><img src={AirlineIcon} alt="Airline-icon" className='roundedCircle' />&nbsp; Canada Airlines</Col>
                                                        <Col xs={12} md={8} className='duration'>
                                                            <div className="from"><b>20:45</b> (ABV) Abuja</div>
                                                            <div className="time">30h - 45m <br /> 1 Stop</div>
                                                            <div className="to"><b>07:45</b> (DOH) DOHA</div>
                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} md={4}><img src={AirlineIcon} alt="Airline-icon" className='roundedCircle' />&nbsp; Canada Airlines</Col>
                                                        <Col xs={12} md={8} className='duration'>
                                                            <div className="from"><b>20:45</b> (ABV) Abuja</div>
                                                            <div className="time">30h - 45m <br /> 1 Stop</div>
                                                            <div className="to"><b>07:45</b> (DOH) DOHA</div>
                                                        </Col>

                                                    </Row>
                                                
                                                </Col>
                                                
                                                <Col xs={12} md={3} className='cta-btn'>
                                                    <h5>N506,768</h5>
                                                    <Link to={'/flight-booking'}><div className="btn btn-primary"> Book Now <FaEye /> </div></Link>
                                                </Col>
                                            </Row>
                                        
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    </>
  )
}

export default FlightsListing