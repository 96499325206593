import React, { useEffect, useState } from "react";  
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OwnerNavbar from './OwnerNavbar'; 
import './ManageHosts.css';
import NavbarMenu from '../Layouts/Navbar';   
import axios from "axios";
import { Table, Badge } from 'react-bootstrap';
import { FaCheckDouble, FaEye, FaPenAlt, FaRemoveFormat, FaTrash } from 'react-icons/fa';
import { Link } from "react-router-dom";
import { PaymentOutlined, RemoveCircleOutline } from "@mui/icons-material";
import { BaseURL } from "../../config/variables";
import { approveOrBanHost, getAllHosts, limit, totalPages } from "../../utils/ownerActions/ownerRequests";
import { handleError } from "../../utils/notifications/toasts";
import Spinner from "../Alert/Spinner/Spinner";
import { toast } from "react-toastify";

const ManageHosts = () => {
    // show all registered hosts     
    // const hostID = window.localStorage.getItem("hostID");
    const [data, setData] = useState([]); 
    const [msg, setMsg] = useState(""); 
    const [loading, setLoading] = useState(false)
    const [hostlist, setHostlist] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [startIndex, setstartIndex] = useState(0)
    const [statusText, setStatusText] = useState("")
    const [hostID, setHostID] = useState(0)
    const [hostEmail, setHostEmail] = useState("")

    const fetchHostlist = async()=>{
      setLoading(true);
      setstartIndex((currentPage - 1) * limit)
      const response = await getAllHosts(currentPage, limit)
      if (response?.status) {
        setData(response.hosts)
          setLoading(false);
          console.log(response)
        
      } else {
        handleError("Could not send report.");
        setLoading(false);
      }
  }

  const handlePrevious = () =>{
    currentPage > 1 ? setCurrentPage(currentPage - 1) : setCurrentPage(currentPage);
    
}

const handleNextPage = () =>{
    currentPage < totalPages ? setCurrentPage(currentPage + 1) : setCurrentPage(currentPage);
    
}

const goToPage = (number) =>{
    setCurrentPage(number) 
    
}

    useEffect(() => {
      // scroll to top on page load
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    useEffect(() => {

      fetchHostlist()
    }, [currentPage]);
    // }



    const setHostDetails = (text, id, email )=>{
      setStatusText(text)
      setHostID(id)
      setHostEmail(email)
    }

    const approveOrBanHostRequest = async()=>{
      setLoading(true)
      const response = await approveOrBanHost({statusText, hostEmail}, hostID)
      if (response?.status) {
        toast.success("Request successful")
        fetchHostlist()
        
      } else {
        handleError(`Could not ${statusText} this host.`);
        setLoading(false);
      }
    }
      
    

  return (
    <>
        <NavbarMenu  style={{backgroundColor: '#003B95' }} />


        {/* Modal */}

        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel" text-centered>Do you want to {statusText} this host?</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            
            <div className="modal-footer">
                <button type="button" className={statusText === "ban" ? "btn btn-secondary" : "btn btn-danger"}data-bs-dismiss="modal">Cancel</button>
                <button type="button" className={statusText === "approve" ? "btn btn-success" : "btn btn-danger"} data-bs-dismiss="modal" onClick={approveOrBanHostRequest}>Proceed</button>
            </div>
            </div>
        </div>
        </div>





        
        <div className='ListAccommodation'>
            <Container>
                <Row>
                    <Col xs={12} md={3} lg={3}>
                        <OwnerNavbar />
                    </Col>
                    <Col xs={12} md={9} lg={9}>                               
                      <div className="table-panel">
                        <h2>Registered Hosts </h2>
                        <p>
                          Find All your hosts in one place. Manage your host accounts and transactions
                        </p>
                         
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th> S/N </th>
                                    <th> Host Name </th>
                                    <th> Status </th> 
                                    <th> Details </th>
                                    <th> Payments </th> 
                                    {/* 
                                    <th> Rooms </th>*/}
                                    <th> Ban/Approve </th>
                                </tr>
                            </thead>
                            <tbody>
                              {data.map((host, index) => {
                                  return <tr key={index}>
                                      <th>{index + 1 + startIndex}.</th>
                                      <td>{host.firstName + " " + host.lastName}</td>
                                      <td>{host.approvalStatus === 0 ? <Badge bg='warning'>Pending Approval</Badge> :
                                      host.approvalStatus === 2 ? <Badge bg='success'>Approved</Badge> : <Badge bg='danger'>Banned</Badge>}</td>
                                      <td>
                                        <Link to={`/host-preview/${host.hostID}`} className="btn btn-primary"><FaEye /> Preview </Link> &nbsp;                                  
                                      </td> 
                                      <td>
                                          <Link to={`/account-history/${host.hostID}`} className="btn btn-success"><PaymentOutlined /> Account History </Link> &nbsp;
                                      </td>
                                      {/* host.emailAddress host.hostID*/}
                                      <td>
                                          <button className="btn btn-warning" type="button"  data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={()=> setHostDetails("approve", host.hostID, host.emailAddress )}>
                                            <FaCheckDouble /> 
                                            </button> &nbsp;

                                          <button  className="btn btn-danger" type="button"  data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={()=> setHostDetails("ban", host.hostID, host.emailAddress )}>
                                            <RemoveCircleOutline /> 
                                            </button> &nbsp;
                                          {/* <button onClick={e => handleDelete(property.propertyID)} className="btn btn-danger"><FaTrash /> </button>  */}
                                      </td>
                                  </tr>
                              })}
                               
                                 
                            </tbody>
                        </Table>

                        <ul className="pagination "
                          style={{ alignItems: "right", justifyContent: "right"}}
                          >
                              <li className="page-item" onClick={handlePrevious}>
                              <Link className="page-link" to="" aria-label="Previous">
                                  <span aria-hidden="true">&laquo;</span>
                              </Link>
                              </li>
                              <li className="page-item" onClick={()=> goToPage(1)}><Link className="page-link" to="">1</Link></li>
                              <li className="page-item" onClick={()=> goToPage(2)}><Link className="page-link" to="">2</Link></li>
                              <li className="page-item" onClick={()=> goToPage(3)}><Link className="page-link" to="">3</Link></li>

                              <li className="page-item" onClick={handleNextPage}>
                              <Link className="page-link" to="" aria-label="Next">
                                  <span aria-hidden="true">&raquo;</span>
                              </Link>
                              </li>
                          </ul>
                         
                        {msg && msg}
                      </div>
                             
                    </Col>
                </Row>
            </Container>

            { loading && <Spinner />}
        </div>
    </>
  )
}

export default ManageHosts