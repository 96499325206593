     

export const checkImageFormat = (file) => {
  const types = ["image/png", "image/jpeg"];
  let err = "";
  if (!file) return (err = "File does not exist.");

  if (file.size > 1024 * 1024)
    // 1mb
    err = "The largest image size is 1mb";

  if (!types.includes(file.type)) err = "The image type is png / jpeg";

  return err;
};









export const uploadImageToCloud = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", "jcgkqq0x");
  formData.append("cloud_name", "db0mhnvvo");

  const res = await fetch(
    "https://api.cloudinary.com/v1_1/db0mhnvvo/upload",
    {
      method: "POST",
      body: formData,
    }
  );

  const data = await res.json();
  return { public_id: data.public_id, url: data.secure_url };
};






export const uploadImagesToCloud = async (files) => {
  const uploadedImages = [];

  for (const file of files) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "jcgkqq0x");
    formData.append("cloud_name", "db0mhnvvo");

    const res = await fetch(
      "https://api.cloudinary.com/v1_1/db0mhnvvo/upload",
      {
        method: "POST",
        body: formData,
      }
    );

    const data = await res.json();
    uploadedImages.push({ public_id: data.public_id, url: data.secure_url });
  }

  return uploadedImages;
};





