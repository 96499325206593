import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Footer.css';
import HomeIcon from "@mui/icons-material/Home";
import LocalTaxi from "@mui/icons-material/LocalTaxi";  
import { FlightTakeoffOutlined, Person3TwoTone, RoomServiceTwoTone, TourTwoTone } from "@mui/icons-material";
import { FaFacebook, FaTwitter, FaLinkedin, FaCheck, FaCheckCircle } from 'react-icons/fa';
import { YouTube } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='Footer'> 
        <Container className='container'>
            <Row>
                <Col xs={12}  sm={6}  md={4}>
                    <h2>Mac<span className='coloredText'>zuby</span></h2>
                    <p>
                        We’re Africa’s leading hospitality and rental services company, providing secured 
                        transaction between service providers and their customers.
                    </p>
                </Col>
                <Col xs={12} sm={6} md={3}>
                    <h3>Info </h3>
                    <ul>
                        <li><FaCheckCircle style={{color: '#003B95'}}  /> Who we are</li>
                        <li><FaCheckCircle style={{color: '#003B95'}}  /> Contact Us</li>
                        <Link to="/privacy-policy" style={{textDecoration: "none", color: "inherit"}}>
                        <li><FaCheckCircle style={{color: '#003B95'}}  /> Privacy Policy</li>
                        </Link>
                        <li><FaCheckCircle style={{color: '#003B95'}}  /> Terms & Conditions</li>
                    </ul>
                </Col>
                <Col xs={12} sm={6} md={3} className='quicklinks'>
                    <h3>Quicklinks </h3>
                    <ul>
                        <li><Link to={'/accommodations'}><HomeIcon /> Accommodations</Link></li>
                        <li><Link to={'/cars'}><LocalTaxi /> Car Rentals</Link></li>
                        <li><Link to={'/flights'}><FlightTakeoffOutlined  /> Flight Tickets</Link></li>
                        <li><Link to={'/tours'}><TourTwoTone  /> Tour A City</Link></li>
                        <li><Link to={'/services'}><RoomServiceTwoTone /> Other Services</Link></li> 
                    </ul> 
                </Col>
                <Col xs={12} sm={6} md={2}>
                    <h3>Social </h3>
                    <ul>
                        <li><FaFacebook style={{color: '#003B95'}} /> &nbsp; Facebook</li>
                        <li><FaTwitter style={{color: '#003B95'}} /> &nbsp; Twitter</li>
                        <li><FaLinkedin style={{color: '#003B95'}} /> &nbsp; LinkedIn</li>
                        <li><YouTube style={{color: '#003B95'}} /> YouTube</li>
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={12} className='copyright'>
                    <p>Copyright { new Date().getFullYear()}. All Rights Reserved. <span className='ftLogoText'>Mac<span className='coloredText'>zuby</span></span></p>
                </Col>

            </Row>
        </Container>
    </div>
  )
}

export default Footer