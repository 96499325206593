import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HostNavbar from "./HostNavbar";
import "./ListVehicle.css";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { Table, Badge } from "react-bootstrap";
import {
  FaAddressBook,
  FaCheck,
  FaEye,
  FaPenAlt,
  FaTrash,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { BaseURL } from "../../config/variables";
import { limit, totalPages } from "../../utils/ownerActions/ownerRequests";
import { handleError, handleSuccess } from "../../utils/notifications/toasts";
import {
  deleteVehicle,
  getAvailableCars,
  getBookedCars,
  getHostListing,
  togleVehicleAvailable,
} from "../../utils/hostActions/hostRequests";
import Spinner from "../Alert/Spinner/Spinner";

const ListVehicle = () => {
  // show all accommodations
  const hostID = window.localStorage.getItem("hostID");
  const [data, setData] = useState([]);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentVPage, setCurrentVPage] = useState(1)
  const [currentFPage, setCurrentFPage] = useState(1)

  const [startIndex, setstartIndex] = useState(0);
  const [startFIndex, setstartFIndex] = useState(0) 
  const [startVIndex, setstartVIndex] = useState(0)

  const [bookedCars, setBookedCars] = useState([]);
  const [availableCars, setAvailableCars] = useState([]);
  const [currentAPage, setCurrentAPage] = useState(1);
  const [startBIndex, setstartBIndex] = useState(0);
  const [vehicleID, setVehicleID] = useState("");

  const fetchAccommodations = async () => {
    setLoading(true);
    setstartIndex((currentPage - 1) * limit);
    const response = await getHostListing(
      "vehiclelist",
      hostID,
      currentPage,
      limit
    );
    // console.log({response})
    if (response?.status) {
      setData(response.Result);
      setLoading(false);
    } else {
      handleError("Could not get data.");
      setLoading(false);
    }
  };

  const fetchAllAvailableCars = async () => {
    setLoading(true);
    setstartFIndex((currentFPage - 1) * limit);
    const available = await getAvailableCars(hostID, currentFPage, limit);

    if (available?.status) {
      setAvailableCars(available.vehicles)
      setLoading(false);
    } else {
      handleError("Could not get available rooms");
      setLoading(false);
    }
  };
  const fetchAllBookedCars = async () => {
    setLoading(true);
    setstartVIndex((currentVPage - 1) * limit);
    const booked = await getBookedCars(hostID, currentVPage, limit);

    if (booked?.status) {
        setBookedCars(booked.vehicles);
      setLoading(false);
    } else {
      handleError("Could not get booked rooms");
      setLoading(false);
    }
  };

  const handlePrevious = (text) => {
    if(text === "v"){
      currentVPage > 1 ? setCurrentVPage(currentVPage - 1) : setCurrentVPage(currentVPage);
      
  } else if(text === "f"){
      currentFPage > 1 ? setCurrentFPage(currentFPage - 1) : setCurrentFPage(currentFPage);
      
  } else {

    currentPage > 1
      ? setCurrentPage(currentPage - 1)
      : setCurrentPage(currentPage);
  }
  };

  const handleNextPage = (text) => {
    if(text === "v"){
      currentVPage < totalPages ? setCurrentVPage(currentVPage + 1) : setCurrentVPage(currentVPage);
      
  } else if(text === "f"){
      currentFPage < totalPages ? setCurrentFPage(currentFPage + 1) : setCurrentFPage(currentFPage);
      
  } else {

    currentPage < totalPages
      ? setCurrentPage(currentPage + 1)
      : setCurrentPage(currentPage);
  }
  };

  const goToPage = (number, text) => {
    if(text === "v"){
      setCurrentVPage(number) 
      
  } else if(text === "f"){
      setCurrentFPage(number) 
      
  } else {

    setCurrentPage(number);
  }
  };

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    fetchAccommodations();
  }, [currentPage]);

  useEffect(() => {
    fetchAllAvailableCars();
  }, [currentFPage]);
  useEffect(() => {
    fetchAllBookedCars();
  }, [currentVPage]);

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  // console.log(data);



  const setDeleteDetails = async (id, idd) => {
    setVehicleID(id);
  };

  const handleDeleteVehicle = async () => {
    setLoading(true);

    const result = await deleteVehicle(vehicleID, hostID)
    if (result?.status) {
      const { message } = result;
        handleSuccess(message)
        fetchAllAvailableCars()
        fetchAllBookedCars()
      //   setTimeout(() => {
      //     navigate("/list-accommodation");
      // }, 1000);

    } else {
      handleError("Could not delete vehicle")
      setLoading(false);
    }
  };

  const makeVehicleAvailable = async () => {
    setLoading(true);

    const result = await togleVehicleAvailable(vehicleID, hostID)
    if (result?.status) {
      const { message } = result;
        handleSuccess(message)
        fetchAllAvailableCars()
        fetchAllBookedCars()
      //   setTimeout(() => {
      //     navigate("/list-accommodation");
      // }, 1000);

    } else {
      handleError("Could not make vehcile available for booking");
      setLoading(false);
    }
  };

  // console.log({ bookedCars })

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />

      {/* Modal */}

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5"
                id="exampleModalLabel"
                text-centered
              >
                Do you want to delete this Vehicle?
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={handleDeleteVehicle}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}

      <div
        className="modal fade"
        id="exampleModall"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5"
                id="exampleModalLabel"
                text-centered
              >
                Make this vehicle available for booking?
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-success"
                data-bs-dismiss="modal"
                onClick={makeVehicleAvailable}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="ListVehicle">
        <Container className="container">
          <Row>
            <Col xs={12} md={3} lg={3}>
              <HostNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <div className="table-panel">
                <h2>My Vehicle Listings </h2>
                <p>
                  Find All your Listings in one place. Ensure to set features
                  and rules for your vehicle to make it easy for drivers to book
                  quicker
                </p>
                <p>
                  <Link
                    to={"/list-vehicle-01"}
                    className="btnMore"
                    style={{ width: "150px", textDecoration: "none" }}
                  >
                    <FaPenAlt /> Add New
                  </Link>
                </p>
                <Table responsive>
                  <thead>
                    <tr>
                      <th> S/N </th>
                      <th> Vehicle Name </th>
                      <th> Status </th>
                      <th> Details </th>
                      <th> Features & Rules </th>
                      <th> Bookings </th>
                      <th> Action </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((vehicle, index) => {
                      return (
                        <tr key={index}>
                          <th>{index + 1 + startIndex}.</th>
                          <td>{vehicle.vehicleName}</td>
                          <td>
                            {/* {vehicle.approvalStatus === 0 ? (
                              <Badge bg="warning">Pending Approval</Badge>
                            ) : (
                              <Badge bg="success">Approved</Badge>
                            )} */}
                             <Badge bg='success'>Approved</Badge>
                          </td>
                          <td>
                            <Link
                              to={`/list-vehicle-01-edit/${vehicle.vehicleID}`}
                              className="text-primary"
                            >
                              <FaPenAlt /> Details
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={`/list-vehicle-02-edit/${vehicle.vehicleID}`}
                              className="text-primary"
                            >
                              <FaPenAlt /> Features & Rules
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={`./${vehicle.vehicleID}`}
                              className="btn btn-success"
                            >
                              <FaAddressBook /> Bookings
                            </Link>
                          </td>
                          <td width={120}>
                            <Link
                              to={`/car-preview/${vehicle.vehicleID}`}
                              target="_blank"
                              className="btn btn-primary"
                            >
                              <FaEye /> Preview{" "}
                            </Link>{" "}
                            &nbsp;
                            {/* <button onClick={e => handleDelete(vehicle.vehicleID)} className="btn btn-danger"><FaTrash /> </button>  */}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>

                <ul
                  className="pagination "
                  style={{ alignItems: "right", justifyContent: "right" }}
                >
                  <li className="page-item" onClick={handlePrevious}>
                    <Link className="page-link" to="" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </Link>
                  </li>
                  <li className="page-item" onClick={() => goToPage(1)}>
                    <Link className="page-link" to="">
                      1
                    </Link>
                  </li>
                  <li className="page-item" onClick={() => goToPage(2)}>
                    <Link className="page-link" to="">
                      2
                    </Link>
                  </li>
                  <li className="page-item" onClick={() => goToPage(3)}>
                    <Link className="page-link" to="">
                      3
                    </Link>
                  </li>

                  <li className="page-item" onClick={handleNextPage}>
                    <Link className="page-link" to="" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </Link>
                  </li>
                </ul>

                {msg && msg}
              </div>

              {/* Booked Rooms Table */}
              <div className="table-panel mb-5 mt-5">
                <h2>Booked Vehicles </h2>
                <p>All vehicles that are currently booked</p>

                <Table responsive>
                  <thead>
                    <tr>
                      <th> S/N </th>
                      <th> Host Name </th>
                      <th> Vehicle Name </th>
                      <th> vehicle Model </th>
                      <th> Details </th>
                      <th> Action </th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookedCars &&
                      bookedCars.map((each, index) => {
                        return (
                          <tr key={index}>
                            <th>{index + 1 + startVIndex}.</th>
                            <td>{each.hostName}</td>
                            <td>{each.vehicleName}</td>
                            <td>{each.vehicleModel}</td>
                            <td>{each.aboutVehicle}</td>
                            <td
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModall"
                              onClick={() =>
                                setDeleteDetails(each.vehicleID, each.hostID)
                              }
                            >
                              unbook
                              <FaCheck color="green" />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>

                <ul
                  className="pagination "
                  style={{ alignItems: "right", justifyContent: "right" }}
                >
                  <li className="page-item" onClick={() => handlePrevious("v")}>
                    <Link className="page-link" to="" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </Link>
                  </li>
                  <li className="page-item" onClick={() => goToPage(1, "v")}>
                    <Link className="page-link" to="">
                      1
                    </Link>
                  </li>
                  <li className="page-item" onClick={() => goToPage(2, "v")}>
                    <Link className="page-link" to="">
                      2
                    </Link>
                  </li>
                  <li className="page-item" onClick={() => goToPage(3, "v")}>
                    <Link className="page-link" to="">
                      3
                    </Link>
                  </li>

                  <li className="page-item" onClick={() => handleNextPage("v")}>
                    <Link className="page-link" to="" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </Link>
                  </li>
                </ul>

                {/* {msg && msg} */}
              </div>

              {/* All available rooms */}
              <div className="table-panel mb-5">
                <h2>Available Vehicles </h2>
                <p>Vehicles that are currently available</p>

                <Table responsive>
                  <thead>
                    <tr>
                      <th> S/N </th>
                      <th> Host Name </th>
                      <th> Vehicle Name </th>
                      <th> vehicle Model </th>
                      <th> Details </th>
                      <th> Action </th>
                    </tr>
                  </thead>
                  <tbody>
                    {availableCars &&
                      availableCars.map((each, index) => {
                        return (
                          <tr key={index}>
                            <th>{index + 1 + startFIndex}.</th>
                            <td>{each.hostName}</td>
                            <td>{each.vehicleName}</td>
                            <td>{each.vehicleModel}</td>
                            <td>{each.aboutVehicle}</td>
                            <td
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              onClick={() =>
                                setDeleteDetails(each.vehicleID, each.hostID)
                              }
                            >
                              <FaTrash color="red" />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>

                <ul
                  className="pagination "
                  style={{ alignItems: "right", justifyContent: "right" }}
                >
                  <li className="page-item" onClick={() => handlePrevious("f")}>
                    <Link className="page-link" to="" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </Link>
                  </li>
                  <li className="page-item" onClick={() => goToPage(1, "f")}>
                    <Link className="page-link" to="">
                      1
                    </Link>
                  </li>
                  <li className="page-item" onClick={() => goToPage(2, "f")}>
                    <Link className="page-link" to="">
                      2
                    </Link>
                  </li>
                  <li className="page-item" onClick={() => goToPage(3, "f")}>
                    <Link className="page-link" to="">
                      3
                    </Link>
                  </li>

                  <li className="page-item" onClick={() => handleNextPage("f")}>
                    <Link className="page-link" to="" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </Link>
                  </li>
                </ul>

                {/* {msg && msg} */}
              </div>
            </Col>
          </Row>
        </Container>

        {loading && <Spinner />}
      </div>
    </>
  );
};

export default ListVehicle;
