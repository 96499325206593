import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './DownloadApp.css';
import Appstore from '../../Assets/Img/IOSimage.png'
import Playstore from '../../Assets/Img/Playstore.png'
import AppImage from '../../Assets/Img/AppImage.png'
import { Link } from 'react-router-dom';

const DownloadApp = () => {
  return (
    <div className='DownloadApp app-image-wrap'>
        <Container className='container'>
            <Row>
                <Col xs={12} md={6}>
                    <div className="app-image" data-aos="flip-left">
                        <img src={AppImage} alt="Maczuby App" className='img-fluid' />
                    </div>
                </Col>
                <Col xs={12} md={6}>
                    <div className="app-text" data-aos="flip-right">
                        <h2>Enjoy fast booking experience on mobile</h2>
                        <p>
                            Download our Mobile App free today to book your flights, hotels, apartments, cars plus other services
                            and enjoy amazing deals on the go!
                        </p>
                        <div className="app-icons">
                            <Link to="https://apps.apple.com/ng/app/maczuby/id6476564384" target="_blank">
                                <img src={Appstore} title='Download from App store' alt="Download from appstore" />
                            </Link>

                            <Link to="https://play.google.com/store/apps/details?id=com.maczuby.app" target="_blank">
                                <img src={Playstore} title='Download from Play store' alt="Download from Playstore" />
                            </Link>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default DownloadApp;