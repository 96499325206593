import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Table, Badge } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import './OwnerDashboard.css'; 
import OwnerNavbar from './OwnerNavbar';
import {Link, useNavigate} from 'react-router-dom';
import NavbarMenu from "../Layouts/Navbar";
import { useCookies } from "react-cookie";  
import { ToastContainer, toast } from "react-toastify"; 
import { handleError } from '../../utils/notifications/toasts';
import Spinner from '../Alert/Spinner/Spinner';
import { getAllUserRefundRequests, limit, totalPages } from '../../utils/ownerActions/ownerRequests';
import { HiReceiptRefund } from 'react-icons/hi2';



 
const ManageRefunds = () => {
  const navigate = useNavigate();


  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [startIndex, setstartIndex] = useState(0)
  const [data, setData] = useState(null);
  const [refundItem, setRefundItem] = useState(null);



  const fetchAllRefunds = async()=>{
      setLoading(true);
      setstartIndex((currentPage - 1) * limit)
      const result = await getAllUserRefundRequests(currentPage, limit)
      
      if (result?.status) {
          setData(result.refundRequests)
          setLoading(false);
          console.log({result})
      
      } else {
      handleError("Could not get refund requests lists.");
      setLoading(false);
      }
  
  }





  useEffect(() => {
 
      fetchAllRefunds()
    }, [currentPage]);
  
  
    const handlePrevious = () =>{
      currentPage > 1 ? setCurrentPage(currentPage - 1) : setCurrentPage(currentPage);
      
  }
  
  const handleNextPage = () =>{
      currentPage < totalPages ? setCurrentPage(currentPage + 1) : setCurrentPage(currentPage);
      
  }
  
  const goToPage = (number) =>{
      setCurrentPage(number) 
      
  }
  
  
    useEffect(() => {
      // scroll to top on page load
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  
     
      // fetchAllBookings()
    }, []);




const handleRefundItem = (id, index )=>{
    
    const filteredItem = data.filter((each, i)=> each.id === id && i === index)
    setRefundItem(filteredItem[0])
    console.log({filteredItem})
}





  return (
    <>
        <NavbarMenu  style={{backgroundColor: '#003B95' }} />

        {/* Fund Wallet Modal */}
        {refundItem  && (<div className="modal fade" id="refundItemModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">User Refund Request</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                
                <div className="modal-body">
                
                <div class="card" style={{width: "18rem;"}}>
                    <div class="card-body">
                        <h6 class="card-title">Reservation: {refundItem?.reservation}</h6> 
                        <h6 class="card-title">Category: {refundItem?.category}</h6> 
                        <h6 class="card-title">Amount Paid: {refundItem?.amountPaid}</h6> 

                        <h6 class="card-title mt-4">User Email: {refundItem?.email}</h6> 
                        <h6 class="card-title">User phone number: {refundItem?.phoneNumber}</h6> 
                        <h6 class="card-title">hostName: {refundItem?.hostName}</h6> 

                        <h6 class="card-subtitle mb-2 text-body-secondary mt-4">Reason For Refund:</h6>
                        <p class="card-text">{refundItem?.reasonForRefund}</p>
                        <h6 class="card-title">Date Refund was Requested: {new Date(refundItem?.createdAt).toLocaleDateString()}</h6> 
                        <h6 class="card-title">Date of Booking: {new Date(refundItem?.dateBooked).toLocaleDateString()}</h6> 
                    </div>
                </div>
                
                </div>


                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Reject</button>
                        <button type="submit" className="btn btn-success" data-bs-dismiss="modal" 
                        >Approve</button>
                    </div>
            </div>
        </div>
        </div>)}

        <div className='HostDashboard'>
            {/* Do a mini navbar just below the main navbar */}
            <Container className='container'>
                <Row>
                    <Col xs={12} md={3} lg={3} className='mt-5'>
                        <OwnerNavbar />
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                        <Row>
                            <Col xs={12} md={12} className='mt-5 mb-3'>
                                <h2>All Refund Requests</h2>
                                <p>List of all the refund requests submitted by users</p>

                            </Col>

                            


                            <Col xs={12} md={12} >
                                <div className="table-panel">
                                    {/* <h3>Refunds for Service Bookings</h3> */}
                                    <Table responsive className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th> S/N </th>
                                                <th> Reservations </th>
                                                <th> Category </th>
                                                <th> Reason for Refund </th>
                                                <th> Amount </th>
                                                <th> Email </th>
                                                <th> Host Name </th>
                                                {/* <th> Action </th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data && data.map((each, index)=>{
                                                    return(

                                                    <tr key={index}  style={{ cursor: "pointer"}}
                                                    data-bs-toggle="modal" data-bs-target="#refundItemModal"
                                                    onClick={()=> handleRefundItem(each.id, index)}
                                                    >
                                                        <th>{index + 1 + startIndex}.</th>
                                                        <td>{each.reservation}</td>
                                                        <td>{each.category}</td>
                                                        <td>{each.reasonForRefund}</td>
                                                       
                                                        <td>
                                                            <div className="text-primary">₦{each.amountPaid > 100 ? Number(each.amountPaid).toLocaleString() : each.amountPaid}</div>
                                                            </td>
                                                      

                                                        <td> { each.email}</td>
                                                        <td> { each.hostName}</td>
                                                    </tr>
                                                    )
                                                })
                                            }
                                           
                                        </tbody>
                                    </Table>



                                    <ul className="pagination "
                                    style={{ alignItems: "right", justifyContent: "right"}}
                                    >
                                        <li className="page-item" onClick={handlePrevious}>
                                        <Link className="page-link" to="" aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                        </Link>
                                        </li>
                                        <li className="page-item" onClick={()=> goToPage(1)}><Link className="page-link" to="">1</Link></li>
                                        <li className="page-item" onClick={()=> goToPage(2)}><Link className="page-link" to="">2</Link></li>
                                        <li className="page-item" onClick={()=> goToPage(3)}><Link className="page-link" to="">3</Link></li>

                                        <li className="page-item" onClick={handleNextPage}>
                                        <Link className="page-link" to="" aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                        </Link>
                                        </li>
                                    </ul>

                                </div>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Container>

            { loading && <Spinner />}

        </div>
        <ToastContainer />

    </>
    
  )
}

export default ManageRefunds