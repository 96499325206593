import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import LocalTaxi from "@mui/icons-material/LocalTaxi";
import { Link, useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import {
  DashboardOutlined,
  FlightTakeoffOutlined,
  Person3TwoTone,
  Phone,
  RoomServiceTwoTone,
  TourTwoTone,
} from "@mui/icons-material";
import { useGetOwnerID } from "../../Hooks/useGetOwnerID";
import { useGetHostID } from "../../Hooks/useGetHostID";
import { useGetUserID, useGetUserSession } from "../../Hooks/useGetUserID";
const NavbarMenu = () => {
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies([]);
  const userID = useGetUserID();
  const hostID = useGetHostID();
  const ownerID = useGetOwnerID();
  const session = useGetUserSession();
  const clearSession = () => {
    sessionStorage.clear();
    window.location.reload();
  };

  const logout = () => {
    sessionStorage.clear();
    // window.location.reload();
    removeCookie("token", "");

    if (ownerID) {
      window.localStorage.removeItem("ownerID");
      navigate("/owner-login");
    }

    if (hostID) {
      window.localStorage.removeItem("hostID");
      navigate("/host-login");
    }

    if (userID) {
      window.localStorage.removeItem("userID");
      navigate("/login");
    }
  };

  const [openMenu, setOpenMenu] = useState(false);
  const menuOptions = [
    {
      text: "Accommodations",
      icon: <HomeIcon />,
    },
    {
      text: "Cars",
      icon: <LocalTaxi />,
    },
    {
      text: "Flights",
      icon: <FlightTakeoffOutlined />,
    },
    {
      text: "Tours",
      icon: <TourTwoTone />,
    },
    {
      text: "Services",
      icon: <RoomServiceTwoTone />,
    },
    // {
    //   text: "Companion",
    //   icon: <Person3TwoTone />,
    // },
  ];
  return (
    <div className="header-wrap" id="top">
      <nav className="container">
        <div style={{ display: "block", margin: "20px 0" }}>
          <div className="nav-logo-container">
            {/* <img src={Logo} alt="" /> */}
            <p className="logoText">
              <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
                Mac<span className="logoSpan">zuby</span>
              </Link>
            </p>
          </div>
          
          <div className="navbar-call">
            {/* <p><Phone /> +234 704 745 6706</p> */}
            <p>
              <a
              style={{color: "white"}}
                className="px-3 text-decoration-none "
                href="tel:+2347047456706"
              >
                <Phone /> +234 704 745 6706
              </a>
            </p>
          </div>
          <div></div>
          <div className="navbar-links-container">
            <Link to="/accommodations">
              <HomeIcon style={{ fontSize: "1rem" }} /> Accommodations
            </Link>
            <Link to="/cars">
              <LocalTaxi style={{ fontSize: "1rem" }} /> Cars
            </Link>
            <Link to="/flights">
              <FlightTakeoffOutlined style={{ fontSize: "1rem" }} /> Flights
            </Link>
            <Link to="/tours">
              <TourTwoTone style={{ fontSize: "1rem" }} /> Tours
            </Link>
            <Link to="/services">
              <RoomServiceTwoTone style={{ fontSize: "1rem" }} /> Services
            </Link>
            {/* <Link to="/companion"><Person3TwoTone style={{fontSize: "1rem"}} /> Companion</Link> */}
            {ownerID && (
              <Link
                to="/owner-dashboard"
                style={{
                  border: "1px solid #fff",
                  borderRadius: "25px",
                  padding: "15px",
                }}
              >
                {" "}
                <DashboardOutlined /> My Owner Account
              </Link>
            )}
            {hostID && (
              <Link
                to="/host-dashboard"
                style={{
                  border: "1px solid #fff",
                  borderRadius: "25px",
                  padding: "15px",
                }}
              >
                {" "}
                <DashboardOutlined /> My Host Account
              </Link>
            )}
            {userID && (
              <Link
                to="/user-dashboard"
                style={{
                  border: "1px solid #fff",
                  borderRadius: "25px",
                  padding: "15px",
                }}
              >
                {" "}
                <DashboardOutlined /> My User Account
              </Link>
            )}
            {
              //  (cookies.token) ?
              session ? (
                <button
                  className="primary-button"
                  onClick={logout}
                  style={{ marginLeft: "475px", float: "right" }}
                >
                  Logout
                </button>
              ) : (
                <Link to="/login">
                  <button
                    className="primary-button"
                    style={{ marginLeft: "375px", float: "right" }}
                  >
                    Login/Register
                  </button>{" "}
                </Link>
              )
            }
          </div>
        </div>

        <div className="navbar-menu-container">
          <HiOutlineBars3
            onClick={() => setOpenMenu(true)}
            style={{ color: "#fff" }}
          />
        </div>
        <Drawer
          open={openMenu}
          onClose={() => setOpenMenu(false)}
          anchor="right"
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={() => setOpenMenu(false)}
            onKeyDown={() => setOpenMenu(false)}
          >
            <List>
              {menuOptions.map((item) => (
                <ListItem key={item.text} disablePadding>
                  <Link
                    to={"/" + item.text.toLowerCase()}
                    style={{
                      color: "#333",
                      textDecoration: "none",
                      fontSize: "14px",
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                    </ListItemButton>
                  </Link>
                </ListItem>
              ))}
            </List>
            <Divider />
            {/* <p className="px-3"><Phone />  +234 704 745 6706 </p> */}
            <p
              style={{
                color: "black",
              }}
            >
              <a
                className="px-3 text-decoration-none "
                href="tel:+2347047456706"
                style={{
                  color: "black",
                }}
              >
                <Phone /> +234 704 745 6706
              </a>
            </p>

            {ownerID && (
              <Link
                to="/owner-dashboard"
                style={{
                  border: "1px solid #fff",
                  borderRadius: "25px",
                  padding: "15px",
                }}
              >
                {" "}
                <DashboardOutlined /> My Owner Account
              </Link>
            )}
            {hostID && (
              <Link
                to="/host-dashboard"
                style={{
                  border: "1px solid #fff",
                  borderRadius: "25px",
                  padding: "15px",
                }}
              >
                {" "}
                <DashboardOutlined /> My Host Account
              </Link>
            )}
            {userID && (
              <Link
                to="/user-dashboard"
                style={{
                  border: "1px solid #fff",
                  borderRadius: "25px",
                  padding: "15px",
                }}
              >
                {" "}
                <DashboardOutlined /> My User Account
              </Link>
            )}

            {
              //  (cookies.token) ?
              sessionStorage.getItem("session") ? (
                <button
                  className="primary-button"
                  onClick={logout}
                  style={{ marginLeft: "20px" }}
                >
                  Logout
                </button>
              ) : (
                <Link to="/login">
                  <button
                    className="primary-button"
                    style={{ marginLeft: "20px" }}
                  >
                    Login/Register
                  </button>{" "}
                </Link>
              )
            }
            {/* <Link to="/login#top"><button className="primary-button" style={{marginLeft: '20px'}}>Login/Register</button></Link> */}
          </Box>
        </Drawer>
      </nav>
    </div>
  );
};

export default NavbarMenu;
