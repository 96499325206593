import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaArrowRight, FaPenAlt } from "react-icons/fa";
import "./OwnerAccount.css";
import OwnerNavbar from "./OwnerNavbar";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BaseURL } from "../../config/variables";

const OwnerProfileChangePass = () => {
  // const { id } = useParams();
  const navigate = useNavigate();
  const currentOwnerID = window.localStorage.getItem("ownerID");

  const [inputValue, setInputValue] = useState({
    ownerID: currentOwnerID,
    oldPassword: "",
    newPassword: "",
  });

  const { ownerID, oldPassword, newPassword } = inputValue;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  useEffect(() => {
    // scroll to top on page load

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(data)
    axios
      .put(`${BaseURL}/editownerpass/${currentOwnerID}`, inputValue)
      .then((res) => {
        if (res.data) {
          const { success, message } = res.data;
          // console.log(message);
          if (success) {
            handleSuccess(message);
            setTimeout(() => {
              navigate("/owner-profile");
            }, 1000);
          } else {
            handleError(message);
          }
        }
      })
      .catch((err) => console.log(err));

    setInputValue({
      ...inputValue,
    });
  };

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <ToastContainer />

      <div className="ListAccommodation">
        <Container>
          <Row>
            <Col xs={12} md={3} lg={3}>
              <OwnerNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <form className="form-panel" onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <h2>Change Owner Password</h2>
                    <p>
                      Make sure you use a secure password - Use a minimum of 8
                      characters, including uppercase, lower case letters,
                      numbers and special characters.
                    </p>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <label htmlFor="oldPassword">Old Password:</label>
                    <input
                      type="password"
                      name="oldPassword"
                      id="oldPassword"
                      className="form-control"
                      required
                      value={oldPassword}
                      onChange={handleOnChange}
                    />
                  </Col>

                  <Col xs={12} md={6} lg={6}>
                    <label htmlFor="newPassword">New Password</label>
                    <input
                      type="password"
                      name="newPassword"
                      id="newPassword"
                      className="form-control"
                      required
                      value={newPassword}
                      onChange={handleOnChange}
                    />
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <button type="submit" className="btnMore mt-5">
                      Update <FaPenAlt />
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default OwnerProfileChangePass;
