import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HostNavbar from "./HostNavbar";
import { 
  FaUpload,
} from "react-icons/fa";
import "./ListTour.css";
import dropImg from "../../Assets/illustrations/ep-picture-filled.svg";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BaseURL } from "../../config/variables";
import Spinner from "../Alert/Spinner/Spinner";
import { uploadImagesToCloud } from "../../utils/imageUtils/uploadImage";

const ListTour03 = () => {
  const navigate = useNavigate();
  const currenttourID = window.localStorage.getItem("tourID");
  // alert (currenttourID);
  const [data, setData] = useState({
    tourID: currenttourID,
  });
  const [loading, setLoading ] = useState(false)


  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    // const formdata = new FormData();
    // formdata.append("tourID", data.tourID);
    // formdata.append("tourPic1", data.tourPic1);
    // formdata.append("tourPic2", data.tourPic2);
    // formdata.append("tourPic3", data.tourPic3);
    // formdata.append("tourPic4", data.tourPic4);
    // formdata.append("tourPic5", data.tourPic5);

    try {

      const filteredArrayOfImages = [data.tourPic1, data.tourPic2, data.tourPic3, data.tourPic4, data.tourPic5].filter(imageUrl => imageUrl !== "" && imageUrl !== null && imageUrl !== undefined);

      const uploadedImageURLs = await uploadImagesToCloud(filteredArrayOfImages)
    const imageURLs = []
    uploadedImageURLs.forEach((each, i)=>{
      imageURLs.push(each.url)
    })

      const response = await axios.post(
        `${BaseURL}/addtourimages`,
        {tourID: data.tourID, imageURLs}
      );
      const { success, message } = response.data;
      // console.log(data);

      if (success) {
        handleSuccess(message);
        setTimeout(() => {
          navigate("/list-tour");
        }, 1000);
        window.localStorage.removeItem("tourID");
        setLoading(false)
      } else {
        setLoading(false)
        handleError(message);
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  };

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <ToastContainer />

      <div className="ListTour">
        <Container className="container">
          <Row>
            <Col xs={12} md={3} lg={3}>
              <HostNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <form
                className="form-panel"
                encType="multipart/form-data"
                onSubmit={handleSubmit}
              >
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <h2>Tour Center Photos:</h2>
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <div className="dropbox mt-5">
                      <p>
                        <strong>
                          Upload at least 5 photos of your tour center.{" "}
                        </strong>
                        The more you upload , the more likely you are to get
                        bookings. You can add more later.{" "}
                      </p>
                      <div className="dropphotos">
                        <div className="picbox mt-5">
                          <img src={dropImg} alt="tour pic" />
                        </div>
                        <p>Click below to select your photos</p>
                        <div className="col-12 mb-3">
                          <label className="form-label" htmlFor="tourPic1">
                            Main Photo
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="tourPic1"
                            id="tourPic1"
                            // value={tourPic1}
                            onChange={(e) =>
                              setData({
                                ...data,
                                tourPic1: e.target.files[0],
                              })
                            }
                          />
                          <label className="form-label" htmlFor="tourPic2">
                            Photo 2
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="tourPic2"
                            id="tourPic2"
                            // value={tourPic2}
                            onChange={(e) =>
                              setData({
                                ...data,
                                tourPic2: e.target.files[0],
                              })
                            }
                          />
                          <label className="form-label" htmlFor="tourPic3">
                            Photo 3
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="tourPic3"
                            id="tourPic3"
                            // value={tourPic3}
                            onChange={(e) =>
                              setData({
                                ...data,
                                tourPic3: e.target.files[0],
                              })
                            }
                          />
                          <label className="form-label" htmlFor="tourPic4">
                            Photo 4
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="tourPic4"
                            id="tourPic4"
                            // value={tourPic4}
                            onChange={(e) =>
                              setData({
                                ...data,
                                tourPic4: e.target.files[0],
                              })
                            }
                          />
                          <label className="form-label" htmlFor="tourPic5">
                            Photo 5
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="tourPic5"
                            id="tourPic5"
                            // value={tourPic5}
                            onChange={(e) =>
                              setData({
                                ...data,
                                tourPic5: e.target.files[0],
                              })
                            }
                          />
                        </div>

                        {/* <button className="btn btn-outline mb-3">
                          <FaCamera /> Upload photos
                        </button> */}
                      </div>
                    </div>
                  </Col>

                  <Col xs={12} md={12} lg={4} className="mt-3">
                    <button type="submit" className="btnOutline mt-5">
                      Publish <FaUpload />
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
        { loading && <Spinner />}

      </div>
    </>
  );
};

export default ListTour03;
