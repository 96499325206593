import React from 'react';
import { FaGlobeAfrica, FaHeadphonesAlt } from 'react-icons/fa';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './Stats.css'

const Stats = () => {
  return (
    <div className='stats-wrap'>
        <Container>
           <div className="container">
                <Row>
                    <Col xs={12} md={5} className='stats-left' data-aos="flip-up"  style={{ marginTop: '-87px', padding: '', height: '220px' }}>
                        <span className='stats-icon'><FaGlobeAfrica /></span>
                        <span className='stats-left-text'>
                            <h2>EXPLORE AFRICA’S NO 1</h2>
                            <p>Enjoy best deals from Africa’s leading hospitality provider</p>
                        </span>
                    </Col>
                    <Col xs={12} md={6} className='stats-right' data-aos="flip-down" style={{ marginTop: '87px', padding: '' }}>
                        <span className='stats-icon'><FaHeadphonesAlt /></span>
                        <span className='stats-right-text'>
                            <h2>24/7 RESPONSE</h2>
                            <p>Are you stranded? We’re always here for you, 24/7</p>
                        </span>
                        <div className="clearfix"></div>

                    </Col>
                    
                    <div className="clearfix"></div>
                </Row>
                 
            </div> 
        </Container>
        
        
    </div>
  )
}

export default Stats