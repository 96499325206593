import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Ewallet.css'; 
import { FaWallet } from 'react-icons/fa';
import UserNavbar from './UserNavbar';
import NavbarMenu from '../Layouts/Navbar'; 
import { useGetUserID, useGetUserSession } from '../../Hooks/useGetUserID';
import { getUserWallet, getUserWalletTransactions } from '../../utils/userActions/paymentActions';
import Spinner from '../Alert/Spinner/Spinner';
import { handleError, handleSuccess } from '../../utils/notifications/toasts';
import { toast } from 'react-toastify';
import { reportIssue } from '../../utils/userActions/authActions';
import { useNavigate } from 'react-router-dom';


const ReportIssuesPage = () => {

    const session = useGetUserSession()
    const userId = useGetUserID()

    const navigate = useNavigate()

    const initialState = { firstName: '', lastName: '', emailAddress: '', subject: '', message: '', }
    const [userReport, setUserReport] = useState(initialState)
    const { firstName, lastName, emailAddress, subject, message } = userReport
    const [loading, setLoading] = useState(false)



    const handleChangeInput = (e) => {
        const {value, name} = e.target
        setUserReport({...userReport, [name]:value})
      }

    const handleReportIssue = async(e)=>{
        e.preventDefault()
        setLoading(true);

        

        if(!emailAddress){
            setLoading(false);
            return handleError("Email address is required!")
        } else if(!subject){
            setLoading(false);
            return handleError("Subject is required!")
        } else if(!message){
            setLoading(false);
            return handleError("Message is required!")
        } else if(!firstName || !lastName){
            setLoading(false);
            return handleError("Enter first name or last name!")
        }

        // console.log({ userReport })

        const response = await reportIssue(userReport)
        if (response?.success) {
            // setTransactions(response.transactions)
            handleSuccess("issue reported successfully.")
            navigate("/user-dashboard")
            setLoading(false);
          
        } else {
          handleError("Could not send report.");
          setLoading(false);
        }
        //   setLoading(false);
    }
  
    useEffect(() => {
    //   getWallet()
    //   getWalletTransactions()
  
    }, []);

// console.log({ transactions })

  return (
    <>
        <NavbarMenu  style={{backgroundColor: '#003B95' }} />
        
        <div className='Ewallet'>
            {/* Do a mini navbar just below the main navbar */}
            <Container className='container'>
                <Row>
                    <Col xs={12} md={3} lg={3}>
                        <UserNavbar />
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                        <Row>
                            <Col xs={12} md={12} className='mt-5'>
                                <div className="welcome-panel">
                                    <Row>
                                        <Col xs={12} md={6}>
                                            {/* <p>
                                                <FaWallet /> My Ewallet Balance:
                                            </p>  */}
                                            <h2>Report An Issue</h2>  
                                            <p>Encountered an issue or challenge or feedback? Fill out this form.</p>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            {/* <h4>
                                            ₦{balance > 100 ?balance.toLocaleString() : balance }.00
                                            </h4>    */}
                                        </Col>                                                            
                                    </Row>
                                </div>
                            </Col>
                            <Col xs={12} md={12} className='mt-5'>

                                {/* {
                                    transactions.length> 0 ? transactions.map((each, i)=>{
                                        
                                        return (
                                            
                                            <div className="ewallet-box mb-3" key={i}>
                                                <p>
                                                    { 
                                                        each.transactionType === "credit" ? "Added" : ""
                                                    } 
                                                    <b> ₦{each.amount > 100 ? each.amount.toLocaleString() : each.amount } </b> 
                                                     to your account on  
                                                    <b>  {""}
                                                        {
                                                            new Date(each.createdAt).toLocaleString('en-US', { timeZone: 'UTC' })
                                                        } 
                                                    </b>
                                                </p>
                                            </div>
                                        )
                                    })
                                    

                                    :

                                    (

                                        <div className="ewallet-box mb-3">
                                            <p>
                                                <b>No Transaction History to show</b>
                                            </p>
                                        </div>
                                    )
                                } */}

                                <form onSubmit={handleReportIssue}>

                                    <div className="row g-3 mb-4">
                                        <div className="col">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">First Name</label>
                                            <input type="text" className="form-control" placeholder="First name" aria-label="First name" value={firstName} name='firstName' onChange={handleChangeInput}/>
                                        </div>
                                        <div className="col">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Last Name</label>
                                            <input type="text" className="form-control" placeholder="Last name" aria-label="Last name" value={lastName} name='lastName' onChange={handleChangeInput}/>
                                        </div>
                                    </div>
                                    <div className="row g-3 mb-4">
                                        <div className="col">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Email address</label>
                                            <input type="text" className="form-control" placeholder="Email Address" aria-label="Email Address" value={emailAddress} name='emailAddress' onChange={handleChangeInput}/>
                                        </div>
                                        <div className="col">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Subject</label>
                                            <input type="text" className="form-control" placeholder="Subject" aria-label="Subject" value={subject} name='subject' onChange={handleChangeInput}/>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlTextarea1" className="form-label">Message</label>
                                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder='Type Something...' value={message} name='message' onChange={handleChangeInput}></textarea>
                                    </div>

                                    <button type="submit" className="btn btn-primary w-100 mt-4">Submit</button>
                                </form>


                                
                            </Col>

                            
                        </Row>
                    </Col>
                </Row>
            </Container>

            { loading && <Spinner />}

        </div>
    </>
  )
}

export default ReportIssuesPage