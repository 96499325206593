import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './FlightDeals.css';
import FlightImg from '../../Assets/Img/FlightImg.png'
import AirplaneImg from '../../Assets/Img/AirplaneImg.png'
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const FlightDeals = () => {
  return (
    <div className='FlightDeals'>
        <Container className='container'>
            <Row>                
                <Col xs={12} md={7} className='travelWrap'>
                    <div className="ticket-img" data-aos="flip-left">
                        <img src={FlightImg} alt="Flight Img" className='img-fluid' />
                    </div>
                    <div className='travelText'>
                        <h2>Browse travel deals</h2>
                        <p>Explore amazing travel deals for your next vacation</p>
                        <Link to={'/flights'}>
                            <div className="btnMore">
                                Explore flight deals now <FaArrowRight />
                            </div> 
                        </Link>
                    </div>
                </Col>                     
                <Col xs={12} md={5}>
                    <div className="plane-image" data-aos="flip-right">
                        <img src={AirplaneImg} alt="Airplane Img" className='img-fluid' />
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default FlightDeals