import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import { ArrowRight } from '@mui/icons-material';
import './LandingPage.css'
import { FaArrowAltCircleRight, FaCheck, FaCloudDownloadAlt, FaCreditCard, FaListUl, FaSignInAlt, FaSitemap, FaTags, FaWallet } from 'react-icons/fa';
import hostImg from "../../Assets/illustrations/host-img.jpg"
import guestImg from "../../Assets/illustrations/guest-img.jpg"
import { Link } from 'react-router-dom';
import NavbarMenu from '../Layouts/Navbar'; 

const LandingPage = () => {
  return (
    <div>
        <NavbarMenu  style={{backgroundColor: '#003B95' }} />

        <div className="hero">
            <Container className='container'>
                <Row>
                    <Col xs={12} md={6}>
                        <div className="hero-form" data-aos="flip-left">
                            <h3>Create New Listing Account</h3>
                            {/* <p>More than 200k vacation rentals already listed</p>
                            <p>Over 500k vacation bookings and guest arrivals</p> */}
                            <p><FaCheck className="icon" /> Get secured bookings and proper insurance for your listing</p>
                            <p><FaCheck className="icon" /> More than 40% of new service listings get their first booking within a week</p>
                            <hr />
                            <h4>Create a host account to get started:</h4>
                            <p>By continuing, you agree to let Maczuby email you regarding your property registration.</p>
                            <Link to={"/host-login"} className="btn d-block btn-warning mt-2 mb-3">Get Started <ArrowRight /></Link>
                            <h4 className='d-block'>Already A Host? </h4>
                            <p>Sign in to your host account to manage listings and other reports</p>
                            <Link to={"/host-login"} className="btn btn-primary d-block">Sign In here to continue <FaSignInAlt /></Link>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <div className="hero-text-left" data-aos="flip-right">
                            <h1>You can List</h1>
                            <h2>Anything today on Maczuby</h2>
                            <p>
                                Registration is 100% free and can take as little as 15minutes to complete, start listing 
                                your car, restaurant, hotel, apartments, services today!
                            </p>
                        </div>
                    </Col>
                </Row>
                
            </Container>
        </div>

        <div className="about-section">
            <Container className='container'>
                <Row>
                    <Col xs={12} md={12} lg={5}>
                        <div className="about-left" data-aos="flip-up">
                            <h2>Your <span className="highlight">satisfaction</span> is our top priority</h2>
                            <p className='mt-5 mb-3'>Here's how we're helping you feel confident welcoming guest:</p>
                            <p><FaCheck className="icon" /> Set <b>house rules</b> guest must agree to before they stay</p>
                            <p><FaCheck className="icon" /> Request <b>damage deposits</b> for extra security</p>
                            <p><FaCheck className="icon" /> <b>Report guest misconduct</b> if something goes wrong</p>
                            <p><FaCheck className="icon" /> Access <b>24/7 support</b> in 43+ languages</p>
                            <p><FaCheck className="icon" /> Protection against <b>liability claims</b> from guests</p>
                             
                            <div className="btnMore mt-3">Start Now <FaArrowAltCircleRight /></div>
                        </div>
                        
                    </Col>
                    <Col xs={12} md={12} lg={7}>
                        <div className="about-img mb-3" data-aos="flip-down">
                            <img src={hostImg} alt="Host Image" className='img-fluid' />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={12} lg={12} className='text-center mt-5 mb-3' data-aos="flip-left">
                        <h2>Why work with us?</h2>
                    </Col>
                    <Col xs={12} md={12} lg={4}>
                        <div className="benefits" data-aos="flip-up">
                            <div className="icon-big">
                                <FaSitemap />
                            </div>
                            <h4>List any type of property </h4>
                            <p>Apartments, cars, flight tickets, tours, services and everything in between can be listed for free.</p>
                        </div>
                        
                    </Col>
                    <Col xs={12} md={12} lg={4}>
                        <div className="benefits" data-aos="flip-up">
                            <div className="icon-big">
                                <FaCloudDownloadAlt />
                            </div>
                            <h4>Easily import details </h4>
                            <p>To  save you time, many of the details from your existing listings can be imported.</p>
                        </div>
                        
                    </Col>
                    <Col xs={12} md={12} lg={4}>
                        <div className="benefits" data-aos="flip-up">
                            <div className="icon-big">
                                <FaListUl/>
                            </div>
                            <h4>Step by step guidance  </h4>
                            <p>You don't need to worry about complexities, each step is easy to follow while listing.</p>
                        </div>
                        
                    </Col>
                    <Col xs={12} md={12} lg={4}>
                        <div className="benefits" data-aos="flip-down">
                            <div className="icon-big">
                                <FaTags />
                            </div>
                            <h4>Unique discounts  </h4>
                            <p>Offer unique discounts for each rental property based on duration or holiday seasons</p>
                        </div>
                        
                    </Col>
                    <Col xs={12} md={12} lg={4}>
                        <div className="benefits" data-aos="flip-down">
                            <div className="icon-big">
                                <FaCreditCard />
                            </div>
                            <h4>Enjoy More flexibility </h4>
                            <p>Easily allow your guests enjoy the flexibility of free cancellation policies.</p>
                        </div>
                        
                    </Col>
                    <Col xs={12} md={12} lg={4}>
                        <div className="benefits" data-aos="flip-down">
                            <div className="icon-big">
                                <FaWallet />
                            </div>
                            <h4>Review Score </h4>
                            <p>Show reviews from third-party platforms easily so as to increase your booking rates.</p>
                        </div>
                        
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={12} lg={5} className='mt-5'>
                        <div className="about-left" data-aos="flip-up">
                            <h2>Get to know your guests </h2>
                            <p className='mt-5 mb-3'>No matter where they're are from, our guest share a few similarities: </p>
                            <p><FaCheck className="icon" /> 75% of night booked are by guests of 5 or more previous bookings</p>
                            <p><FaCheck className="icon" /> 68% of night booked are by family or couples </p>
                            <p><FaCheck className="icon" /> 42% of night booked are for properties other than hotel</p>
                             
                            <div className="btnMore mt-3">Start Now <FaArrowAltCircleRight /></div>
                        </div>
                        
                    </Col>
                    <Col xs={12} md={12} lg={7}>
                        <div className="about-img mb-3" data-aos="flip-down">
                            <img src={guestImg} alt="Guest Image" className='img-fluid' />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={12} lg={12} className='mt-5 mb-3' data-aos="flip-right">
                        <h2 className='text-center'>Frequently Asked Questions</h2>
                        <p className='text-center'>Your booking questions answered!</p>
                    </Col>
                    <Col xs={12} md={12} lg={12} className='mt-5 mb-3' data-aos="flip-left">
                        <div className="faq-wrap justify-content-center align-items-center">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header><h5>What happens if my property is damaged by a guest?</h5></Accordion.Header>
                                    <Accordion.Body>
                                        Property owners can request damage deposits from guests. Deposits help cover any potential damage caused by a guest, offering some reassurance that your property will be treated respectfully. If anything goes wrong, it can be reported to our team through our misconduct reporting feature.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><h5>When will my property go online?</h5></Accordion.Header>
                                    <Accordion.Body>
                                        Once you finish creating your listing, you can open your property for bookings on our site. We may ask you to verify your property before you can start accepting bookings, but you can use this time to get familiar with the platform and get prepared for your first guests.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

        <div className="hero">
            <Container className='container'>
                <Row>
                    <Col xs={12} md={12} lg={6}>
                        <div className="hero-text-left mt-5 mb-3" data-aos="flip-right">
                            <h1>
                                Sign up and start - welcoming guests today! 
                            </h1>
                        </div>
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                        <div className="hero-form mt-5 mb-3" data-aos="flip-left">
                            <h3>Create New Listing</h3>
                            {/* <p>More than 200k vacation rentals already listed</p>
                            <p>Over 500k vacation bookings and guest arrivals</p> */}
                            <p><FaCheck className="icon" /> Get secured bookings and proper insurance for your listing</p>
                            <p><FaCheck className="icon" /> More than 40% of new service listings get their first booking within a week</p>
                            <hr />
                            <h4>Create a host account to get started:</h4>
                            <p>By continuing, you agree to let Maczuby email you regarding your property registration.</p>
                            <div className="btn d-block btn-warning mt-2 mb-3">Get Started <ArrowRight /></div>
                            <h4 className='d-block'>Already a Host?</h4>
                            <p className="btn text-primary">Sign In here to continue <FaSignInAlt /></p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

    </div>
  )
}

export default LandingPage