import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HostNavbar from "./HostNavbar";
import { FaUpload } from "react-icons/fa";
import "./ListFlight.css";
import dropImg from "../../Assets/illustrations/ep-picture-filled.svg";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BaseURL } from "../../config/variables";
import Spinner from "../Alert/Spinner/Spinner";
import { uploadImagesToCloud } from "../../utils/imageUtils/uploadImage";

const ListFlight02 = () => {
  const navigate = useNavigate();
  const currentFlightID = window.localStorage.getItem("flightID");
  // alert (currentFlightID);
  const [data, setData] = useState({
    flightID: currentFlightID,
  });
  const [loading, setLoading ] = useState(false)


  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)


    // const formdata = new FormData();
    // formdata.append("flightID", data.flightID);
    // formdata.append("flightPic1", data.flightPic1);
    // formdata.append("flightPic2", data.flightPic2); 

    try {

      const filteredArrayOfImages = [data.flightPic1, data.flightPic2].filter(imageUrl => imageUrl !== "" && imageUrl !== null && imageUrl !== undefined);

      const uploadedImageURLs = await uploadImagesToCloud(filteredArrayOfImages)
    const imageURLs = []
    uploadedImageURLs.forEach((each, i)=>{
      imageURLs.push(each.url)
    })

      const response = await axios.post(
        `${BaseURL}/addflightimages`,
        {flightID: data.flightID, imageURLs}
      );
      const { success, message } = response.data;
      // console.log(data);

      if (success) {
        handleSuccess(message);
        setTimeout(() => {
          navigate("/list-flight");
        }, 1000);
        window.localStorage.removeItem("flightID");
        setLoading(false)
        
      } else {
        setLoading(false)
        handleError(message);
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  };

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <ToastContainer />

      <div className="ListFlight">
        <Container className="container">
          <Row>
            <Col xs={12} md={3} lg={3}>
              <HostNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <form className="form-panel" onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <h2>Airline Photos:</h2>
                    <div className="dropbox mt-5">
                      <p>
                        <strong>
                          Upload logo and featured advert picture of your airline flight provider.{" "}
                        </strong>
                      </p>
                      <div className="dropphotos">
                        <div className="picbox mt-5">
                          <img src={dropImg} alt="property pic" />
                        </div>
                        <p>Click below to select your photos</p>
                        <div className="col-12 mb-3">
                          <label className="form-label" htmlFor="flightPic1">
                            Airline Logo
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="flightPic1"
                            id="flightPic1" 
                            onChange={(e) =>
                              setData({
                                ...data,
                                flightPic1: e.target.files[0],
                              })
                            }
                          />
                          <label className="form-label" htmlFor="flightPic2">
                            Featured Advert Photo
                            <small><b>E.g. City photo or popular pictures of flight destination</b></small>
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="flightPic2"
                            id="flightPic2" 
                            onChange={(e) =>
                              setData({
                                ...data,
                                flightPic2: e.target.files[0],
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col xs={12} md={12} lg={4} className="mt-3">
                    <button type="submit" className="btnOutline mt-5">
                      Publish <FaUpload />
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>

        { loading && <Spinner />}

      </div>
    </>
  );
};

export default ListFlight02;
