import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./HostDashboard.css";
import {
  PaymentOutlined,
  Payments,
  RoomServiceTwoTone,
} from "@mui/icons-material";
import HostNavbar from "./HostNavbar";
import { Link, useNavigate } from "react-router-dom";
import {
  FaArrowCircleRight,
  FaCarSide,
  FaHome,
  FaMapSigns,
  FaPlaneDeparture,
} from "react-icons/fa";
import NavbarMenu from "../Layouts/Navbar";
import { useCookies } from "react-cookie";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BaseURL } from "../../config/variables";
import { useGetHostID } from "../../Hooks/useGetHostID";
import Spinner from "../Alert/Spinner/Spinner";
import { handleError } from "../../utils/notifications/toasts";
import { getHostAccountHistory } from "../../utils/ownerActions/ownerRequests";

const HostDashboard = () => {
  const hostID = useGetHostID();

  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies([]);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [accountHistory, setAccountHistory] = useState(null);

  const [amountDueA, setAmountDueA] = useState(0);
  const [amountDueV, setAmountDueV] = useState(0);
  const [amountDueF, setAmountDueF] = useState(0);
  const [amountDueT, setAmountDueT] = useState(0);
  const [amountDueS, setAmountDueS] = useState(0);

  const fetchHostAccountHistory = async () => {
    setLoading(true);

    const history = await getHostAccountHistory(hostID);
    if (history?.status) {
      setAccountHistory(history);
      // console.log({ history })
      setLoading(false);
    } else {
      handleError("Could not get account histroy.");
      setLoading(false);
    }
  };

  const verifyLoggedInUser = async () => {
    try {
      const token = sessionStorage.getItem("session");

      if (token) {
        const user = localStorage.getItem("user");
        setUsername(user);
        setLoading(false);

        return toast(`Hello ${user.toUpperCase()}`, {
          position: "top-right",
        });
      } else {
        handleError("Please login!");
        localStorage.clear();
        await navigate("/host-login");
      }
    } catch (error) {
      if (error.response?.data?.message) {
        return handleError(error.response?.data?.message);
      } else {
        return handleError(error.message);
      }
    }
  };

  useEffect(() => {
    verifyLoggedInUser();
  }, []);

  useEffect(() => {
    fetchHostAccountHistory();
  }, []);

  useEffect(() => {
    //   Amounts Due

    setAmountDueA(
      accountHistory?.hostPayoutsDue?.TotalAccommodationsDue &&
        accountHistory?.hostPayoutsDue?.TotalAccommodationsDue > 100
        ? accountHistory?.hostPayoutsDue?.TotalAccommodationsDue.toLocaleString()
        : accountHistory?.hostPayoutsDue?.TotalAccommodationsDue
    );

    setAmountDueV(
      accountHistory?.hostPayoutsDue?.TotalVehiclesDue &&
        accountHistory?.hostPayoutsDue?.TotalVehiclesDue > 100
        ? accountHistory?.hostPayoutsDue?.TotalVehiclesDue.toLocaleString()
        : accountHistory?.hostPayoutsDue?.TotalVehiclesDue
    );

    setAmountDueF(
      accountHistory?.hostPayoutsDue?.TotalFlightsDue &&
        accountHistory?.hostPayoutsDue?.TotalFlightsDue > 100
        ? accountHistory?.hostPayoutsDue?.TotalFlightsDue.toLocaleString()
        : accountHistory?.hostPayoutsDue?.TotalFlightsDue
    );

    setAmountDueT(
      accountHistory?.hostPayoutsDue?.TotalToursDue &&
        accountHistory?.hostPayoutsDue?.TotalToursDue > 100
        ? accountHistory?.hostPayoutsDue?.TotalToursDue.toLocaleString()
        : accountHistory?.hostPayoutsDue?.TotalToursDue
    );

    setAmountDueS(
      accountHistory?.hostPayoutsDue?.TotalServicesDue &&
        accountHistory?.hostPayoutsDue?.TotalServicesDue > 100
        ? accountHistory?.hostPayoutsDue?.TotalServicesDue.toLocaleString()
        : accountHistory?.hostPayoutsDue?.TotalServicesDue
    );
  }, [accountHistory]);

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />

      <div className="HostDashboard">
        {/* Do a mini navbar just below the main navbar */}
        <Container className="container">
          <Row>
            <Col xs={12} md={3} lg={3}>
              <HostNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <Row>
                <Col xs={12} md={12} className="mt-5 mb-5">
                  <h2 className="mb-3">
                    List your property on Maczuby.com and start earning from
                    rentals in no time!
                  </h2>
                  <div className="welcome-panel">
                    <h3 className="mb-3">
                      Hi {username.charAt(0).toUpperCase() + username.slice(1)},
                    </h3>
                    <p>
                      Welcome to Maczuby Host Account, We’re Africa’s leading
                      hospitality and rental services company, providing secured
                      transaction between service providers and their customers.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} lg={12} className="mb-5">
                  To get started, select the type of property you want to list
                  on Maczuby.com
                </Col>
                <Col xs={12} md={12} lg={4} className="mb-5">
                  <div className="listing-cta">
                    <div className="icon-list">
                      <FaHome className="icon-big" />
                    </div>
                    <h4>Accommodations</h4>
                    <p>
                      List your apartments, guesthouses, villas, hotel e.t.c.
                    </p>
                    <Link
                      to={"/list-accommodation"}
                      className="btn btn-primary"
                    >
                      List your property <FaArrowCircleRight />
                    </Link>
                  </div>
                </Col>
                <Col xs={12} md={12} lg={4} className="mb-5">
                  <div className="listing-cta">
                    <div className="icon-list">
                      <FaCarSide className="icon-big" />
                    </div>
                    <h4>Vehicle</h4>
                    <p>List your car, suv, vans and start getting bookings!.</p>
                    <Link to={"/list-vehicle"} className="btn btn-primary">
                      List your car <FaArrowCircleRight />
                    </Link>
                  </div>
                </Col>
                <Col xs={12} md={12} lg={4} className="mb-5">
                  <div className="listing-cta">
                    <div className="icon-list">
                      <FaPlaneDeparture className="icon-big" />
                    </div>
                    <h4>Flight Tickets</h4>
                    <p>List your flight tickets and start getting bookings.</p>
                    <Link to={"/list-flight"} className="btn btn-primary">
                      List your tickets <FaArrowCircleRight />
                    </Link>
                  </div>
                </Col>
                <Col xs={12} md={12} lg={4} className="mb-5">
                  <div className="listing-cta">
                    <div className="icon-list">
                      <FaMapSigns className="icon-big" />
                    </div>
                    <h4>Tourist Center</h4>
                    <p>
                      Enlist your tourist attraction centers and start getting
                      travellers.
                    </p>
                    <Link to={"/list-tour"} className="btn btn-primary">
                      List your tours <FaArrowCircleRight />
                    </Link>
                  </div>
                </Col>
                <Col xs={12} md={12} lg={4} className="mb-5">
                  <div className="listing-cta">
                    <div className="icon-list">
                      <RoomServiceTwoTone className="icon-big" />
                    </div>
                    <h4>Services</h4>
                    <p>
                      List your professional or vocational services for booking.
                    </p>
                    <Link to={"/list-service"} className="btn btn-primary">
                      List your services <FaArrowCircleRight />
                    </Link>
                  </div>
                </Col>
                {/* <Col xs={12} md={12} lg={4} className='mb-5'>
                                <div className="listing-cta">
                                    <div className="icon-list">
                                        <FaUsers className='icon-big' />
                                    </div>
                                    <h4>Companion</h4>
                                    <p>Furnished and self-catering accommodations where quests rent the entire place.</p>
                                    <div className="btn btn-primary">List your companions <FaArrowCircleRight /></div>
                                </div>
                            </Col> */}
              </Row>
              <Row>
                <Col xs={12} md={6} className="mb-5">
                  <div className="ewallet-panel">
                    <p>Total Earnings:</p>
                    
                    <h3 className="mt-3">
                      ₦
                      {(
                        parseFloat(
                          (amountDueA || "").toString().replace(/,/g, "")
                        ) ||
                        0 +
                          parseFloat(
                            (amountDueV || "").toString().replace(/,/g, "")
                          ) ||
                        0 +
                          parseFloat(
                            (amountDueF || "").toString().replace(/,/g, "")
                          ) ||
                        0 +
                          parseFloat(
                            (amountDueT || "").toString().replace(/,/g, "")
                          ) ||
                        0 +
                          parseFloat(
                            (amountDueS || "").toString().replace(/,/g, "")
                          ) ||
                        0
                      ).toLocaleString()}
                    </h3>

                    <div className="btn btn-warning">
                      {" "}
                      <Payments /> View Payout
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6} className="mb-5">
                  <div className="ewallet-panel">
                    <h4>All your listings:</h4>
                    <p className="mt-3">Find all your booking history here. </p>
                    <Link to="/my-listings">
                      <div className="btn btn-primary">
                        <PaymentOutlined /> View Listings
                      </div>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      <ToastContainer />

      {loading && <Spinner />}
    </>
  );
};

export default HostDashboard;
