import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";  
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HostNavbar from "./HostNavbar";
import { FaArrowRight, FaDollarSign, FaCheck, FaInfoCircle, FaPenAlt } from "react-icons/fa";
import "./ListFlight.css";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BaseURL } from "../../config/variables";

const ListFlight = () => {
   
  const {id} = useParams();
  const navigate = useNavigate();
  const currentHostID = window.localStorage.getItem("hostID");

  const [data, setData] = useState({
    hostID: currentHostID,
  });
 
  useEffect(() => {
    // scroll to top on page load

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    axios
      .get(`${BaseURL}/fetchflightdetails/${id}`)
      .then((res) => {
        if (res.data.status === "Success") {
          setData({...data,  
              fromWhere: res.data.Result[0].fromWhere,
              toWhere: res.data.Result[0].toWhere,
              departure: res.data.Result[0].departure,
              arrival: res.data.Result[0].arrival,
              estimatedHours: res.data.Result[0].estimatedHours,
              estimatedMinutes: res.data.Result[0].estimatedMinutes,
              tripType: res.data.Result[0].tripType,
              stops: res.data.Result[0].stops,
              ticketType: res.data.Result[0].ticketType,
              flightCategory: res.data.Result[0].flightCategory,
              flexibility: res.data.Result[0].flexibility,
              hostName: res.data.Result[0].hostName,
              aboutAgency: res.data.Result[0].aboutAgency,
              phoneNumber: res.data.Result[0].phoneNumber,
              price: res.data.Result[0].price,
              discount: res.data.Result[0].discount,
              agreeCancel: res.data.Result[0].agreeCancel,               
          });
          console.log(res.data.Result[0]);
        } else {
          console.log("No flight details found"); 
        }
      })
      .catch((err) => console.log(err));
  }, []); 

  
    const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
    const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

    const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(data)
    axios
      .put(`${BaseURL}/${id}`, data)
      .then((res) => {
        if (res.data.success) {
          const { success, message } = res.data;
          console.log(res.data);
          if (success) {
            handleSuccess(message);
            setTimeout(() => {
              navigate("/list-flight");
            }, 1000);
          } else {
            handleError(message);
          }
        }
      })
      .catch((err) => console.log(err));
    }

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <ToastContainer />

      <div className="ListFlight">
        <Container className="container">
          <Row>
            <Col xs={12} md={3} lg={3}>
              <HostNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <form className="form-panel" onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <h2>Edit Your Flight Ticket</h2>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <label htmlFor="fromWhere">From Where?</label>
                    <input
                      type="text"
                      name="fromWhere"
                      id="fromWhere"
                      className="form-control"
                      placeholder="e.g. Abuja (ABV)"
                      onChange={(e) =>
                        setData({ ...data, fromWhere: e.target.value })
                      }
                      value={data.fromWhere || ""}
                    />
                    <label htmlFor="toWhere">To Where?</label>
                    <input
                      type="text"
                      name="toWhere"
                      id="toWhere"
                      className="form-control"
                      placeholder="e.g. Dubai (DOHA)"
                      onChange={(e) =>
                        setData({ ...data, toWhere: e.target.value })
                      }
                      value={data.toWhere || ""}
                    />
                    <label htmlFor="departure">Departure:</label>
                    <input
                      type="time"
                      name="departure"
                      id="departure"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, departure: e.target.value })
                      }
                      value={data.departure || ""}
                    />
                    <label htmlFor="arrival">Arrival:</label>
                    <input
                      type="time"
                      name="arrival"
                      id="arrival"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, arrival: e.target.value })
                      }
                      value={data.arrival || ""}
                    />
                    <label htmlFor="estimatedHours">Estimated Hours:</label>
                    <input
                      type="number"
                      name="estimatedHours"
                      id="estimatedHours"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, estimatedHours: e.target.value })
                      }
                      value={data.estimatedHours || ""}
                    />
                    <label htmlFor="estimatedMinutes">Estimated Minutes:</label>
                    <input
                      type="number"
                      name="estimatedMinutes"
                      id="estimatedMinutes"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, estimatedMinutes: e.target.value })
                      }
                      value={data.estimatedMinutes || ""}
                    />

                    <label htmlFor="tripType">Type of Trip: </label>
                    <select
                      name="tripType"
                      id="tripType"
                      className="form-select selectt"
                      onChange={(e) =>
                        setData({ ...data, tripType: e.target.value })
                      }
                      value={data.tripType || ""}
                    >
                      <option value={""}>Choose Trip type</option>
                      <option value={"Round Trip"}>Round Trip</option>
                      <option value={"One Way"}>One Way</option>
                      <option value={"Multi-city"}>Multi City</option>
                    </select>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <label htmlFor="stops">How many stops?</label>
                    <input
                      type="number"
                      name="stops"
                      id="stops"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, stops: e.target.value })
                      }
                      value={data.stops || ""}
                    />

                    <label htmlFor="ticketType">Flight Ticket Type: </label>
                    <select
                      name="ticketType"
                      id="ticketType"
                      className="form-select selectt"
                      onChange={(e) =>
                        setData({ ...data, ticketType: e.target.value })
                      }
                      value={data.ticketType || ""}
                    >
                      <option value={""}>Choose Ticket type</option>
                      <option value={"Economy"}>Economy</option>
                      <option value={"Premium Economy"}>Premium Economy</option>
                      <option value={"Business"}>Business</option>
                      <option value={"First Class"}>First Class</option>
                    </select>

                    <label htmlFor="flightCategory">Flight Category: </label>
                    <select
                      name="flightCategory"
                      id="flightCategory"
                      className="form-select selectt"
                      onChange={(e) =>
                        setData({ ...data, flightCategory: e.target.value })
                      }
                      value={data.flightCategory || ""}
                    >
                      <option value={""}>Choose Category</option>
                      <option value={"Cheapest"}>Cheapest</option>
                      <option value={"Fastest"}>Fastest</option>
                      <option value={"Other"}>Other</option>
                    </select>

                    <label htmlFor="flexibility">Flexibility: </label>
                    <select
                      name="flexibility"
                      id="flexibility"
                      className="form-select selectt"
                      onChange={(e) =>
                        setData({ ...data, flexibility: e.target.value })
                      }
                      value={data.flexibility || ""}
                    >
                      <option value={""}>Choose Flexibility</option>
                      <option value={"Refundable"}>Refundable</option>
                      <option value={"Non Refundable"}>Non Refundable</option>
                    </select>

                    <label htmlFor="hostName">Airline Name</label>
                    <input
                      type="text"
                      name="hostName"
                      id="hostName"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, hostName: e.target.value })
                      }
                      value={data.hostName || ""}
                    />

                    <label htmlFor="aboutAgency">About Your Agency</label>
                    <textarea
                      name="aboutAgency"
                      id="aboutAgency"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, aboutAgency: e.target.value })
                      }
                      value={data.aboutAgency || ""}
                    ></textarea>

                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, phoneNumber: e.target.value })
                      }
                      value={data.phoneNumber || ""}
                    />
                  </Col>

                  <Col xs={12} md={12} lg={12} className="mt-5">
                    <Row>
                      <Col xs={12} md={12} lg={6} className="mt-5">
                        <h4>How much do you want to charge per ticket?</h4>

                        <label htmlFor="price">
                          Ticket Price travellers pay (₦
                          ):{" "}
                          <small>
                            Amount in dollars will be paid in your exchange rate
                          </small>{" "}
                        </label>
                        <input
                          type="number"
                          name="price"
                          id="price"
                          className="form-control"
                          onChange={(e) =>
                        setData({ ...data, price: e.target.value })
                      }
                      value={data.price || ""}
                        />
                        <small>Including taxes, commission, and fees</small>
                        <h4 className="mt-5">
                          {" "}
                          Get guest’s attention with a 20% discount:{" "}
                        </h4>
                        <select
                          name="discount"
                          id="discount"
                          className="form-select selectt"
                          onChange={(e) =>
                        setData({ ...data, discount: e.target.value })
                      }
                      value={data.discount || ""}
                        >
                          <option value={""}>Choose Y/N?</option>
                          <option value={"Yes"}>Yes</option>
                          <option value={"No"}>No</option>
                        </select>
                        <p>
                          Give 20% off your first 3 bookings for 90 days,
                          whichever comes first.
                        </p>
                      </Col>
                      <Col xs={12} md={12} lg={6} className="mt-5">
                        <h4>Cancellation policy</h4>
                        <p>
                          This policy is set at the property level - any changes
                          made will apply to all units.{" "}
                        </p>
                        <p>
                          <FaCheck className="icon" /> Guests can cancel their
                          bookings for free up to 1 day before their arrival
                        </p>
                        <p>
                          <FaCheck className="icon" /> Guests who cancel can
                          within 24 hours will have their cancellation fee
                          waived{" "}
                        </p>

                        <h4>
                          Price & cancellation policy{" "}
                          <FaInfoCircle className="icon" />
                        </h4>
                        <p>
                          <FaCheck className="icon" /> Guests will pay 10% less
                          than the standard rate for a non-refundable{" "}
                        </p>
                        <p>
                          <FaCheck className="icon" /> Guests can’t cancel their
                          bookings for free anytime{" "}
                        </p>
                        <h4>Agree to cancellation policy</h4>
                        <select
                          name="agreeCancel"
                          id="agreeCancel"
                          className="form-select selectt"
                          onChange={(e) =>
                        setData({ ...data, agreeCancel: e.target.value })
                      }
                      value={data.agreeCancel || ""}
                        >
                          <option value={""}>Choose Y/N?</option>
                          <option value={"Yes"}>Yes</option>
                          <option value={"No"}>No</option>
                        </select>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <button type="submit" className="btnOutline mt-5">
                      Edit Flight <FaPenAlt />
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ListFlight;
