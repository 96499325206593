import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Login.css";
import { FaArrowRight, FaSignInAlt } from "react-icons/fa";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import NavbarMenu from "../Layouts/Navbar";
import { Cookies, useCookies } from "react-cookie";
import { useGetHostID } from "../../Hooks/useGetHostID";
import { useGetUserID } from "../../Hooks/useGetUserID";
import { BaseURL } from "../../config/variables";
import Spinner from "../Alert/Spinner/Spinner";
import { useGetOwnerID } from "../../Hooks/useGetOwnerID";

const HostLogin = () => {
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies([]);
  const userID = useGetUserID();
  const ownerID = useGetOwnerID();
  const [query] = useSearchParams();
  const pv = query.get("verify");
  const verify = pv;
  const hostID = useGetHostID();

  // if (hostID) {
  //   navigate("/host-dashboard");
  // }

  if (userID) {
    window.localStorage.removeItem("userID");
    window.sessionStorage.removeItem("session");
    // navigate("/login");
    removeCookie("token", "");
    window.location.reload();
  }

  const [inputValue, setInputValue] = useState({
    emailAddress: "",
    password: "",
  });
  const [loading, setLoading] = useState(false)

  const { emailAddress, password } = inputValue; 

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    if(verify == null) return

    if (verify !== "") {
      setLoading(true)
      axios
        .put(`${BaseURL}/hostlogin/${verify}`)
        .then((res) => {
          if (res.data.success) {
            const { success, message } = res.data;
            // verify = '';
          }
        })
        .catch((err) => console.log(err));
    }
    setLoading(false)

    
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-left",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {

      // console.log({inputValue})
      const { data } = await axios.post(
        `${BaseURL}/hostlogin`,
        {
          ...inputValue,
        },
        // { withCredentials: true }
      );

      // console.log(data);
      const { success, message, hostID, accessToken, user } = data;

      if (success) {
        window.localStorage.setItem("hostID", hostID);
        window.localStorage.setItem("user", user?.username);
        window.localStorage.setItem("emailAddress", user?.emailAddress);
        sessionStorage.setItem("session", accessToken)
        handleSuccess(message);

        navigate("/host-dashboard");
        setLoading(false)
          

      } else {
        setLoading(false)
        handleError(message);
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }

    setInputValue({
      ...inputValue,
      emailAddress: "",
      password: "",
    });
  };


  localStorage.clear()

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />

      <div className="LoginPage">
        <ToastContainer />

        <Container className="container">
          <Row className="justify-items-center">
            <Col xs={12} md={12}>
              <div className="login-panel">
                {verify && (
                  <div className="alert alert-success">
                    Email address verified successfully!
                  </div>
                )}

                <h3>Sign In - Host Account</h3>
                <p>
                  Login to continue or Create an account to list and manage your
                  property
                </p>
                <form onSubmit={handleSubmit}>
                  <label htmlFor="Email Address">Email Address</label>
                  <input
                    type="email"
                    name="emailAddress"
                    id="emailAddress"
                    className="form-control mb-3"
                    value={emailAddress}
                    onChange={handleOnChange}
                  />
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="form-control mb-3"
                    value={password}
                    onChange={handleOnChange}
                  />

                  <button
                    type="submit"
                    className="btnMore"
                    style={{ padding: "20px 30px", width: "100%" }}
                  >
                    Login <FaArrowRight />
                  </button>
                  <hr />
                  <p>
                    Forgot Password?{" "}
                    <Link to={"/host-forgot-pass"}>Get reset link</Link>
                  </p>
                </form>
                <div className="policy">
                  <p>
                    New Host?{" "}
                    <Link to={"/host-reg-1"}>
                      Sign Up <FaSignInAlt />
                    </Link>
                  </p>
                  <hr /> 
                  <p>
                    By sign in or creating an account you agree with our &nbsp;
                    <Link to="/">Terms & Conditions</Link> and{" "}
                    <Link to="/">Privacy Policy</Link>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        { loading && <Spinner />}
      </div>
    </>
  );
};

export default HostLogin;
