import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./MyBookings.css";
import UserNavbar from "./UserNavbar";
import { Table, Badge } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { RemoveCircleOutline } from "@mui/icons-material";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { Link } from "react-router-dom";
import { BaseURL } from "../../config/variables";
import Spinner from "../Alert/Spinner/Spinner";
import { handleError } from "../../utils/notifications/toasts";
import { getBookedUserAccommodation, getBookedUserFlights, getBookedUserServices, getBookedUserTours, getBookedUserVehicles } from "../../utils/userActions/userRequests";
import { limit, totalPages } from "../../utils/ownerActions/ownerRequests";

const MyBookings = () => {
  // Show all bookings for this user
  const userID = window.localStorage.getItem("userID");
  const [data, setData] = useState([]);
  const [flightData, setFlightData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [tourData, setTourData] = useState([]);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false)

const [currentAPage, setCurrentAPage] = useState(1)
  const [currentVPage, setCurrentVPage] = useState(1)
  const [currentFPage, setCurrentFPage] = useState(1)
  const [currentTPage, setCurrentTPage] = useState(1)
  const [currentSPage, setCurrentSPage] = useState(1)

  const [startAIndex, setstartAIndex] = useState(0)
  const [startVIndex, setstartVIndex] = useState(0)
  const [startFIndex, setstartFIndex] = useState(0)
  const [startTIndex, setstartTIndex] = useState(0)
  const [startSIndex, setstartSIndex] = useState(0)


  // const fetchAllBookings = async()=>{
  //   setLoading(true);
  //     setstartIndex((currentPage - 1) * limit)
  //     const accomm = await getBookedUserAccommodation(userID, currentPage, limit)
  //     const flights = await getBookedUserFlights(userID, currentPage, limit)
  //     const vehicles = await getBookedUserVehicles(userID, currentPage, limit)
  //     const tours = await getBookedUserTours(userID, currentPage, limit)
  //     const services = await getBookedUserServices(userID, currentPage, limit)
      
  //     if (accomm?.status) {
  //       setData(accomm.result)
  //         // setLoading(false);
        
  //     } else {
  //       handleError("Could not get accommodation lists.");
  //       // setLoading(false);
  //     }

  //     if (flights?.status) {
  //       setFlightData(flights.result)
  //         // setLoading(false);
        
  //     } else {
  //       handleError("Could not get flight lists.");
  //       // setLoading(false);
  //     }

  //     if (vehicles?.status) {
  //       setVehicleData(vehicles.result)
  //         // setLoading(false);
        
  //     } else {
  //       handleError("Could not get vehicles lists.");
  //       // setLoading(false);
  //     }

  //     if (tours?.status) {
  //       setTourData(tours.result)
  //         // setLoading(false);
        
  //     } else {
  //       handleError("Could not get tours lists.");
  //       // setLoading(false);
  //     }

  //     if (services?.status) {
  //       setServiceData(services.result)
  //         setLoading(false);
        
  //     } else {
  //       handleError("Could not get services lists.");
  //       setLoading(false);
  //     }
    
  // }

  const fetchAccomm = async()=>{
    setLoading(true);
    setstartAIndex((currentAPage - 1) * limit)
    const accomm = await getBookedUserAccommodation(userID, currentAPage, limit)
    if (accomm?.status) {
        setData(accomm.result)
        // console.log(accomm.result) 
        setLoading(false);
        // console.log({accomm})
    
    } else {
    handleError("Could not get accommodation lists.");
    setLoading(false);
    }

}

const fetchVehicles = async()=>{
    setLoading(true);
    setstartVIndex((currentAPage - 1) * limit)
    const vehicles = await getBookedUserVehicles(userID, currentVPage, limit)
    if (vehicles?.status) {
        setVehicleData(vehicles.result)
            setLoading(false);
        
        } else {
        handleError("Could not get vehicles lists.");
        setLoading(false);
        }

    
}
const fetchFlights = async()=>{
    setLoading(true);        
    setstartFIndex((currentAPage - 1) * limit)
    const flights = await getBookedUserFlights(userID, currentFPage, limit)
    if (flights?.status) {
        setFlightData(flights.result)
            setLoading(false);
        
        } else {
        handleError("Could not get flight lists.");
        setLoading(false);
    }


}
const fetchTourses = async()=>{
    setLoading(true);
    setstartTIndex((currentAPage - 1) * limit)
    const tours = await getBookedUserTours(userID, currentTPage, limit)
    if (tours?.status) {
        setTourData(tours.result)
            setLoading(false);
        
        } else {
        handleError("Could not get tours lists.");
        setLoading(false);
        }


}
const fetchServices = async()=>{
    setLoading(true);
    setstartSIndex((currentAPage - 1) * limit)
    const services = await getBookedUserServices(userID, currentSPage, limit)
    if (services?.status) {
        setServiceData(services.result)
            setLoading(false);
        
        } else {
        handleError("Could not get services lists.");
        setLoading(false);
        }


}


  // useEffect(() => {
   
  //   fetchAllBookings()
  // }, [currentPage]);

  useEffect(() => {
    fetchAccomm()
  }, [currentAPage]);

  useEffect(() => {
    fetchVehicles()
  }, [currentVPage]);

  useEffect(() => {
    fetchFlights()
  }, [currentFPage]);

  useEffect(() => {
    fetchTourses()
  }, [currentTPage]);

  useEffect(() => {
    fetchServices()
  }, [currentSPage]);


//   const handlePrevious = () =>{
//     currentPage > 1 ? setCurrentPage(currentPage - 1) : setCurrentPage(currentPage);
    
// }

// const handleNextPage = () =>{
//     currentPage < totalPages ? setCurrentPage(currentPage + 1) : setCurrentPage(currentPage);
    
// }

// const goToPage = (number) =>{
//     setCurrentPage(number) 
    
// }

const handlePrevious = (text) =>{
  if(text === "a"){
      currentAPage > 1 ? setCurrentAPage(currentAPage - 1) : setCurrentAPage(currentAPage);
      
  } else if(text === "v"){
      currentVPage > 1 ? setCurrentVPage(currentVPage - 1) : setCurrentVPage(currentVPage);
      
  } else if(text === "f"){
      currentFPage > 1 ? setCurrentFPage(currentFPage - 1) : setCurrentFPage(currentFPage);
      
  } else if(text === "t"){
      currentTPage > 1 ? setCurrentTPage(currentTPage - 1) : setCurrentTPage(currentTPage);
      
  } else if(text === "s"){
      currentSPage > 1 ? setCurrentSPage(currentSPage - 1) : setCurrentSPage(currentSPage);

  } 
  
}

const handleNextPage = (text) =>{
  if(text === "a"){
      currentAPage < totalPages ? setCurrentAPage(currentAPage + 1) : setCurrentAPage(currentAPage);
      
  } else if(text === "v"){
      currentVPage < totalPages ? setCurrentVPage(currentVPage + 1) : setCurrentVPage(currentVPage);
      
  } else if(text === "f"){
      currentFPage < totalPages ? setCurrentFPage(currentFPage + 1) : setCurrentFPage(currentFPage);
      
  } else if(text === "t"){
      currentTPage < totalPages ? setCurrentTPage(currentTPage + 1) : setCurrentTPage(currentTPage);
      
  } else if(text === "s"){
      currentSPage < totalPages ? setCurrentSPage(currentSPage + 1) : setCurrentSPage(currentSPage);

  } 
  
}

const goToPage = (number, text) =>{
  if(text === "a"){
      setCurrentAPage(number) 
      
  } else if(text === "v"){
      setCurrentVPage(number) 
      
  } else if(text === "f"){
      setCurrentFPage(number) 
      
  } else if(text === "t"){
      setCurrentTPage(number) 
      
  } else if(text === "s"){
      setCurrentSPage(number) 

  } 
  
}


  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

   
    // fetchAllBookings()
  }, []);

  //   console.log(data);

  function onTimeChange(value) {
    let timeSplit = value.split(":"),
      hours,
      minutes,
      meridian;
    hours = timeSplit[0];
    minutes = timeSplit[1];
    if (hours > 12) {
      meridian = "PM";
      hours -= 12;
      return hours + ":" + minutes + " " + meridian;
    } else if (hours < 12) {
      meridian = "AM";
      if (hours == 0) {
        hours = 12;
      }
      return hours + ":" + minutes + " " + meridian;
    } else {
      meridian = "PM";
      return hours + ":" + minutes + " " + meridian;
    }
  }

  function formatDate(inputDate) {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
      // Months use 0 index.
      return (
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
      );
    }
  }

  function datediff(first, second) {
    let start = new Date(first);
    start.setHours(0, 0, 0, 0); // Sets hours, minutes, seconds, and milliseconds
    let end = new Date(second);
    end.setHours(0, 0, 0, 0);
    let diff = Math.round((end - start) / 86400000) + 1; // See note below re `+ 1`
    return diff; // 3
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />

      <div className="MyBookings">
        <Container>
          <Row>
            <Col xs={12} md={3} lg={3}>
              <UserNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <Row>
                <div className="mt-5">
                  <h2>My Bookings &amp; Trips </h2>
                  <p>Find All your bookings and trips in one place. </p>
                </div>

                <Col xs={12} md={12} className=" mb-5">
                  <div className="table-panel">
                    <h2 style={{fontSize: "25px"}}>Accommodation(s) </h2>
                    
                    <Table responsive>
                      <thead>
                        <tr>
                          <th> S/N </th>
                          <th> Reservations </th>
                          <th> Room Type </th>
                          <th> Room Number </th>
                          {/* <th> Category </th> */}
                          <th> Duration </th>
                          <th> Status </th>
                          <th> Amount </th>
                          <th> Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length > 0 && data.map((property, index) => {
                          return (
                            <tr key={index}>
                              <th>{index + 1 + startAIndex}.</th>
                              <td>{property.propertyName} </td>
                              <td>{property.roomType}</td>
                              <td>{property.roomNo}</td>
                              {/* <td>{property.propertyType}</td> */}
                              <td>
                                From: {formatDate(property.checkInDate)} (
                                {onTimeChange(property.checkInTime)})<br />
                                To: {formatDate(property.checkOutDate)} (
                                {onTimeChange(property.checkOutTime)})
                              </td>
                              <td>
                                {property.confirmation === 0 && (
                                  <Badge bg="warning">Pending</Badge>
                                )}
                                {property.confirmation === 1 && (
                                  <Badge bg="success">Accepted</Badge>
                                )}
                                {property.confirmation === 2 && (
                                  <Badge bg="danger">Rejected</Badge>
                                )}
                              </td>
                              <td>
                                <div className="text-primary">
                                  NGN{numberWithCommas(property.amountPaid)}
                                </div>
                              </td>
                              <td width={120}>
                                <Link
                                  to={`/booked-accom/${property.bookingID}`}
                                >
                                  <div className="btn btn-primary">
                                    <FaEye />{" "}
                                  </div>
                                </Link>{" "}
                                
                              </td>
                            </tr>
                          );
                        })}

                        {/* <tr>
                          <td>2.</td>
                          <td>Flat Egea Petrol Manual - Car101 </td>
                          <td>Car Rentals</td>
                          <td>
                            From: 28/05/2023 <br />
                            To: 01/06/2023
                          </td>
                          <td>
                            <Badge bg="danger">Canceled</Badge>
                          </td>
                          <td>
                            <div className="text-primary">NGN148,343.00</div>
                          </td>
                          <td width={120}>
                            <div className="btn btn-primary">
                              <FaEye />{" "}
                            </div>{" "}
                            &nbsp;
                            <div className="btn btn-danger">
                              <RemoveCircleOutline />{" "}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>3.</td>
                          <td>Abuja (ABV) - Calabar (CBQ) Two-way Trips </td>
                          <td>Flight Tickets</td>
                          <td>
                            From: 18/05/2023 <br />
                            To: 01/06/2023
                          </td>
                          <td>
                            <Badge bg="primary">Completed</Badge>
                          </td>
                          <td>
                            <div className="text-primary">NGN88,343.00</div>
                          </td>
                          <td width={120}>
                            <div className="btn btn-primary">
                              <FaEye />{" "}
                            </div>{" "}
                            &nbsp;
                            <div className="btn btn-danger">
                              <RemoveCircleOutline />{" "}
                            </div>
                          </td>
                        </tr> */}
                      </tbody>
                    </Table>

                
                    <ul className="pagination "
                    style={{ alignItems: "right", justifyContent: "right"}}
                    >
                        <li className="page-item" onClick={()=> handlePrevious("a")}>
                        <Link className="page-link" to="" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                        </Link>
                        </li>
                        <li className="page-item" onClick={()=> goToPage(1, "a")}><Link className="page-link" to="">1</Link></li>
                        <li className="page-item" onClick={()=> goToPage(2, "a")}><Link className="page-link" to="">2</Link></li>
                        <li className="page-item" onClick={()=> goToPage(3, "a")}><Link className="page-link" to="">3</Link></li>

                        <li className="page-item" onClick={()=>  handleNextPage("a")}>
                        <Link className="page-link" to="" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                        </Link>
                        </li>
                    </ul>

                  </div>
                </Col>

                <Col xs={12} md={12} className=" mb-5">
                  <div className="table-panel">

                    <h2 style={{fontSize: "25px"}}>Flight(s)</h2>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th> S/N </th>
                          <th> Reservations </th>
                          <th> Ticket Type </th>
                          <th> Details </th>
                          <th> Status </th>
                          <th> Amount </th>
                          <th> Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        {flightData.map((property, index) => {
                          return (
                            <tr key={index}>
                              <th>{index + 1 + startFIndex}.</th>
                              <td>
                                {property.fromWhere} - {property.toWhere}{" "}
                              </td>
                              <td>{property.ticketType}</td>
                              <td>
                                From: {formatDate(property.checkInDate)} (
                                {onTimeChange(property.checkInTime)})<br />
                                To: {formatDate(property.checkOutDate)} (
                                {onTimeChange(property.checkOutTime)}) <br />
                                <b>Duration: </b>
                                {property.duration} days
                                <br />
                                <b>Passengers: </b>
                                {property.tickets}
                              </td>
                              <td>
                                {property.confirmation === 0 && (
                                  <Badge bg="warning">Pending</Badge>
                                )}
                                {property.confirmation === 1 && (
                                  <Badge bg="success">Accepted</Badge>
                                )}
                                {property.confirmation === 2 && (
                                  <Badge bg="danger">Rejected</Badge>
                                )}
                              </td>
                              <td>
                                <div className="text-primary">
                                  NGN{numberWithCommas(property.amountPaid)}
                                </div>
                              </td>
                              <td width={120}>
                                <Link
                                  to={`/booked-flight/${property.bookingID}`}
                                >
                                  <div className="btn btn-primary">
                                    <FaEye />{" "}
                                  </div>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <ul className="pagination "
                      style={{ alignItems: "right", justifyContent: "right"}}
                      >
                          <li className="page-item" onClick={()=> handlePrevious("f")}>
                          <Link className="page-link" to="" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                          </Link>
                          </li>
                          <li className="page-item" onClick={()=> goToPage(1, "f")}><Link className="page-link" to="">1</Link></li>
                          <li className="page-item" onClick={()=> goToPage(2, "f")}><Link className="page-link" to="">2</Link></li>
                          <li className="page-item" onClick={()=> goToPage(3, "f")}><Link className="page-link" to="">3</Link></li>

                          <li className="page-item" onClick={()=>  handleNextPage("f")}>
                          <Link className="page-link" to="" aria-label="Next">
                              <span aria-hidden="true">&raquo;</span>
                          </Link>
                          </li>
                      </ul>

                  </div>
                </Col>

                <Col xs={12} md={12} className=" mb-5">
                  <div className="table-panel">

                    <h2 style={{fontSize: "25px"}}>Vehicle(s)</h2>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th> S/N </th>
                          <th> Reservations </th>
                          <th> Category </th>
                          <th> Duration </th>
                          <th> Status </th>
                          <th> Amount </th>
                          <th> Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        {vehicleData.map((property, index) => {
                          return (
                            <tr key={index}>
                              <th>{index + 1 + startVIndex }.</th>
                              <td>{property.vehicleName} </td>
                              <td>{property.vehicleModel}</td>
                              <td>
                                From: {formatDate(property.checkInDate)} (
                                {onTimeChange(property.checkInTime)})<br />
                                To: {formatDate(property.checkOutDate)} (
                                {onTimeChange(property.checkOutTime)})
                              </td>
                              <td>
                                {property.confirmation === 0 && (
                                  <Badge bg="warning">Pending</Badge>
                                )}
                                {property.confirmation === 1 && (
                                  <Badge bg="success">Accepted</Badge>
                                )}
                                {property.confirmation === 2 && (
                                  <Badge bg="danger">Rejected</Badge>
                                )}
                              </td>
                              <td>
                                <div className="text-primary">
                                  NGN{numberWithCommas(property.amountPaid)}
                                </div>
                              </td>
                              <td width={120}>
                                <Link
                                  to={`/booked-vehicle/${property.bookingID}`}
                                >
                                  <div className="btn btn-primary">
                                    <FaEye />{" "}
                                  </div>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>


                    <ul className="pagination "
                    style={{ alignItems: "right", justifyContent: "right"}}
                    >
                        <li className="page-item" onClick={()=> handlePrevious("v")}>
                        <Link className="page-link" to="" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                        </Link>
                        </li>
                        <li className="page-item" onClick={()=> goToPage(1, "v")}><Link className="page-link" to="">1</Link></li>
                        <li className="page-item" onClick={()=> goToPage(2, "v")}><Link className="page-link" to="">2</Link></li>
                        <li className="page-item" onClick={()=> goToPage(3, "v")}><Link className="page-link" to="">3</Link></li>

                        <li className="page-item" onClick={()=>  handleNextPage("v")}>
                        <Link className="page-link" to="" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                        </Link>
                        </li>
                    </ul>

                  </div>
                </Col>

                <Col xs={12} md={12} className=" mb-5">
                  <div className="table-panel">

                    <h2 style={{fontSize: "25px"}}>Tour(s)</h2>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th> S/N </th>
                          <th> Reservations </th>
                          <th> Category </th>
                          <th> Duration </th>
                          <th> Status </th>
                          <th> Amount </th>
                          <th> Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tourData.map((property, index) => {
                          return (
                            <tr key={index}>
                              <th>{index + 1 + startTIndex }.</th>
                              <td>{property.tourName} </td>
                              <td>{property.tourType}</td>
                              <td>
                                From: {formatDate(property.checkInDate)} (
                                {onTimeChange(property.checkInTime)})<br />
                                To: {formatDate(property.checkOutDate)} (
                                {onTimeChange(property.checkOutTime)})
                              </td>
                              <td>
                                {property.confirmation === 0 && (
                                  <Badge bg="warning">Pending</Badge>
                                )}
                                {property.confirmation === 1 && (
                                  <Badge bg="success">Accepted</Badge>
                                )}
                                {property.confirmation === 2 && (
                                  <Badge bg="danger">Rejected</Badge>
                                )}
                              </td>
                              <td>
                                <div className="text-primary">
                                  NGN{numberWithCommas(property.amountPaid)}
                                </div>
                              </td>
                              <td width={120}>
                                <Link to={`/booked-tour/${property.bookingID}`}>
                                  <div className="btn btn-primary">
                                    <FaEye />{" "}
                                  </div>
                                </Link>{" "}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>


                    <ul className="pagination "
                    style={{ alignItems: "right", justifyContent: "right"}}
                    >
                        <li className="page-item" onClick={()=> handlePrevious("t")}>
                        <Link className="page-link" to="" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                        </Link>
                        </li>
                        <li className="page-item" onClick={()=> goToPage(1, "t")}><Link className="page-link" to="">1</Link></li>
                        <li className="page-item" onClick={()=> goToPage(2, "t")}><Link className="page-link" to="">2</Link></li>
                        <li className="page-item" onClick={()=> goToPage(3, "t")}><Link className="page-link" to="">3</Link></li>

                        <li className="page-item" onClick={()=>  handleNextPage("t")}>
                        <Link className="page-link" to="" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                        </Link>
                        </li>
                    </ul>

                  </div>
                </Col>

                <Col xs={12} md={12} className=" mb-5">
                  <div className="table-panel">

                    <h2 style={{fontSize: "25px"}}>Service(s)</h2>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th> S/N </th>
                          <th> Reservations </th>
                          <th> Category </th>
                          <th> Duration </th>
                          <th> Status </th>
                          <th> Amount </th>
                          <th> Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        {serviceData.map((property, index) => {
                          return (
                            <tr key={index}>
                              <th>{index + 1 + startSIndex }.</th>
                              <td>{property.serviceName} </td>
                              <td>{property.serviceType}</td>
                              <td>
                                From: {formatDate(property.checkInDate)} (
                                {onTimeChange(property.checkInTime)})<br />
                                To: {formatDate(property.checkOutDate)} (
                                {onTimeChange(property.checkOutTime)})
                              </td>
                              <td>
                                {property.confirmation === 0 && (
                                  <Badge bg="warning">Pending</Badge>
                                )}
                                {property.confirmation === 1 && (
                                  <Badge bg="success">Accepted</Badge>
                                )}
                                {property.confirmation === 2 && (
                                  <Badge bg="danger">Rejected</Badge>
                                )}
                              </td>
                              <td>
                                <div className="text-primary">
                                  NGN{numberWithCommas(property.amountPaid)}
                                </div>
                              </td>
                              <td width={120}>
                                <Link
                                  to={`/booked-service/${property.bookingID}`}
                                >
                                  <div className="btn btn-primary">
                                    <FaEye />{" "}
                                  </div>
                                </Link>{" "}
                                
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>



                    <ul className="pagination "
                      style={{ alignItems: "right", justifyContent: "right"}}
                      >
                          <li className="page-item" onClick={()=> handlePrevious("s")}>
                          <Link className="page-link" to="" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                          </Link>
                          </li>
                          <li className="page-item" onClick={()=> goToPage(1, "s")}><Link className="page-link" to="">1</Link></li>
                          <li className="page-item" onClick={()=> goToPage(2, "s")}><Link className="page-link" to="">2</Link></li>
                          <li className="page-item" onClick={()=> goToPage(3, "s")}><Link className="page-link" to="">3</Link></li>

                          <li className="page-item" onClick={()=>  handleNextPage("s")}>
                          <Link className="page-link" to="" aria-label="Next">
                              <span aria-hidden="true">&raquo;</span>
                          </Link>
                          </li>
                      </ul>

                  </div>
                </Col>

              </Row>
            </Col>
          </Row>
        </Container>

        { loading && <Spinner />}
      </div>
    </>
  );
};

export default MyBookings;
