

import axios from "axios"
import { BaseURL } from "../../config/variables"
import { handleError } from "../notifications/toasts"
import { toast } from "react-toastify"
import { checkImageFormat, uploadImageToCloud } from "../imageUtils/uploadImage"





export const getBookedAccommodation = async (hostID, page, limit) => {
  try {

      const response = await axios.get( `${BaseURL}/hostbookeditems/${hostID}?page=${page}&limit=${limit}`,

      {headers: {  
          'Accept': 'application/json', 'Content-Type': 'application/json',
      }}, 
      )
      
      const { data } = (response)

      return data
   
  } catch (error ) {

      if(error.response?.data?.message){
          return handleError(error.response?.data?.message) 
      } else {
          return handleError(error.message) 
      }
      
  } 

}

export const getBookedFlights = async (hostID, page, limit) => {
    try {
  
        const response = await axios.get( `${BaseURL}/hostbookedflights/${hostID}?page=${page}&limit=${limit}`,

        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)

        return data
        
    } catch (error ) {

        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
}


export const getBookedVehicles = async (hostID, page, limit) => {
    try {
  
        const response = await axios.get( `${BaseURL}/hostbookedvehicles/${hostID}?page=${page}&limit=${limit}`,

        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)

        return data
        
    } catch (error ) {

        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
}


export const getBookedTours = async (hostID, page, limit) => {
    try {
  
        const response = await axios.get( `${BaseURL}/hostbookedtours/${hostID}?page=${page}&limit=${limit}`,

        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)

        return data
        
    } catch (error ) {

        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
}


export const getBookedServices = async (hostID, page, limit) => {
    try {
  
        const response = await axios.get( `${BaseURL}/hostbookedservices/${hostID}?page=${page}&limit=${limit}`,

        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)

        return data
        
    } catch (error ) {

        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
}

export const getHostListing = async (endpoint, hostID, page, limit) => {
    try {
  
        const response = await axios.get( `${BaseURL}/${endpoint}/${hostID}?page=${page}&limit=${limit}`,

        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)

        return data
        
    } catch (error ) {

        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
}


export const getAvailableRooms = async (propertyID, page, limit) => {
    try {
  
        const response = await axios.get( `${BaseURL}/available-room/${propertyID}?page=${page}&limit=${limit}`,
  
        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)
  
        return data
     
    } catch (error ) {
  
        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
}


export const getBookedRooms = async (propertyID, page, limit) => {
    try {
  
        const response = await axios.get( `${BaseURL}/booked-room/${propertyID}?page=${page}&limit=${limit}`,
  
        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)
  
        return data
     
    } catch (error ) {
  
        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
}

export const addPropertyRoom = async (payload) => {
    try {
  
        const response = await axios.post( `${BaseURL}/add-room`,
        payload,
        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)
  
        return data
     
    } catch (error ) {
  
        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
}


export const deletePropertyRoom = async (roomID, hostID) => {
    try {
  
        const response = await axios.delete( `${BaseURL}/room/${roomID}/${hostID}`,
        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)
  
        return data
     
    } catch (error ) {
  
        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
}


export const tooglePropertyRoomAvailable = async (roomID, hostID) => {
    try {
  
        const response = await axios.patch( `${BaseURL}/room/${roomID}/${hostID}`,
        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)
  
        return data
     
    } catch (error ) {
  
        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
}

export const getAvailableCars = async (hostID, page, limit) => {
    try {
  
        const response = await axios.get( `${BaseURL}/available-cars/${hostID}?page=${page}&limit=${limit}`,
  
        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)
  
        return data
     
    } catch (error ) {
  
        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
}

export const getBookedCars = async (hostID, page, limit) => {
    try {
  
        const response = await axios.get( `${BaseURL}/booked-cars/${hostID}?page=${page}&limit=${limit}`,
  
        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)
  
        return data
     
    } catch (error ) {
  
        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
}

export const togleVehicleAvailable = async (vehicleID, hostID) => {
    try {
  
        const response = await axios.patch( `${BaseURL}/car/${vehicleID}/${hostID}`,
        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)
  
        return data
     
    } catch (error ) {
  
        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
}

export const deleteVehicle = async (vehicleID, hostID) => {
    try {
  
        const response = await axios.delete( `${BaseURL}/car/${vehicleID}/${hostID}`,
        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)
  
        return data
     
    } catch (error ) {
  
        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
}


export const uploadHostKYC = async ( kycDoc, kycTitle, currentHostID ) => {
    try {

        let imageUrl = ""

        if(typeof kycDoc === "string"){
            imageUrl = kycDoc
        } else {

            const checkImageErrors = checkImageFormat(kycDoc)
            if(checkImageErrors){
                return toast.error(checkImageErrors)
            }

            const image = await uploadImageToCloud(kycDoc)
            imageUrl = image.url

        }

        const response = await axios.post( `${BaseURL}/add-host-kyc`, {kycURL: imageUrl, documentName: kycTitle, hostID: currentHostID},

        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)

        return data
     
    } catch (error ) {

        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 

}


export const uploadHostAvatar = async ( avatar, currentHostID ) => {
    try {

        let imageUrl = ""

        if(typeof kycDoc === "string"){
            imageUrl = avatar
        } else {

            const checkImageErrors = checkImageFormat(avatar)
            if(checkImageErrors){
                return toast.error(checkImageErrors)
            }

            const image = await uploadImageToCloud(avatar)
            imageUrl = image.url

        }

        const response = await axios.post( `${BaseURL}/add-host-avatar`, {avatarURL: imageUrl, userID: currentHostID},

        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)

        return data
     
    } catch (error ) {

        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 

}
