import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  ArrowRight,
  PaymentOutlined,
  CheckCircle,
  TrendingUpOutlined,
} from "@mui/icons-material";
import "./FlightBooking.css";
import { useGetUserID, useGetUserSession } from "../../Hooks/useGetUserID";
import { Link, useParams, useNavigate } from "react-router-dom";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { FaSignInAlt } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { BaseURL } from "../../config/variables";
import Spinner from "../Alert/Spinner/Spinner";
import { validateUserBasicBooking } from "../../utils/userActions/userValidations";
import Toast from "../Alert/Toast";

const FlightBooking = () => {
  const userID = useGetUserID();
  const session = useGetUserSession();
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [countAdult, setCountAdult] = useState(1);
  const [countChildren, setChildrenCount] = useState(0);

  const subtractAdult = () => {
    if (countAdult === 0) {
      setCountAdult(countAdult + 1);
    } else {
      setCountAdult(countAdult - 1);
    }
  };

  const addAdult = () => {
    setCountAdult(countAdult + 1);
  };

  const handleAdultCount = (e) => {
    setCountAdult(e.target.value);
  };

  const subChildren = () => {
    if (countChildren === 0) {
      setChildrenCount(countChildren + 1);
    } else {
      setChildrenCount(countChildren - 1);
    }
  };

  const addChildren = () => {
    setChildrenCount(countChildren + 1);
  };

  const handleChildrenCount = (e) => {
    setChildrenCount(e.target.value);
  };

  const [msg, setMsg] = useState("");
  const [data, setData] = useState({
    flightID: "",
    fromWhere: "",
    toWhere: "",
    departure: "",
    arrival: "",
    estimatedHours: "",
    estimatedMinutes: "",
    tripType: "",
    stops: "",
    ticketType: "",
    flightCategory: "",
    flexibility: "",
    hostName: "",
    aboutAgency: "",
    price: "",
    discount: "",
    flightPic1: "",
    flightPic2: "",
  });

  function onTimeChange(value) {
    let timeSplit = value.split(":"),
      hours,
      minutes,
      meridian;
    hours = timeSplit[0];
    minutes = timeSplit[1];
    if (hours > 12) {
      meridian = "PM";
      hours -= 12;
      return hours + ":" + minutes + " " + meridian;
    } else if (hours < 12) {
      meridian = "AM";
      if (hours == 0) {
        hours = 12;
      }
      return hours + ":" + minutes + " " + meridian;
    } else {
      meridian = "PM";
      return hours + ":" + minutes + " " + meridian;
    }
  }

  function formatDate(inputDate) {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
      // Months use 0 index.
      return (
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
      );
    }
  }

  function datediff(first, second) {
    let start = new Date(first);
    start.setHours(0, 0, 0, 0); // Sets hours, minutes, seconds, and milliseconds
    let end = new Date(second);
    end.setHours(0, 0, 0, 0);
    let diff = Math.round((end - start) / 86400000) + 1; // See note below re `+ 1`
    return diff; // 3
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    // Default fetch
    axios
      .get(`${BaseURL}/flightdetails/` + id)
      .then((res) => {
        if (res.data.status === "Success") {
          setData({
            ...data,
            flightID: res.data.Result[0].flightID,
            hostID: res.data.Result[0].hostID,
            fromWhere: res.data.Result[0].fromWhere,
            toWhere: res.data.Result[0].toWhere,
            departure: res.data.Result[0].departure,
            arrival: res.data.Result[0].arrival,
            estimatedHours: res.data.Result[0].estimatedHours,
            estimatedMinutes: res.data.Result[0].estimatedMinutes,
            tripType: res.data.Result[0].tripType,
            stops: res.data.Result[0].stops,
            ticketType: res.data.Result[0].ticketType,
            flightCategory: res.data.Result[0].flightCategory,
            flexibility: res.data.Result[0].flexibility,
            hostName: res.data.Result[0].hostName,
            aboutAgency: res.data.Result[0].aboutAgency,
            price: res.data.Result[0].price,
            discount: res.data.Result[0].discount,
            flightPic1: res.data.Result[0].flightPic1,
            flightPic2: res.data.Result[0].flightPic2,
          });
          //console.log(res.data.Result);
        } else {
          console.log("No flight found");
          // setMsg("No flight found");
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const [checkInDate, setCheckInDate] = useState("");
  const [checkInTime, setCheckInTime] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [checkOutTime, setCheckOutTime] = useState("");
  const [bookingFor, setBookingFor] = useState("");

  const handleSubmit = async (text) => {
    setLoading(true);

    localStorage.setItem("paymentMethod", text);

    const result = validateUserBasicBooking({
      checkInDate,
      checkInTime,
      checkOutDate,
      checkOutTime,
      bookingFor,
    });

    if (result.errLength) {
      setLoading(false);

      return toast.error(() => <Toast title="Error" body={result.errMsg} />);
    }

    localStorage.setItem(
      "bookingDetails",
      JSON.stringify({
        checkInDate,
        checkInTime,
        checkOutDate,
        checkOutTime,
        bookingFor,
      })
    );

    navigate(
      `/pay-now/?itemID=${id}&itemType=${"flight"}&duration=${Number(
        datediff(checkInDate, checkOutDate)
      )}&unitPrice=${Number(
        data.price
      )}&checkInDate=${checkInDate}&checkInTime=${checkInTime}&checkOutDate=${checkOutDate}&checkOutTime=${checkOutTime}&hostID=${
        data.hostID
      }&bookingFor=${bookingFor}&tickets=${Number(
        countAdult + countChildren
      )}&amount=${
        data.discount === "No"
          ? (Number(data.price) * Number(datediff(checkInDate, checkOutDate)) +
              Number(data.price) *
                Number(datediff(checkInDate, checkOutDate))) *
            Number(countAdult + countChildren)
          : (Number(data.price) * Number(datediff(checkInDate, checkOutDate)) +
              Number(data.price) * Number(datediff(checkInDate, checkOutDate)) -
              Number(data.price) *
                Number(datediff(checkInDate, checkOutDate)) *
                0.2) *
            Number(countAdult + countChildren)
      }`
    );
    setLoading(false);
  };

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      {/* <ToastContainer /> */}

      <div className="FlightBooking">
        <div className="main-hero-wrap">
          <Container>
            <Row>
              <Col xs={12} md={12}>
                {/* Search Results */}
                <div className="search-results">
                  <Row>
                    <Col xs={12} md={12}>
                      <div className="tickets mb-5">
                        <Row>
                          <Col xs={12} md={12} lg={12}>
                            <Row>
                              <Col xs={12} md={4}>
                                <img
                                  src={data.flightPic1}
                                  alt={data.hostName}
                                  width={75}
                                />
                                &nbsp; {data.hostName}
                              </Col>
                              <Col xs={12} md={8} className="duration">
                                <div className="from">
                                  <b>{onTimeChange(data.departure)}</b> <br />
                                  {data.fromWhere}
                                </div>
                                <div className="time">
                                  {data.estimatedHours}hrs -{" "}
                                  {data.estimatedMinutes}mins <br />{" "}
                                  <b>{data.stops} Stop(s)</b>
                                </div>
                                <div className="to">
                                  <b>{onTimeChange(data.arrival)}</b>
                                  <br /> {data.toWhere}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col xs={12} md={12}>
                      <div className="ticket-form">
                        <form>
                          <Row>
                            <Col xs={12} md={12}>
                              <h2>Personal Information</h2>
                              <p>
                                We will send confirmation to your registered
                                email address.
                              </p>
                            </Col>
                            <Col xs={12} md={12}>
                              <div className="login-center mb-3">
                                {!session ? (
                                  <Link
                                    className="btnMore mb-5"
                                    style={{
                                      textDecoration: "none",
                                      padding: "15px",
                                      display: "block",
                                    }}
                                    to={`/login/?q=flight-booking&id=${id}`}
                                  >
                                    Sign In to continue! <FaSignInAlt />
                                  </Link>
                                ) : (
                                  <div className="alert alert-info">
                                    You're logged In!
                                  </div>
                                )}
                              </div>
                            </Col>

                            <Col xs={12} md={12} lg={12} className="mb-5">
                              <label htmlFor="booking">
                                <b>For yourself/Someone else?</b>
                              </label>
                              <input
                                type="text"
                                id=""
                                placeholder="name of who you are booking for"
                                className="form-control"
                                onChange={(e) => setBookingFor(e.target.value)}
                              />
                            </Col>

                            <Col xs={12} md={4}>
                              <div className="number_ct">
                                <label htmlFor="adult">Adult (s)</label>
                                <div className="number">
                                  <span
                                    className="minus"
                                    onClick={subtractAdult}
                                  >
                                    -
                                  </span>
                                  <input
                                    type="text"
                                    name="adult"
                                    id="adult"
                                    value={countAdult}
                                    onChange={handleAdultCount}
                                  />
                                  <span className="plus" onClick={addAdult}>
                                    +
                                  </span>
                                </div>
                              </div>
                            </Col>

                            <Col xs={12} md={4}>
                              <div className="number_ct">
                                <label htmlFor="children">Children</label>
                                <div className="number">
                                  <span className="minus" onClick={subChildren}>
                                    -
                                  </span>
                                  <input
                                    type="text"
                                    name="children"
                                    id="children"
                                    value={countChildren}
                                    onChange={handleChildrenCount}
                                  />
                                  <span className="plus" onClick={addChildren}>
                                    +
                                  </span>
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Row>
                              <Col xs={12} md={4}>
                                <h4>Booking Details:</h4>
                                <p>fill out your booking details below</p>
                              </Col>
                              <Col xs={12} md={8}>
                                <Row>
                                  <Col xs={12} md={3}>
                                    <span>
                                      <b>Departure: </b>
                                    </span>
                                  </Col>
                                  <Col xs={12} md={3}>
                                    <span>
                                      <b>
                                        {checkInDate && formatDate(checkInDate)}
                                      </b>
                                    </span>
                                    &nbsp;
                                    {checkInTime &&
                                      "(" + onTimeChange(checkInTime) + ")"}
                                  </Col>
                                  <Col xs={12} md={3}>
                                    <span>
                                      <b>Returning: </b>
                                    </span>
                                  </Col>
                                  <Col xs={12} md={3}>
                                    <span>
                                      <b>
                                        {checkOutDate &&
                                          formatDate(checkOutDate)}
                                      </b>
                                    </span>
                                    &nbsp;
                                    {checkOutTime &&
                                      "(" + onTimeChange(checkOutTime) + ")"}
                                  </Col>
                                  <Col xs={12} md={3}>
                                    <span>
                                      <b>No of Passengers: </b>
                                    </span>
                                  </Col>
                                  <Col xs={12} md={3}>
                                    <p>
                                      {countAdult && countAdult + countChildren}{" "}
                                      ticket(s)
                                    </p>
                                  </Col>
                                  <Col xs={12} md={3}>
                                    <span>
                                      <b>Total length of stay: </b>
                                    </span>
                                  </Col>
                                  <Col xs={12} md={3}>
                                    <p>
                                      {checkOutDate &&
                                        datediff(checkInDate, checkOutDate) +
                                          " day(s)"}
                                    </p>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Col xs={12} md={12} lg={3}>
                              <label htmlFor="checkInDate">
                                Departure Date:
                              </label>
                              <input
                                type="date"
                                id="checkInDate"
                                placeholder="mm/dd/yyyy"
                                className="form-control"
                                onChange={(e) => setCheckInDate(e.target.value)}
                              />
                            </Col>
                            <Col xs={12} md={12} lg={3}>
                              <label htmlFor="checkInTime">
                                Departure Time:
                              </label>
                              <input
                                type="time"
                                id="checkInTime"
                                placeholder="10:00AM"
                                className="form-control"
                                onChange={(e) => setCheckInTime(e.target.value)}
                              />
                            </Col>
                            <Col xs={12} md={12} lg={3}>
                              <label htmlFor="checkOutDate">
                                Returning Date:
                              </label>
                              <input
                                type="date"
                                id="checkOutDate"
                                placeholder="mm/dd/yyyy"
                                className="form-control"
                                onChange={(e) =>
                                  setCheckOutDate(e.target.value)
                                }
                              />
                            </Col>
                            <Col xs={12} md={12} lg={3}>
                              <label htmlFor="checkOutTime">
                                Returning Time:
                              </label>
                              <input
                                type="time"
                                id="checkOutTime"
                                placeholder="10:00AM"
                                className="form-control"
                                onChange={(e) =>
                                  setCheckOutTime(e.target.value)
                                }
                              />
                            </Col>
                          </Row>
                        </form>
                      </div>
                    </Col>

                    <Col xs={12} md={12} lg={12}>
                      <div className="reserve-pay">
                        <Row>
                          <Col xs={12} md={4}>
                            <p className="text-success">
                              <CheckCircle /> <br />
                              <b>
                                Flight Agency reserves right to cancel <br />
                                if you're not eligible
                              </b>
                            </p>
                          </Col>
                          <Col xs={12} md={4}>
                            <h4 className="text-center">Repayment Guarantee</h4>
                            <p className="text-center">
                              You can request refund if you're not satisfied
                              with this flight booking experience!
                            </p>
                          </Col>
                          <Col xs={12} md={4}>
                            {checkOutDate && (
                              // <Link
                              //   to={`/pay-now/?itemID=${id}&itemType=${"flight"}&duration=${Number(
                              //     datediff(checkInDate, checkOutDate)
                              //   )}&unitPrice=${Number(
                              //     data.price
                              //   )}&checkInDate=${checkInDate}&checkInTime=${checkInTime}&checkOutDate=${checkOutDate}&checkOutTime=${checkOutTime}&hostID=${
                              //     data.hostID
                              //   }&bookingFor=${bookingFor}&tickets=${Number(countAdult + countChildren)}&amount=${
                              //     data.discount === "No"
                              //       ? (Number(data.price) *
                              //           Number(
                              //             datediff(checkInDate, checkOutDate)
                              //           ) +
                              //         Number(data.price) *
                              //           Number(
                              //             datediff(checkInDate, checkOutDate)
                              //           )) * Number(countAdult + countChildren)
                              //       : (Number(data.price) *
                              //           Number(
                              //             datediff(checkInDate, checkOutDate)
                              //           ) +
                              //         Number(data.price) *
                              //           Number(
                              //             datediff(checkInDate, checkOutDate)
                              //           ) -
                              //         Number(data.price) *
                              //           Number(
                              //             datediff(checkInDate, checkOutDate)
                              //           ) *
                              //           0.2) * Number(countAdult + countChildren)
                              //   }`}
                              // >
                              <>
                                <h5 className="mt-3 mb-3">
                                  ₦
                                  {data.discount === "No"
                                    ? numberWithCommas(
                                        (Number(data.price) *
                                          Number(
                                            datediff(checkInDate, checkOutDate)
                                          ) +
                                          Number(data.price) *
                                            Number(
                                              datediff(
                                                checkInDate,
                                                checkOutDate
                                              )
                                            )) *
                                          Number(countAdult + countChildren)
                                      )
                                    : numberWithCommas(
                                        (Number(data.price) *
                                          Number(
                                            datediff(checkInDate, checkOutDate)
                                          ) +
                                          Number(data.price) *
                                            Number(
                                              datediff(
                                                checkInDate,
                                                checkOutDate
                                              )
                                            ) -
                                          Number(data.price) *
                                            Number(
                                              datediff(
                                                checkInDate,
                                                checkOutDate
                                              )
                                            ) *
                                            0.2) *
                                          Number(countAdult + countChildren)
                                      )}
                                </h5>
                                <div
                                  className="btnMore"
                                  onClick={() => handleSubmit("paystack")}
                                >
                                  Pay with Paystack <PaymentOutlined />
                                </div>
                                <div
                                  className="btnMore mt-3"
                                  onClick={() => handleSubmit("wallet")}
                                >
                                  Pay with Wallet <PaymentOutlined />
                                </div>
                              </>
                              // </Link>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {loading && <Spinner />}
      </div>
    </>
  );
};

export default FlightBooking;
