import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./OwnerDashboard.css";
import { PaymentOutlined, Payments } from "@mui/icons-material";
import NavbarMenu from "../Layouts/Navbar"; 
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useGetUserID } from "../../Hooks/useGetUserID";
import {
  FaAddressBook,
  FaArrowCircleRight,
  FaBriefcase,
  FaCreditCard,
  FaKey,
  FaReceipt,
  FaUserCheck,
} from "react-icons/fa";
import axios from "axios";
import OwnerNavbar from "./OwnerNavbar";
import { BaseURL } from "../../config/variables";

const UserPreview = () => { 
  const { id } = useParams();
  // alert(userID);

  const [data, setData] = useState({
    userID: "",
  });

  useEffect(() => {
    // scroll to top on page load

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    axios
      .get(`${BaseURL}/fetchuserdetails/${id}/`)
      .then((res) => {
        if (res.data.status === "Success") {
          setData({
            ...data,
            userID: res.data.Result[0].userID,
            firstName: res.data.Result[0].firstName,
            lastName: res.data.Result[0].lastName,
            username: res.data.Result[0].username,
            emailAddress: res.data.Result[0].emailAddress,
            phoneNumber: res.data.Result[0].phoneNumber,
            country: res.data.Result[0].country,
            stateProvince: res.data.Result[0].stateProvince,
            city: res.data.Result[0].city,
            zipCode: res.data.Result[0].zipCode, 
            streetAddress: res.data.Result[0].streetAddress, 
            createdAt: res.data.Result[0].createdAt, 
            kycUrl: res.data.Result[0].kycURL, 
            documentName: res.data.Result[0].documentName, 
          });
          console.log(res.data.Result[0]);
        } else {
          console.log("No such user found");
        }
      })
      .catch((err) => console.log(err));
  }, []);

  function onTimeChange(value) {
    let timeSplit = value.split(":"),
      hours,
      minutes,
      meridian;
    hours = timeSplit[0];
    minutes = timeSplit[1];
    if (hours > 12) {
      meridian = "PM";
      hours -= 12;
      return hours + ":" + minutes + " " + meridian;
    } else if (hours < 12) {
      meridian = "AM";
      if (hours == 0) {
        hours = 12;
      }
      return hours + ":" + minutes + " " + meridian;
    } else {
      meridian = "PM";
      return hours + ":" + minutes + " " + meridian;
    }
  }
  function formatDate(inputDate) {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
      // Months use 0 index.
      return (
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
      );
    }
  }

  function datediff(first, second) {
    let start = new Date(first);
    start.setHours(0, 0, 0, 0); // Sets hours, minutes, seconds, and milliseconds
    let end = new Date(second);
    end.setHours(0, 0, 0, 0);
    let diff = Math.round((end - start) / 86400000) + 1; // See note below re `+ 1`
    return diff; // 3
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <div className="OwnerDashboard">
        {/* Do a mini navbar just below the main navbar */}
        <Container className="container">
          <Row>
            <Col xs={12} md={3} lg={3}>
              <OwnerNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <Row>
                <Col xs={12} md={12} className="mt-5">
                  <h2 className="mb-3">User Details</h2>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} lg={12} className="">
                  <p>Here's the personal information of selected user: </p>
                </Col>
                <Col xs={12} md={12} lg={6} className="mb-5">
                  <div className="profile-bg">
                    <div className="icon-list">
                      <FaReceipt className="icon-big" />
                    </div>
                    <h4>Bio Details</h4>
                    <p>
                      <b>First Name: </b>{data.firstName}<br/>
                      <b>Last Name: </b>{data.lastName}<br/>
                      <b>Email Address: </b>{data.emailAddress}
                      <br/>                     
                      <b>Phone Number: </b>{data.phoneNumber}
                      <br/>
                      <b>Username: </b>{data.username}<br />
                      <b>Date Registered: </b>{formatDate(data.createdAt)} <small>(MM/DD/YYYY)</small>
                    </p> 
                   
                  </div>
                </Col>
                <Col xs={12} md={12} lg={6} className="mb-5">
                  <div className="profile-bg">
                    <div className="icon-list">
                      <FaAddressBook className="icon-big" />
                    </div>
                    <h4>Other Details</h4>
                    <p>
                      <b>Country:</b> {data.country}
                      <br />
                      <b>State/Province:</b> {data.stateProvince}
                      <br />
                      <b>City:</b> {data.city}
                      <br />
                      <b>Zip Code:</b> {data.zipCode}
                      <br />
                      <b>Street Address:</b> {data.streetAddress}
                    </p>
                  </div>
                </Col>


                <Col xs={12} md={12} lg={6} className="mb-5">
                <div className="profile-bg"
                  style={{
                    width: "100%"
                  }}
                  >
                    <img src={data.kycUrl} alt={data.kycUrl} 
                    style={{width: "100%", marginBottom: "15px"}}
                    />
                   
                    <p>KYC: {data.documentName}</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </>
  );
};

export default UserPreview;
