import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Subscribe.css';

const Subscribe = () => {
  return (
    <div className='Subscribe-wrap'>
        <Container>
            <Row>
                <Col xs={12} md={12} className='justify-items-center'>
                    <h2 data-aos="flip-left">Save Money On Your Next Trip with <span style={{fontWeight: 'bolder'}}>Mac<span className='coloredText'>zuby</span></span></h2>
                    <form>
                        <input type="email" placeholder='Enter your email address' />
                        <button>Subscribe Now</button>
                    </form>
                    <p>
                        Your privacy is important to us, so we'll never spam you or share your info with third parties. 
                        <br />Take a look at our privacy policy. You can unsubscribe at any time.
                    </p>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Subscribe