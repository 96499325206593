import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SearchRounded, TrendingUp } from "@mui/icons-material";
import "./Accommodations.css";
import apImg from "../Assets/illustrations/no-data-found.gif";
import Apartments from "./Apartments";
import NavbarMenu from "./Layouts/Navbar";
import axios from "axios";
import { FaFireAlt } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import Spinner from "./Alert/Spinner/Spinner";
import { BaseURL } from "../config/variables";
import { toast } from "react-toastify";

const Accommodations = () => {
  const [countRoom, setCountRoom] = useState(1);
  const [countAdult, setAdultCount] = useState(0);
  const [countChildren, setChildrenCount] = useState(0);
  const [loading, setLoading] = useState(false)
  
  // Search params from home search form
  const [query] = useSearchParams();
  const destination = query.get("destination");

  const subtractRoom = () => {
    if (countRoom === 0) {
      setCountRoom(countRoom + 1);
    } else {
      setCountRoom(countRoom - 1);
    }
  };

  const addRoom = () => {
    setCountRoom(countRoom + 1);
  };

  const handleRoomCount = (e) => {
    setCountRoom(e.target.value);
  };

  const subtractAdult = () => {
    if (countAdult === 0) {
      setAdultCount(countAdult + 1);
    } else {
      setAdultCount(countAdult - 1);
    }
  };

  const addAdult = () => {
    setAdultCount(countAdult + 1);
  };

  const handleAdultCount = (e) => {
    setAdultCount(e.target.value);
  };

  const subChildren = () => {
    if (countChildren === 0) {
      setChildrenCount(countChildren + 1);
    } else {
      setChildrenCount(countChildren - 1);
    }
  };

  const addChildren = () => {
    setChildrenCount(countChildren + 1);
  };

  const handleChildrenCount = (e) => {
    setChildrenCount(e.target.value);
  };
  const [data, setData] = useState([]);
  const [msg, setMsg] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchCity, setSearchCity] = useState("");
  const [searchCategory, setsearchCategory] = useState("");
  const [searchAmount, setSearchAmount] = useState(0);

  

  const handleCity = (e) => {
    // e.preventDefault();
    const searchCity = e.target.value;

    if (searchCity.length > 1) {
      const newFilter = data.filter((value) => {
        return (
          value.country.toLowerCase().includes(searchCity.toLowerCase()) ||
          value.stateProvince.toLowerCase().includes(searchCity.toLowerCase())
        );
      });

      setFilteredData(newFilter);

    } else if (searchCity.length === 0) {
      setFilteredData(data);
    }
  }

  
  
  const handleCategory = (e) => {
    // e.preventDefault();
    const searchCategory = e.target.value;

    if (searchCategory.length > 1) {
      const newFilter = data.filter((value) => {
        return (
          value.propertyType === searchCategory
        );
      });

      setFilteredData(newFilter);

    } else if (searchCategory.length === 0) {
      setFilteredData(data);
    }
  }
  
  const handlePrice = (e) => {
    // e.preventDefault();
    const searchPrice = e.target.value;

    if (searchPrice > 0) {
      const newFilter = data.filter((value) => {
        return (
          Number(value.price) <= Number(searchPrice)
        );
      });

      setFilteredData(newFilter);

    } else if (searchPrice.length === 0) {
      setFilteredData(data);
    }
  }

  const handleError = (err) =>{
    toast.error(err, {
      position: "bottom-left",
    });
    setLoading(false)
  }


  const fetchAccommodationList = async ()=>{
    setLoading(true)
    
    try {
      const res = await axios.get(`${BaseURL}/propertylist/`)
  
      if (res.data.status === "Success") {
        // setData(res.data.Result);
        setFilteredData(res.data.Result);
        // console.log(res.data.Result);
        setLoading(false)
      } else {
        // console.log("No vehicles found");
        setMsg("No vehicles found");
        setLoading(false)
      }
      
    } catch (error) {
      handleError("Could not get all list")
    }
  }



  useEffect(() => {
    // scroll to top on page load

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    fetchAccommodationList()
      
     
  }, []);

  // function numberWithCommas(x) {
  //   return x?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  // }

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />

      <div className="Accommodations">
        <Container>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <h2 className="mb-3">
                <FaFireAlt /> Hot Deals
              </h2>
            </Col>
            <Col xs={12} md={12} lg={4} className="mt-5">
              <div className="search-panel">
                <h5 className="mt-3">
                  Find the best accommodations for your next vacation, holiday,
                  business trip or remort work?
                </h5>
                <form>
                  {/* <label htmlFor="travelPurpose"><input type="checkbox" name="travelPurpose" id="travelPurpose" /> Business {" "}</label> {" "}
                              <label htmlFor="travelPurpose">{" "}<input type="checkbox" name="travelPurpose" id="travelPurpose" /> Leisure</label>
                              <br></br> */}

                  <Row>
                    <Col xs={12} md={12} lg={12}>
                      <label htmlFor="location">Location:</label>
                      <input
                        type="text"
                        placeholder="e.g. Abuja"
                        className="form-control mb-3"
                        onChange={handleCity}
                      />
                    </Col>
                    <Col xs={12} md={12} lg={12}>
                      <label htmlFor="propertyType">Category:</label>

                      <select
                        className="form-select mb-3"
                        id="propertyType"
                        onChange={handleCategory}
                      >
                        <option value={""}>Choose type</option>
                        <option value={"Hotel"}>Hotel</option>
                        <option value={"Villa"}>Villa</option>
                        <option value={"Apartment"}>Apartment</option>
                        <option value={"Guest House"}>Guest House</option>
                        <option value={"Hostel"}>Hostel</option>
                        <option value={"Other"}>Other</option>
                      </select>
                    </Col>
                    <Col xs={12} md={12} lg={12}>
                      <label htmlFor="price">Price:</label>
                      <input
                        type="number"
                        id="price"
                        placeholder="Amount"
                        className="form-control mb-3"
                        onChange={handlePrice}
                      />
                    </Col>
                    {/* <Col xs={12} md={6} lg={3}>
                      <div className="number_ct">
                        <label htmlFor="rooms">Room(s)</label>
                        <div className="number">
                          <span className="minus" onClick={subtractRoom}>
                            -
                          </span>
                          <input
                            type="text"
                            value={countRoom}
                            onChange={handleRoomCount}
                          />
                          <span className="plus" onClick={addRoom}>
                            +
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={6} lg={3}>
                      <div className="number_ct">
                        <label htmlFor="guests">Guest(s)&nbsp;</label>
                        <div className="number">
                          <span className="minus" onClick={subtractAdult}>
                            -
                          </span>
                          <input
                            type="text"
                            value={countAdult}
                            onChange={handleAdultCount}
                          />
                          <span className="plus" onClick={addAdult}>
                            +
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={6} lg={3}>
                      <div className="number_ct">
                        <label htmlFor="children">Children</label>
                        <div className="number">
                          <span className="minus" onClick={subChildren}>
                            -
                          </span>
                          <input
                            type="text"
                            value={countChildren}
                            onChange={handleChildrenCount}
                          />
                          <span className="plus" onClick={addChildren}>
                            +
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={6} lg={3}>
                      <button
                        className="btnMore"
                        style={{ marginTop: "8px" }}
                        type="submit"
                        onClick={handleFilter}
                      >
                        Find Deals <SearchRounded />
                      </button>
                    </Col> */}
                  </Row>
                </form>
              </div>
            </Col>
            <Col xs={12} md={12} lg={8} className="mt-5">
              <Row>
                {filteredData.length > 0 ? (
                  filteredData.map((property, index) => {
                    return (
                      <Col xs={12} md={12} lg={6} key={index}>
                        <Apartments
                          src={
                           
                            property.propertyPic1
                          }
                          alt={property.propertyName}
                          location={
                            property.stateProvince + "/" + property.country
                          }
                          city={property.city}
                          price={(property.price)?.toLocaleString() + "/night"}
                          caption={property.propertyName}
                          link={property.propertyID}
                          hashtag={property.propertyType}
                        />
                      </Col>
                    );
                  })
                ) : (
                  <div className="msgBox">
                    <img src={apImg} alt="no data found" />
                  </div>
                )}
              </Row>
            </Col>
          </Row>
        </Container>

        { loading && <Spinner />}
      </div>
    </>
  );
};

export default Accommodations;
