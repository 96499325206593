import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./TourPreview.css";
import landlordImg from "../../Assets/illustrations/avatar.png";
import {
  BookOnlineOutlined,
  CheckCircle,
  ChildCareTwoTone,
  CancelOutlined,
  PaymentOutlined,
  Person2TwoTone,
  PetsOutlined,
  ShareSharp,
} from "@mui/icons-material";
import {
  FaHeart,
  FaSignInAlt,
  FaSignOutAlt,
  FaSmokingBan,
} from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { BaseURL } from "../../config/variables";

const TourPreview = () => {
  const [data, setData] = useState({
    tourID: "",
    tourName: "",
    tourType: "",
    city: "",
    zipCode: "",
    stateProvince: "",
    country: "",
    streetAddress: "",
    aboutTourCenter: "",
    hostName: "",
    aboutHost: "",
    phoneNumber: "",
    aboutNeighborhood: "",
    price: "",
    discount: "",
    smoking: "",
    pet: "",
    children: "",
    checkInFrom: "",
    checkInUntil: "",
    checkOutFrom: "",
    checkOutUntil: "",
    tourPic1: "",
    tourPic2: "",
    tourPic3: "",
    tourPic4: "",
    tourPic5: "",
  });

  const { id } = useParams();
  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    axios
      .get(`${BaseURL}/tourdetails/` + id)
      .then((res) => {
        setData({
          ...data,
          tourID: res.data.Result[0].tourID,
          tourName: res.data.Result[0].tourName,
          tourType: res.data.Result[0].tourType,
          city: res.data.Result[0].city,
          zipCode: res.data.Result[0].zipCode,
          stateProvince: res.data.Result[0].stateProvince,
          country: res.data.Result[0].country,
          streetAddress: res.data.Result[0].streetAddress,
          aboutTourCenter: res.data.Result[0].aboutTourCenter,
          hostName: res.data.Result[0].hostName,
          aboutHost: res.data.Result[0].aboutHost,
          phoneNumber: res.data.Result[0].phoneNumber,
          aboutNeighborhood: res.data.Result[0].aboutNeighborhood,
          price: res.data.Result[0].price,
          discount: res.data.Result[0].discount,
          smoking: res.data.Result[0].smoking,
          pet: res.data.Result[0].pet,
          children: res.data.Result[0].children,
          checkInFrom: res.data.Result[0].checkInFrom,
          checkInUntil: res.data.Result[0].checkInUntil,
          checkOutFrom: res.data.Result[0].checkOutFrom,
          checkOutUntil: res.data.Result[0].checkOutUntil,
          tourPic1: res.data.Result[0].tourPic1,
          tourPic2: res.data.Result[0].tourPic2,
          tourPic3: res.data.Result[0].tourPic3,
          tourPic4: res.data.Result[0].tourPic4,
          tourPic5: res.data.Result[0].tourPic5,
        });
      })
      .catch((err) => console.log(err));
  }, []);

  function onTimeChange(value) {
    let timeSplit = value.split(":"),
      hours,
      minutes,
      meridian;
    hours = timeSplit[0];
    minutes = timeSplit[1];
    if (hours > 12) {
      meridian = "PM";
      hours -= 12;
      return hours + ":" + minutes + " " + meridian;
    } else if (hours < 12) {
      meridian = "AM";
      if (hours == 0) {
        hours = 12;
      }
      return hours + ":" + minutes + " " + meridian;
    } else {
      meridian = "PM";
      return hours + ":" + minutes + " " + meridian;
    }
  } 

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />

      <div className="TourPreview">
        <Container className="container">
          <Row>
            <Col xs={12} md={8}>
              <h2>{data.tourName}</h2>
              <p>
                {data.stateProvince}, {data.country}
              </p>
            </Col>
            <Col xs={12} md={4}>
              <span>
                <ShareSharp /> Share &nbsp;&nbsp;
              </span>
              <span>
                <FaHeart /> Save
              </span>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6} className="mt-5">
              <Row>
                <Col xs={12} md={12} className="mt-3">
                  <img
                    src={ data.tourPic1}
                    alt={data.tourName}
                    className="main-img img-fluid"
                  />
                </Col>
              </Row>
            </Col>
            {/* More pictures here */}
            <Col xs={12} md={6} className="mt-5 mb-5">
              <Row>
                <Col xs={12} md={6} className="mt-3">
                  <img
                    src={ data.tourPic2}
                    alt={data.tourName}
                    className="img-fluid"
                  />
                </Col>
                <Col xs={12} md={6} className="mt-3">
                  <img
                    src={ data.tourPic3}
                    alt={data.tourName}
                    className="third-img img-fluid"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} className="mt-3">
                  <img
                    src={ data.tourPic4}
                    alt={data.tourName}
                    className="img-fluid"
                  />
                </Col>
                <Col xs={12} md={6} className="mt-3">
                  <div className="featBoxImg">
                    <img
                      src={ data.tourPic5}
                      alt={data.tourName}
                      className="fourth-img img-fluid"
                    />
                    {/* <div className='seeMore'>
                                        See all photos
                                    </div> */}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={12} className="rules mb-5">
              <Row>
                <Col xs={12} md={12} className="mb-5">
                  <Row>
                    <Col xs={8} md={6}>
                      <h3>
                        <span className="rental-property">
                          {data.tourType}{" "}
                        </span>{" "}
                        Tourist Center{" "}
                      </h3>
                    </Col>
                    <Col xs={4} md={6}>
                      <h3>
                        Hosted by{" "}
                        <span className="landlord" style={{ marginRight: "" }}>
                          {data.hostName}{" "}
                        </span>
                        &nbsp;{" "}
                        <img
                          src={landlordImg}
                          className="landlordImg"
                          alt="landlord-img"
                        />
                      </h3>
                      <p> {data.aboutHost}</p>
                    </Col>
                  </Row>
                </Col>

                <Col xs={12} md={12}>
                  <h4>About This Place</h4>
                  <p>
                    Located in {data.stateProvince + "/" + data.country},{" "}
                    {data.aboutTourCenter}, {data.aboutNeighborhood}.
                  </p>
                </Col>

                <Col xs={12} md={12}>
                  <h4>Tour Center Rules</h4>
                </Col>
                <Col xs={12} md={6}>
                  <span>
                    <FaSignInAlt /> Check In
                  </span>
                </Col>
                <Col xs={12} md={6}>
                  <p>
                    From <b>{onTimeChange(data.checkInFrom)}</b> to{" "}
                    <b>{onTimeChange(data.checkInUntil)} </b>
                  </p>
                </Col>
                <Col xs={12} md={6}>
                  <span>
                    <FaSignOutAlt /> Check Out
                  </span>
                </Col>
                <Col xs={12} md={6}>
                  <p>
                    From <b>{onTimeChange(data.checkOutFrom)} </b> to{" "}
                    <b>{onTimeChange(data.checkOutUntil)} </b>
                  </p>
                </Col>
                <Col xs={12} md={6}>
                  <span>
                    <CancelOutlined /> Cancellation/prepayment
                  </span>
                </Col>
                <Col xs={12} md={6}>
                  <p>
                    Cancellation and prepayment policies vary according to
                    accommodations type. Check what conditions might apply to
                    each option when making your selection.
                  </p>
                </Col>
                <Col xs={12} md={6}>
                  <span>
                    <Person2TwoTone /> No age restriction
                  </span>
                </Col>
                <Col xs={12} md={6}>
                  <p>There's no age requirement for check-in</p>
                </Col>
                <Col xs={12} md={6}>
                  <span>
                    <ChildCareTwoTone /> Children
                  </span>
                </Col>
                <Col xs={12} md={6}>
                  {data.children === "Yes" ? (
                    <p>
                      <b>Child policies -</b> Children of all ages are welcome.
                    </p>
                  ) : (
                    <b>Children are not allowed in this place.</b>
                  )}
                </Col>
                <Col xs={12} md={6}>
                  <span>
                    <PetsOutlined /> Pets
                  </span>
                </Col>
                <Col xs={12} md={6}>
                  <p>
                    Pets are {data.pet === "Yes" ? "allowed" : "not allowed"}.
                  </p>
                </Col>
                <Col xs={12} md={6}>
                  <span>
                    <FaSmokingBan /> Smoking
                  </span>
                </Col>
                <Col xs={12} md={6}>
                  <p>
                    Smoking{" "}
                    {data.smoking === "Yes" ? " is allowed" : " is not allowed"}
                    .
                  </p>
                </Col>
                <Col xs={12} md={6}>
                  <span>
                    <PaymentOutlined /> Accepted payment methods
                  </span>
                </Col>
                <Col xs={12} md={6}>
                  <p>
                    Cash is not accepted,{" "}
                    <b>Pay securely through Paystack or your e-wallet.</b>
                  </p>
                </Col>

                <Col xs={12} md={12}>
                  <div className="reserve-pay">
                    <Row>
                      <Col xs={12} md={4}>
                        <p className="text-success text-center mt-5">
                          <CheckCircle /> <br />
                          <b>
                            Property Owner reserves right to cancel <br />
                            if you're not eligible
                          </b>
                        </p>
                      </Col>
                      <Col xs={12} md={4}>
                        <h4 className="text-center">Repayment Guarantee</h4>
                        <p className="text-center">
                          You can request refund if you're not satisfied with
                          this property listing
                        </p>
                      </Col>
                      <Col xs={12} md={4}>
                        <Link to={`/tour-booking/${id}`}>
                          <div className="btnMore mt-5">
                            Book Now <BookOnlineOutlined />
                          </div>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TourPreview;
