import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './UserDashboard.css'; 
import { PaymentOutlined, Payments } from '@mui/icons-material'; 
import NavbarMenu from '../Layouts/Navbar'; 
import UserNavbar from './UserNavbar'; 
// import { useCookies } from "react-cookie";  
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom'; 
import Spinner from '../Alert/Spinner/Spinner';
import { fundUserWallet, getUserWallet, paystackPayment, verifypaystackPayment } from '../../utils/userActions/paymentActions';
import { clientURL } from '../../config/variables';
import { useGetUserID, useGetUserSession } from '../../Hooks/useGetUserID';
// import { BaseURL } from '../../config/variables';
// import { useGetUserID } from '../../Hooks/useGetUserID';
// import { useGetHostID } from '../../Hooks/useGetHostID';
// import { useGetOwnerID } from '../../Hooks/useGetOwnerID';

const UserDashboard = () => {
  const navigate = useNavigate();
//   const [cookies, removeCookie] = useCookies([]);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false)
  const [ amount, setAmount] = useState("")
  const [ balance, setBalance] = useState(0)


  const session = useGetUserSession()
  const userId = useGetUserID()

  

  const handleError = (err) =>{
    toast.error(err, {
      position: "bottom-left",
    });
    setLoading(false)
  }
  const handleSuccess = (msg) =>{
    toast.success(msg, {
      position: "bottom-right",
    });
    setLoading(false)
  }



  const verifyLoggedInUser = async () => {

    try {

        if(session){
            const user = localStorage.getItem("user")
            setUsername(user); 
            setLoading(false)

            return toast(`Hello ${user.toUpperCase()}`, {
                position: "top-right",
            })

        } else {
            handleError("Please login!") 
            localStorage.clear();
            await navigate("/login");
        } 
        
        
    } catch (error) {
        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
    }}
    


  useEffect(() => {
    
    // scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    
    
    verifyLoggedInUser();
    handlepaystackPayment()
  }, []);

  const getWallet = async()=>{

      const response = await getUserWallet(userId)
      setBalance(response?.wallet?.balance)
  }

  useEffect(() => {
    getWallet()

  }, [session]);


  const makePayment = async()=>{
    const emailAddress = localStorage.getItem("emailAddress")
    setLoading(true)

    const payload = {
      amount,
      emailAddress: `${emailAddress}`,
      callbackUrl: `${clientURL}/user-dashboard`

    }

    const response = await paystackPayment( payload );

    if (response?.status) {
      
      
      if(response?.data?.authorization_url){
        setAmount("")
        localStorage.setItem('transId', response?.data?.reference);
        setLoading(false);
        window.location.href = response?.data?.authorization_url

      }
    } else {

      handleError("Could not call up Paystack");
      setLoading(false);

    }
  }

  const verifyPayment = async(transId)=>{
    setLoading(true)

    const response = await verifypaystackPayment( transId );

    if (response?.status) {

      if(response?.data?.status === "success"){

        handleSuccess("payment successful")
        localStorage.removeItem("transId")
        await fundWallet()
        
        // await handleSubmit()

      } else {
        
        await localStorage.removeItem("transId")
        handleError(response?.data?.gateway_response);
        await navigate("/")
      }
    } else {
      await localStorage.removeItem("transId")
      handleError("Could not verify payment");
      setLoading(false);
      await navigate("/")
    }
  }

  const fundWallet = async()=>{
    const amount = localStorage.getItem("amount")
    const response = await fundUserWallet(userId, amount)

    if (response?.status) {
      
          setLoading(false);
          getWallet()
          localStorage.removeItem("amount")
        
      } else {
        handleError("Could not fund wallet");
        setLoading(false);
      }
  }




  const handlepaystackPayment = async()=>{
    const transId = localStorage.getItem("transId") === null ? "" : localStorage.getItem("transId");

    if(!transId){
        if(!amount) return
        localStorage.setItem("amount", amount);
        makePayment()

    } else {
      verifyPayment(transId)
    }



  }



   
  return (
    <>
        <NavbarMenu  style={{backgroundColor: '#003B95' }} />


{/* Fund Wallet Modal */}
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">How much do you want to fund?</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
            <div className="input-group mb-3">
                <span className="input-group-text">₦</span>
                <input type="text" className="form-control" aria-label="Amount (to the nearest naira)" value={amount} onChange={(e)=> setAmount(e.target.value)} required />
                <span className="input-group-text">.00</span>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handlepaystackPayment}>Proceed</button>
            </div>
            </div>
        </div>
        </div>


        <div className='UserDashboard'>
            {/* Do a mini navbar just below the main navbar */}
            <Container className='container'>
                <Row>
                    <Col xs={12} md={3} lg={3}>
                        <UserNavbar />
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                        <Row>
                            <Col xs={12} md={12} className='mt-5 mb-5'>
                                <div className="welcome-panel">
                                    <h3 className='mb-3'>
                                        {/* Hi { username.charAt(0).toUpperCase() + username.slice(1) }, */}
                                    </h3>
                                    <p>
                                        Welcome to Maczuby, We’re Africa’s leading hospitality and rental services company, 
                                        providing secured transaction between service providers and their customers.
                                    </p>
                                    <p>
                                        Save money on your next booking or trip by paying through your wallet, pay securely.
                                    </p>

                                </div>
                            </Col>
                            <Col xs={12} md={6} className='mb-5'>
                                <div className="ewallet-panel">
                                    <p>Wallet Balance:</p>
                                    <h3 className='mt-3'>₦{balance > 100 ?balance.toLocaleString() : balance }.00</h3> 
                                    <div className="btn btn-warning" type="button"  data-bs-toggle="modal" data-bs-target="#exampleModal"> <Payments /> Add Funds</div>
                                </div> 
                            </Col>
                            <Col xs={12} md={6} className='mb-5'>
                                <div className="ewallet-panel">
                                    <h4>All your bookings:</h4>
                                    <p className='mt-3'>Find all your booking history here. </p>
                                    <div className="btn btn-primary" onClick={()=> navigate("/my-bookings")}><PaymentOutlined /> View History</div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />


            { loading && <Spinner />}
        </div>
    </>
  )
}

export default UserDashboard