import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HostNavbar from "./HostNavbar";
import {
  FaArrowRight,
  FaCheck,
  FaCheckDouble,
  FaDollarSign,
  FaEye,
  FaFolderPlus,
  FaInfoCircle,
  FaPenAlt,
  FaUpload,
  FaTrash,

} from "react-icons/fa";
import "./ListAccommodation.css";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BaseURL } from "../../config/variables";
import { limit, totalPages } from "../../utils/ownerActions/ownerRequests";
import { PaymentOutlined, RemoveCircleOutline } from "@mui/icons-material";
import { Badge, Table } from "react-bootstrap";
import { addPropertyRoom, deletePropertyRoom, getAvailableRooms, getBookedRooms, tooglePropertyRoomAvailable } from "../../utils/hostActions/hostRequests";
import Spinner from "../Alert/Spinner/Spinner";
import { validateCreateRooms } from "../../utils/hostActions/hostValiadtions";
import Toast from "../Alert/Toast";

const ListAccommodation03 = () => {
  const {id} = useParams();
  const navigate = useNavigate();  
  const currentHostID = window.localStorage.getItem("hostID");

  const [data, setData] = useState({
    hostID: Number(currentHostID),
  });
  const [roomsData, setRoomsData] = useState([])
  const [bookedRooms, setBookedRooms] = useState([])
  const [availableRooms, setAvailableRooms] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [currentAPage, setCurrentAPage] = useState(1)
  const [startAIndex, setstartAIndex] = useState(0)
  const [startIndex, setstartIndex] = useState(0)
  const [loading, setLoading] = useState(false)
  const [roomID, setRoomID] = useState("")
  const [hostID, sethostID] = useState("")




  const handlePrevious = (text) =>{
    if(text === "a"){
      currentAPage > 1 ? setCurrentAPage(currentAPage - 1) : setCurrentAPage(currentAPage);
    } else {

      currentPage > 1 ? setCurrentPage(currentPage - 1) : setCurrentPage(currentPage);
    }
    
}

const handleNextPage = (text) =>{
  if(text === "a"){
    currentAPage < totalPages ? setCurrentAPage(currentAPage + 1) : setCurrentAPage(currentAPage);
    
} else {

  currentPage < totalPages ? setCurrentPage(currentPage + 1) : setCurrentPage(currentPage);
}
    
}

const goToPage = (number, text) =>{
  if(text === "a"){
    setCurrentAPage(number) 
    
} else {
  setCurrentPage(number) 

}
    
}

const fetchAllRooms = async()=>{
  setLoading(true)
  setstartIndex((currentPage - 1) * limit)
  setstartAIndex((currentAPage - 1) * limit)
  const available = await getAvailableRooms(id, currentAPage, limit)
  const booked = await getBookedRooms(id, currentPage, limit)

  if (available?.status) {
      setAvailableRooms(available.rooms)
      if (booked?.status) {
        // console.log({booked})
        setBookedRooms(booked.rooms)
      }
      setLoading(false)
  } else {
    handleError("Could not get available rooms");
    setLoading(false);
  }

}

  useEffect(() => {
    // scroll to top on page load

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    // axios
    //   .get(`${BaseURL}/fetchpropertyroom/${id}`)
    //   .then((res) => {
    //     if (res.data.status === "Success") {
    //       setData({
    //         ...data,
    //         roomNo: res.data.Result[0].roomNo,
    //         guestNo: res.data.Result[0].guestNo,
    //         bathroomNo: res.data.Result[0].bathroomNo,
    //         roomBedType: res.data.Result[0].roomBedType,
    //         price: res.data.Result[0].price,
    //         discount: res.data.Result[0].discount,
    //         agreeCancel: res.data.Result[0].agreeCancel,             
    //       });
    //       console.log(res.data.Result[0]);
    //     } else {
    //       console.log("No properties found");
    //     }
    //   }) 
    //   .catch((err) => console.log(err));
    fetchAllRooms()
  }, [currentPage, currentAPage]);
 

    const handleError = (err) =>
        toast.error(err, {
      position: "bottom-left",
    });
    const handleSuccess = (msg) =>
        toast.success(msg, {
      position: "bottom-right",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const payload = {
          propertyID: id,
          available: 1,
          ...data
        }

        // console.log({ payload })

        const result = validateCreateRooms(payload);

        if (result.errLength) {
          setLoading(false);

          return toast.error(() => <Toast title="Error" body={result.errMsg} />);
        }

        const response = await addPropertyRoom(payload)
        if (response?.status) {
          const { message } = response;
            handleSuccess(message)
            setLoading(false);
            setTimeout(() => {
                navigate("/list-accommodation");
            }, 1000);

        } else {
          handleError("Could not add room");
          setLoading(false);
        }

    }


    const setDeleteDetails = async(id, idd)=>{
      setRoomID(id)
      sethostID(idd)
      
    }

    const handleDeleteRoom = async()=>{
      setLoading(true);

      const result = await deletePropertyRoom(roomID, hostID)
      if (result?.status) {
        const { message } = result;
          handleSuccess(message)
          setLoading(false);
          setTimeout(() => {
            navigate("/list-accommodation");
        }, 1000);

      } else {
        handleError("Could not delete room");
        setLoading(false);
      }
    }


    const makeRoomAvailable = async()=>{
      setLoading(true);

      const result = await tooglePropertyRoomAvailable(roomID, hostID)
      if (result?.status) {
        const { message } = result;
          handleSuccess(message)
          setLoading(false);
          setTimeout(() => {
            navigate("/list-accommodation");
        }, 1000);

      } else {
        handleError("Could not make room available for booking");
        setLoading(false);
      }
    }

    // console.log({ roomID, hostID })
// console.log({ availableRooms, bookedRooms })
  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <ToastContainer />


{/* Modal */}

<div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel" text-centered>Do you want to delete this room?</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            
            <div className="modal-footer">
                <button type="button" className= "btn btn-secondary"  data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={handleDeleteRoom}>Proceed</button>
            </div>
            </div>
        </div>
        </div>

{/* Modal */}

<div className="modal fade" id="exampleModall" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel" text-centered>Make this room available for booking?</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            
            <div className="modal-footer">
                <button type="button" className= "btn btn-secondary"  data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="btn btn-success" data-bs-dismiss="modal" onClick={makeRoomAvailable}>Proceed</button>
            </div>
            </div>
        </div>
        </div>



      <div className="ListAccommodation">
        <Container>
          <Row>
            <Col xs={12} md={3} lg={3}>
              <HostNavbar />
            </Col>

            <Col xs={12} md={9} lg={9}>

{/* Booked Rooms Table */}                               
                      <div className="table-panel mb-5">
                        <h2>Booked Rooms </h2>
                        <p>
                          All rooms that are currently booked
                        </p>
                         
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th> S/N </th>
                                    <th> Room Type </th>
                                    <th> Room Bed Type </th> 
                                    <th> Guest No. </th>
                                    <th> Bathroom No. </th> 
                                    <th> Room No. </th>
                                    <th> Price </th>
                                    <th> Discount </th>
                                    <th> Can Cancel </th>
                                </tr>
                            </thead>
                            <tbody>
                            {bookedRooms && bookedRooms.map((each, index) => {
                                  return <tr key={index}>
                                      <th>{index + 1 + startIndex}.</th>
                                      <td>{each.roomType}</td>
                                      <td>{each.roomBedType}</td>
                                      <td>
                                        {each.guestNo }                                 
                                      </td> 
                                      <td>
                                          {each.bathroomNo}
                                      </td>
                                      <td>
                                          {each.roomNo}
                                      </td>
                                      <td>₦{Number(each.price) > 100 ? Number(each.price).toLocaleString() : each.price}</td>
                                      <td>{each.discount}</td>
                                      <td>{each.agreeCancel}</td>
                                      <td 
                                      style={{display: "flex", flexDirection: "column", alignItems: "center"}}
                                      type="button"  data-bs-toggle="modal" data-bs-target="#exampleModall"
                                        onClick={()=> setDeleteDetails(each.roomID, each.hostID)}
                                      >
                                        unbook
                                        <FaCheck color="green"  />
                                      </td>
                                  </tr>
                              })}
                               
                                 
                            </tbody>
                        </Table>

                        <ul className="pagination "
                          style={{ alignItems: "right", justifyContent: "right"}}
                          >
                              <li className="page-item" onClick={handlePrevious}>
                              <Link className="page-link" to="" aria-label="Previous">
                                  <span aria-hidden="true">&laquo;</span>
                              </Link>
                              </li>
                              <li className="page-item" onClick={()=> goToPage(1)}><Link className="page-link" to="">1</Link></li>
                              <li className="page-item" onClick={()=> goToPage(2)}><Link className="page-link" to="">2</Link></li>
                              <li className="page-item" onClick={()=> goToPage(3)}><Link className="page-link" to="">3</Link></li>

                              <li className="page-item" onClick={handleNextPage}>
                              <Link className="page-link" to="" aria-label="Next">
                                  <span aria-hidden="true">&raquo;</span>
                              </Link>
                              </li>
                          </ul>
                         
                        {/* {msg && msg} */}
                      </div>


                      {/* All available rooms */}
                      <div className="table-panel mb-5">
                        <h2>Available Rooms </h2>
                        <p>
                          Rooms that are currently available
                        </p>
                         
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th> S/N </th>
                                    <th> Room Type </th>
                                    <th> Room Bed Type </th> 
                                    <th> Guest No. </th>
                                    <th> Bathroom No. </th> 
                                    <th> Room No. </th>
                                    <th> Price </th>
                                    <th> Discount </th>
                                    <th> Can Cancel </th>
                                </tr>
                            </thead>
                            <tbody>
                              {availableRooms && availableRooms.map((each, index) => {
                                  return <tr key={index}>
                                      <th>{index + 1 + startAIndex}.</th>
                                      <td>{each.roomType}</td>
                                      <td>{each.roomBedType}</td>
                                      <td>
                                        {each.guestNo }                                 
                                      </td> 
                                      <td>
                                          {each.bathroomNo}
                                      </td>
                                      <td>
                                          {each.roomNo}
                                      </td>
                                      <td>₦{Number(each.price) > 100 ? Number(each.price).toLocaleString() : each.price}</td>
                                      <td>{each.discount}</td>
                                      <td>{each.agreeCancel}</td>
                                      <td 
                                      type="button"  data-bs-toggle="modal" data-bs-target="#exampleModal"
                                        onClick={()=> setDeleteDetails(each.roomID, each.hostID)}
                                      >
                                        <FaTrash color="red"  />
                                      </td>
                                  </tr>
                              })}
                               
                                 
                            </tbody>
                        </Table>

                        <ul className="pagination "
                    style={{ alignItems: "right", justifyContent: "right"}}
                    >
                        <li className="page-item" onClick={()=> handlePrevious("a")}>
                        <Link className="page-link" to="" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                        </Link>
                        </li>
                        <li className="page-item" onClick={()=> goToPage(1, "a")}><Link className="page-link" to="">1</Link></li>
                        <li className="page-item" onClick={()=> goToPage(2, "a")}><Link className="page-link" to="">2</Link></li>
                        <li className="page-item" onClick={()=> goToPage(3, "a")}><Link className="page-link" to="">3</Link></li>

                        <li className="page-item" onClick={()=>  handleNextPage("a")}>
                        <Link className="page-link" to="" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                        </Link>
                        </li>
                    </ul>
                         
                        {/* {msg && msg} */}
                      </div>
                             
                


{/* Add Room form */}
            
              <form className="form-panel" onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <h2>Add Room To Property:</h2>
                    <p>You can add more rooms later to this property later</p>
                  </Col>
                  <Col xs={12} md={12} lg={12} className="addRoomInput">
                    <Row>
                      <Col xs={12} md={12} lg={3}>
                        <label htmlFor="roomNo">
                          Room No:{" "}
                          <small>
                            <b>e.g. 105</b>
                          </small>
                        </label>
                        <input
                          type="number"
                          name="roomNo"
                          id="roomNo"
                          className="form-control"
                          onChange={(e) =>
                            setData({ ...data, roomNo: e.target.value })
                          }
                          value={data.roomNo || ''}
                        />
                      </Col>
                      <Col xs={12} md={12} lg={3}>
                        <label htmlFor="GuestNo">Guests (Adults)</label>
                        <input
                          type="number"
                          name="guestNo"
                          id="guestNo"
                          min={1}
                          className="form-control"
                          onChange={(e) =>
                            setData({ ...data, guestNo: e.target.value })
                          }
                          value={data.guestNo || ''}
                        />
                      </Col>
                      <Col xs={12} md={12} lg={3}>
                        <label htmlFor="bathroomNo">Bathrooms?</label>
                        <input
                          type="number"
                          name="bathroomNo"
                          id="bathroomNo"
                          min={1}
                          className="form-control"
                          onChange={(e) =>
                            setData({ ...data, bathroomNo: e.target.value })
                          }
                          value={data.bathroomNo || ''}
                        />
                      </Col>
                      <Col xs={12} md={12} lg={3}>
                        <label htmlFor="roomType">Room Type: </label>
                        <select
                          name="roomType"
                          id="roomType"
                          className="form-select selectt"
                          onChange={(e) =>
                            setData({ ...data, roomType: e.target.value })
                          }
                          value={data.roomType || ''}
                        >
                          <option value={""}>Choose type</option>
                          <option value={"Standard Room"}>Standard Room</option>
                          <option value={"Deluxe Room"}>Deluxe Room</option>
                          <option value={"Standard Suite"}>Standard Suite</option>
                          <option value={"Family Suite"}>Family Suite</option>
                        </select>
                      </Col>
                      <Col xs={12} md={12} lg={3}>
                        <label htmlFor="roomBedType">Room Bed Type: </label>
                        <select
                          name="roomBedType"
                          id="roomBedType"
                          className="form-select selectt"
                          onChange={(e) =>
                            setData({ ...data, roomBedType: e.target.value })
                          }
                          value={data.roomBedType || ''}
                        >
                          <option value={""}>Choose type</option>
                          <option value={"Twin Bed"}>Twin Bed(s)</option>
                          <option value={"Full Bed"}>Full bed(s)</option>
                          <option value={"Queen bed"}>Queen bed(s)</option>
                          <option value={"King bed"}>King bed(s)</option>
                        </select>
                      </Col>
                      
                    </Row>

                    
                  </Col>

                  <Col xs={12} md={12} lg={6} className="mt-5">
                    <h4>How much do you want to charge per night?</h4>

                    <label htmlFor="price">
                      Price guests pay ₦
                    
                      {/* <small>
                        Amount in dollars will be paid in your exchange rate
                      </small>{" "} */}
                    </label>
                    <input
                      type="number"
                      name="price"
                      id="price"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, price: e.target.value })
                      }
                      value={data.price || ''}
                    />
                    <small>Including taxes, commission, and fees</small>

                    <h4 className="mt-5">
                      {" "}
                      Get guest’s attention with a 20% discount:{" "}
                    </h4>
                    <select
                      name="discount"
                      id="discount"
                      className="form-select selectt"
                      onChange={(e) =>
                        setData({ ...data, discount: e.target.value })
                      }
                      value={data.discount || ''}
                    >
                      <option value={""}>Choose Y/N?</option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </select>
                    <p>
                      Give 20% off your first 3 bookings for 90 days, whichever
                      comes first.
                    </p>
                  </Col>

                  <Col xs={12} md={12} lg={6} className="mt-5">
                    <h4>Cancellation policy</h4>
                    <p>
                      This policy is set at the property level - any changes
                      made will apply to all units.{" "}
                    </p>
                    <p>
                      <FaCheck className="icon" /> Guests can cancel their
                      bookings for free up to 1 day before their arrival
                    </p>
                    <p>
                      <FaCheck className="icon" /> Guests who cancel can within
                      24 hours will have their cancellation fee waived{" "}
                    </p>

                    <h4>
                      Price & cancellation policy{" "}
                      <FaInfoCircle className="icon" />
                    </h4>
                    <p>
                      <FaCheck className="icon" /> Guests will pay 10% less than
                      the standard rate for a non-refundable{" "}
                    </p>
                    <p>
                      <FaCheck className="icon" /> Guests can’t cancel their
                      bookings for free anytime{" "}
                    </p>
                    <h4>Agree to cancellation policy</h4>
                    <select
                      name="agreeCancel"
                      id="agreeCancel"
                      className="form-select selectt"
                      onChange={(e) =>
                        setData({ ...data, agreeCancel: e.target.value })
                      }
                      value={data.agreeCancel || ''}
                    >
                      <option value={""}>Choose Y/N?</option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </select>
                  </Col>

                  <Col xs={12} md={12} lg={4} className="mt-5">
                    <button type="submit" className="btnOutline mt-5">
                      Add Room <FaPenAlt />
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
        { loading && <Spinner />}
      </div>
    </>
  );
};

export default ListAccommodation03;
