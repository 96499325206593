import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import OwnerNavbar from './OwnerNavbar';
// import './ManageHosts.css';
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { Table, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BaseURL } from "../../config/variables";
import {
  getAllHostReports,
  getHostAccountHistory,
  getUserReports,
  limit,
  totalPages,
} from "../../utils/ownerActions/ownerRequests";
import Spinner from "../Alert/Spinner/Spinner";
import { handleError, handleSuccess } from "../../utils/notifications/toasts";
import HostNavbar from "./HostNavbar";
import { useGetHostID } from "../../Hooks/useGetHostID";

const HostAccountHistoryPage = () => {
  // show all registered hosts
  // const hostID = window.localStorage.getItem("hostID");

  const hostID = useGetHostID();

  const [data, setData] = useState([]);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [complaints, setComplaints] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startIndex, setstartIndex] = useState(0);
  const [accountHistory, setAccountHistory] = useState(null);

  const [totalAccommodations, setTotalAccommodations] = useState(0);
  const [totalServices, setTotalServices] = useState(0);
  const [totalVehicles, setTotalVehicles] = useState(0);
  const [totalFlights, setTotalFlights] = useState(0);
  const [totalTours, setTotalTours] = useState(0);

  const [amountDueA, setAmountDueA] = useState(0);
  const [amountDueV, setAmountDueV] = useState(0);
  const [amountDueF, setAmountDueF] = useState(0);
  const [amountDueT, setAmountDueT] = useState(0);
  const [amountDueS, setAmountDueS] = useState(0);

  const [amountPaidA, setAmountPaidA] = useState(0);
  const [amountPaidV, setAmountPaidV] = useState(0);
  const [amountPaidF, setAmountPaidF] = useState(0);
  const [amountPaidT, setAmountPaidT] = useState(0);
  const [amountPaidS, setAmountPaidS] = useState(0);

  const fetchHostAccountHistory = async () => {
    setLoading(true);

    const history = await getHostAccountHistory(hostID);
    if (history?.status) {
      setAccountHistory(history);
      // console.log({ history })
      setLoading(false);
    } else {
      handleError("Could not get account histroy.");
      setLoading(false);
    }
  };

  const handlePrevious = () => {
    currentPage > 1
      ? setCurrentPage(currentPage - 1)
      : setCurrentPage(currentPage);
  };

  const handleNextPage = () => {
    currentPage < totalPages
      ? setCurrentPage(currentPage + 1)
      : setCurrentPage(currentPage);
  };

  const goToPage = (number) => {
    setCurrentPage(number);
  };

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    fetchHostAccountHistory();
  }, []);

  useEffect(() => {
    // Total Amounts Made
    setTotalAccommodations(
      accountHistory?.totalAmounts?.accommodationsTotalAmount &&
        accountHistory?.totalAmounts?.accommodationsTotalAmount > 100
        ? accountHistory?.totalAmounts?.accommodationsTotalAmount.toLocaleString()
        : accountHistory?.totalAmounts?.accommodationsTotalAmount
    );

    setTotalServices(
      accountHistory?.totalAmounts?.servicesTotalAmount &&
        accountHistory?.totalAmounts?.servicesTotalAmount > 100
        ? accountHistory?.totalAmounts?.servicesTotalAmount.toLocaleString()
        : accountHistory?.totalAmounts?.servicesTotalAmount
    );

    setTotalVehicles(
      accountHistory?.totalAmounts?.vehiclesTotalAmount &&
        accountHistory?.totalAmounts?.vehiclesTotalAmount > 100
        ? accountHistory?.totalAmounts?.vehiclesTotalAmount.toLocaleString()
        : accountHistory?.totalAmounts?.vehiclesTotalAmount
    );

    setTotalFlights(
      accountHistory?.totalAmounts?.flightsTotalAmount &&
        accountHistory?.totalAmounts?.flightsTotalAmount > 100
        ? accountHistory?.totalAmounts?.flightsTotalAmount.toLocaleString()
        : accountHistory?.totalAmounts?.flightsTotalAmount
    );

    setTotalTours(
      accountHistory?.totalAmounts?.toursTotalAmount &&
        accountHistory?.totalAmounts?.toursTotalAmount > 100
        ? accountHistory?.totalAmounts?.toursTotalAmount.toLocaleString()
        : accountHistory?.totalAmounts?.toursTotalAmount
    );

    //   Amounts Due

    setAmountDueA(
      accountHistory?.hostPayoutsDue?.TotalAccommodationsDue &&
        accountHistory?.hostPayoutsDue?.TotalAccommodationsDue > 100
        ? accountHistory?.hostPayoutsDue?.TotalAccommodationsDue.toLocaleString()
        : accountHistory?.hostPayoutsDue?.TotalAccommodationsDue
    );

    setAmountDueV(
      accountHistory?.hostPayoutsDue?.TotalVehiclesDue &&
        accountHistory?.hostPayoutsDue?.TotalVehiclesDue > 100
        ? accountHistory?.hostPayoutsDue?.TotalVehiclesDue.toLocaleString()
        : accountHistory?.hostPayoutsDue?.TotalVehiclesDue
    );

    setAmountDueF(
      accountHistory?.hostPayoutsDue?.TotalFlightsDue &&
        accountHistory?.hostPayoutsDue?.TotalFlightsDue > 100
        ? accountHistory?.hostPayoutsDue?.TotalFlightsDue.toLocaleString()
        : accountHistory?.hostPayoutsDue?.TotalFlightsDue
    );

    setAmountDueT(
      accountHistory?.hostPayoutsDue?.TotalToursDue &&
        accountHistory?.hostPayoutsDue?.TotalToursDue > 100
        ? accountHistory?.hostPayoutsDue?.TotalToursDue.toLocaleString()
        : accountHistory?.hostPayoutsDue?.TotalToursDue
    );

    setAmountDueS(
      accountHistory?.hostPayoutsDue?.TotalServicesDue &&
        accountHistory?.hostPayoutsDue?.TotalServicesDue > 100
        ? accountHistory?.hostPayoutsDue?.TotalServicesDue.toLocaleString()
        : accountHistory?.hostPayoutsDue?.TotalServicesDue
    );

    // Amounts Paid

    setAmountPaidA(
      accountHistory?.hostPayouts?.totalAccommodationsPayout &&
        accountHistory?.hostPayouts?.totalAccommodationsPayout > 100
        ? accountHistory?.hostPayouts?.totalAccommodationsPayout.toLocaleString()
        : accountHistory?.hostPayouts?.totalAccommodationsPayout
    );

    setAmountPaidV(
      accountHistory?.hostPayouts?.totalVehiclesPayout &&
        accountHistory?.hostPayouts?.totalVehiclesPayout > 100
        ? accountHistory?.hostPayouts?.totalVehiclesPayout.toLocaleString()
        : accountHistory?.hostPayouts?.totalVehiclesPayout
    );

    setAmountPaidF(
      accountHistory?.hostPayouts?.totalFlightsPayout &&
        accountHistory?.hostPayouts?.totalFlightsPayout > 100
        ? accountHistory?.hostPayouts?.totalFlightsPayout.toLocaleString()
        : accountHistory?.hostPayouts?.totalFlightsPayout
    );

    setAmountPaidT(
      accountHistory?.hostPayouts?.totalToursPayout &&
        accountHistory?.hostPayouts?.totalToursPayout > 100
        ? accountHistory?.hostPayouts?.totalToursPayout.toLocaleString()
        : accountHistory?.hostPayouts?.totalToursPayout
    );

    setAmountPaidS(
      accountHistory?.hostPayouts?.totalServicesPayout &&
        accountHistory?.hostPayouts?.totalServicesPayout > 100
        ? accountHistory?.hostPayouts?.totalServicesPayout.toLocaleString()
        : accountHistory?.hostPayouts?.totalServicesPayout
    );
  }, [accountHistory]);

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />

      <div className="ListAccommodation">
        <Container>
          <Row>
            <Col xs={12} md={3} lg={3}>
              <HostNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <div className="table-panel">
                <h2>Account History </h2>
                <p>A break down of your financial history on Maczuby</p>

                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">S/N</th>
                      <th scope="col">Listing</th>
                      <th scope="col">Amount Made</th>
                      <th scope="col">Amount Due</th>
                      <th scope="col">Amount Paid</th>
                      {/* <th scope="col">Action</th> */}
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <th scope="row">1.</th>
                      <th>Accommodations:</th>

                      <td> ₦ {totalAccommodations}</td>

                      <td> ₦ {amountDueA}</td>

                      <td> ₦ {amountPaidA}</td>
                      {/* <td>Here</td> */}
                    </tr>

                    <tr>
                      <th scope="row">2.</th>
                      <th> Vehicles:</th>

                      <td> ₦ {totalVehicles}</td>

                      <td> ₦ {amountDueV}</td>

                      <td> ₦ {amountPaidV}</td>

                      {/* <td>Here</td> */}
                    </tr>

                    <tr>
                      <th scope="row">3.</th>
                      <th>Flights:</th>

                      <td> ₦ {totalFlights}</td>

                      <td> ₦ {amountDueF} </td>

                      <td> ₦ {amountPaidF}</td>

                      {/* <td>Here</td> */}
                    </tr>

                    <tr>
                      <th scope="row">4.</th>
                      <th>Tours:</th>

                      <td> ₦ {totalTours}</td>

                      <td> ₦ {amountDueT}</td>

                      <td> ₦ {amountPaidT}</td>

                      {/* <td>Here</td> */}
                    </tr>

                    <tr>
                      <th scope="row">5.</th>
                      <th>Services:</th>

                      <td> ₦ {totalServices}</td>

                      <td> ₦ {amountDueS}</td>

                      <td> ₦ {amountPaidS}</td>

                      {/* <td>Here</td> */}
                    </tr>

                    <tr>
                      <th scope="row">6.</th>
                      <th>Total:</th>

                      <th>
                        {" "}
                        ₦
                        {(
                          parseFloat(
                            (totalAccommodations || "")
                              .toString()
                              .replace(/,/g, "")
                          ) ||
                          0 +
                            parseFloat(
                              (totalFlights || "").toString().replace(/,/g, "")
                            ) ||
                          0 +
                            parseFloat(
                              (totalTours || "").toString().replace(/,/g, "")
                            ) ||
                          0 +
                            parseFloat(
                              (totalVehicles || "").toString().replace(/,/g, "")
                            ) ||
                          0 +
                            parseFloat(
                              (totalServices || "").toString().replace(/,/g, "")
                            ) ||
                          0
                        ).toLocaleString()}
                      </th>

                      <th>
                        {" "}
                        ₦
                        {(
                          parseFloat(
                            (amountDueA || "").toString().replace(/,/g, "")
                          ) ||
                          0 +
                            parseFloat(
                              (amountDueV || "").toString().replace(/,/g, "")
                            ) ||
                          0 +
                            parseFloat(
                              (amountDueF || "").toString().replace(/,/g, "")
                            ) ||
                          0 +
                            parseFloat(
                              (amountDueT || "").toString().replace(/,/g, "")
                            ) ||
                          0 +
                            parseFloat(
                              (amountDueS || "").toString().replace(/,/g, "")
                            ) ||
                          0
                        ).toLocaleString()}
                      </th>

                      <th>
                        {" "}
                        ₦
                        {(
                          parseFloat(
                            (amountPaidA || "").toString().replace(/,/g, "")
                          ) ||
                          0 +
                            parseFloat(
                              (amountPaidV || "").toString().replace(/,/g, "")
                            ) ||
                          0 +
                            parseFloat(
                              (amountPaidF || "").toString().replace(/,/g, "")
                            ) ||
                          0 +
                            parseFloat(
                              (amountPaidT || "").toString().replace(/,/g, "")
                            ) ||
                          0 +
                            parseFloat(
                              (amountPaidS || "").toString().replace(/,/g, "")
                            ) ||
                          0
                        ).toLocaleString()}
                      </th>

                      {/* <th>Here</th> */}
                    </tr>
                  </tbody>
                </table>

                {/* <nav aria-label="Page navigation example"> */}
                {/* Pagination */}
                {/* <ul
                  className="pagination "
                  style={{ alignItems: "right", justifyContent: "right" }}
                >
                  <li className="page-item" onClick={handlePrevious}>
                    <Link className="page-link" to="" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </Link>
                  </li>
                  <li className="page-item" onClick={() => goToPage(1)}>
                    <Link className="page-link" to="">
                      1
                    </Link>
                  </li>
                  <li className="page-item" onClick={() => goToPage(2)}>
                    <Link className="page-link" to="">
                      2
                    </Link>
                  </li>
                  <li className="page-item" onClick={() => goToPage(3)}>
                    <Link className="page-link" to="">
                      3
                    </Link>
                  </li>

                  <li className="page-item" onClick={handleNextPage}>
                    <Link className="page-link" to="" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </Link>
                  </li>
                </ul> */}
                {/* </nav> */}

                {msg && msg}
              </div>
            </Col>
          </Row>
        </Container>

        {loading && <Spinner />}
      </div>
    </>
  );
};

export default HostAccountHistoryPage;
