

export const validateCreateRooms = (details) => {
    const { roomNo, guestNo, bathroomNo, roomBedType, price, discount, agreeCancel, roomType  } = details;
    const errors  = [];
  
    if(!roomType){
      errors.push("Please select a room type.")
    } 
    if(!roomNo){
      errors.push("Please add a room number.")
    } 
    if(!guestNo){
      errors.push("Please indicate number of guests.")
    } 
    if(!bathroomNo){
      errors.push("Please add number of bathrooms.")
    } 
    if(!roomBedType){
      errors.push("Please select a room bed type.")
    } 
    if(!price){
      errors.push("Please add room price.")
    } 
    if(!discount){
      errors.push("Please indicate discount.")
    } 
    if(!agreeCancel){
      errors.push("Please cancellation policy.")
    } 
  
    return {
      errMsg: errors,
      errLength: errors.length
    }
  }