import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './ApplyForRefund.css'; 
import UserNavbar from './UserNavbar';
import ApImg1 from '../../Assets/Img/apartment-101.jpg';
import { PaymentOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import NavbarMenu from '../Layouts/Navbar'; 
 
const ApplyForRefund = () => {
  return (
    <>
        <NavbarMenu  style={{backgroundColor: '#003B95' }} />

        <div className='ApplyForRefund'>
            <Container>
                <Row>
                <Col xs={12} md={3} lg={3}>
                        <UserNavbar />
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                        <Row>
                            <Col xs={12} md={12} className='mb-3'>
                                <h2>Apply For Refund</h2>
                                <p>To request a refund on any of your Bookings & Trips, Kindly fill out the form below based on the booking you paid for.</p>

                            </Col>

                            <Col xs={12} md={12}>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <h2>Your Selection</h2>
                                        <p>Booking Information</p>
                                    </Col>
                                    
                                    
                                    <Col xs={12} md={6}>
                                        <img src={ApImg1} alt="booking-img" className='img-fluid' />
                                    </Col>
                                    <Col xs={12} md={6} className='booking-details'>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <h2>Zubiano Multiplex Apartments</h2>
                                                <p>Gudu, <b>Abuja</b></p>
                                                <h4>Booking Details:</h4>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <span><b>Check In: </b></span>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <span><b>Sun, Sep 10, 2023 </b></span>
                                                (12:00 PM – 2:00 PM)
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <span><b>Check Out: </b></span>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <span><b>Mon, Sep 11, 2023 </b></span>
                                                (11:00 AM – 12:00 PM)
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <span><b>Total length of stay:  </b></span>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <p>1 night</p>
                                            </Col> 
                                            <Col xs={12} md={6}>
                                                <span><b>You selected: </b></span>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <p>Studio Apartment</p>
                                            </Col>
                                        </Row>
                                        
                                    </Col>

                                    
                                    
                                </Row>

                                <Row>
                                    <form className='booking-form-1'>                                     

                                        <Row>
                                            <Col xs={12} md={12} className='mb-5'>
                                                <label htmlFor="booking"><b>Which of the following best describes the issues you met?</b></label><br />
                                            </Col> 
                                            
                                            <Col xs={12} md={6} className='mb-5'>
                                                <label htmlFor="bookingfor1"><input type="radio" name='booking' id="bookingfor1" /> &nbsp; Host customer service</label> <br />
                                                <label htmlFor="bookingfor2"><input type="radio" name='booking' id="bookingfor2" /> &nbsp; Fake Account</label><br />
                                            </Col> 
                                            <Col xs={12} md={6} className='mb-5'>
                                                <label htmlFor="bookingfor3"><input type="radio" name='booking' id="bookingfor3" /> &nbsp; Features advertised doesn’t exist</label> <br />
                                                <label htmlFor="bookingfor4"><input type="radio" name='booking' id="bookingfor4" /> &nbsp; I made the wrong decision</label> <br />
                                            </Col> 
                                            <Col xs={12} md={12} className='mb-5'>
                                                <label htmlFor="bookingbox"><b>Kindly describe issues you faced after booking?</b></label><br />
                                                <textarea className="form-control" id="bookingbox"></textarea>
                                            </Col> 
                                            <Col xs={12} md={6}>
                                                <p className='highlight'>
                                                    This reservation fee is 100% refundable if you’re not satisfied with the 
                                                    property rented, the owner will not get your money.
                                                </p>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <div className="refundbox">
                                                    <h4 className="price">
                                                        NGN48,343.00
                                                    </h4>
                                                    <Link to="/refund-thanks">
                                                        <div className="btnMore">Request Now <PaymentOutlined /></div>
                                                    </Link>
                                                </div>
                                            </Col>

                                        </Row>
                                        
                                    </form>
                                    
                                </Row>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>    
    </>
  )
}

export default ApplyForRefund