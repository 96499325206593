import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './MyBookings.css'; 
import UserNavbar from './UserNavbar';
import { Table, Badge } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa';
import { Close, RemoveCircleOutline } from '@mui/icons-material';
import { HiReceiptRefund } from 'react-icons/hi2';
import { Link, useNavigate } from 'react-router-dom';
import NavbarMenu from '../Layouts/Navbar'; 
import { limit, totalPages } from '../../utils/ownerActions/ownerRequests';
import { getBookedUserAccommodation, getBookedUserFlights, getBookedUserServices, getBookedUserTours, getBookedUserVehicles, requestRefund } from '../../utils/userActions/userRequests';
import { handleError } from '../../utils/notifications/toasts';
import Spinner from '../Alert/Spinner/Spinner';
import { toast } from 'react-toastify';

const Refunds = () => {

    const userID = window.localStorage.getItem("userID");
    const [bookings, setBookings] = useState([]);

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [currentAPage, setCurrentAPage] = useState(1)
    const [currentVPage, setCurrentVPage] = useState(1)
    const [currentFPage, setCurrentFPage] = useState(1)
    const [currentTPage, setCurrentTPage] = useState(1)
    const [currentSPage, setCurrentSPage] = useState(1)

    const [startAIndex, setstartAIndex] = useState(0)
    const [startVIndex, setstartVIndex] = useState(0)
    const [startFIndex, setstartFIndex] = useState(0)
    const [startTIndex, setstartTIndex] = useState(0)
    const [startSIndex, setstartSIndex] = useState(0)

    const [data, setData] = useState([]);
    const [flightData, setFlightData] = useState([]);
    const [vehicleData, setVehicleData] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [tourData, setTourData] = useState([]);

    const [terms, setTerms] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [reasonForRefund, setReasonFotRefund] = useState("");

    const [bookedItemId, setBookedItemId] = useState(0)
    const [bookedItemName, setBookedItemName] = useState("")
    const [bookedItemIndex, setBookedItemIndex] = useState(0)



    const fetchAccomm = async()=>{
        setLoading(true);
        setstartAIndex((currentAPage - 1) * limit)
        const accomm = await getBookedUserAccommodation(userID, currentAPage, limit)
        if (accomm?.status) {
            setData(accomm.result)
            setLoading(false);
            console.log({accomm})
        
        } else {
        handleError("Could not get accommodation lists.");
        setLoading(false);
        }

    }

    const fetchVehicles = async()=>{
        setLoading(true);
        setstartVIndex((currentAPage - 1) * limit)
        const vehicles = await getBookedUserVehicles(userID, currentVPage, limit)
        if (vehicles?.status) {
            setVehicleData(vehicles.result)
                setLoading(false);
            
            } else {
            handleError("Could not get vehicles lists.");
            setLoading(false);
            }

        
    }
    const fetchFlights = async()=>{
        setLoading(true);        
        setstartFIndex((currentAPage - 1) * limit)
        const flights = await getBookedUserFlights(userID, currentFPage, limit)
        if (flights?.status) {
            setFlightData(flights.result)
                setLoading(false);
            
            } else {
            handleError("Could not get flight lists.");
            setLoading(false);
        }


    }
    const fetchTourses = async()=>{
        setLoading(true);
        setstartTIndex((currentAPage - 1) * limit)
        const tours = await getBookedUserTours(userID, currentTPage, limit)
        if (tours?.status) {
            setTourData(tours.result)
                setLoading(false);
            
            } else {
            handleError("Could not get tours lists.");
            setLoading(false);
            }


    }
    const fetchServices = async()=>{
        setLoading(true);
        setstartSIndex((currentAPage - 1) * limit)
        const services = await getBookedUserServices(userID, currentSPage, limit)
        if (services?.status) {
            setServiceData(services.result)
                setLoading(false);
            
            } else {
            handleError("Could not get services lists.");
            setLoading(false);
            }


    }





    useEffect(() => {
   fetchAccomm()
      }, [currentAPage]);
    useEffect(() => {
   fetchVehicles()
      }, [currentVPage]);
    useEffect(() => {
   fetchFlights()
      }, [currentFPage]);
    useEffect(() => {
   fetchTourses()
      }, [currentTPage]);
    useEffect(() => {
   fetchServices()
      }, [currentSPage]);
    
    
      const handlePrevious = (text) =>{
        if(text === "a"){
            currentAPage > 1 ? setCurrentAPage(currentAPage - 1) : setCurrentAPage(currentAPage);
            
        } else if(text === "v"){
            currentVPage > 1 ? setCurrentVPage(currentVPage - 1) : setCurrentVPage(currentVPage);
            
        } else if(text === "f"){
            currentFPage > 1 ? setCurrentFPage(currentFPage - 1) : setCurrentFPage(currentFPage);
            
        } else if(text === "t"){
            currentTPage > 1 ? setCurrentTPage(currentTPage - 1) : setCurrentTPage(currentTPage);
            
        } else if(text === "s"){
            currentSPage > 1 ? setCurrentSPage(currentSPage - 1) : setCurrentSPage(currentSPage);

        } 
        
    }
    
    const handleNextPage = (text) =>{
        if(text === "a"){
            currentAPage < totalPages ? setCurrentAPage(currentAPage + 1) : setCurrentAPage(currentAPage);
            
        } else if(text === "v"){
            currentVPage < totalPages ? setCurrentVPage(currentVPage + 1) : setCurrentVPage(currentVPage);
            
        } else if(text === "f"){
            currentFPage < totalPages ? setCurrentFPage(currentFPage + 1) : setCurrentFPage(currentFPage);
            
        } else if(text === "t"){
            currentTPage < totalPages ? setCurrentTPage(currentTPage + 1) : setCurrentTPage(currentTPage);
            
        } else if(text === "s"){
            currentSPage < totalPages ? setCurrentSPage(currentSPage + 1) : setCurrentSPage(currentSPage);

        } 
        
    }
    
    const goToPage = (number, text) =>{
        if(text === "a"){
            setCurrentAPage(number) 
            
        } else if(text === "v"){
            setCurrentVPage(number) 
            
        } else if(text === "f"){
            setCurrentFPage(number) 
            
        } else if(text === "t"){
            setCurrentTPage(number) 
            
        } else if(text === "s"){
            setCurrentSPage(number) 

        } 
        
    }
    
    
      useEffect(() => {
        // scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    
       
        // fetchAllBookings()
      }, []);

      const handleBookedItem = (id, name, index)=>{
        setBookedItemId(id)
        setBookedItemName(name)
        setBookedItemIndex(index)
      }

      const handleSubmit = async(e)=>{
        e.preventDefault()
        setLoading(true)
        // console.log({ bookedItemId, bookedItemName })
        let bookedItem 
        let reservation
        let category

        if(bookedItemName === "accommodation"){
            const filteredItem = data.filter((each, i)=> each.itemID === bookedItemId && i === bookedItemIndex)
            bookedItem = filteredItem
            reservation = filteredItem[0].propertyName
            category = filteredItem[0].propertyType
        } else if(bookedItemName === "vehicle"){
            const filteredItem = vehicleData.filter((each, i)=> each.itemID === bookedItemId && i === bookedItemIndex)
            bookedItem = filteredItem
            reservation = filteredItem[0].vehicleName
            category = filteredItem[0].vehicleModel
        } else if(bookedItemName === "flight"){
            const filteredItem = flightData.filter((each, i)=> each.itemID === bookedItemId && i === bookedItemIndex)
            bookedItem = filteredItem
            reservation = filteredItem[0].fromWhere + " - " + filteredItem[0].toWhere
            category = filteredItem[0].ticketType
        } else if(bookedItemName === "tour"){
            const filteredItem = tourData.filter((each, i)=> each.itemID === bookedItemId && i === bookedItemIndex)
            bookedItem = filteredItem
            reservation = filteredItem[0].tourName
            category = filteredItem[0].tourType
        } else if(bookedItemName === "service"){
            const filteredItem = serviceData.filter((each, i)=> each.itemID === bookedItemId && i === bookedItemIndex)
            bookedItem = filteredItem
            reservation = filteredItem[0].serviceName
            category = filteredItem[0].serviceType
        }

        console.log({bookedItem})

        
        // const result = await requestRefund({ ...bookedItem[0], email, phoneNumber, reasonForRefund, terms, reservation, category })

        // if (result?.status) {
            
        //     toast.success("Your response has been recorded. Thank you.")
        //     navigate("/user-dashboard")
        //     setLoading(false)
        
        // } else {
        // handleError("Could not send request.");
        // setLoading(false);
        // }
    setLoading(false);
      }
    



  return (
    <>
        <NavbarMenu  style={{backgroundColor: '#003B95' }} />


   


        <div className='Refunds'>

        {/* Fund Wallet Modal */}
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">Something went wrong with your booking?</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                
                    <form onSubmit={handleSubmit}>
                        <div class="form-group">
                            <label htmlFor="exampleInputEmail1">Phone Number</label>
                            <input type="tel" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter phonenumber" value={phoneNumber} onChange={(e)=> setPhoneNumber(e.target.value)}/>
                            <small id="emailHelp" class="form-text text-muted text-danger">We'll never share your phone number with anyone else.</small>
                        </div>
                        <div class="form-group">
                            <label htmlFor="exampleInputEmail1">Email</label>
                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" value={email} onChange={(e)=> setEmail(e.target.value)}/>
                            <small id="emailHelp" class="form-text text-muted text-danger">We'll never share your email with anyone else.</small>
                        </div>

                        <div class="form-group">
                            <label htmlFor="exampleInputPassword1">Reason for Refund</label>
                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="7" value={reasonForRefund} onChange={(e)=> setReasonFotRefund(e.target.value)}></textarea>
                        </div>
                        <div class="form-check mt-4 mb-4">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1"  
                            checked={terms}
                            onChange={(e) => setTerms(e.target.checked)}/>
                            <span class="form-check-label" for="exampleCheck1">I agree to the terms</span>
                        </div>
                        
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" 
                         disabled = {(!terms || !email || !reasonForRefund) && true}
                        >Proceed</button>
                    </div>
                    </form> 
                
                </div>
            </div>
        </div>
        </div>
            <Container>
                <Row>
                <Col xs={12} md={3} lg={3}>
                        <UserNavbar />
                    </Col>
                    <Col xs={12} md={9} lg={9}>
                        <Row>
                            <Col xs={12} md={12} className='mt-5 mb-3'>
                                <h2>Request A Refund </h2>
                                <p>You can request a refund on any of your recent Bookings & Trips, it takes approximately 72hrs to review and effect refunds for bookings on weekends.</p>

                            </Col>

                            <Col xs={12} md={12}>
                                <div className="table-panel">
                                    <h3>Refunds for Accommodation Bookings</h3>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th> S/N </th>
                                                <th> Reservations </th>
                                                <th> Category </th>
                                                <th> Duration </th>
                                                <th> Status </th>
                                                <th> Amount </th>
                                                <th> Action </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.map((property, index)=>{
                                                    return(

                                                    <tr key={index}>
                                                        <th>{index + 1 + startAIndex}.</th>
                                                        <td>{property.propertyName}</td>
                                                        <td>{property.propertyType}</td>
                                                        <td>
                                                            From: {new Date(property.checkInDate).toLocaleDateString()} 

                                                            <br />CheckInTime: { property.checkInTime} 

                                                            <br/>To: {new Date(property.checkOutDate).toLocaleDateString()} 

                                                             <br />CheckOutTime: {property.checkOutTime}
                                                            </td>
                                                        <td>
                                                            {property.confirmation === 0 && ( <Badge bg="warning">Pending</Badge>
                                                                )}
                                                                {property.confirmation === 1 && (
                                                                <Badge bg="success">Accepted</Badge>
                                                                )}
                                                                {property.confirmation === 2 && (
                                                                <Badge bg="danger">Rejected</Badge>
                                                                )}
                                                        </td>
                                                        <td>
                                                            <div className="text-primary">₦{property.amountPaid > 100 ? Number(property.amountPaid).toLocaleString() : property.amountPaid}</div>
                                                            </td>
                                                        <td width={120}>
                                                            {
                                                                property.refundStatus === 0 ?
                                                                <button type="button" className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                onClick={()=> handleBookedItem(property.itemID, "accommodation", index)}
                                                                ><HiReceiptRefund /> Refund</button>
                                                                
                                                                :
                                                                <div className="btn btn-default" aria-disabled><HiReceiptRefund /> Refunded</div>


                                                            }
                                                        </td>
                                                    </tr>
                                                    )
                                                })
                                            }
                                            {/* <tr>
                                                <td>2.</td>
                                                <td className="text-danger">Flat Egea Petrol Manual - Car101 </td>
                                                <td>Car Rentals</td>
                                                <td>From: 28/05/2023 <br/>To: 01/06/2023</td>
                                                <td><Badge bg='danger'>Refunded</Badge></td>
                                                <td><div className="text-danger">NGN148,343.00</div></td>
                                                <td width={120}>                                                
                                                    <div className="btn btn-default" aria-disabled><HiReceiptRefund /> Refunded</div>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>3.</td>
                                                <td>Abuja (ABV) - Calabar (CBQ) Two-way Trips </td>
                                                <td>Flight Tickets</td>
                                                <td>From: 18/05/2023 <br/>To: 01/06/2023</td>
                                                <td><Badge bg='primary'>Completed</Badge></td>
                                                <td><div className="text-primary">NGN88,343.00</div></td>
                                                <td width={120}>
                                                    <Link to={'./apply-refund'}><div className="btn btn-danger"><HiReceiptRefund /> Refund</div></Link>
                                                    
                                                </td>
                                            </tr> */}
                                        </tbody>
                                    </Table>



                                    <ul className="pagination "
                                    style={{ alignItems: "right", justifyContent: "right"}}
                                    >
                                        <li className="page-item" onClick={()=> handlePrevious("a")}>
                                        <Link className="page-link" to="" aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                        </Link>
                                        </li>
                                        <li className="page-item" onClick={()=> goToPage(1, "a")}><Link className="page-link" to="">1</Link></li>
                                        <li className="page-item" onClick={()=> goToPage(2, "a")}><Link className="page-link" to="">2</Link></li>
                                        <li className="page-item" onClick={()=> goToPage(3, "a")}><Link className="page-link" to="">3</Link></li>

                                        <li className="page-item" onClick={()=>  handleNextPage("a")}>
                                        <Link className="page-link" to="" aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                        </Link>
                                        </li>
                                    </ul>

                                </div>
                            </Col>


                            <Col xs={12} md={12} className='mt-5'>
                                <div className="table-panel">
                                    <h3>Refunds for Vehicle Bookings</h3>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th> S/N </th>
                                                <th> Reservations </th>
                                                <th> Category </th>
                                                <th> Duration </th>
                                                <th> Status </th>
                                                <th> Amount </th>
                                                <th> Action </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                vehicleData.map((property, index)=>{
                                                    return(

                                                    <tr key={index}>
                                                        <th>{index + 1 + startVIndex}.</th>
                                                        <td>{property.vehicleName}</td>
                                                        <td>{property.vehicleModel}</td>
                                                        <td>
                                                            {/* From: {new Date(property.checkInDate).toLocaleDateString()} { new Date(property.checkInTime).toLocaleTimeString()} <br/>To: {new Date(property.checkOutDate).toLocaleDateString()} {new Date(property.checkOutTime).toTimeString()} */}

                                                            From: {new Date(property.checkInDate).toLocaleDateString()} 

                                                            <br />CheckInTime: { property.checkInTime} 

                                                            <br/>To: {new Date(property.checkOutDate).toLocaleDateString()} 
                                                            
                                                             <br />CheckOutTime: {property.checkOutTime}
                                                            </td>
                                                        <td>
                                                            {property.confirmation === 0 && ( <Badge bg="warning">Pending</Badge>
                                                                )}
                                                                {property.confirmation === 1 && (
                                                                <Badge bg="success">Accepted</Badge>
                                                                )}
                                                                {property.confirmation === 2 && (
                                                                <Badge bg="danger">Rejected</Badge>
                                                                )}
                                                        </td>
                                                        <td>
                                                            <div className="text-primary">₦{property.amountPaid > 100 ? Number(property.amountPaid).toLocaleString() : property.amountPaid}</div>
                                                            </td>
                                                        <td width={120}>
                                                            {
                                                                property.refundStatus === 0 ?
                                                                <button type="button" className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                onClick={()=> handleBookedItem(property.itemID, "vehicle", index)}
                                                                ><HiReceiptRefund /> Refund</button>
                                                                
                                                                :
                                                                <div className="btn btn-default" aria-disabled><HiReceiptRefund /> Refunded</div>


                                                            }
                                                        </td>
                                                    </tr>
                                                    )
                                                })
                                            }
                                            {/* <tr>
                                                <td>2.</td>
                                                <td className="text-danger">Flat Egea Petrol Manual - Car101 </td>
                                                <td>Car Rentals</td>
                                                <td>From: 28/05/2023 <br/>To: 01/06/2023</td>
                                                <td><Badge bg='danger'>Refunded</Badge></td>
                                                <td><div className="text-danger">NGN148,343.00</div></td>
                                                <td width={120}>                                                
                                                    <div className="btn btn-default" aria-disabled><HiReceiptRefund /> Refunded</div>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>3.</td>
                                                <td>Abuja (ABV) - Calabar (CBQ) Two-way Trips </td>
                                                <td>Flight Tickets</td>
                                                <td>From: 18/05/2023 <br/>To: 01/06/2023</td>
                                                <td><Badge bg='primary'>Completed</Badge></td>
                                                <td><div className="text-primary">NGN88,343.00</div></td>
                                                <td width={120}>
                                                    <Link to={'./apply-refund'}><div className="btn btn-danger"><HiReceiptRefund /> Refund</div></Link>
                                                    
                                                </td>
                                            </tr> */}
                                        </tbody>
                                    </Table>



                                    <ul className="pagination "
                                    style={{ alignItems: "right", justifyContent: "right"}}
                                    >
                                        <li className="page-item" onClick={()=> handlePrevious("v")}>
                                        <Link className="page-link" to="" aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                        </Link>
                                        </li>
                                        <li className="page-item" onClick={()=> goToPage(1, "v")}><Link className="page-link" to="">1</Link></li>
                                        <li className="page-item" onClick={()=> goToPage(2, "v")}><Link className="page-link" to="">2</Link></li>
                                        <li className="page-item" onClick={()=> goToPage(3, "v")}><Link className="page-link" to="">3</Link></li>

                                        <li className="page-item" onClick={()=>  handleNextPage("v")}>
                                        <Link className="page-link" to="" aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                        </Link>
                                        </li>
                                    </ul>

                                </div>
                            </Col>


                            <Col xs={12} md={12} className='mt-5'>
                                <div className="table-panel">
                                    <h3>Refunds for Flight Bookings</h3>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th> S/N </th>
                                                <th> Reservations </th>
                                                <th> Ticket Type </th>
                                                <th> Details </th>
                                                <th> Status </th>
                                                <th> Amount </th>
                                                <th> Action </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                flightData.map((property, index)=>{
                                                    return(

                                                    <tr key={index}>
                                                        <th>{index + 1 + startFIndex}.</th>
                                                        <td>{property.fromWhere} - {property.toWhere}{" "} </td>
                                                        <td>{property.ticketType}</td>
                                                        <td>
                                                            {/* From: {new Date(property.checkInDate).toLocaleDateString()} { new Date(property.checkInTime).toLocaleTimeString()} <br/>To: {new Date(property.checkOutDate).toLocaleDateString()} {new Date(property.checkOutTime).toTimeString()} */}

                                                            From: {new Date(property.checkInDate).toLocaleDateString()} 

                                                            <br />CheckInTime: { property.checkInTime} 

                                                            <br/>To: {new Date(property.checkOutDate).toLocaleDateString()} 
                                                            
                                                             <br />CheckOutTime: {property.checkOutTime}


                                                            <br />
                                                            {/* <b>Duration: </b>
                                                            {property.duration} days
                                                            <br />
                                                            <b>Passengers: </b>
                                                            {property.tickets} */}
                                                            </td>
                                                        <td>
                                                            {property.confirmation === 0 && ( <Badge bg="warning">Pending</Badge>
                                                                )}
                                                                {property.confirmation === 1 && (
                                                                <Badge bg="success">Accepted</Badge>
                                                                )}
                                                                {property.confirmation === 2 && (
                                                                <Badge bg="danger">Rejected</Badge>
                                                                )}
                                                        </td>
                                                        <td>
                                                            <div className="text-primary">₦{property.amountPaid > 100 ? Number(property.amountPaid).toLocaleString() : property.amountPaid}</div>
                                                            </td>
                                                        <td width={120}>
                                                            {
                                                                property.refundStatus === 0 ?
                                                                <button type="button" className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                onClick={()=> handleBookedItem(property.itemID, "flight", index)}
                                                                ><HiReceiptRefund /> Refund</button>
                                                                
                                                                :
                                                                <div className="btn btn-default" aria-disabled><HiReceiptRefund /> Refunded</div>


                                                            }
                                                        </td>
                                                    </tr>
                                                    )
                                                })
                                            }
                                            {/* <tr>
                                                <td>2.</td>
                                                <td className="text-danger">Flat Egea Petrol Manual - Car101 </td>
                                                <td>Car Rentals</td>
                                                <td>From: 28/05/2023 <br/>To: 01/06/2023</td>
                                                <td><Badge bg='danger'>Refunded</Badge></td>
                                                <td><div className="text-danger">NGN148,343.00</div></td>
                                                <td width={120}>                                                
                                                    <div className="btn btn-default" aria-disabled><HiReceiptRefund /> Refunded</div>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>3.</td>
                                                <td>Abuja (ABV) - Calabar (CBQ) Two-way Trips </td>
                                                <td>Flight Tickets</td>
                                                <td>From: 18/05/2023 <br/>To: 01/06/2023</td>
                                                <td><Badge bg='primary'>Completed</Badge></td>
                                                <td><div className="text-primary">NGN88,343.00</div></td>
                                                <td width={120}>
                                                    <Link to={'./apply-refund'}><div className="btn btn-danger"><HiReceiptRefund /> Refund</div></Link>
                                                    
                                                </td>
                                            </tr> */}
                                        </tbody>
                                    </Table>



                                    <ul className="pagination "
                                    style={{ alignItems: "right", justifyContent: "right"}}
                                    >
                                        <li className="page-item" onClick={()=> handlePrevious("f")}>
                                        <Link className="page-link" to="" aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                        </Link>
                                        </li>
                                        <li className="page-item" onClick={()=> goToPage(1, "f")}><Link className="page-link" to="">1</Link></li>
                                        <li className="page-item" onClick={()=> goToPage(2, "f")}><Link className="page-link" to="">2</Link></li>
                                        <li className="page-item" onClick={()=> goToPage(3, "f")}><Link className="page-link" to="">3</Link></li>

                                        <li className="page-item" onClick={()=>  handleNextPage("f")}>
                                        <Link className="page-link" to="" aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                        </Link>
                                        </li>
                                    </ul>

                                </div>
                            </Col>


                            <Col xs={12} md={12} className='mt-5'>
                                <div className="table-panel">
                                    <h3>Refunds for Tour Bookings</h3>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th> S/N </th>
                                                <th> Reservations </th>
                                                <th> Category </th>
                                                <th> Duration </th>
                                                <th> Status </th>
                                                <th> Amount </th>
                                                <th> Action </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tourData.map((property, index)=>{
                                                    return(

                                                    <tr key={index}>
                                                        <th>{index + 1 + startTIndex}.</th>
                                                        <td>{property.tourName}</td>
                                                        <td>{property.tourType}</td>
                                                        <td>
                                                            {/* From: {new Date(property.checkInDate).toLocaleDateString()} { new Date(property.checkInTime).toLocaleTimeString()} <br/>To: {new Date(property.checkOutDate).toLocaleDateString()} {new Date(property.checkOutTime).toTimeString()} */}

                                                            From: {new Date(property.checkInDate).toLocaleDateString()} 

                                                            <br />CheckInTime: { property.checkInTime} 

                                                            <br/>To: {new Date(property.checkOutDate).toLocaleDateString()} 
                                                            
                                                             <br />CheckOutTime: {property.checkOutTime}

                                                            </td>
                                                        <td>
                                                            {property.confirmation === 0 && ( <Badge bg="warning">Pending</Badge>
                                                                )}
                                                                {property.confirmation === 1 && (
                                                                <Badge bg="success">Accepted</Badge>
                                                                )}
                                                                {property.confirmation === 2 && (
                                                                <Badge bg="danger">Rejected</Badge>
                                                                )}
                                                        </td>
                                                        <td>
                                                            <div className="text-primary">₦{property.amountPaid > 100 ? Number(property.amountPaid).toLocaleString() : property.amountPaid}</div>
                                                            </td>
                                                        <td width={120}>
                                                            {
                                                                property.refundStatus === 0 ?
                                                                <button type="button" className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                onClick={()=> handleBookedItem(property.itemID, "tour", index)}
                                                                ><HiReceiptRefund /> Refund</button>
                                                                
                                                                :
                                                                <div className="btn btn-default" aria-disabled><HiReceiptRefund /> Refunded</div>


                                                            }
                                                        </td>
                                                    </tr>
                                                    )
                                                })
                                            }
                                            {/* <tr>
                                                <td>2.</td>
                                                <td className="text-danger">Flat Egea Petrol Manual - Car101 </td>
                                                <td>Car Rentals</td>
                                                <td>From: 28/05/2023 <br/>To: 01/06/2023</td>
                                                <td><Badge bg='danger'>Refunded</Badge></td>
                                                <td><div className="text-danger">NGN148,343.00</div></td>
                                                <td width={120}>                                                
                                                    <div className="btn btn-default" aria-disabled><HiReceiptRefund /> Refunded</div>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>3.</td>
                                                <td>Abuja (ABV) - Calabar (CBQ) Two-way Trips </td>
                                                <td>Flight Tickets</td>
                                                <td>From: 18/05/2023 <br/>To: 01/06/2023</td>
                                                <td><Badge bg='primary'>Completed</Badge></td>
                                                <td><div className="text-primary">NGN88,343.00</div></td>
                                                <td width={120}>
                                                    <Link to={'./apply-refund'}><div className="btn btn-danger"><HiReceiptRefund /> Refund</div></Link>
                                                    
                                                </td>
                                            </tr> */}
                                        </tbody>
                                    </Table>



                                    <ul className="pagination "
                                    style={{ alignItems: "right", justifyContent: "right"}}
                                    >
                                        <li className="page-item" onClick={()=> handlePrevious("t")}>
                                        <Link className="page-link" to="" aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                        </Link>
                                        </li>
                                        <li className="page-item" onClick={()=> goToPage(1, "t")}><Link className="page-link" to="">1</Link></li>
                                        <li className="page-item" onClick={()=> goToPage(2, "t")}><Link className="page-link" to="">2</Link></li>
                                        <li className="page-item" onClick={()=> goToPage(3, "t")}><Link className="page-link" to="">3</Link></li>

                                        <li className="page-item" onClick={()=>  handleNextPage("t")}>
                                        <Link className="page-link" to="" aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                        </Link>
                                        </li>
                                    </ul>

                                </div>
                            </Col>


                            <Col xs={12} md={12} className='mt-5'>
                                <div className="table-panel">
                                    <h3>Refunds for Service Bookings</h3>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th> S/N </th>
                                                <th> Reservations </th>
                                                <th> Category </th>
                                                <th> Duration </th>
                                                <th> Status </th>
                                                <th> Amount </th>
                                                <th> Action </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                serviceData.map((property, index)=>{
                                                    return(

                                                    <tr key={index}>
                                                        <th>{index + 1 + startSIndex}.</th>
                                                        <td>{property.serviceName}</td>
                                                        <td>{property.serviceType}</td>
                                                        <td>
                                                            {/* From: {new Date(property.checkInDate).toLocaleDateString()} { new Date(property.checkInTime).toLocaleTimeString()} <br/>To: {new Date(property.checkOutDate).toLocaleDateString()} {new Date(property.checkOutTime).toTimeString()} */}

                                                            From: {new Date(property.checkInDate).toLocaleDateString()} 

                                                            <br />CheckInTime: { property.checkInTime} 

                                                            <br/>To: {new Date(property.checkOutDate).toLocaleDateString()} 
                                                            
                                                             <br />CheckOutTime: {property.checkOutTime}

                                                            </td>
                                                        <td>
                                                            {property.confirmation === 0 && ( <Badge bg="warning">Pending</Badge>
                                                                )}
                                                                {property.confirmation === 1 && (
                                                                <Badge bg="success">Accepted</Badge>
                                                                )}
                                                                {property.confirmation === 2 && (
                                                                <Badge bg="danger">Rejected</Badge>
                                                                )}
                                                        </td>
                                                        <td>
                                                            <div className="text-primary">₦{property.amountPaid > 100 ? Number(property.amountPaid).toLocaleString() : property.amountPaid}</div>
                                                            </td>
                                                        <td width={120}>
                                                            {
                                                                property.refundStatus === 0 ?
                                                                <button type="button" className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                onClick={()=> handleBookedItem(property.itemID, "service", index)}
                                                                ><HiReceiptRefund /> Refund</button>
                                                                
                                                                :
                                                                <div className="btn btn-default" aria-disabled><HiReceiptRefund /> Refunded</div>


                                                            }
                                                        </td>
                                                    </tr>
                                                    )
                                                })
                                            }
                                            {/* <tr>
                                                <td>2.</td>
                                                <td className="text-danger">Flat Egea Petrol Manual - Car101 </td>
                                                <td>Car Rentals</td>
                                                <td>From: 28/05/2023 <br/>To: 01/06/2023</td>
                                                <td><Badge bg='danger'>Refunded</Badge></td>
                                                <td><div className="text-danger">NGN148,343.00</div></td>
                                                <td width={120}>                                                
                                                    <div className="btn btn-default" aria-disabled><HiReceiptRefund /> Refunded</div>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>3.</td>
                                                <td>Abuja (ABV) - Calabar (CBQ) Two-way Trips </td>
                                                <td>Flight Tickets</td>
                                                <td>From: 18/05/2023 <br/>To: 01/06/2023</td>
                                                <td><Badge bg='primary'>Completed</Badge></td>
                                                <td><div className="text-primary">NGN88,343.00</div></td>
                                                <td width={120}>
                                                    <Link to={'./apply-refund'}><div className="btn btn-danger"><HiReceiptRefund /> Refund</div></Link>
                                                    
                                                </td>
                                            </tr> */}
                                        </tbody>
                                    </Table>



                                    <ul className="pagination "
                                    style={{ alignItems: "right", justifyContent: "right"}}
                                    >
                                        <li className="page-item" onClick={()=> handlePrevious("s")}>
                                        <Link className="page-link" to="" aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                        </Link>
                                        </li>
                                        <li className="page-item" onClick={()=> goToPage(1, "s")}><Link className="page-link" to="">1</Link></li>
                                        <li className="page-item" onClick={()=> goToPage(2, "s")}><Link className="page-link" to="">2</Link></li>
                                        <li className="page-item" onClick={()=> goToPage(3, "s")}><Link className="page-link" to="">3</Link></li>

                                        <li className="page-item" onClick={()=>  handleNextPage("s")}>
                                        <Link className="page-link" to="" aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                        </Link>
                                        </li>
                                    </ul>

                                </div>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Container>

            { loading && <Spinner />}
        </div>
    </>
    
  )
}

export default Refunds