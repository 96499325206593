import React, { useState, useEffect } from "react"; 
import validator from "validator";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; 
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import './ListAccommodation.css';   
import HostNavbar from './HostNavbar';  
import NavbarMenu from '../Layouts/Navbar';   
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BaseURL } from "../../config/variables";
import Spinner from "../Alert/Spinner/Spinner";
 
const ListAccommodation02 = () => {
      
    const navigate = useNavigate();
    const currentPropertyID = window.localStorage.getItem("propertyID");
    //alert (currentPropertyID);
    const [inputValue, setInputValue] = useState({ 
        smoking: "",
        pet: "",
        children: "",
        checkInFrom: "",
        checkInUntil: "",
        checkOutFrom: "",
        checkOutUntil: "",
        bar: "", 
        wifi: "", 
        grill: "", 
        airConditioner: "", 
        flatScreenTv: "", 
        fitnessCenter: "", 
        kitchen: "", 
        garden: "", 
        pool: "", 
        breakfast: "", 
        parking: "", 
        jacuzzi: "", 
        heating: "", 
        washingMachine: "", 
        overMonthStays: "", 
        overMonthDays: "", 
        propertyID: currentPropertyID
    });

    const [loading, setLoading ] = useState(false)

    const { smoking, pet, children, checkInFrom, checkInUntil, checkOutFrom, checkOutUntil, bar, wifi, grill, airConditioner, flatScreenTv, fitnessCenter, kitchen, garden, pool, breakfast, parking, jacuzzi, heating, washingMachine, overMonthStays, overMonthDays, propertyID } = inputValue;
    
    useEffect(() => {
      // scroll to top on page load
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
    
    const handleOnChange = (e) => {
      const { name, value } = e.target;
      setInputValue({
        ...inputValue,
        [name]: value,
      });
    };
  
    const handleError = (err) =>
      toast.error(err, {
        position: "bottom-left",
      });
    const handleSuccess = (msg) =>
      toast.success(msg, {
        position: "bottom-right",
      });
      
      const handleSubmit = async (e) => {
           
          e.preventDefault();
          setLoading(true)
          try {
          const { data } = await axios.post(
              `${BaseURL}/addpropertydetails`,
              {
              ...inputValue, 
              }
              
          );
          const { success, message } = data;
  
          if (success) {
              handleSuccess(message);
              setTimeout(() => {
              navigate("/list-accommodation-04");
              }, 1000);
              setLoading(false)
            } else {
                setLoading(false)
                handleError(message);
            }
        } catch (error) {
              setLoading(false)
          console.log(error);
          }
          setInputValue({
          ...inputValue, 
          });
      };
    
  return (
    <>
        <NavbarMenu  style={{backgroundColor: '#003B95' }} />
        <ToastContainer />
        
        <div className='ListAccommodation'>
            <Container>
                <Row>
                    <Col xs={12} md={3} lg={3}>
                        <HostNavbar />
                    </Col>
                    <Col xs={12} md={9} lg={9}> 
                        <form className='form-panel' onSubmit={handleSubmit}>
                            <Row>
                                <Col xs={12} md={12} lg={12}>
                                    <h2>Property Details:</h2>
                                </Col>
                                <Col xs={12} md={6} lg={4} className='mt-5'>
                                    
                                    <h4 className="mb-3">House Rules</h4>
                                    
                                    <div class="form-group row mb-3">
                                        <label for="smoking" class="col-form-label">Smoking Allowed</label>
                                        <div>
                                            <select name="smoking" id="smoking" className="form-select selectt" value={smoking} onChange={handleOnChange} >   
                                                <option value={""}>Choose Y/N?</option>    
                                                <option value={"Yes"}>Yes</option>    
                                                <option value={"No"}>No</option>      
                                            </select> 
                                        </div>
                                    </div> 
                                    
                                    <div class="form-group row mb-3">
                                        <label for="pet" class="col-form-label">Pet Allowed</label>
                                        <div>
                                            <select name="pet" id="pet" className="form-select selectt" value={pet} onChange={handleOnChange} >   
                                                <option value={""}>Choose Y/N?</option>    
                                                <option value={"Yes"}>Yes</option>    
                                                <option value={"No"}>No</option>      
                                            </select> 
                                        </div>
                                    </div> 
                                    
                                    <div class="form-group row mb-3">
                                        <label for="children" class="col-form-label">Children Allowed</label>
                                        <div>
                                            <select name="children" id="children" className="form-select selectt" value={children} onChange={handleOnChange}>   
                                                <option value={""}>Choose Y/N?</option>    
                                                <option value={"Yes"}>Yes</option>    
                                                <option value={"No"}>No</option>      
                                            </select> 
                                        </div>
                                    </div> 
                                </Col> 

                                <Col xs={12} md={12} lg={4} className='mt-5'>
                                    <h4>Check In</h4>
                                    <label htmlFor="checkInFrom">From: </label>
                                    <input type='time' name="checkInFrom" id="checkInFrom" className="form-control" value={checkInFrom} onChange={handleOnChange} />
                                     
                                    <label htmlFor="checkInUntil">Until: </label>
                                    <input type='time' name="checkInUntil" id="checkInUntil" className="form-control" value={checkInUntil} onChange={handleOnChange} />
                                    
                                </Col> 

                                <Col xs={12} md={12} lg={4} className='mt-5'>
                                    <h4>Check Out</h4>
                                    <label htmlFor="checkOutFrom">From: </label>
                                    <input type='time' name="checkOutFrom" id="checkOutFrom" className="form-control" value={checkOutFrom} onChange={handleOnChange} />
                                     
                                    <label htmlFor="checkOutUntil">Until: </label>
                                    <input type='time' name="checkOutUntil" id="checkOutUntil" className="form-control" value={checkOutUntil} onChange={handleOnChange} />
                                    
                                </Col>

                                <Col xs={12} md={12} lg={6}>
                                    
                                    <h4 className="mt-5">Do you want to allow 30+ night stays? <small>(optional)</small></h4>
                                    <p>Allowing guests to stay for up to 90 nights can help you fill your calender and tap into trend of guests working remotely.</p>
                                    <p>Will you accept reservations for stays over 30 nights?</p>
                                    <select name="overMonthStays" id="overMonthStays" className="form-select selectt" value={overMonthStays} onChange={handleOnChange}>
                                        <option value={""}>Choose Y/N</option>    
                                        <option value={"Yes"}>Yes</option>    
                                        <option value={"No"}>No</option>    
                                    </select> 
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                    <h4 className="mt-5">How many nights can guests overstay?</h4>
                                    <p>What’s the maximum number of night you want guest to be able to book? <b>Select NONE if you're not interested over stay</b> </p> 
                                    <select name="overMonthDays" id="overMonthDays" className="form-select selectt" value={overMonthDays} onChange={handleOnChange}>
                                        <option value={""}>Choose nights</option>    
                                        <option value={"0"}>NONE</option>    
                                        <option value={"30"}>30</option>    
                                        <option value={"90"}>90</option>    
                                    </select> 
                                    
                                </Col> 
                                <Col xs={12} md={12} lg={12} className='mt-5'>
                                    <h4>Property Features </h4>
                                    <p>Tell visitors about the environment</p>
                                    <Row>
                                        <Col xs={12} md={12} lg={4}>
                                            
                                            <label for="bar" class="col-form-label">Bar:</label>
                                            <div>
                                                <select name="bar" id="bar" className="form-select selectt" value={bar} onChange={handleOnChange}>   
                                                    <option value={""}>Choose Y/N?</option>    
                                                    <option value={"Yes"}>Yes</option>    
                                                    <option value={"No"}>No</option>      
                                                </select> 
                                            </div>
                                            
                                            <label for="wifi" class="col-form-label">Free Wifi:</label>
                                            <div>
                                                <select name="wifi" id="wifi" className="form-select selectt" value={wifi} onChange={handleOnChange}>   
                                                    <option value={""}>Choose Y/N?</option>    
                                                    <option value={"Yes"}>Yes</option>    
                                                    <option value={"No"}>No</option>      
                                                </select> 
                                            </div>
                                            
                                            <label for="grill" class="col-form-label">Grill:</label>
                                            <div>
                                                <select name="grill" id="grill" className="form-select selectt" value={grill} onChange={handleOnChange}>   
                                                    <option value={""}>Choose Y/N?</option>    
                                                    <option value={"Yes"}>Yes</option>    
                                                    <option value={"No"}>No</option>      
                                                </select> 
                                            </div>
                                            
                                            <label for="airConditioner" class="col-form-label">Air Conditioner:</label>
                                            <div>
                                                <select name="airConditioner" id="airConditioner" className="form-select selectt" value={airConditioner} onChange={handleOnChange}>   
                                                    <option value={""}>Choose Y/N?</option>    
                                                    <option value={"Yes"}>Yes</option>    
                                                    <option value={"No"}>No</option>      
                                                </select> 
                                            </div>

                                            
                                            <label for="flatScreenTv" class="col-form-label">Flat screen Tv: </label>
                                            <div>
                                                <select name="flatScreenTv" id="flatScreenTv" className="form-select selectt" value={flatScreenTv} onChange={handleOnChange}>   
                                                    <option value={""}>Choose Y/N?</option>    
                                                    <option value={"Yes"}>Yes</option>    
                                                    <option value={"No"}>No</option>      
                                                </select> 
                                            </div>
                                            
                                            
                                            
                                        </Col>
                                        <Col xs={12} md={12} lg={4}>                                    
                                            
                                            <label for="fitnessCenter" class="col-form-label">Fitness Center: </label>
                                            <div>
                                                <select name="fitnessCenter" id="fitnessCenter" className="form-select selectt" value={fitnessCenter} onChange={handleOnChange}>   
                                                    <option value={""}>Choose Y/N?</option>    
                                                    <option value={"Yes"}>Yes</option>    
                                                    <option value={"No"}>No</option>      
                                                </select> 
                                            </div>

                                            <label for="kitchen" class="col-form-label">Kitchen:</label>
                                            <div>
                                                <select name="kitchen" id="kitchen" className="form-select selectt" value={kitchen} onChange={handleOnChange}>   
                                                    <option value={""}>Choose Y/N?</option>    
                                                    <option value={"Yes"}>Yes</option>    
                                                    <option value={"No"}>No</option>      
                                                </select> 
                                            </div>
                                            
                                            <label for="garden" class="col-form-label">Garden</label>
                                            <div>
                                                <select name="garden" id="garden" className="form-select selectt" value={garden} onChange={handleOnChange}>   
                                                    <option value={""}>Choose Y/N?</option>    
                                                    <option value={"Yes"}>Yes</option>    
                                                    <option value={"No"}>No</option>      
                                                </select> 
                                            </div>
                                            
                                            <label for="pool" class="col-form-label">Swimming Pool</label>
                                            <div>
                                                <select name="pool" id="pool" className="form-select selectt" value={pool} onChange={handleOnChange}>   
                                                    <option value={""}>Choose Y/N?</option>    
                                                    <option value={"Yes"}>Yes</option>    
                                                    <option value={"No"}>No</option>      
                                                </select> 
                                            </div>
                                                                                        
                                            <label for="breakfast" class="col-form-label">Breakfast</label>
                                            <div>
                                                <select name="breakfast" id="breakfast" className="form-select selectt" value={breakfast} onChange={handleOnChange}>   
                                                    <option value={""}>Choose Y/N?</option>    
                                                    <option value={"Yes"}>Yes</option>    
                                                    <option value={"No"}>No</option>      
                                                </select> 
                                            </div>
                                            
                                        </Col>
                                        <Col xs={12} md={12} lg={4}>
                                                                                        
                                            <label for="parking" class="col-form-label">Parking Lot:</label>
                                            <div>
                                                <select name="parking" id="parking" className="form-select selectt" value={parking} onChange={handleOnChange}>   
                                                    <option value={""}>Choose Y/N?</option>    
                                                    <option value={"Yes"}>Yes</option>    
                                                    <option value={"No"}>No</option>      
                                                </select> 
                                            </div>
                                            
                                            <label for="jacuzzi" class="col-form-label">Hot tub/Jacuzzi:</label>
                                            <div>
                                                <select name="jacuzzi" id="jacuzzi" className="form-select selectt" value={jacuzzi} onChange={handleOnChange}>   
                                                    <option value={""}>Choose Y/N?</option>    
                                                    <option value={"Yes"}>Yes</option>    
                                                    <option value={"No"}>No</option>      
                                                </select> 
                                            </div>
                                            
                                            <label for="heating" class="col-form-label">Heating:</label>
                                            <div>
                                                <select name="heating" id="heating" className="form-select selectt" value={heating} onChange={handleOnChange}>   
                                                    <option value={""}>Choose Y/N?</option>    
                                                    <option value={"Yes"}>Yes</option>    
                                                    <option value={"No"}>No</option>      
                                                </select> 
                                            </div>
                                            
                                            <label for="washingMachine" class="col-form-label">Washing Machine:</label>
                                            <div>
                                                <select name="washingMachine" id="washingMachine" className="form-select selectt" value={washingMachine} onChange={handleOnChange}>   
                                                    <option value={""}>Choose Y/N?</option>    
                                                    <option value={"Yes"}>Yes</option>    
                                                    <option value={"No"}>No</option>      
                                                </select> 
                                            </div>
                                            
                                        </Col>
                                    </Row>
                                     
                                </Col>
                                 
                                {/* <Col xs={12} md={12} lg={6} className='mt-5'>                    
                                    <button type="button" className='btnOutline mt-5'><FaArrowLeft />Previous </button>                    
                                </Col>  */}
                                <Col xs={12} md={12} lg={6} className='mt-5'>
                                    <button type="submit" className='btnOutline mt-5'>Next <FaArrowRight /></button>                    
                                </Col>
                                
                            </Row>
                            
                        </form>
                    </Col>
                </Row>
            </Container>

            { loading && <Spinner />}

        </div>
    </>
  );
};

export default ListAccommodation02