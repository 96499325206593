import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { DashboardOutlined, RoomServiceTwoTone } from '@mui/icons-material'; 
import { HiUser } from 'react-icons/hi2';
import { FaCarSide, FaClipboardCheck, FaComment, FaCreditCard, FaHome, FaListUl, FaMapSigns, FaPlaneDeparture, FaSignOutAlt, FaUsers} from 'react-icons/fa';
import { useGetOwnerID } from '../../Hooks/useGetOwnerID';

const OwnerNavbar = () => {
  const navigate = useNavigate();  
  const ownerID = useGetOwnerID();
  if(!ownerID){
    navigate("/owner-login");
  }
  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
    <div>
         
        <div className='list-group'>
            <Link to={'/owner-dashboard'} className="list-group-item list-group-item-action" aria-current="true"><DashboardOutlined/> Dashboard</Link>
            {/* <Link to={'/notifications'} className="list-group-item list-group-item-action"><FaClipboardCheck /> Notifications</Link> */}
            <Link to={'/payout-history'} className="list-group-item list-group-item-action"><FaListUl /> Payout History</Link>
            <Link to={'/manage-hosts'} className="list-group-item list-group-item-action"><FaCarSide /> Manage Hosts</Link>
            <Link to={'/manage-users'} className="list-group-item list-group-item-action"><FaHome /> Manage Users</Link>
            <Link to={'/manage-refunds'} className="list-group-item list-group-item-action"><FaMapSigns /> Manage Refunds</Link>
            <Link to={'/owner-profile'} className="list-group-item list-group-item-action"><HiUser /> Manage Profile</Link>
            <Link to={'/gateway-payments'} className="list-group-item list-group-item-action"><FaPlaneDeparture /> Gateway Payments</Link> 
            <Link to={'/user-feedback'} className="list-group-item list-group-item-action"><FaUsers /> User Feedbacks</Link>
            <Link to={'/host-reports'} className="list-group-item list-group-item-action"><FaComment /> Host Reports </Link>
            {/* <Link to={'#logout'} className="list-group-item list-group-item-action"><FaSignOutAlt /> Logout</Link> */}
        </div>
    </div>
  )
}

export default OwnerNavbar