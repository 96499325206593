

import axios from "axios"
import { BaseURL } from "../../config/variables"
import { handleError } from "../notifications/toasts"
import { checkImageFormat, uploadImageToCloud } from "../imageUtils/uploadImage"
import { toast } from "react-toastify"



export const getBookedUserAccommodation = async (hostID, page, limit) => {
    try {
  
        const response = await axios.get( `${BaseURL}/bookeditems/${hostID}?page=${page}&limit=${limit}`,
  
        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)
  
        return data
     
    } catch (error ) {
  
        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
  }
  
  export const getBookedUserFlights = async (hostID, page, limit) => {
      try {
    
          const response = await axios.get( `${BaseURL}/bookedflights/${hostID}?page=${page}&limit=${limit}`,
  
          {headers: {  
              'Accept': 'application/json', 'Content-Type': 'application/json',
          }}, 
          )
          
          const { data } = (response)
  
          return data
          
      } catch (error ) {
  
          if(error.response?.data?.message){
              return handleError(error.response?.data?.message) 
          } else {
              return handleError(error.message) 
          }
          
      } 
    
  }
  
  
  export const getBookedUserVehicles = async (hostID, page, limit) => {
      try {
    
          const response = await axios.get( `${BaseURL}/bookedvehicles/${hostID}?page=${page}&limit=${limit}`,
  
          {headers: {  
              'Accept': 'application/json', 'Content-Type': 'application/json',
          }}, 
          )
          
          const { data } = (response)
  
          return data
          
      } catch (error ) {
  
          if(error.response?.data?.message){
              return handleError(error.response?.data?.message) 
          } else {
              return handleError(error.message) 
          }
          
      } 
    
  }
  
  
  export const getBookedUserTours = async (hostID, page, limit) => {
      try {
    
          const response = await axios.get( `${BaseURL}/bookedtours/${hostID}?page=${page}&limit=${limit}`,
  
          {headers: {  
              'Accept': 'application/json', 'Content-Type': 'application/json',
          }}, 
          )
          
          const { data } = (response)
  
          return data
          
      } catch (error ) {
  
          if(error.response?.data?.message){
              return handleError(error.response?.data?.message) 
          } else {
              return handleError(error.message) 
          }
          
      } 
    
  }
  
  
  export const getBookedUserServices = async (hostID, page, limit) => {
      try {
    
          const response = await axios.get( `${BaseURL}/bookedservices/${hostID}?page=${page}&limit=${limit}`,
  
          {headers: {  
              'Accept': 'application/json', 'Content-Type': 'application/json',
          }}, 
          )
          
          const { data } = (response)
  
          return data
          
      } catch (error ) {
  
          if(error.response?.data?.message){
              return handleError(error.response?.data?.message) 
          } else {
              return handleError(error.message) 
          }
          
      } 
    
  }


  export const requestRefund = async (payload) => {
    try {
  
        const response = await axios.post( `${BaseURL}/refund-request`,
        payload,
        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)

        return data
        
    } catch (error ) {

        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
}



export const uploadUserKYC = async ( kycDoc, kycTitle, currentUserID ) => {
    try {

        let imageUrl = ""

        if(typeof kycDoc === "string"){
            imageUrl = kycDoc
        } else {

            const checkImageErrors = checkImageFormat(kycDoc)
            if(checkImageErrors){
                return toast.error(checkImageErrors)
            }

            const image = await uploadImageToCloud(kycDoc)
            imageUrl = image.url

        }



        const response = await axios.post( `${BaseURL}/add-user-kyc`, {kycURL: imageUrl, documentName: kycTitle, userID: currentUserID},

        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)

        return data
     
    } catch (error ) {

        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 

}


export const uploadUserAvatar = async ( avatar, currentUserID ) => {
    try {

        let imageUrl = ""

        if(typeof kycDoc === "string"){
            imageUrl = avatar
        } else {

            const checkImageErrors = checkImageFormat(avatar)
            if(checkImageErrors){
                return toast.error(checkImageErrors)
            }

            const image = await uploadImageToCloud(avatar)
            imageUrl = image.url

        }

        const response = await axios.post( `${BaseURL}/add-user-avatar`, {avatarURL: imageUrl, userID: currentUserID},

        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)

        return data
     
    } catch (error ) {

        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 

}