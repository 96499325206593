import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HostNavbar from "./HostNavbar";
import {
  FaArrowRight,
  FaCheck,
  FaDollarSign,
  FaFolderPlus,
  FaInfoCircle,
  FaUpload,
} from "react-icons/fa";
import "./ListAccommodation.css";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BaseURL } from "../../config/variables";
import Spinner from "../Alert/Spinner/Spinner";

const ListAccommodation03 = () => {
  const navigate = useNavigate();
  const currentPropertyID = window.localStorage.getItem("propertyID");
  // alert (currentPropertyID);
  const [inputValue, setInputValue] = useState({
    roomNo: "",
    guestNo: "",
    bathroomNo: "",
    roomBedType: "",
    price: "",
    discount: "",
    agreeCancel: "",
    propertyID: currentPropertyID,
  });
  const {
    roomNo,
    guestNo,
    bathroomNo,
    roomBedType,
    price,
    discount,
    agreeCancel,
    propertyID,
  } = inputValue;
  const [loading, setLoading ] = useState(false)


  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const { data } = await axios.post(
        `${BaseURL}/addpropertyrooms`,
        {
          ...inputValue,
        }
      );
      const { success, message } = data;
      console.log(inputValue);
      console.log(data);

      if (success) {
        handleSuccess(message);
        setTimeout(() => {
          navigate("/list-accommodation-04");
        }, 1000);
        setLoading(false)
      } else {
        setLoading(false)
        handleError(message);
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
    setInputValue({
      ...inputValue,
    });
  };

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <ToastContainer />

      <div className="ListAccommodation">
        <Container>
          <Row>
            <Col xs={12} md={3} lg={3}>
              <HostNavbar />
            </Col>
            
            <Col xs={12} md={9} lg={9}>
              <form className="form-panel" onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <h2>Add Room To Property:</h2>
                    <p>You can add more rooms later to this property later</p>
                  </Col>
                  <Col xs={12} md={12} lg={12} className="addRoomInput">
                    <Row>
                      <Col xs={12} md={12} lg={3}>
                        <label htmlFor="roomNo">
                          Room No:{" "}
                          <small>
                            <b>e.g. 105</b>
                          </small>
                        </label>
                        <input
                          type="number"
                          name="roomNo"
                          id="roomNo"
                          className="form-control"
                          value={roomNo}
                          onChange={handleOnChange}
                        />
                      </Col>
                      <Col xs={12} md={12} lg={3}>
                        <label htmlFor="GuestNo">Guests (Adults)</label>
                        <input
                          type="number"
                          name="guestNo"
                          id="guestNo"
                          min={1}
                          className="form-control"
                          value={guestNo}
                          onChange={handleOnChange}
                        />
                      </Col>
                      <Col xs={12} md={12} lg={3}>
                        <label htmlFor="bathroomNo">Bathrooms?</label>
                        <input
                          type="number"
                          name="bathroomNo"
                          id="bathroomNo"
                          min={1}
                          className="form-control"
                          value={bathroomNo}
                          onChange={handleOnChange}
                        />
                      </Col>
                      <Col xs={12} md={12} lg={3}>
                        <label htmlFor="roomBedType">Room Bed Type: </label>
                        <select
                          name="roomBedType"
                          id="roomBedType"
                          className="form-select"
                          value={roomBedType}
                          onChange={handleOnChange}
                        >
                          <option value={""}>Choose type</option>
                          <option value={"Twin Bed"}>Twin Bed(s)</option>
                          <option value={"Full Bed"}>Full bed(s)</option>
                          <option value={"Queen bed"}>Queen bed(s)</option>
                          <option value={"King bed"}>King bed(s)</option>
                        </select>
                      </Col>
                    </Row>

                    {/* <Row>
                                        <Col xs={12} md={12} lg={6}>                                   
                                            <label htmlFor="roomNo">Room No: <small><b>e.g. 105</b></small></label>
                                            <input type="number" name="roomNo" id="roomNo" className="form-control" value={roomNo} onChange={handleOnChange} />

                                            <label htmlFor="GuestNo">How many guests can stay here?</label>
                                            <input type="number" name="guestNo" id="guestNo" min={1} className="form-control" value={guestNo} onChange={handleOnChange} />
                                            
                                        </Col>
                                        <Col xs={12} md={12} lg={6}>
                                            <label htmlFor="bathroomNo">How many bathrooms are there?</label>
                                            <input type="number" name="bathroomNo" id="bathroomNo" min={1} className="form-control" value={bathroomNo} onChange={handleOnChange} />

                                            <label htmlFor="roomBedType">Room Bed Type: </label>
                                            <select name="roomBedType" id="roomBedType" className="form-select" value={roomBedType} onChange={handleOnChange} >
                                                <option value={""}>Choose type</option>    
                                                <option value={"Twin Bed"}>Twin Bed(s)</option>    
                                                <option value={"Full Bed"}>Full bed(s)</option>    
                                                <option value={"Queen bed"}>Queen bed(s)</option>    
                                                <option value={"King bed"}>King bed(s)</option>  
                                            </select> 
                                        </Col>
                                        
                                    </Row>  
                                    <Row>
                                        <Col xs={12} md={12} lg={12}>
                                            <button className="btnMore mt-5" onClick={""} type='button'><FaFolderPlus /> Add More Bedroom</button>
                                        </Col>
                                    </Row>*/}
                  </Col>

                  <Col xs={12} md={12} lg={6} className="mt-5">
                    <h4>How much do you want to charge per night?</h4>

                    <label htmlFor="price">
                      Price guests pay (₦
                      ):{" "}
                      <small>
                        Amount in dollars will be paid in your exchange rate
                      </small>{" "}
                    </label>
                    <input
                      type="number"
                      name="price"
                      id="price"
                      className="form-control"
                      value={price}
                      onChange={handleOnChange}
                    />
                    <small>Including taxes, commission, and fees</small>

                    <h4 className="mt-5">
                      {" "}
                      Get guest’s attention with a 20% discount:{" "}
                    </h4>
                    <select
                      name="discount"
                      id="discount"
                      className="form-select"
                      value={discount}
                      onChange={handleOnChange}
                    >
                      <option value={""}>Choose Y/N?</option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </select>
                    <p>
                      Give 20% off your first 3 bookings for 90 days, whichever
                      comes first.
                    </p>
                  </Col>

                  <Col xs={12} md={12} lg={6} className="mt-5">
                    <h4>Cancellation policy</h4>
                    <p>
                      This policy is set at the property level - any changes
                      made will apply to all units.{" "}
                    </p>
                    <p>
                      <FaCheck className="icon" /> Guests can cancel their
                      bookings for free up to 1 day before their arrival
                    </p>
                    <p>
                      <FaCheck className="icon" /> Guests who cancel can within
                      24 hours will have their cancellation fee waived{" "}
                    </p>

                    <h4>
                      Price & cancellation policy{" "}
                      <FaInfoCircle className="icon" />
                    </h4>
                    <p>
                      <FaCheck className="icon" /> Guests will pay 10% less than
                      the standard rate for a non-refundable{" "}
                    </p>
                    <p>
                      <FaCheck className="icon" /> Guests can’t cancel their
                      bookings for free anytime{" "}
                    </p>
                    <h4>Agree to cancellation policy</h4>
                    <select
                      name="agreeCancel"
                      id="agreeCancel"
                      className="form-select"
                      value={agreeCancel}
                      onChange={handleOnChange}
                    >
                      <option value={""}>Choose Y/N?</option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </select>
                  </Col>

                  <Col xs={12} md={12} lg={4} className="mt-5">
                    <button type="submit" className="btnOutline mt-5">
                      Next <FaArrowRight />
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>

        { loading && <Spinner />}

      </div>
    </>
  );
};

export default ListAccommodation03;
