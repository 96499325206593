import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./MyListings.css";
import HostNavbar from "./HostNavbar";
import { Table, Badge } from "react-bootstrap";
import { FaCheckCircle, FaEye, FaTrash } from "react-icons/fa";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { CancelOutlined } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { BaseURL } from "../../config/variables";
import Spinner from "../Alert/Spinner/Spinner";

const MyListingConfirmation = () => {
  // Show all bookings for this host
  const hostID = window.localStorage.getItem("hostID");
  const navigate = useNavigate();

  const [query] = useSearchParams();
  const confirm = query.get("confirm");
  const email = query.get("email");
  const bookingID = query.get("bookingID");
  const roomID = query.get("roomID") || null;
  const vehicleID = query.get("vehicleID") || null;

  const [loading, setLoading ] = useState(false)
  const [successful, setSuccessful ] = useState(false)

  // console.log({vehicleID})

  const confirmOrRejectBooking = async()=>{
    if ((confirm > 0 && confirm < 2) && (bookingID !== "")) {
      // alert(confirm);
      setLoading(true)
      await axios
        .put(`${BaseURL}/confirmbooking/${bookingID}/${confirm}/${email}/${roomID}/${vehicleID}`)
        .then((res) => {
          if (res.data) {
            const { success, message } = res.data;
  
            if (success) {
              setSuccessful(true)
              handleSuccess(message);
              setTimeout(() => {
                navigate("/my-listings");
              }, 2000);
              setLoading(false)
            } else {
              setLoading(false)
              handleError(message);
            }
          }
        })
        .catch((err) => console.log(err));
    } else if ((confirm > 1) && (bookingID !== "")) {
      setLoading(true)
      console.log("rejecting...")
      await axios
        .put(`${BaseURL}/rejectbooking/${bookingID}/${confirm}/${email}`)
        .then((res) => {
          if (res.data) {
            const { success, message } = res.data;
  
            if (success) {
              setSuccessful(true)
              handleSuccess(message);
              setTimeout(() => {
                navigate("/my-listings");
              }, 2000);
              setLoading(false)
            } else {
              setLoading(false)
              handleError(message);
            }
          }
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
        });
    }
  }

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    confirmOrRejectBooking()
  }, []);

  
  

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <ToastContainer />

      <div className="MyListings">
        <Container>
          <Row>
            <Col xs={12} md={3} lg={3}>
              <HostNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <Row>
                { successful ? 
                (<Col xs={12} md={12} className="mb-3">
                  {confirm == 1 && (
                    <>
                      <h2 className="text-success">Customer Booking Confirmed </h2>

                      <p>
                        Booking was accepted successfully, prepare to deliver
                        excellence, <b>We'll notify the customer...</b>
                      </p>
                      <p>
                        Users can request a refund if order fulfillment is poor!
                      </p>
                    </>
                  )}

                  {confirm == 2 && (
                    <>
                      <h2 className="text-danger">Customer Booking Rejected </h2>

                      <p>
                        Booking was rejected successfully, <b>We'll notify the customer and start the process for refund!</b>
                      </p>
                      <p>
                        Thanks for your apt response!
                      </p>
                    </>
                  )}
                </Col>)

                :

                (<Col xs={12} md={12} className="mb-3">
                  
                    {loading ? "" : (<>
                      <h2 className="text-danger">Error... </h2>

                      <p>
                        Something went wrong with this request, try again later.
                      </p>
                      
                    </>)}
                </Col>)

                }
              </Row> 
            </Col>
          </Row>
        </Container>

        { loading && <Spinner />}
      </div>
    </>
  );
};

export default MyListingConfirmation;
