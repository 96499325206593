import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HostNavbar from "./HostNavbar";
import {
  FaArrowLeft,
  FaArrowRight,
  FaCamera,
  FaCheck,
  FaDollarSign,
  FaInfoCircle,
  FaRegSave,
  FaUpload,
} from "react-icons/fa";
import "./ListVehicle.css";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BaseURL } from "../../config/variables";
import Spinner from "../Alert/Spinner/Spinner";

const ListVehicle02 = () => {
  const navigate = useNavigate();
  const currentVehicleID = window.localStorage.getItem("vehicleID");
  const [inputValue, setInputValue] = useState({
    vehicleID: currentVehicleID,
    vehicleClass: "",
    fuelType: "",
    transmission: "",
    engineSize: "",
    enginePower: "",
    fuelConsumption: "",
    seats: "",
    doors: "",
    airConditioner: "",
    availability: "",
    pickUpFrom: "",
    pickUpUntil: "",
    dropOffFrom: "",
    dropOffUntil: "",
    price: "",
    discount: "",
    agreeCancel: "",
  });
  const {
    vehicleID,
    vehicleClass,
    fuelType,
    transmission,
    engineSize,
    enginePower,
    fuelConsumption,
    seats,
    doors,
    airConditioner,
    availability,
    pickUpFrom,
    pickUpUntil,
    dropOffFrom,
    dropOffUntil,
    price,
    discount,
    agreeCancel,
  } = inputValue;
  const [loading, setLoading ] = useState(false)


  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(inputValue);
    setLoading(true)

    try {
      const { data } = await axios.post(`${BaseURL}/addvehiclefeatures`, {
        ...inputValue,
      });
      const { success, message } = data;
      //window.localStorage.setItem("vehicleID", vehicleID);
      console.log(data);
      //console.log(data.vehicleID);
      //alert("vehicle ID is: "+window.localStorage.getItem("vehicleID"));

      if (success) {
        handleSuccess(message);
        setTimeout(() => {
          navigate("/list-vehicle-03");
        }, 1000);
        setLoading(false)
        
      } else {
        setLoading(false)
        handleError(message);
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
    setInputValue({
      ...inputValue,
    });
  };
  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <ToastContainer />

      <div className="ListVehicle">
        <Container className="container">
          <Row>
            <Col xs={12} md={3} lg={3}>
              <HostNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <form className="form-panel" onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <h2>Vehicle Features:</h2>
                  </Col>
                  <Col xs={12} md={12} lg={6}>
                    <label htmlFor="vehicleClass">Vehicle Class: </label>
                    <select
                      name="vehicleClass"
                      id="vehicleClass"
                      className="form-select selectt"
                      value={vehicleClass || ""}
                      onChange={handleOnChange}
                    >
                      <option value={""}>Choose type</option>
                      <option value={"Economy"}>Economy</option>
                      <option value={"Luxury"}>Luxury</option>
                      <option value={"Medium"}>Medium</option>
                      <option value={"7+ Seats"}>7+ Seats</option>
                      <option value={"SUV"}>SUV</option>
                      <option value={"Other"}>Other</option>
                    </select>

                    <label htmlFor="fuelType">Fuel Type: </label>
                    <select
                      name="fuelType"
                      id="fuelType"
                      className="form-select selectt"
                      value={fuelType || ""}
                      onChange={handleOnChange}
                    >
                      <option value={""}>Choose type</option>
                      <option value={"Diesel "}>Diesel </option>
                      <option value={"Petrol"}>Petrol</option>
                    </select>
                    <label htmlFor="transmission">Transmission: </label>
                    <select
                      name="transmission"
                      id="transmission"
                      className="form-select selectt"
                      value={transmission || ""}
                      onChange={handleOnChange}
                    >
                      <option value={""}>Choose type</option>
                      <option value={"Manual"}>Manual</option>
                      <option value={"Automatic"}>Automatic</option>
                    </select>

                    <label htmlFor="engineSize">Base Engine Size</label>
                    <input
                      type="number"
                      name="engineSize"
                      id="engineSize"
                      placeholder="e.g. 1400"
                      className="form-control"
                      value={engineSize || ""}
                      onChange={handleOnChange}
                    />

                    <label htmlFor="enginePower">Engine Power</label>
                    <input
                      type="number"
                      name="enginePower"
                      id="enginePower"
                      placeholder="e.g. 95"
                      className="form-control"
                      value={enginePower || ""}
                      onChange={handleOnChange}
                    />
                  </Col>
                  <Col xs={12} md={12} lg={6}>
                    <label htmlFor="fuelConsumption">Fuel Consumption</label>
                    <input
                      type="text"
                      name="fuelConsumption"
                      id="fuelConsumption"
                      placeholder="e.g. 5.3"
                      className="form-control"
                      value={fuelConsumption || ""}
                      onChange={handleOnChange}
                    />

                    <label htmlFor="seats">No of seats</label>
                    <input
                      type="number"
                      name="seats"
                      id="seats"
                      placeholder="e.g. 5"
                      className="form-control"
                      value={seats || ""}
                      onChange={handleOnChange}
                    />

                    <label htmlFor="doors">No of Doors</label>
                    <input
                      type="number"
                      name="doors"
                      id="doors"
                      placeholder="e.g. 4"
                      className="form-control"
                      value={doors || ""}
                      onChange={handleOnChange}
                    />

                    <label htmlFor="airConditioner">Air Conditioner?</label>
                    <select
                      name="airConditioner"
                      id="airConditioner"
                      className="form-select selectt"
                      value={airConditioner || ""}
                      onChange={handleOnChange}
                    >
                      <option value={""}>Choose Y/N</option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </select>
                    <label htmlFor="availability">Availability (Days)</label>
                    <input
                      type="text"
                      name="availability"
                      id="availability"
                      placeholder="e.g. 6 Days"
                      className="form-control"
                      value={availability || ""}
                      onChange={handleOnChange}
                    />
                  </Col>
                  <Col xs={12} md={12} lg={12} className="mt-5">
                    <h4>Set Vehicle Rules</h4>
                    <Row>
                      <Col xs={12} md={12} lg={6}>
                        <Row className="mt-5">
                          <p className="lead">
                            <strong>Pick Up</strong>
                          </p>
                          <Col xs={12} md={12} lg={6}>
                            <label htmlFor="pickUpFrom">From: </label>
                            <input
                              type="time"
                              name="pickUpFrom"
                              id="pickUpFrom"
                              className="form-control"
                              value={pickUpFrom || ""}
                              onChange={handleOnChange}
                            />
                          </Col>
                          <Col xs={12} md={12} lg={6}>
                            <label htmlFor="pickUpUntil">Until: </label>
                            <input
                              type="time"
                              name="pickUpUntil"
                              id="pickUpUntil"
                              className="form-control"
                              value={pickUpUntil || ""}
                              onChange={handleOnChange}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={12} lg={6}>
                        <Row className="mt-5">
                          <p className="lead">
                            <strong>Drop Off</strong>
                          </p>

                          <Col xs={12} md={12} lg={6}>
                            <label htmlFor="dropOffFrom">From: </label>
                            <input
                              type="time"
                              name="dropOffFrom"
                              id="dropOffFrom"
                              className="form-control"
                              value={dropOffFrom || ""}
                              onChange={handleOnChange}
                            />
                          </Col>
                          <Col xs={12} md={12} lg={6}>
                            <label htmlFor="dropOffUntil">Until: </label>
                            <input
                              type="time"
                              name="dropOffUntil"
                              id="dropOffUntil"
                              className="form-control"
                              value={dropOffUntil || ""}
                              onChange={handleOnChange}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={12} lg={6} className="mt-5">
                    <h4>How much do you want to charge per day?</h4>
                    <label htmlFor="price">
                      Price guests pay (₦
                      ):{" "}
                      <small>
                        Amount in dollars will be paid in your exchange rate
                      </small>{" "}
                    </label>
                    <input
                      type="number"
                      name="price"
                      id="price"
                      className="form-control"
                      value={price || ""}
                      onChange={handleOnChange}
                    />
                    <small>Including taxes, commission, and fees</small>

                    <h4 className="mt-5">
                      {" "}
                      Get guest’s attention with a 20% discount:{" "}
                    </h4>
                    <select
                      name="discount"
                      id="discount"
                      className="form-select selectt"
                      value={discount || ""}
                      onChange={handleOnChange}
                    >
                      <option value={""}>Choose Y/N?</option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </select>
                    <p>
                      Give 20% off your first 3 bookings for 90 days, whichever
                      comes first.
                    </p>
                  </Col>

                  <Col xs={12} md={12} lg={6} className="mt-5">
                    <h4>Cancellation policy</h4>
                    <p>
                      This policy is set at the property level - any changes
                      made will apply to all units.{" "}
                    </p>
                    <p>
                      <FaCheck className="icon" /> Guests can cancel their
                      bookings for free up to 1 day before their arrival
                    </p>
                    <p>
                      <FaCheck className="icon" /> Guests who cancel can within
                      24 hours will have their cancellation fee waived{" "}
                    </p>

                    <h4>
                      Price & cancellation policy{" "}
                      <FaInfoCircle className="icon" />
                    </h4>
                    <p>
                      <FaCheck className="icon" /> Guests will pay 10% less than
                      the standard rate for a non-refundable{" "}
                    </p>
                    <p>
                      <FaCheck className="icon" /> Guests can’t cancel their
                      bookings for free anytime{" "}
                    </p>
                    <h4>Agree to cancellation policy</h4>
                    <select
                      name="agreeCancel"
                      id="agreeCancel"
                      className="form-select selectt"
                      value={agreeCancel || ""}
                      onChange={handleOnChange}
                    >
                      <option value={""}>Choose Y/N?</option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </select>
                  </Col>

                  {/* <Col xs={12} md={12} lg={4} className='mt-3'>
                                <button className='btnOutline mt-5'><FaArrowLeft />Previous </button>
                                
                            </Col> */}
                  <Col xs={12} md={12} lg={12} className="mt-3">
                    <button type="submit" className="btnOutline mt-5">
                      Next <FaArrowRight />
                    </button>
                  </Col>
                  {/* <Col xs={12} md={12} lg={4} className='mt-3'>
                                <button className='btnOutline mt-5'>Save Draft <FaRegSave /></button>
                                
                            </Col> */}
                </Row>
              </form>
            </Col>
          </Row>
        </Container>

        { loading && <Spinner />}

      </div>
    </>
  );
};

export default ListVehicle02;
