import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./CarPreview.css";
import landlordImg from "../../Assets/illustrations/avatar.png";
import {
  AirOutlined,
  BookOnlineOutlined,
  CancelOutlined,
  CarCrashOutlined,
  ChairAltOutlined,
  CheckCircle,
  ChildCareTwoTone,
  EngineeringOutlined,
  GasMeterOutlined,
  PaymentOutlined,
  Person2TwoTone,
  PetsOutlined,
  PowerInputOutlined,
  ShareSharp,
} from "@mui/icons-material";
import {
  FaGasPump,
  FaHeart,
  FaSignInAlt,
  FaSignOutAlt,
  FaTaxi,
} from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { HiUsers } from "react-icons/hi2";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { BaseURL } from "../../config/variables";

const CarPreview = () => {
  const [data, setData] = useState({
    vehicleName: "",
    vehicleModel: "",
    vehicleNo: "",
    city: "",
    zipCode: "",
    stateProvince: "",
    country: "",
    streetAddress: "",
    aboutVehicle: "",
    hostName: "",
    aboutHost: "",
    phoneNumber: "",
    vehicleClass: "",
    fuelType: "",
    transmission: "",
    engineSize: "",
    enginePower: "",
    fuelConsumption: "",
    seats: "",
    doors: "",
    airConditioner: "",
    availability: "",
    pickUpFrom: "",
    pickUpUntil: "",
    dropOffFrom: "",
    dropOffUntil: "",
    price: "",
    discount: "",
    vehiclePic1: "",
    vehiclePic2: "",
    vehiclePic3: "",
    vehiclePic4: "",
    vehiclePic5: "",
  });

  const { id } = useParams();
  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    axios
      .get(`${BaseURL}/vehicledetails/` + id)
      .then((res) => {
        setData({
          ...data,
          vehicleName: res.data.Result[0].vehicleName,
          vehicleModel: res.data.Result[0].vehicleModel,
          vehicleNo: res.data.Result[0].vehicleNo,
          city: res.data.Result[0].city,
          zipCode: res.data.Result[0].zipCode,
          stateProvince: res.data.Result[0].stateProvince,
          country: res.data.Result[0].country,
          streetAddress: res.data.Result[0].streetAddress,
          aboutVehicle: res.data.Result[0].aboutVehicle,
          hostName: res.data.Result[0].hostName,
          aboutHost: res.data.Result[0].aboutHost,
          phoneNumber: res.data.Result[0].phoneNumber,
          vehicleClass: res.data.Result[0].vehicleClass,
          fuelType: res.data.Result[0].fuelType,
          transmission: res.data.Result[0].transmission,
          engineSize: res.data.Result[0].engineSize,
          enginePower: res.data.Result[0].enginePower,
          fuelConsumption: res.data.Result[0].fuelConsumption,
          seats: res.data.Result[0].seats,
          doors: res.data.Result[0].doors,
          airConditioner: res.data.Result[0].airConditioner,
          availability: res.data.Result[0].availability,
          pickUpFrom: res.data.Result[0].pickUpFrom,
          pickUpUntil: res.data.Result[0].pickUpUntil,
          dropOffFrom: res.data.Result[0].dropOffFrom,
          dropOffUntil: res.data.Result[0].dropOffUntil,
          price: res.data.Result[0].price,
          discount: res.data.Result[0].discount,
          vehiclePic1: res.data.Result[0].vehiclePic1,
          vehiclePic2: res.data.Result[0].vehiclePic2,
          vehiclePic3: res.data.Result[0].vehiclePic3,
          vehiclePic4: res.data.Result[0].vehiclePic4,
          vehiclePic5: res.data.Result[0].vehiclePic5,
        });
      })
      .catch((err) => console.log(err));
  }, []);

  function onTimeChange(value) {
    let timeSplit = value.split(":"),
      hours,
      minutes,
      meridian;
    hours = timeSplit[0];
    minutes = timeSplit[1];
    if (hours > 12) {
      meridian = "PM";
      hours -= 12;
      return hours + ":" + minutes + " " + meridian;
    } else if (hours < 12) {
      meridian = "AM";
      if (hours === 0) {
        hours = 12;
      }
      return hours + ":" + minutes + " " + meridian;
    } else {
      meridian = "PM";
      return hours + ":" + minutes + " " + meridian;
    }
  } 


 





  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />

      <div className="CarPreview">
        <Container className="container">
          <Row>
            <Col xs={12} md={8}>
              <h2>{data.vehicleName} </h2>
              <p>
                {data.stateProvince}, {data.country}
              </p>
            </Col>
            <Col xs={12} md={4}>
              <span>
                <ShareSharp /> Share &nbsp;&nbsp;
              </span>
              <span>
                <FaHeart /> Save
              </span>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6} className="mt-5">
              <Row>
                <Col xs={12} md={12} className="mt-3">
                  <img
                    src={ data.vehiclePic1}
                    alt={data.vehicleName}
                    className="main-img img-fluid"
                  />
                </Col>
              </Row>
            </Col>
            {/* More pictures here */}
            <Col xs={12} md={6} className="mt-5 mb-5">
              <Row>
                <Col xs={12} md={6} className="mt-3">
                  <img
                    src={ data.vehiclePic2}
                    alt={data.vehicleName}
                    className="img-fluid"
                  />
                </Col>
                <Col xs={12} md={6} className="mt-3">
                  <img
                    src={ data.vehiclePic3}
                    alt={data.vehicleName}
                    className="third-img img-fluid"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} className="mt-3">
                  <img
                    src={ data.vehiclePic4}
                    alt={data.vehicleName}
                    className="img-fluid"
                  />
                </Col>
                <Col xs={12} md={6} className="mt-3">
                  <div className="featBoxImg">
                    <img
                      src={ data.vehiclePic5}
                      alt={data.vehicleName}
                      className="fourth-img img-fluid"
                    />
                    {/* <div className='seeMore'>
                                        See all photos
                                    </div> */}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={12} className="rules mb-5">
              <Row>
                <Col xs={12} md={12} className="mb-5">
                  <Row>
                    <Col xs={8} md={6}>
                      <h3>
                        <span
                          className="rental-vehicle"
                          style={{ fontSize: "25px" }}
                        >
                          {data.vehicleClass} Vehicle
                        </span>{" "}
                        in a rental unit{" "}
                      </h3>
                    </Col>
                    <Col xs={4} md={6}>
                      <h3>
                        Hosted by{" "}
                        <span className="landlord" style={{ marginRight: "" }}>
                          {data.hostName}{" "}
                        </span>
                        &nbsp;{" "}
                        <img
                          src={landlordImg}
                          className="landlordImg"
                          alt="landlord-img"
                        />
                      </h3>
                      <p>{data.aboutHost}</p>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={12} className="mb-5">
                  <Row>
                    <Col xs={12} md={4}>
                      <div className="features">
                        <FaGasPump />
                        <br /> {data.fuelType}
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <div className="features">
                        <GasMeterOutlined />
                        <br /> {data.transmission}
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <div className="features">
                        <HiUsers />
                        <br />
                        {data.seats} Persons
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={12}>
                  <h4>About This Vehicle</h4>
                  <p>
                    {data.aboutVehicle}. <b>Model: </b>
                    {data.vehicleModel}. <b>Fuel Type: </b>
                    {data.fuelType}
                  </p>
                </Col>
                <Col xs={12} md={12} className="offers">
                  <h4>Vehicle Features</h4>
                  <span>
                    <EngineeringOutlined /> Base Engine Size:{" "}
                    <b>({data.engineSize})</b>
                  </span>
                  <span>
                    <PowerInputOutlined /> Engine Power{" "}
                    <b>({data.enginePower})</b>
                  </span>
                  <span>
                    <FaTaxi /> Fuel Consumption <b>({data.fuelConsumption})</b>
                  </span>
                  <span>
                    <ChairAltOutlined /> No of seats <b>({data.seats})</b>
                  </span>
                  <span>
                    <AirOutlined /> Air Conditioner{" "}
                    <b>({data.airConditioner})</b>
                  </span>
                  <span>
                    <CarCrashOutlined /> Doors <b>({data.doors})</b>
                  </span>
                </Col>

                <Col xs={12} md={12}>
                  <h4>Vehicle Rules</h4>
                </Col>
                <Col xs={12} md={6}>
                  <span>
                    <FaSignInAlt /> Pick Up
                  </span>
                </Col>
                <Col xs={12} md={6}>
                  <p>
                    From <b>{onTimeChange(data.pickUpFrom)} </b> to{" "}
                    <b>{onTimeChange(data.pickUpUntil)} </b>
                  </p>
                </Col>
                <Col xs={12} md={6}>
                  <span>
                    <FaSignOutAlt /> Drop Off
                  </span>
                </Col>
                <Col xs={12} md={6}>
                  <p>
                    From <b>{onTimeChange(data.dropOffFrom)} </b> to{" "}
                    <b>{onTimeChange(data.dropOffUntil)} </b>
                  </p>
                </Col>
                <Col xs={12} md={6}>
                  <span>
                    <CancelOutlined /> Cancellation/prepayment
                  </span>
                </Col>
                <Col xs={12} md={6}>
                  <p>
                    Cancellation and prepayment policies vary according to
                    vehicle type. Check what conditions might apply to each
                    option when making your selection.
                  </p>
                </Col>
                <Col xs={12} md={6}>
                  <span>
                    <ChildCareTwoTone /> Baby Safety
                  </span>
                </Col>
                <Col xs={12} md={6}>
                  <p>Baby safety chair is not available in this vehicle.</p>
                </Col>
                <Col xs={12} md={6}>
                  <span>
                    <Person2TwoTone /> Driver Restrictions
                  </span>
                </Col>
                <Col xs={12} md={6}>
                  <p>
                    Minimum age requirement for car reservation is 25yrs and
                    above.
                  </p>
                </Col>
                <Col xs={12} md={6}>
                  <span>
                    <PetsOutlined /> Pets
                  </span>
                </Col>
                <Col xs={12} md={6}>
                  <p>Pets are not allowed.</p>
                </Col>
                <Col xs={12} md={6}>
                  <span>
                    <PaymentOutlined /> Accepted payment methods
                  </span>
                </Col>
                <Col xs={12} md={6}>
                  <p>
                    Cash is not accepted,{" "}
                    <b>Pay securely through Paystack or your e-wallet.</b>

                  </p>
                </Col>

                <Col xs={12} md={12}>
                  <div className="reserve-pay">
                    <Row>
                      <Col xs={12} md={4}>
                        <p className="text-success">
                          <CheckCircle /> <br />
                          <b>
                            Car Owner reserves right to cancel <br />
                            if you're not eligible
                          </b>
                        </p>
                      </Col>
                      <Col xs={12} md={4}>
                        <p>
                          Total Price includes Damage Insurance which will be
                          refunded on Drop Off date
                        </p>
                        {/* <h3 className='text-primary'>NGN125,000.00</h3> */}
                      </Col>
                      <Col xs={12} md={4}>
                        <Link to={`/car-booking/${id}`}>
                          <div className="btnMore mt-3">
                            Book Now 
                            <BookOnlineOutlined />
                          </div>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CarPreview;
