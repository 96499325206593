import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HostNavbar from "./HostNavbar";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import {
  FaCheck,
  FaDollarSign,
  FaFolderPlus,
  FaInfoCircle,
  FaUpload,
} from "react-icons/fa";
import "./ListTour.css";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { BaseURL } from "../../config/variables";

const ListTour02 = () => {
  const {id} = useParams();
  const navigate = useNavigate();  
  const currentHostID = window.localStorage.getItem("hostID");

  const [data, setData] = useState({
    hostID: currentHostID,
  });

  useEffect(() => {
    // scroll to top on page load

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    axios
      .get(`${BaseURL}/fetchtourfeatures/${id}`)
      .then((res) => {
        if (res.data.status === "Success") {
          setData({
            ...data,
            smoking: res.data.Result[0].smoking,
            pet: res.data.Result[0].pet,
            children: res.data.Result[0].children,
            checkInFrom: res.data.Result[0].checkInFrom,
            checkInUntil: res.data.Result[0].checkInUntil,
            checkOutFrom: res.data.Result[0].checkOutFrom,
            checkOutUntil: res.data.Result[0].checkOutUntil,
            price: res.data.Result[0].price,
            discount: res.data.Result[0].discount,
            agreeCancel: res.data.Result[0].agreeCancel,             
          });
          console.log(res.data.Result[0]);
        } else {
          console.log("No tour centers found");
        }
      })
      .catch((err) => console.log(err));
  }, []);
 

    const handleError = (err) =>
        toast.error(err, {
      position: "bottom-left",
    });
    const handleSuccess = (msg) =>
        toast.success(msg, {
      position: "bottom-right",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(data)
        axios
        .put(`${BaseURL}/edittourfeatures/${id}`, data)
        .then((res) => {
            if (res.data.success) {
                const { success, message } = res.data;
                // console.log(message);
                if (success) {
                    handleSuccess(message);
                    setTimeout(() => {
                        navigate("/list-tour");
                    }, 1000);
                } else {
                    handleError(message);
                }
            }
        })
        .catch((err) => console.log(err));
    }


  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <ToastContainer />

      <div className="ListTour">
        <Container className="container">
          <Row>
            <Col xs={12} md={3} lg={3}>
              <HostNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <form className="form-panel" onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <h2>Edit Property Regulations:</h2>
                  </Col>
                  <Col xs={12} md={12} lg={12} className="mt-5">
                    <h4 className="mb-3">Tour Center Rules</h4>
                    <Row>
                      <Col xs={12} md={12} lg={4}>
                        <label htmlFor="smoking" className="col-form-label">
                          Smoking Allowed
                        </label>
                        <div>
                          <select
                            name="smoking"
                            id="smoking"
                            className="form-select selectt"
                            onChange={(e) =>
                            setData({ ...data, smoking: e.target.value })
                          }
                          value={data.smoking || ''}
                          >
                            <option value={""}>Choose Y/N?</option>
                            <option value={"Yes"}>Yes</option>
                            <option value={"No"}>No</option>
                          </select>
                        </div>
                      </Col>
                      <Col xs={12} md={12} lg={4}>
                        <label htmlFor="pet" className="col-form-label">
                          Pet Allowed
                        </label>
                        <div>
                          <select
                            name="pet"
                            id="pet"
                            className="form-select selectt"
                            onChange={(e) =>
                            setData({ ...data, pet: e.target.value })
                          }
                          value={data.pet || ''}
                          >
                            <option value={""}>Choose Y/N?</option>
                            <option value={"Yes"}>Yes</option>
                            <option value={"No"}>No</option>
                          </select>
                        </div>
                      </Col>
                      <Col xs={12} md={12} lg={4}>
                        <label htmlFor="children" className="col-form-label">
                          Children Allowed
                        </label>
                        <div>
                          <select
                            name="children"
                            id="children"
                            className="form-select selectt"
                            onChange={(e) =>
                            setData({ ...data, children: e.target.value })
                          }
                          value={data.children || ''}
                          >
                            <option value={""}>Choose Y/N?</option>
                            <option value={"Yes"}>Yes</option>
                            <option value={"No"}>No</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} md={12} lg={6} className="mt-5">
                    <h4>Check In</h4>
                    <Row>
                      <Col xs={12} md={12} lg={6}>
                        <label htmlFor="checkInFrom">From: </label>
                        <input
                          type="time"
                          name="checkInFrom"
                          id="checkInFrom"
                          className="form-control"
                          onChange={(e) =>
                            setData({ ...data, checkInFrom: e.target.value })
                          }
                          value={data.checkInFrom || ''}
                        />
                      </Col>
                      <Col xs={12} md={12} lg={6}>
                        <label htmlFor="checkInUntil">Until: </label>
                        <input
                          type="time"
                          name="checkInUntil"
                          id="checkInUntil"
                          className="form-control"
                          onChange={(e) =>
                            setData({ ...data, checkInUntil: e.target.value })
                          }
                          value={data.checkInUntil || ''}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} md={12} lg={6} className="mt-5">
                    <h4>Check Out</h4>
                    <Row>
                      <Col xs={12} md={12} lg={6}>
                        <label htmlFor="checkOutFrom">From: </label>
                        <input
                          type="time"
                          name="checkOutFrom"
                          id="checkOutFrom"
                          className="form-control"
                          onChange={(e) =>
                            setData({ ...data, checkOutFrom: e.target.value })
                          }
                          value={data.checkOutFrom || ''}
                        />
                      </Col>
                      <Col xs={12} md={12} lg={6}>
                        <label htmlFor="checkOutUntil">Until: </label>
                        <input
                          type="time"
                          name="checkOutUntil"
                          id="checkOutUntil"
                          className="form-control"
                          onChange={(e) =>
                            setData({ ...data, checkOutUntil: e.target.value })
                          }
                          value={data.checkOutUntil || ''}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={12} md={12} lg={12} className="mt-5">
                    <Row>
                      <Col xs={12} md={12} lg={6} className="mt-5">
                        <h4>How much do you want to charge per night?</h4>

                        <label htmlFor="price">
                          Price guests pay (₦
                          ):{" "}
                          <small>
                            Amount in dollars will be paid in your exchange rate
                          </small>{" "}
                        </label>
                        <input
                          type="number"
                          name="price"
                          id="price"
                          className="form-control"
                          onChange={(e) =>
                            setData({ ...data, price: e.target.value })
                          }
                          value={data.price || ''}
                        />
                        <small>Including taxes, commission, and fees</small>
                        <h4 className="mt-5">
                          {" "}
                          Get guest’s attention with a 20% discount:{" "}
                        </h4>
                        <select
                          name="discount"
                          id="discount"
                          className="form-select selectt"
                          onChange={(e) =>
                            setData({ ...data, discount: e.target.value })
                          }
                          value={data.discount || ''}
                        >
                          <option value={""}>Choose Y/N?</option>
                          <option value={"Yes"}>Yes</option>
                          <option value={"No"}>No</option>
                        </select>
                        <p>
                          Give 20% off your first 3 bookings for 90 days,
                          whichever comes first.
                        </p>
                      </Col>
                      <Col xs={12} md={12} lg={6} className="mt-5">
                        <h4>Cancellation policy</h4>
                        <p>
                          This policy is set at the property level - any changes
                          made will apply to all units.{" "}
                        </p>
                        <p>
                          <FaCheck className="icon" /> Guests can cancel their
                          bookings for free up to 1 day before their arrival
                        </p>
                        <p>
                          <FaCheck className="icon" /> Guests who cancel can
                          within 24 hours will have their cancellation fee
                          waived{" "}
                        </p>

                        <h4>
                          Price & cancellation policy{" "}
                          <FaInfoCircle className="icon" />
                        </h4>
                        <p>
                          <FaCheck className="icon" /> Guests will pay 10% less
                          than the standard rate for a non-refundable{" "}
                        </p>
                        <p>
                          <FaCheck className="icon" /> Guests can’t cancel their
                          bookings for free anytime{" "}
                        </p>
                        <h4>Agree to cancellation policy</h4>
                        <select
                          name="agreeCancel"
                          id="agreeCancel"
                          className="form-select selectt"
                          onChange={(e) =>
                            setData({ ...data, agreeCancel: e.target.value })
                          }
                          value={data.agreeCancel || ''}
                        >
                          <option value={""}>Choose Y/N?</option>
                          <option value={"Yes"}>Yes</option>
                          <option value={"No"}>No</option>
                        </select>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={12} lg={12} className="mt-3">
                    <button type="submit" className="btnOutline mt-5">
                      Next <FaArrowRight />
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ListTour02;
