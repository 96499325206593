import React, { useState } from 'react' 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Home.css'
import {Link, useNavigate} from 'react-router-dom'
import { FaArrowRight, FaFire } from 'react-icons/fa'; 

const Home = () => {
  const [countRoom, setCountRoom] = useState(1);
  const [countAdult, setAdultCount] = useState(0);
  const [countChildren, setChildrenCount] = useState(0);

  const subtractRoom = () => {
    if(countRoom === 0){
      setCountRoom(countRoom + 1);
    } else {
      setCountRoom(countRoom - 1);
    }
  }

  const addRoom = () => {
    setCountRoom(countRoom + 1);
  }

  const handleRoomCount = (e) => {
    setCountRoom(e.target.value);
  }

  const subtractAdult = () => {
    if(countAdult === 0){
      setAdultCount(countAdult + 1);
    } else {
      setAdultCount(countAdult - 1);
    }
  }

  const addAdult = () => {
    setAdultCount(countAdult + 1);
  }

  const handleAdultCount = (e) => {
    setAdultCount(e.target.value);
  }

  const subChildren = () => {
    if(countChildren === 0){
      setChildrenCount(countChildren + 1);
    } else {
      setChildrenCount(countChildren - 1);
    }
  }

  const addChildren = () => {
    setChildrenCount(countChildren + 1);
  }

  const handleChildrenCount = (e) => {
    setChildrenCount(e.target.value);
  }

  const [destination, setDestination] = useState('');
  const navigate = useNavigate();

  function handleSearch() {
    const url = `/accommodations/?destination=${destination}&rooms=${countRoom}`;
    if(destination.length>2){
      navigate(url);
    }
  }
  

  return (
    <div>

      <div className='hero'>
        <Container className='container'>
          <Row>
            <Col xs={12} md={7}>
              <div className='hero-text-left' data-aos="flip-left">
                <h1>ENJOY SAFE </h1>
                <h2>Booking & Rentals</h2>
                <p>Find the best Hotels, Apartments, Car/Taxi, Flights, and Other Services <span className='coloredText'>with guaranteed satisfaction or 100% refund</span></p>

                <div className='btn-hero-wrap'>
                  <Link to={"/accommodations"} className='btn-hero-blue'><FaFire /> Explore Hot Deals</Link>
                  <Link to={"/host-account"} className='btn-hero-outline'>List Your Property <FaArrowRight /> </Link>
                  <div className="clearfix"></div>
                </div>
                
              </div>
            </Col>

            <Col xs={12} md={5}>
              <div className='hero-form-right' data-aos="flip-right">
                <div className="text">
                  SEARCH AND BOOK FLIGHTS & HOTELS EASILY FOR YOUR NEXT VACATION
                </div>
                <div className="breadcrumbs">
                  <ul>
                    <li><Link>HOTELS</Link></li>
                    <li><Link className='yellow-bg' to={"./flights"}>FLIGHTS</Link></li>
                  </ul>
                  <div className="form-panel">
                    <p>WHAT ARE YOU TRAVELLING FOR?</p>
                    <form>
                      <label htmlFor="travelPurpose"><input type="checkbox" name="travelPurpose" id="travelPurpose" /> Business {" "}</label> {" "}
                      <label htmlFor="travelPurpose">{" "}<input type="checkbox" name="travelPurpose" id="travelPurpose" /> Leisure</label>
                      <br></br>
                      <label htmlFor="destination">Destination</label>
                      <input type="text" name="destination" value={destination} onChange={(e) => setDestination(e.target.value)} placeholder='Country, State, Region, or District' id="destination" />
                      <label htmlFor="checkin">Check-In Date</label>
                      <input type="date" name="checkin" id="checkin" />
                      <label htmlFor="checkout">Check-Out Date</label>
                      <input type="date" name="checkout" id="checkout" />

                      {/* Counter inputs */}
                      <div className="number_ct">
                        <label htmlFor="rooms">Room(s)</label>
                        <div className="number">
                          <span className="minus" onClick={subtractRoom}>-</span>
                          <input type="text" value={countRoom} onChange={handleRoomCount} />
                          <span className="plus" onClick={addRoom}>+</span>
                        </div>
                      </div>

                      <div className="number_ct">
                        <label htmlFor="rooms">Guest(s)&nbsp;</label>
                        <div className="number">
                          <span className="minus" onClick={subtractAdult}>-</span>
                          <input type="text" value={countAdult} onChange={handleAdultCount} />
                          <span className="plus" onClick={addAdult}>+</span>
                        </div>
                      </div>

                      <div className="number_ct">
                        <label htmlFor="rooms">Children</label>
                        <div className="number">
                          <span className="minus" onClick={subChildren}>-</span>
                          <input type="text" value={countChildren} onChange={handleChildrenCount} />
                          <span className="plus" onClick={addChildren}>+</span>
                        </div>
                      </div>

                      <button type='submit' onClick={handleSearch} className='btn'>Find Deals</button> 
                    </form>
                  </div>
                </div>                
              </div>
            </Col>         
            
          </Row>
        </Container>

        <div className='clearfix'></div>

      </div> 
    </div>
  )
}

export default Home