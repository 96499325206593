import React, { useState, useEffect } from "react";
import validator from "validator";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaArrowLeft, FaArrowRight, FaPenAlt } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import "./ListAccommodation.css";
import HostNavbar from "./HostNavbar";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BaseURL } from "../../config/variables";

const ListAccommodation02 = () => {
  const {id} = useParams();
  const navigate = useNavigate();  
  const currentHostID = window.localStorage.getItem("hostID");

  const [data, setData] = useState({
    hostID: currentHostID,
  });

  useEffect(() => {
    // scroll to top on page load

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    axios
      .get(`${BaseURL}/fetchpropertyfeatures/${id}`)
      .then((res) => {
        if (res.data.status === "Success") {
          setData({
            ...data,
            smoking: res.data.Result[0].smoking,
            pet: res.data.Result[0].pet,
            children: res.data.Result[0].children,
            checkInFrom: res.data.Result[0].checkInFrom,
            checkInUntil: res.data.Result[0].checkInUntil,
            checkOutFrom: res.data.Result[0].checkOutFrom,
            checkOutUntil: res.data.Result[0].checkOutUntil,
            bar: res.data.Result[0].bar,
            wifi: res.data.Result[0].wifi,
            grill: res.data.Result[0].grill,
            airConditioner: res.data.Result[0].airConditioner,
            flatScreenTv: res.data.Result[0].flatScreenTv,
            fitnessCenter: res.data.Result[0].fitnessCenter,
            kitchen: res.data.Result[0].kitchen,
            garden: res.data.Result[0].garden,
            pool: res.data.Result[0].pool,
            breakfast: res.data.Result[0].breakfast,
            parking: res.data.Result[0].parking,
            jacuzzi: res.data.Result[0].jacuzzi,
            heating: res.data.Result[0].heating,
            washingMachine: res.data.Result[0].washingMachine,
            overMonthStays: res.data.Result[0].overMonthStays,
            overMonthDays: res.data.Result[0].overMonthDays,
          });
          //console.log(res.data.Result[0]);
        } else {
          console.log("No properties found");
        }
      })
      .catch((err) => console.log(err));
  }, []);
 

    const handleError = (err) =>
        toast.error(err, {
      position: "bottom-left",
    });
    const handleSuccess = (msg) =>
        toast.success(msg, {
      position: "bottom-right",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        //console.log(data)
        axios
        .put(`${BaseURL}/editpropertyfeatures/${id}`, data)
        .then((res) => {
            if (res.data.success) {
                const { success, message } = res.data;

                if (success) {
                    handleSuccess(message);
                    setTimeout(() => {
                        navigate("/list-accommodation");
                    }, 1000);
                } else {
                    handleError(message);
                }
            }
        })
        .catch((err) => console.log(err));
    }


  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <ToastContainer />

      <div className="ListAccommodation">
        <Container>
          <Row>
            <Col xs={12} md={3} lg={3}>
              <HostNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <form className="form-panel" onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <h2>Property Details:</h2>
                  </Col>
                  <Col xs={12} md={6} lg={4} className="mt-5">
                    <h4 className="mb-3">House Rules</h4>

                    <div className="form-group row mb-3">
                      <label htmlFor="smoking" className="col-form-label">
                        Smoking Allowed
                      </label>
                      <div>
                        <select
                          name="smoking"
                          id="smoking"
                          className="form-select selectt"
                          onChange={(e) =>
                            setData({ ...data, smoking: e.target.value })
                          }
                          value={data.smoking || ''}
                        >
                          <option value={""}>Choose Y/N?</option>
                          <option value={"Yes"}>Yes</option>
                          <option value={"No"}>No</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group row mb-3">
                      <label htmlFor="pet" className="col-form-label">
                        Pet Allowed
                      </label>
                      <div>
                        <select
                          name="pet"
                          id="pet"
                          className="form-select selectt"
                          onChange={(e) =>
                            setData({ ...data, pet: e.target.value })
                          }
                          value={data.pet || ''}
                        >
                          <option value={""}>Choose Y/N?</option>
                          <option value={"Yes"}>Yes</option>
                          <option value={"No"}>No</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-group row mb-3">
                      <label htmlFor="children" className="col-form-label">
                        Children Allowed
                      </label>
                      <div>
                        <select
                          name="children"
                          id="children"
                          className="form-select selectt"
                          onChange={(e) =>
                            setData({ ...data, children: e.target.value })
                          }
                          value={data.children || ''}
                        >
                          <option value={""}>Choose Y/N?</option>
                          <option value={"Yes"}>Yes</option>
                          <option value={"No"}>No</option>
                        </select>
                      </div>
                    </div>
                  </Col>

                  <Col xs={12} md={12} lg={4} className="mt-5">
                    <h4>Check In</h4>
                    <label htmlFor="checkInFrom">From: </label>
                    <input
                      type="time"
                      name="checkInFrom"
                      id="checkInFrom"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, checkInFrom: e.target.value })
                      }
                      value={data.checkInFrom || ''}
                    />

                    <label htmlFor="checkInUntil">Until: </label>
                    <input
                      type="time"
                      name="checkInUntil"
                      id="checkInUntil"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, checkInUntil: e.target.value })
                      }
                      value={data.checkInUntil || ''}
                    />
                  </Col>

                  <Col xs={12} md={12} lg={4} className="mt-5">
                    <h4>Check Out</h4>
                    <label htmlFor="checkOutFrom">From: </label>
                    <input
                      type="time"
                      name="checkOutFrom"
                      id="checkOutFrom"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, checkOutFrom: e.target.value })
                      }
                      value={data.checkOutFrom || ''}
                    />

                    <label htmlFor="checkOutUntil">Until: </label>
                    <input
                      type="time"
                      name="checkOutUntil"
                      id="checkOutUntil"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, checkOutUntil: e.target.value })
                      }
                      value={data.checkOutUntil || ''}
                    />
                  </Col>

                  <Col xs={12} md={12} lg={6}>
                    <h4 className="mt-5">
                      Do you want to allow 30+ night stays?{" "}
                      <small>(optional)</small>
                    </h4>
                    <p>
                      Allowing guests to stay for up to 90 nights can help you
                      fill your calender and tap into trend of guests working
                      remotely.
                    </p>
                    <p>
                      Will you accept reservations for stays over 30 nights?
                    </p>
                    <select
                      name="overMonthStays"
                      id="overMonthStays"
                      className="form-select selectt"
                      onChange={(e) =>
                        setData({ ...data, overMonthStays: e.target.value })
                      }
                      value={data.overMonthStays || ''}
                    >
                      <option value={""}>Choose Y/N</option>
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </select>
                  </Col>
                  <Col xs={12} md={12} lg={6}>
                    <h4 className="mt-5">
                      How many nights can guests overstay?
                    </h4>
                    <p>
                      What’s the maximum number of night you want guest to be
                      able to book?{" "}
                      <b>Select NONE if you're not interested over stay</b>{" "}
                    </p>
                    <select
                      name="overMonthDays"
                      id="overMonthDays"
                      className="form-select selectt"
                      onChange={(e) =>
                        setData({ ...data, overMonthDays: e.target.value })
                      }
                      value={data.overMonthDays || ''}
                    >
                      <option value={""}>Choose nights</option>
                      <option value={"0"}>NONE</option>
                      <option value={"30"}>30</option>
                      <option value={"90"}>90</option>
                    </select>
                  </Col>
                  <Col xs={12} md={12} lg={12} className="mt-5">
                    <h4>Property Features </h4>
                    <p>Tell visitors about the environment</p>
                    <Row>
                      <Col xs={12} md={12} lg={4}>
                        <label htmlFor="bar" className="col-form-label">
                          Bar:
                        </label>
                        <div>
                          <select
                            name="bar"
                            id="bar"
                            className="form-select selectt"
                            onChange={(e) =>
                                setData({ ...data, bar: e.target.value })
                            }
                            value={data.bar || ''}
                          >
                            <option value={""}>Choose Y/N?</option>
                            <option value={"Yes"}>Yes</option>
                            <option value={"No"}>No</option>
                          </select>
                        </div>

                        <label htmlFor="wifi" className="col-form-label">
                          Free Wifi:
                        </label>
                        <div>
                          <select
                            name="wifi"
                            id="wifi"
                            className="form-select selectt"
                            onChange={(e) =>
                                setData({ ...data, wifi: e.target.value })
                            }
                            value={data.wifi || ''}
                          >
                            <option value={""}>Choose Y/N?</option>
                            <option value={"Yes"}>Yes</option>
                            <option value={"No"}>No</option>
                          </select>
                        </div>

                        <label htmlFor="grill" className="col-form-label">
                          Grill:
                        </label>
                        <div>
                          <select
                            name="grill"
                            id="grill"
                            className="form-select selectt"
                            onChange={(e) =>
                                setData({ ...data, grill: e.target.value })
                            }
                            value={data.grill || ''}
                          >
                            <option value={""}>Choose Y/N?</option>
                            <option value={"Yes"}>Yes</option>
                            <option value={"No"}>No</option>
                          </select>
                        </div>

                        <label htmlFor="airConditioner" className="col-form-label">
                          Air Conditioner:
                        </label>
                        <div>
                          <select
                            name="airConditioner"
                            id="airConditioner"
                            className="form-select selectt"
                            onChange={(e) =>
                                setData({ ...data, airConditioner: e.target.value })
                            }
                            value={data.airConditioner || ''}
                          >
                            <option value={""}>Choose Y/N?</option>
                            <option value={"Yes"}>Yes</option>
                            <option value={"No"}>No</option>
                          </select>
                        </div>

                        <label htmlFor="flatScreenTv" className="col-form-label">
                          Flat screen Tv:{" "}
                        </label>
                        <div>
                          <select
                            name="flatScreenTv"
                            id="flatScreenTv"
                            className="form-select selectt"
                            onChange={(e) =>
                                setData({ ...data, flatScreenTv: e.target.value })
                            }
                            value={data.flatScreenTv || ''}
                          >
                            <option value={""}>Choose Y/N?</option>
                            <option value={"Yes"}>Yes</option>
                            <option value={"No"}>No</option>
                          </select>
                        </div>
                      </Col>
                      <Col xs={12} md={12} lg={4}>
                        <label htmlFor="fitnessCenter" className="col-form-label">
                          Fitness Center:{" "}
                        </label>
                        <div>
                          <select
                            name="fitnessCenter"
                            id="fitnessCenter"
                            className="form-select selectt"
                            onChange={(e) =>
                                setData({ ...data, fitnessCenter: e.target.value })
                            }
                            value={data.fitnessCenter || ''}
                          >
                            <option value={""}>Choose Y/N?</option>
                            <option value={"Yes"}>Yes</option>
                            <option value={"No"}>No</option>
                          </select>
                        </div>

                        <label htmlFor="kitchen" className="col-form-label">
                          Kitchen:
                        </label>
                        <div>
                          <select
                            name="kitchen"
                            id="kitchen"
                            className="form-select selectt"
                            onChange={(e) =>
                                setData({ ...data, kitchen: e.target.value })
                            }
                            value={data.kitchen || ''}
                          >
                            <option value={""}>Choose Y/N?</option>
                            <option value={"Yes"}>Yes</option>
                            <option value={"No"}>No</option>
                          </select>
                        </div>

                        <label htmlFor="garden" className="col-form-label">
                          Garden
                        </label>
                        <div>
                          <select
                            name="garden"
                            id="garden"
                            className="form-select selectt"
                            onChange={(e) =>
                                setData({ ...data, garden: e.target.value })
                            }
                            value={data.garden || ''}
                          >
                            <option value={""}>Choose Y/N?</option>
                            <option value={"Yes"}>Yes</option>
                            <option value={"No"}>No</option>
                          </select>
                        </div>

                        <label htmlFor="pool" className="col-form-label">
                          Swimming Pool
                        </label>
                        <div>
                          <select
                            name="pool"
                            id="pool"
                            className="form-select selectt"
                            onChange={(e) =>
                                setData({ ...data, pool: e.target.value })
                            }
                            value={data.pool || ''}
                          >
                            <option value={""}>Choose Y/N?</option>
                            <option value={"Yes"}>Yes</option>
                            <option value={"No"}>No</option>
                          </select>
                        </div>

                        <label htmlFor="breakfast" className="col-form-label">
                          Breakfast
                        </label>
                        <div>
                          <select
                            name="breakfast"
                            id="breakfast"
                            className="form-select selectt"
                            onChange={(e) =>
                                setData({ ...data, breakfast: e.target.value })
                            }
                            value={data.breakfast || ''}
                          >
                            <option value={""}>Choose Y/N?</option>
                            <option value={"Yes"}>Yes</option>
                            <option value={"No"}>No</option>
                          </select>
                        </div>
                      </Col>
                      <Col xs={12} md={12} lg={4}>
                        <label htmlFor="parking" className="col-form-label">
                          Parking Lot:
                        </label>
                        <div>
                          <select
                            name="parking"
                            id="parking"
                            className="form-select selectt"
                            onChange={(e) =>
                                setData({ ...data, parking: e.target.value })
                            }
                            value={data.parking || ''}
                          >
                            <option value={""}>Choose Y/N?</option>
                            <option value={"Yes"}>Yes</option>
                            <option value={"No"}>No</option>
                          </select>
                        </div>

                        <label htmlFor="jacuzzi" className="col-form-label">
                          Hot tub/Jacuzzi:
                        </label>
                        <div>
                          <select
                            name="jacuzzi"
                            id="jacuzzi"
                            className="form-select selectt"
                            onChange={(e) =>
                                setData({ ...data, jacuzzi: e.target.value })
                            }
                            value={data.jacuzzi || ''}
                          >
                            <option value={""}>Choose Y/N?</option>
                            <option value={"Yes"}>Yes</option>
                            <option value={"No"}>No</option>
                          </select>
                        </div>

                        <label htmlFor="heating" className="col-form-label">
                          Heating:
                        </label>
                        <div>
                          <select
                            name="heating"
                            id="heating"
                            className="form-select selectt"
                            onChange={(e) =>
                                setData({ ...data, heating: e.target.value })
                            }
                            value={data.heating || ''}
                          >
                            <option value={""}>Choose Y/N?</option>
                            <option value={"Yes"}>Yes</option>
                            <option value={"No"}>No</option>
                          </select>
                        </div>

                        <label htmlFor="washingMachine" className="col-form-label">
                          Washing Machine:
                        </label>
                        <div>
                          <select
                            name="washingMachine"
                            id="washingMachine"
                            className="form-select selectt"
                            onChange={(e) =>
                                setData({ ...data, washingMachine: e.target.value })
                            }
                            value={data.washingMachine || ''}
                          >
                            <option value={""}>Choose Y/N?</option>
                            <option value={"Yes"}>Yes</option>
                            <option value={"No"}>No</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  {/* <Col xs={12} md={12} lg={6} className='mt-5'>                    
                                    <button type="button" className='btnOutline mt-5'><FaArrowLeft />Previous </button>                    
                                </Col>  */}
                  <Col xs={12} md={12} lg={6} className="mt-5">
                    <button type="submit" className="btnOutline mt-5">
                      Edit Features <FaPenAlt />
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ListAccommodation02;
