import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./CarBooking.css";
import CarImg1 from "../../Assets/Img/car101.jpg";
import { FaSignInAlt } from "react-icons/fa";
import { Link, useParams, useNavigate } from "react-router-dom";
import { PaymentOutlined } from "@mui/icons-material";
import { useGetUserID } from "../../Hooks/useGetUserID";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { BaseURL } from "../../config/variables";
import { validateUserBasicBooking } from "../../utils/userActions/userValidations";
import { toast } from "react-toastify";
import Toast from "../Alert/Toast";
import Spinner from "../Alert/Spinner/Spinner";
import {
  calculateNights,
  calculatePercentage,
  convertDateStringArray,
  findMatchingDates,
  formatCalendarDate,
} from "../../utils/utilities";

const CarBooking = () => {
  const navigate = useNavigate();

  const [data, setData] = useState({
    vehicleID: "",
    hostID: "",
    vehicleName: "",
    vehicleModel: "",
    vehicleNo: "",
    city: "",
    zipCode: "",
    stateProvince: "",
    country: "",
    streetAddress: "",
    aboutVehicle: "",
    hostName: "",
    aboutHost: "",
    phoneNumber: "",
    vehicleClass: "",
    fuelType: "",
    transmission: "",
    engineSize: "",
    enginePower: "",
    fuelConsumption: "",
    seats: "",
    doors: "",
    airConditioner: "",
    availability: "",
    pickUpFrom: "",
    pickUpUntil: "",
    dropOffFrom: "",
    dropOffUntil: "",
    price: "",
    discount: "",
    vehiclePic1: "",
    vehiclePic2: "",
    vehiclePic3: "",
    vehiclePic4: "",
    vehiclePic5: "",
    vehicleBookedDates: null,
  });

  const userID = useGetUserID();
  const session = sessionStorage.getItem("session");
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [numberOfDays, setNumberOfDays] = useState(0);
  const [fivePercentVAT, setFivePercentVAT] = useState(0);
  const [tenPercentCityTax, setTenPercentCityTax] = useState(0);

  const [checkInDate, setCheckInDate] = useState("");
  const [checkInTime, setCheckInTime] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [checkOutTime, setCheckOutTime] = useState("12:00");
  const [bookingFor, setBookingFor] = useState("");
  const [bookedDates, setBookedDates] = useState([]);
  const [proceedToBook, setProceedToBook] = useState(false);

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    axios
      .get(`${BaseURL}/vehicledetails/` + id)
      .then((res) => {
        setData({
          ...data,
          vehicleID: res.data.Result[0].vehicleID,
          hostID: res.data.Result[0].hostID,
          vehicleName: res.data.Result[0].vehicleName,
          vehicleModel: res.data.Result[0].vehicleModel,
          vehicleNo: res.data.Result[0].vehicleNo,
          city: res.data.Result[0].city,
          zipCode: res.data.Result[0].zipCode,
          stateProvince: res.data.Result[0].stateProvince,
          country: res.data.Result[0].country,
          streetAddress: res.data.Result[0].streetAddress,
          aboutVehicle: res.data.Result[0].aboutVehicle,
          hostName: res.data.Result[0].hostName,
          aboutHost: res.data.Result[0].aboutHost,
          phoneNumber: res.data.Result[0].phoneNumber,
          vehicleClass: res.data.Result[0].vehicleClass,
          fuelType: res.data.Result[0].fuelType,
          transmission: res.data.Result[0].transmission,
          engineSize: res.data.Result[0].engineSize,
          enginePower: res.data.Result[0].enginePower,
          fuelConsumption: res.data.Result[0].fuelConsumption,
          seats: res.data.Result[0].seats,
          doors: res.data.Result[0].doors,
          airConditioner: res.data.Result[0].airConditioner,
          availability: res.data.Result[0].availability,
          pickUpFrom: res.data.Result[0].pickUpFrom,
          pickUpUntil: res.data.Result[0].pickUpUntil,
          dropOffFrom: res.data.Result[0].dropOffFrom,
          dropOffUntil: res.data.Result[0].dropOffUntil,
          price: res.data.Result[0].price,
          discount: res.data.Result[0].discount,
          vehiclePic1: res.data.Result[0].vehiclePic1,
          vehiclePic2: res.data.Result[0].vehiclePic2,
          vehiclePic3: res.data.Result[0].vehiclePic3,
          vehiclePic4: res.data.Result[0].vehiclePic4,
          vehiclePic5: res.data.Result[0].vehiclePic5,
          vehicleBookedDates: res.data.Result[0].bookedDates,
        });
      })
      .catch((err) => console.log(err));
  }, []);

  function onTimeChange(value) {
    let timeSplit = value.split(":"),
      hours,
      minutes,
      meridian;
    hours = timeSplit[0];
    minutes = timeSplit[1];
    if (hours > 12) {
      meridian = "PM";
      hours -= 12;
      return hours + ":" + minutes + " " + meridian;
    } else if (hours < 12) {
      meridian = "AM";
      if (hours == 0) {
        hours = 12;
      }
      return hours + ":" + minutes + " " + meridian;
    } else {
      meridian = "PM";
      return hours + ":" + minutes + " " + meridian;
    }
  }

  function formatDate(inputDate) {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
      // Months use 0 index.
      return (
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
      );
    }
  }

  function datediff(first, second) {
    let start = new Date(first);
    start.setHours(0, 0, 0, 0); // Sets hours, minutes, seconds, and milliseconds
    let end = new Date(second);
    end.setHours(0, 0, 0, 0);
    let diff = Math.round((end - start) / 86400000) + 1; // See note below re `+ 1`
    return diff; // 3
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  const generateDateArray = () => {
    const startDate = new Date(checkInDate);
    const endDate = new Date(checkOutDate);
    const dateArray = [];

    while (startDate <= endDate) {
      dateArray.push(new Date(startDate));
      startDate.setDate(startDate.getDate() + 1);
    }

    setBookedDates(dateArray);
    return dateArray;
  };

  const checkVehicleAvailability = async () => {
    setLoading(true);
    console.log({ bookedDates });

    if (
      !data?.vehicleBookedDates ||
      data?.vehicleBookedDates === "null" ||
      data?.vehicleBookedDates == null
    ) {
      setLoading(false);
      setProceedToBook(true);
      console.log("exited");
      return;
    }

    const vehicleBookedDatesString = JSON.parse(data?.vehicleBookedDates);

    const vehicleBookedDate = convertDateStringArray(vehicleBookedDatesString);

    const matchingDates = findMatchingDates(bookedDates, vehicleBookedDate);

    if (matchingDates.length > 0) {
      let error = [];
      matchingDates.forEach((matchingDate) =>
        error.push(
          matchingDate.toLocaleDateString(undefined, {
            year: "numeric",
            month: "long",
            day: "numeric",
          })
        )
      );

      setLoading(false);
      return toast.error(() => (
        <Toast title="Error: These dates have been booked" body={error} />
      ));
    } else {
      setLoading(false);
      setProceedToBook(true);
    }
  };

  useEffect(() => {
    if (checkInDate && checkOutDate) {
      generateDateArray();
    }
  }, [checkInDate, checkOutDate]);

  useEffect(() => {
    if (checkInDate && checkOutDate && data) {
      checkVehicleAvailability();
    }
  }, [checkInDate, checkOutDate, data]);

  const handleSubmit = async (text) => {
    setLoading(true);
    if (!proceedToBook) {
      setLoading(false);
      return toast.error("Sorry, you can't book already booked dates!");
    }

    localStorage.setItem("paymentMethod", text);

    const result = validateUserBasicBooking({
      checkInDate,
      checkInTime,
      checkOutDate,
      checkOutTime,
      bookingFor,
    });

    if (result.errLength) {
      setLoading(false);

      return toast.error(() => <Toast title="Error" body={result.errMsg} />);
    }

    // console.log({ ...data, checkInDate, checkInTime, checkOutDate, checkOutTime, bookingFor})
    localStorage.setItem(
      "bookingDetails",
      JSON.stringify({
        ...data,
        checkInDate,
        checkInTime,
        checkOutDate,
        checkOutTime,
        bookingFor,
        bookedDates,
      })
    );

    navigate(
      `/pay-now/?itemID=${id}&itemType=${"vehicle"}&duration=${Number(
        datediff(checkInDate, checkOutDate)
      )}&unitPrice=${Number(
        data.price
      )}&checkInDate=${checkInDate}&checkInTime=${checkInTime}&checkOutDate=${checkOutDate}&checkOutTime=${checkOutTime}&bookedDates=${bookedDates}&hostID=${
        data.hostID
      }&amount=${
        // data.discount === "No"
        //   ? Number(data.price) *
        //       Number(
        //         datediff(
        //           checkInDate,
        //           checkOutDate
        //         )
        //       ) +
        //     Number(data.price) *
        //       Number(
        //         datediff(
        //           checkInDate,
        //           checkOutDate
        //         )
        //       ) *
        //       0.15
        //   : Number(data.price) *
        //       Number(
        //         datediff(
        //           checkInDate,
        //           checkOutDate
        //         )
        //       ) +
        //     Number(data.price) *
        //       Number(
        //         datediff(
        //           checkInDate,
        //           checkOutDate
        //         )
        //       ) *
        //       0.15 -
        //     Number(data.price) *
        //       Number(
        //         datediff(
        //           checkInDate,
        //           checkOutDate
        //         )
        //       ) *
        //       0.2
        fivePercentVAT +
            tenPercentCityTax +
            (Number(data.price) * numberOfDays)
      }&bookingFor=${bookingFor}`
    );
    setLoading(false);
  };

  useEffect(() => {
    if (checkInDate && checkInTime && checkOutDate) {
      setNumberOfDays(calculateNights(checkInDate, checkInTime, checkOutDate));
    }
  }, [checkInDate, checkInTime, checkOutDate]);

  useEffect(() => {
    if (data.price) {
      setFivePercentVAT(calculatePercentage(Number(Number(data.price) * numberOfDays), 5));
      setTenPercentCityTax(calculatePercentage(Number(Number(data.price) * numberOfDays), 10));
    }
  }, [data.price, numberOfDays]);

  console.log(data.vehicleBookedDates)

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />

      <div className="CarBooking">
        <Container className="container">
          <Row>
            <Col xs={12} md={12} lg={12}>
              <Row>
                <Col xs={12} md={4} lg={4}>
                  <div className="circle-box">
                    <p>Step 1</p>
                    <h5>Select A Car</h5>
                  </div>
                </Col>

                <Col xs={12} md={8} lg={8}>
                  <Row>
                    <Col xs={12} md={12}>
                      <h2>Your Selection</h2>
                      <p>Booking Information</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <img
                        src={data.vehiclePic1}
                        alt={data.vehicleName}
                        className="main-img img-fluid"
                      />
                    </Col>
                    <Col xs={12} md={6} className="booking-details">
                      <Row>
                        <Col xs={12} md={12}>
                          <h2 style={{ fontSize: "25px" }}>
                            {data.vehicleName}
                          </h2>
                          <p>
                            {data.stateProvince}, <b>{data.country}</b>
                          </p>
                        </Col>

                        <Col xs={12} md={6}>
                          <span>
                            <b>Car Type: </b>
                          </span>
                        </Col>
                        <Col xs={12} md={6}>
                          <p>{data.vehicleModel}</p>
                        </Col>
                        <Col xs={12} md={6}>
                          <span>
                            <b>Passengers Allowed: </b>
                          </span>
                        </Col>
                        <Col xs={12} md={6}>
                          <p>{data.seats} Adults</p>
                        </Col>
                        <Col xs={12} md={6}>
                          <span>
                            <b>Price: </b>
                          </span>
                        </Col>
                        <Col xs={12} md={6}>
                          <p>₦{numberWithCommas(data.price)}/day</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col xs={12} md={4} lg={4}>
                  <div className="circle-box">
                    <p>Step 2</p>
                    <h5>Driver Details</h5>
                  </div>
                </Col>

                <Col xs={12} md={8} lg={8}>
                  <Row>
                    <Col xs={12} md={12}>
                      <h2>Your Details</h2>
                      <p>Personal Information</p>
                    </Col>
                    <Col xs={12} md={12}>
                      <div className="login-center mb-3">
                        {!session ? (
                          <Link
                            className="btnMore mb-5"
                            style={{
                              textDecoration: "none",
                              padding: "15px",
                              display: "block",
                            }}
                            to={`/login/?q=car-booking&id=${id}`}
                          >
                            Sign In <FaSignInAlt />
                          </Link>
                        ) : (
                          <div className="alert alert-info">
                            You're logged In!
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xs={12} md={12}>
                      <h4>Booking Details:</h4>
                      <p>fill out your arrival date and time below</p>
                    </Col>
                    <Col xs={12} md={6}>
                      <span>
                        <b>Pick Up: </b>
                      </span>
                    </Col>
                    <Col xs={12} md={6}>
                      <span>
                        <b>{checkInDate && formatCalendarDate(checkInDate)}</b>
                      </span>
                      &nbsp;
                      {checkInTime && "(" + onTimeChange(checkInTime) + ")"}
                    </Col>
                    <Col xs={12} md={6}>
                      <span>
                        <b>Drop Off: </b>
                      </span>
                    </Col>
                    <Col xs={12} md={6}>
                      <span>
                        <b>
                          {checkOutDate && formatCalendarDate(checkOutDate)}
                        </b>
                      </span>
                      &nbsp;
                      {checkOutTime && "(" + onTimeChange(checkOutTime) + ")"}
                    </Col>
                    <Col xs={12} md={6}>
                      <span>
                        <b>Total length of hire: </b>
                      </span>
                    </Col>
                    <Col xs={12} md={6}>
                      <p>
                        {/* {checkOutDate &&
                          datediff(checkInDate, checkOutDate) + " day(s)"} */}
                        {numberOfDays < 1 ? "" : numberOfDays}{" "}
                        {numberOfDays > 0 && numberOfDays > 1
                          ? "Days"
                          : numberOfDays > 0 && numberOfDays === 1
                          ? "Day"
                          : ""}
                      </p>
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                      <label htmlFor="checkInDate">Pick Up Date:</label>
                      <input
                        type="date"
                        id="checkInDate"
                        placeholder="mm/dd/yyyy"
                        className="form-control"
                        onChange={(e) => setCheckInDate(e.target.value)}
                      />
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                      <label htmlFor="checkInTime">Pick Up Time:</label>
                      <input
                        type="time"
                        id="checkInTime"
                        placeholder="10:00AM"
                        className="form-control"
                        onChange={(e) => setCheckInTime(e.target.value)}
                      />
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                      <label htmlFor="checkOutDate">Drop Off Date:</label>
                      <input
                        type="date"
                        id="checkOutDate"
                        placeholder="mm/dd/yyyy"
                        className="form-control"
                        onChange={(e) => setCheckOutDate(e.target.value)}
                      />
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                      <label htmlFor="checkOutTime">Drop Off Time:</label>
                      {/* <input
                        type="time"
                        id="checkOutTime"
                        placeholder="10:00AM"
                        className="form-control"
                        onChange={(e) => setCheckOutTime(e.target.value)}
                      /> */}
                      <input
                        type="time"
                        id="checkOutTime"
                        className="form-control"
                        value="12:00"
                        readOnly
                      />
                    </Col>

                    <Col xs={12} md={12} lg={12} className="mb-5">
                      <label htmlFor="booking">
                        <b>For yourself/Someone else?</b>
                      </label>
                      <input
                        type="text"
                        id=""
                        placeholder="name of who you are booking for"
                        className="form-control"
                        onChange={(e) => setBookingFor(e.target.value)}
                      />
                    </Col>
                  </Row>
                </Col>

                {!userID ? (
                  <div className="alert alert-warning">
                    Login to finish reservation
                  </div>
                ) : (
                  <>
                    <Col xs={12} md={4} lg={4}>
                      <div className="circle-box">
                        <p>Step 3</p>
                        <h5>Finish Reservation</h5>
                      </div>
                    </Col>
                    <Col xs={12} md={8} lg={8}>
                      <Row>
                        <form className="booking-form-1">
                          <Row>
                            <Col xs={12} md={6}>
                              <h4>Your price summary:</h4>
                              {/* <p>
                                Original price: ₦
                                {numberWithCommas(data.price)}
                                /day
                              </p> */}
                              {data.price && numberOfDays && (
                                <p>
                                  Original price: ₦{" "}
                                  {Number(data.price).toLocaleString() ?? 0}/
                                  Day
                                </p>
                              )}
                              <p>
                                {/* {data.discount !== "No" &&
                                  "This car owner is offering a 20% discount: - ₦" +
                                    numberWithCommas(data.price * 0.2)} */}

                                {/* {data.discount &&
                                  `This property is offering a 20% discount: - ₦ +
                                    ${
                                      (
                                        Number(data.price) * 0.2
                                      ).toLocaleString() ?? 0
                                    }`} */}
                              </p>
                            </Col>
                            <Col xs={12} md={6}>
                              {checkOutDate && (
                                <>
                                  <h4>Price information </h4>
                                  <p>
                                    Includes ₦
                                    {Number(fivePercentVAT + tenPercentCityTax).toLocaleString() ?? 0}
                                    {/* {numberWithCommas(
                                      data.price *
                                        0.15 *
                                        Number(
                                          datediff(checkInDate, checkOutDate)
                                        )
                                    )} */}{" "}
                                    in taxes and fees:
                                    <br />5 % VAT: ₦{fivePercentVAT.toLocaleString() ?? 0}
                                    {/* {numberWithCommas(
                                      data.price *
                                        0.05 *
                                        Number(
                                          datediff(checkInDate, checkOutDate)
                                        )
                                    )} */}
                                    <br />
                                    10 % City tax : ₦{tenPercentCityTax.toLocaleString() ?? 0}
                                    {/* {numberWithCommas(
                                      data.price *
                                        0.1 *
                                        Number(
                                          datediff(checkInDate, checkOutDate)
                                        )
                                    )} */}
                                  </p>
                                </>
                              )}
                            </Col>
                            <Col xs={12} md={6}>
                              <p className="highlight">
                                This reservation fee is 100% refundable if
                                you’re not satisfied with the property rented,
                                the owner will not get your money.
                              </p>
                            </Col>
                            <Col xs={12} md={6}>
                              {checkOutDate && (
                                <div className="paybox">
                                  <h4 className="price">₦
                                    {/* {data.discount === "No"
                                      ? "₦" +
                                        numberWithCommas(
                                          Number(data.price) *
                                            Number(
                                              datediff(
                                                checkInDate,
                                                checkOutDate
                                              )
                                            ) +
                                            Number(data.price) *
                                              Number(
                                                datediff(
                                                  checkInDate,
                                                  checkOutDate
                                                )
                                              ) *
                                              0.15
                                        )
                                      : "₦" +
                                        numberWithCommas(
                                          Number(data.price) *
                                            Number(
                                              datediff(
                                                checkInDate,
                                                checkOutDate
                                              )
                                            ) +
                                            Number(data.price) *
                                              Number(
                                                datediff(
                                                  checkInDate,
                                                  checkOutDate
                                                )
                                              ) *
                                              0.15 -
                                            Number(data.price) *
                                              Number(
                                                datediff(
                                                  checkInDate,
                                                  checkOutDate
                                                )
                                              ) *
                                              0.2
                                        )} */}

                                    {Number(
                                      fivePercentVAT +
                                      tenPercentCityTax +
                                      (Number(data.price) * numberOfDays)
                                    ).toLocaleString() ?? 0}
                                  </h4>
                                  {/* <Link
                                    to= {
                                      `/pay-now/?itemID=${id}&itemType=${"vehicle"}&duration=${Number(
                                      datediff(checkInDate, checkOutDate)
                                    )}&unitPrice=${Number(
                                      data.price
                                    )}&checkInDate=${checkInDate}&checkInTime=${checkInTime}&checkOutDate=${checkOutDate}&checkOutTime=${checkOutTime}&hostID=${
                                      data.hostID
                                    }&amount=${
                                      data.discount === "No"
                                        ? Number(data.price) *
                                            Number(
                                              datediff(
                                                checkInDate,
                                                checkOutDate
                                              )
                                            ) +
                                          Number(data.price) *
                                            Number(
                                              datediff(
                                                checkInDate,
                                                checkOutDate
                                              )
                                            ) *
                                            0.15
                                        : Number(data.price) *
                                            Number(
                                              datediff(
                                                checkInDate,
                                                checkOutDate
                                              )
                                            ) +
                                          Number(data.price) *
                                            Number(
                                              datediff(
                                                checkInDate,
                                                checkOutDate
                                              )
                                            ) *
                                            0.15 -
                                          Number(data.price) *
                                            Number(
                                              datediff(
                                                checkInDate,
                                                checkOutDate
                                              )
                                            ) *
                                            0.2
                                    }&bookingFor=${bookingFor}`
                                  }
                                  > */}
                                  <div
                                    className="btnMore"
                                    onClick={() => handleSubmit("paystack")}
                                  >
                                    Pay with Paystack <PaymentOutlined />
                                  </div>
                                  <div
                                    className="btnMore mt-3"
                                    onClick={() => handleSubmit("wallet")}
                                  >
                                    Pay with Wallet <PaymentOutlined />
                                  </div>
                                  {/* </Link> */}
                                </div>
                              )}
                            </Col>
                          </Row>
                        </form>
                      </Row>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </Container>

        {loading && <Spinner />}
      </div>
    </>
  );
};

export default CarBooking;
