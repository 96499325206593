

import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./UserDashboard.css";
import { PaymentOutlined, Payments } from "@mui/icons-material";
import NavbarMenu from "../Layouts/Navbar";
import UserNavbar from "./UserNavbar";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import { 
  FaPenAlt, 
} from "react-icons/fa";
import axios from "axios";
import { BaseURL } from "../../config/variables";
import pdfupload from "../../Assets/Img/pdfupload.png";
import draganddrop from "../../Assets/Img/draganddrop.png";
import Spinner from "../Alert/Spinner/Spinner";
import Toast from "../Alert/Toast";
import { validateUserKYC } from "../../utils/userActions/userValidations";
import { uploadUserKYC } from "../../utils/userActions/userRequests";
import { useGetUserID } from "../../Hooks/useGetUserID";



const KYCPage = () => {
  const navigate = useNavigate();
  const currentUserID = useGetUserID()

  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [userDocument, setUserDocument] = useState("")
  const [documentName, setDocumentName] = useState("")


  // const [data, setData] = useState({
  //   userID: currentUserID,  
  // });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setSelectedFile(file);

    

  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    // scroll to top on page load

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    // axios
    //   .get(`${BaseURL}/fetchuserdetails/${currentUserID}`)
    //   .then((res) => {
    //     if (res.data.status === "Success") {
    //       setData({
    //         ...data,
    //         firstName: res.data.Result[0].firstName,
    //         lastName: res.data.Result[0].lastName,
    //         username: res.data.Result[0].username,
    //         phoneNumber: res.data.Result[0].phoneNumber,
    //         emailAddress: res.data.Result[0].emailAddress,
    //         country: res.data.Result[0].country,
    //         stateProvince: res.data.Result[0].stateProvince,
    //         city: res.data.Result[0].city,
    //         zipCode: res.data.Result[0].zipCode,
    //         streetAddress: res.data.Result[0].streetAddress,
    //       });
    //       // console.log(res.data.Result[0]);
    //     } else {
    //       console.log("No such user found");
    //     }
    //   })
    //   .catch((err) => console.log(err));
  }, []);

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true)
    const result = validateUserKYC({ selectedFile, documentName});
     
    if (result.errLength) {
      setLoading(false);
      
      return toast.error(() => <Toast title="Error" body={result.errMsg} />);
    }
    
    const response = await uploadUserKYC(selectedFile, documentName, currentUserID)
    
    if(response?.success){
      toast.success("KYC uploaded successfully.")
      navigate("/user-profile")
      setLoading(false);
    }else {
      toast.error("Could not upload KYC.")
      setLoading(false);
    }
    
  };


  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <div className="UserDashboard">
        {/* Do a mini navbar just below the main navbar */}
        <Container className="container">
          <Row>
            <Col xs={12} md={3} lg={3}>
              <UserNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <form className="form-panel mt-5" onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <h2>Update KYC</h2>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                  <div className="mt-5">
              <label className="text-black fw-bold">Upload Passport or Driver's license</label>

              <div
                style={{
                  border: "2px dashed #ccc",
                  padding: "1rem",
                  borderRadius: "10px",
                  height: "250px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  overflow: "hidden", 
                }}
                className="mt-4"
                onDrop={handleFileDrop}
                onDragOver={handleDragOver}
              >
                {selectedFile ? (
                  <div
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      position: "relative",
                    }}
                  >
                    {selectedFile.type.startsWith("image/") ? (
                      <img
                        src={URL.createObjectURL(selectedFile)} 
                        alt="Selected File Preview"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <div>
                        <p>Selected File: {selectedFile.name}</p>
                        <a href={URL.createObjectURL(selectedFile)} download>
                          Download Document
                        </a>
                      </div>
                    )}
                    <button
                      className="btn btn-danger"
                      onClick={handleRemoveFile}
                      style={{
                        position: "absolute",
                        top: "100px",
                        left: "70%",
                        transform: "translateX(-50%)",
                        width: "150px",
                        // height: "0px",
                        fontSize:"20px"
                      }}
                    >
                      Remove
                    </button>
                  </div>
                ) : userDocument ? (
                  <div
                    className="text-center"
                    onClick={() => fileInputRef.current.click()}
                    onDrop={handleFileDrop}
                    onDragOver={handleDragOver}
                  >
                    <img src={pdfupload} alt="" 
                    style={{ width : "30%"}}
                    />
                    <p>
                     User Document
                      <br />
                      {/* <span style={{ fontSize: "11px", color: "#C7C9D9" }}>
                        JPG, PNG or PDF, file size no more than 10MB
                      </span> */}
                    </p>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      accept=".jpg, .jpeg, .png, .pdf"
                    />
                    <label
                      className="btn btn-primary mt-3"
                      htmlFor="fileInput"
                      style={{
                        background: "none",
                        border: "2px solid #003B95",
                        transition: "background 0.3s",
                        textTransform: "none",
                        boxShadow: "none",
                        fontSize: "17px",
                        color: "#003B95",
                        cursor: "pointer",
                      }}
                      onMouseEnter={(e) =>
                        (e.target.style.background = "black")
                      }
                      onMouseLeave={(e) => (e.target.style.background = "none")}
                    >
                      Select File
                    </label>
                  </div>
                ) : (
                  <div
                    className="text-center"
                    onClick={() => fileInputRef.current.click()}
                    onDrop={handleFileDrop}
                    onDragOver={handleDragOver}
                  >
                    <img src={draganddrop} alt="" />
                    <p>
                      Select a file or drag and drop here
                      <br />
                      <span style={{ fontSize: "11px", color: "#C7C9D9" }}>
                        JPG, PNG or PDF, file size no more than 10MB
                      </span>
                    </p>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      accept=".jpg, .jpeg, .png, .pdf"
                    />
                    <label
                      className="btn btn-primary mt-3"
                      htmlFor="fileInput"
                      style={{
                        background: "none",
                        border: "2px solid #003B95",
                        transition: "background 0.3s",
                        textTransform: "none",
                        boxShadow: "none",
                        fontSize: "17px",
                        color: "#003B95",
                        cursor: "pointer",
                      }}
                      onMouseEnter={(e) =>
                        (e.target.style.background = "white")
                      }
                      onMouseLeave={(e) => (e.target.style.background = "none")}
                    >
                      Select File
                    </label>
                  </div>
                )
                }
              </div>
            </div>

                   
                  </Col>

                 
                  
                  <Col xs={12} md={6} lg={4}
                  style={{display: "flex", alignItems: "center", justifyContent: "center"}}
                  >
                    
                  <select
                          name="roomBedType" 
                          id="roomBedType"
                          className="form-select selectt"
                          onChange={(e) =>
                            setDocumentName( e.target.value )
                          }
                          value={documentName}
                        >
                          <option value={""}>Choose document Name</option>
                          <option value={"Passport"}>Passport</option>
                          <option value={"Driver's license"}>Driver's license</option>
                        </select>

                   
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <button type="submit" className="btnMore mt-5" style={{ width: "100%" }}>
                      Upload document {" "}<FaPenAlt />
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
        <ToastContainer />

        {loading && <Spinner />}
      </div>
    </>
  );
};

export default KYCPage;
