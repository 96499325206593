import React from 'react'
import NavbarMenu from './Layouts/Navbar'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const PrivacyPolicyPage = () => {
  return (
    <div>
              <NavbarMenu style={{ backgroundColor: "#003B95" }} />

              <div>
              <Container className='container'>
              {/* <Row>
                    <Col xs={12} md={12} lg={12} className='mt-5 mb-3' data-aos="flip-right">
                        <h2 className='text-center'>PRIVACY POLICY</h2>
                        <p className='text-center'>Maczuby</p>
                    </Col>
                   
                </Row> */}

                <Row>
                    <Col xs={12} md={12} lg={12} className=''>
                        <div className="about-left" data-aos="flip-up">
                            <div style={{ marginBottom: "50px"}}>

                            <h2>Privacy Policy </h2>
                            <h5>Maczuby </h5>
                            <p>Our website address is: https://maczuby.com</p>
                            </div>


                            <h5 style={{ color: "#003B95", fontWeight: "bold"}}>What personal data we collect and why we collect it</h5>

                            <div className="containerr mt-5 mb-5">
                                <h6 style={{ color: "#003B95", fontWeight: "bold"}}>Comments:</h6>
                                <p style={{ textAlign: "justify" }}>When visitors leave comments on the site we collect the data shown in the comments form, and also the visitor’s IP address and browser user agent string to help spam detection. An anonymized string created from your email address (also called a hash) may be provided to the Gravatar service to see if you are using it. The Gravatar service privacy policy is available here: https://automattic.com/privacy/. After validation of your comment, your profile photo is publicly visible next to your comment.</p>
                            </div>


                            <div className="containerr mt-5 mb-5">
                                <h6 style={{ color: "#003B95", fontWeight: "bold"}}>Media:</h6>
                                <p style={{ textAlign: "justify" }}>If you upload images to the Website, you should avoid uploading images with embedded location data (EXIF GPS) included. Website visitors can download and extract all location data from images on the website.</p>
                            </div>



                            <div className="containerr mt-5 mb-5">
                                <h6 style={{ color: "#003B95", fontWeight: "bold"}}>Cookies:</h6>
                                <p style={{ textAlign: "justify" }}>If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for a year. If you have an account and log in to this site, we will set a temporary cookie to determine whether your browser accepts cookies. This cookie does not contain any personal data and is deleted when you close your browser. When you log in, we will also set up several cookies to save your connection information and your screen display choices. Login cookies last for two days and screen options cookies last for one year. If you select "Remember Me", your connection will persist for two weeks. If you log out of your account, login cookies will be deleted. If you edit or publish an article, an additional cookie will be stored in your browser. This cookie does not contain any personal data and simply indicates the publication ID of the article you have just edited. It expires after 1 day.</p>
                            </div>


                            <div className="containerr mt-5 mb-5">
                                <h6 style={{ color: "#003B95", fontWeight: "bold"}}>Embedded content from other websites:</h6>
                                <p style={{ textAlign: "justify" }}>Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves exactly the same as if the visitor had visited the other website. These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that site Web.</p>
                            </div>


                            <div className="containerr mt-5 mb-5">
                                <h6 style={{ color: "#003B95", fontWeight: "bold"}}>How long do we keep your data:</h6>
                                <p style={{ textAlign: "justify" }}>If you leave a comment, the comment and its metadata are retained indefinitely. This allows us to automatically recognize and approve all follow-up comments instead of holding them in a moderation queue. For users who register on our website (if applicable), we also store the personal information they provide in their user profile. All users can view, edit or delete their personal information at any time (except they cannot change their username). Website administrators can also view and edit this information.</p>
                            </div>


                            <div className="containerr mt-5 mb-5">
                                <h6 style={{ color: "#003B95", fontWeight: "bold"}}>What rights do you have over your data:</h6>
                                <p style={{ textAlign: "justify" }}>If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also ask us to erase any personal data we hold about you. This does not include data that we are obliged to retain for administrative, legal or security purposes.</p>
                            </div>



                            <div className="containerr mt-5 mb-5">
                                <h6 style={{ color: "#003B95", fontWeight: "bold"}}>Where we send your data:</h6>
                                <p style={{ textAlign: "justify" }}>Visitor comments may be checked by an automated spam detection service.</p>
                            </div>


                            <div className="containerr mt-5 mb-5">
                                <h6 style={{ color: "#003B95", fontWeight: "bold"}}>How we provide services for you:</h6>
                                <p style={{ textAlign: "justify", margin: 0}}>Our engineers are very dedicated to work,</p>
                                <p style={{ textAlign: "justify", padding: 0, margin: 0 }}>Our company always offers the best services,</p>
                                <p style={{ textAlign: "justify", margin: 0 }}>We always offer quality services,</p>
                                <p style={{ textAlign: "justify", margin: 0 }}>We have the ability to provide online service.</p>
                            </div>
                            
                            
                        </div>
                        
                    </Col>
                   
                </Row>
              </Container>
              </div>

    </div>
  )
}

export default PrivacyPolicyPage