import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GasMeterOutlined } from '@mui/icons-material';
import { HiUsers } from 'react-icons/hi2';
import { FaDollarSign, FaGasPump } from 'react-icons/fa';

const Cars = (props) => {
  return (
    <div>
        <Row className='cars mt-5'>
            <Col xs={12} md={4}>
                {/* Car Image */}
                <div className="car-image">
                <Link to={'./car-booking/'+props.link}><img src={props.img} alt="" className='img-fluid' /></Link>
                </div>
            </Col>
            <Col xs={12} md={4}>
                {/* Car details */}
                <div className="car-details">
                    <span className='pills'>{props.vehicleClass}</span><span className='pills'>or Similar </span>
                    <p className="car-title">{props.carTitle} <small>{props.status === 0 ? <Badge bg='danger'>Not available</Badge>: <Badge bg='success'>Available</Badge>}</small></p>
                    <span className="boxicons mt-3">
                        <FaGasPump /><br />
                        {props.fuel}
                    </span>
                    <span className="boxicons-middle mt-3">
                        <GasMeterOutlined /><br />
                        {props.transmission}
                    </span>
                    <span className="boxicons mt-3">
                        <HiUsers /><br />
                        {props.persons} Persons
                    </span>
                </div>
            </Col>
            <Col xs={12} md={4}>
                {/* Car price */}
                <div className="car-price mt-5">
                    <small>{props.days} Days</small>
                    <h5>₦{props.amount}</h5>
                    <p>₦{props.daily}/Per day</p>
                    <Link to={'/car-preview/'+props.link}><button className="btn btn-primary">Choose Car</button></Link>
                </div>
            </Col>

        </Row>
    </div>
  )
}

export default Cars