import axios from "axios"
import { toast } from "react-toastify"
import { BaseURL } from "../../config/variables"

const handleError = (err) =>{
    toast.error(err, {
      position: "bottom-left",
    });
  }
  const handleSuccess = (msg) =>{
    toast.success(msg, {
      position: "bottom-right",
    });
  }

export const userLogin = async ( email, password ) => {
    try {

        const response = await axios.post( `${BaseURL}/login`, {email, password},

        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)

        return data
     
    } catch (error ) {

        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 

}

export const reportIssue = async ( payload ) => {
  try {

      const response = await axios.post( `${BaseURL}/report-issue`, payload,

      {headers: {  
          'Accept': 'application/json', 'Content-Type': 'application/json',
      }}, 
      )
      
      const { data } = (response)

      return data
   
  } catch (error ) {

      if(error.response?.data?.message){
          return handleError(error.response?.data?.message) 
      } else {
          return handleError(error.message) 
      }
      
  } 

}

export const reportHostIssue = async ( payload ) => {
    try {
  
        const response = await axios.post( `${BaseURL}/report-host-issue`, payload,
  
        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)
  
        return data
     
    } catch (error ) {
  
        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
  }
