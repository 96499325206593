export function datediff(first, second) {
  let start = new Date(first);
  start.setHours(0, 0, 0, 0); // Sets hours, minutes, seconds, and milliseconds
  let end = new Date(second);
  end.setHours(0, 0, 0, 0);
  let diff = Math.round((end - start) / 86400000) + 1; // See note below re `+ 1`
  return diff; // 3
}

// export function findMatchingDates(datesArray, bookedDatesArray) {
//   return datesArray.filter((date) =>
//     bookedDatesArray.some((bookedDate) => date.toISOString() === bookedDate)
//   );
// }

export function findMatchingDates(datesArray, bookedDatesArray) {
  return datesArray.filter((date) =>
    bookedDatesArray.some((bookedDate) => isSameDay(date, bookedDate))
  );
}

function isSameDay(date1, date2) {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}

export function convertDateStringToArray(dateString) {
  const dateArray = dateString.split(",");

  const resultArray = dateArray.map(dateString => new Date(dateString));
  return resultArray
}


export function convertDateStringArray(dateStringArray) {
  const resultArray = dateStringArray.flatMap(dateString => {
    if (dateString) {
      const dates = dateString.split(",");
      return dates.map(date => new Date(date.trim()));
    }
    return null;
  });

  return resultArray.filter(date => date !== null); // Remove null entries if needed
}


export function convertDatesToStrings(dateArray) {
  return dateArray.map(date => date.toLocaleString("en-US", { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' }));
}


export const calculateNights = (checkInDate, checkInTime, checkOutDate) => {
  const checkInDateTime = new Date(`${checkInDate}T${checkInTime}`);
  const checkOutDateTime = new Date(checkOutDate);

  const checkInMidnight = new Date(
    checkInDateTime.getFullYear(),
    checkInDateTime.getMonth(),
    checkInDateTime.getDate(),
    12, 0, 0
  );

  const diffTime = checkOutDateTime - checkInMidnight;
  const nights = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return nights > 0 ? nights : 0;
};

export const hostDasboardCalculateNights = (checkInDate, checkInTime, checkOutDate) => {
  // Clean the check-in time if it contains TimeOfDay(
  const cleanCheckInTime = checkInTime.includes('TimeOfDay(') ? checkInTime.replace(/TimeOfDay\(|\)/g, '') : checkInTime;

  // Parse the check-in date and time
  const checkInDateTime = new Date(`${checkInDate.split(' ')[0]}T${cleanCheckInTime}`);

  // Parse the check-out date and time
  const checkOutDateTime = new Date(checkOutDate);

  // Create a new Date object representing 12:00 PM of the check-in day
  const checkInNoon = new Date(
    checkInDateTime.getFullYear(),
    checkInDateTime.getMonth(),
    checkInDateTime.getDate(),
    12, 0, 0
  );

  // Calculate the difference in time from check-in noon to check-out time
  const diffTime = checkOutDateTime - checkInNoon;
  const nights = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return nights > 0 ? nights : 0;
};




export function calculatePercentage(number, percentage) {
  return (number * percentage) / 100;
}

export function formatCalendarDate(dateString) {
  if (!dateString) {
    return 'Invalid date';
  }

  const dateParts = dateString.split('-');
  
  if (dateParts.length !== 3) {
    return 'Invalid date';
  }

  const [year, month, day] = dateParts;

  if (isNaN(Date.parse(`${year}-${month}-${day}`))) {
    return 'Invalid date';
  }

  return `${day}/${month}/${year}`;
}
