import React, {useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DashboardOutlined, PaymentOutlined, TrendingUp } from '@mui/icons-material'; 
import { HiOutlineReceiptRefund, HiUser } from 'react-icons/hi2'; 
import { useGetUserID } from '../../Hooks/useGetUserID';
import { FaBell, FaPhoneAlt } from 'react-icons/fa';

const UserNavbar = () => {
  const navigate = useNavigate();  
  const userID = useGetUserID();
  if(!userID){
    navigate("/login");
  }
  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);


  return (
    <div> 
        <div className='list-group mt-5'>
          <Link to={'/user-dashboard'} className="list-group-item list-group-item-action"><DashboardOutlined/> Dashboard</Link>
          <Link to={''} className="list-group-item list-group-item-action"><FaBell /> Notifications</Link>
          <Link to={'/ewallet'} className="list-group-item list-group-item-action"><PaymentOutlined /> E-Wallet</Link>
          <Link to={'/my-bookings'} className="list-group-item list-group-item-action"><TrendingUp /> Bookings &amp; Trips</Link>
          <Link to={'/request-refund'} className="list-group-item list-group-item-action"><HiOutlineReceiptRefund /> Request Refund</Link>
          <Link to={'/user-profile'} className="list-group-item list-group-item-action"><HiUser /> Manage Profile</Link>
          <Link to={'/report-issue'} className="list-group-item list-group-item-action"><FaPhoneAlt /> Report an Issue</Link>
        </div>
    </div>
  )
}

export default UserNavbar