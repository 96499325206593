import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SearchRounded, TrendingUp } from "@mui/icons-material";
import "./ServicesPage.css";
import Services from "./Services";
import NavbarMenu from "./Layouts/Navbar";
import apImg from "../Assets/illustrations/no-data-found.gif";
import axios from "axios";
import { BaseURL } from "../config/variables";
import { handleError } from "../utils/notifications/toasts";
import Spinner from "./Alert/Spinner/Spinner";

const ServicesPage = () => {
  const [countAdult, setAdultCount] = useState(0);
  const [countChildren, setChildrenCount] = useState(0);
  const [loading, setLoading] = useState(false)

  const subtractAdult = () => {
    if (countAdult === 0) {
      setAdultCount(countAdult + 1);
    } else {
      setAdultCount(countAdult - 1);
    }
  };

  const addAdult = () => {
    setAdultCount(countAdult + 1);
  };

  const handleAdultCount = (e) => {
    setAdultCount(e.target.value);
  };

  const subChildren = () => {
    if (countChildren === 0) {
      setChildrenCount(countChildren + 1);
    } else {
      setChildrenCount(countChildren - 1);
    }
  };

  const addChildren = () => {
    setChildrenCount(countChildren + 1);
  };

  const handleChildrenCount = (e) => {
    setChildrenCount(e.target.value);
  };

  const [data, setData] = useState([]);
  const [msg, setMsg] = useState("");

  const [filteredData, setFilteredData] = useState([]);
  
  const handleCity = (e) => {
    // e.preventDefault();
    const searchCity = e.target.value;

    if (searchCity.length > 1) {
      const newFilter = data.filter((value) => {
        return (
          value.country.toLowerCase().includes(searchCity.toLowerCase()) ||
          value.stateProvince.toLowerCase().includes(searchCity.toLowerCase())
        );
      });

      setFilteredData(newFilter);

    } else if (searchCity.length === 0) {
      setFilteredData(data);
    }
  }
  
  const handleCategory = (e) => {
    // e.preventDefault();
    const searchCategory = e.target.value;

    if (searchCategory.length > 1) {
      const newFilter = data.filter((value) => {
        return (
          value.serviceType === searchCategory
        );
      });

      setFilteredData(newFilter);

    } else if (searchCategory.length === 0) {
      setFilteredData(data);
    }
  }
  
  const handlePrice = (e) => {
    // e.preventDefault();
    const searchPrice = e.target.value;

    if (searchPrice > 0) {
      const newFilter = data.filter((value) => {
        return (
          Number(value.price) <= Number(searchPrice)
        );
      });

      setFilteredData(newFilter);

    } else if (searchPrice.length === 0) {
      setFilteredData(data);
    }
  }

  const fetchToursList = async ()=>{
    setLoading(true)
    
    try {
      const res = await axios.get(`${BaseURL}/servicelist/`)
  
      if (res.data.status === "Success") {
        setData(res.data.Result);
        setFilteredData(res.data.Result); 

        setLoading(false)
      } else {
        setMsg("No service centers found");
        setLoading(false)
      }
      
    } catch (error) {
      setLoading(false)
      handleError("Could not get all list")
    }
  }

  useEffect(() => {
    // scroll to top on page load

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

      fetchToursList()
  }, []);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />

      <div className="ServicesPage">
        <Container>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <h2>
                Other Services <TrendingUp />
              </h2>
            </Col>
            <Col xs={12} md={12} lg={4}>
              
              <div className="search-panel">
                <p>WHAT ARE YOU TRAVELLING FOR?</p>
                <form>
                  {/* <label htmlFor="travelPurpose">
                    <input
                      type="checkbox"
                      name="travelPurpose"
                      id="travelPurpose"
                    />{" "}
                    Business{" "}
                  </label>{" "}
                  <label htmlFor="travelPurpose">
                    {" "}
                    <input
                      type="checkbox"
                      name="travelPurpose"
                      id="travelPurpose"
                    />{" "}
                    Leisure
                  </label>
                  <br></br> */}
                  <Row>
                    <Col xs={12} md={12} lg={12}> 
                      <label htmlFor="location">Location:</label>
                      <input
                        type="text"
                        placeholder="Where?"
                        className="form-control mb-3"
                        onChange={handleCity}

                      />
                    </Col>
                    <Col xs={12} md={12} lg={12}> 
                      <label htmlFor="category">Category:</label>
                      <select
                        className="form-select mb-3"
                        onChange={handleCategory}
                      >
                        <option value={""}>Choose Service type</option>
                        <option value={"SPA"}>SPA</option>
                        <option value={"Photography"}>Photography</option>
                        <option value={"Gym Trainer"}>Gym Trainer</option>
                        <option value={"Private Tutor"}>Private Tutor</option>
                        <option value={"Life Coach"}>Life Coach</option>
                        <option value={"Assistant"}>Assistant</option>
                        <option value={"Companion"}>Companion</option>
                        <option value={"Other"}>Other</option>
                      </select>
                    </Col>
                    <Col xs={12} md={12} lg={12}>
                      <label htmlFor="price">Price:</label>
                      <input
                        type="number"
                        id="price"
                        placeholder="Amount"
                        className="form-control mb-3"
                        onChange={handlePrice} 
                      />
                    </Col>
                    {/* <Col xs={12} md={12} lg={12}> 
                      <label htmlFor="checkin">Check-in Date:</label>
                      <input
                        type="date"
                        placeholder="From?"
                        className="form-control mb-3"
                      />
                    </Col>
                    <Col xs={12} md={12} lg={12}> 
                      <label htmlFor="checkout">Check-out Date:</label>
                      <input
                        type="date"
                        placeholder="to?"
                        className="form-control mb-3"
                      />
                    </Col>

                    <Col xs={12} md={12} lg={12}> 
                      <div className="number_ct">
                        <label htmlFor="guests">Adult(s)&nbsp;</label>
                        <div className="number">
                          <span className="minus" onClick={subtractAdult}>
                            -
                          </span>
                          <input
                            type="text"
                            value={countAdult}
                            onChange={handleAdultCount}
                          />
                          <span className="plus" onClick={addAdult}>
                            +
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={12} lg={12}> 
                      <div className="number_ct">
                        <label htmlFor="children">Children</label>
                        <div className="number">
                          <span className="minus" onClick={subChildren}>
                            -
                          </span>
                          <input
                            type="text"
                            value={countChildren}
                            onChange={handleChildrenCount}
                          />
                          <span className="plus" onClick={addChildren}>
                            +
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={12}>
                      <button
                        type="submit"
                        onClick={handleFilter}
                        className="btnMore"
                        style={{ marginTop: "40px" }}
                      >
                        Find Deals <SearchRounded />
                      </button>
                    </Col> */}
                  </Row>
                </form>
              </div>
            </Col>
            <Col xs={12} md={8}>
              <Row>
                {filteredData.length > 0 ? (
                  filteredData.map((service, index) => {
                    return (
                      <Col xs={12} md={6} lg={6} key={index}>
                        <Services
                          src={
                           
                            service.servicePic1
                          }
                          alt={service.serviceName}
                          location={
                            service.stateProvince + "/" + service.country
                          }
                          price={numberWithCommas(service.price) + "/ticket"}
                          caption={service.serviceName}
                          link={service.serviceID}
                          hashtag={service.serviceType}
                        />
                      </Col>
                    );
                  })
                ) : (
                  <div className="msgBox">
                    <img src={apImg} alt="no data found" />
                  </div>
                )}
              </Row>
            </Col>
          </Row>
        </Container>

        { loading && <Spinner />}
      </div>
    </>
  );
};

export default ServicesPage;
