

import axios from "axios"
import { toast } from "react-toastify"
import { BaseURL } from "../../config/variables"


export const limit = 10
export const limit3 = 3
export const totalPages = 100

const handleError = (err) =>{
    toast.error(err, {
      position: "bottom-left",
    });
  }


export const getUserReports = async ( page, limit) => {
  try {

      const response = await axios.get( `${BaseURL}/reports?page=${page}&limit=${limit}`,

      {headers: {  
          'Accept': 'application/json', 'Content-Type': 'application/json',
      }}, 
      )
      
      const { data } = (response)

      return data
   
  } catch (error ) {

      if(error.response?.data?.message){
          return handleError(error.response?.data?.message) 
      } else {
          return handleError(error.message) 
      }
      
  } 

}


export const getUserReportById = async ( id ) => {
  try {

      const response = await axios.get( `${BaseURL}/report/${id}`,

      {headers: {  
          'Accept': 'application/json', 'Content-Type': 'application/json',
      }}, 
      )
      
      const { data } = (response)

      return data
   
  } catch (error ) {

      if(error.response?.data?.message){
          return handleError(error.response?.data?.message) 
      } else {
          return handleError(error.message) 
      }
      
  } 

}

export const getAllHosts = async ( page, limit) => {
    try {
  
        const response = await axios.get( `${BaseURL}/hostlist?page=${page}&limit=${limit}`,
  
        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)
  
        return data
     
    } catch (error ) {
  
        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
  }



export const getAllUsers = async ( page, limit) => {
    try {
  
        const response = await axios.get( `${BaseURL}/userslist?page=${page}&limit=${limit}`,
  
        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)
  
        return data
     
    } catch (error ) {
  
        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
}



export const getAllPaystackTransactions = async ( page, limit ) => {
    try {
  
        const response = await axios.get( `${BaseURL}/transactions?page=${page}&limit=${limit}`,
  
        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)
  
        return data
     
    } catch (error ) {
  
        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
}


export const getAllUserRefundRequests = async ( page, limit) => {
    try {
  
        const response = await axios.get( `${BaseURL}/refund-requests?page=${page}&limit=${limit}`,
  
        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)
  
        return data
     
    } catch (error ) {
  
        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
}

export const getAllPayoutTransactionHistory = async ( page, limit) => {
    try {
  
        const response = await axios.get( `${BaseURL}/payout-history-transactions?page=${page}&limit=${limit}`,
  
        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)
  
        return data
     
    } catch (error ) {
  
        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
}


export const getHostAccountHistory = async ( hostID ) => {
    try {
  
        const response = await axios.get( `${BaseURL}/host-account-history/${hostID}`,
  
        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)
  
        return data
     
    } catch (error ) {
  
        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
}

export const payHost = async ( payload, hostID) => {
    try {
  
        const response = await axios.patch( `${BaseURL}/host-account-history/${hostID}`,
        payload,
        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)
  
        return data
     
    } catch (error ) {
  
        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
}

export const approveOrBanHost = async ( payload, hostID) => {
    try {
  
        const response = await axios.post( `${BaseURL}/approve-ban-host/${hostID}`,
        payload,
        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)
  
        return data
     
    } catch (error ) {
  
        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
}

export const getAllHostReports = async ( page, limit) => {
    try {
  
        const response = await axios.get( `${BaseURL}/host-reports?page=${page}&limit=${limit}`,
  
        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)
  
        return data
     
    } catch (error ) {
  
        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 
  
  }