import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaArrowRight, FaPenAlt } from "react-icons/fa";
import "./ListAccommodation.css";
import HostNavbar from "./HostNavbar";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BaseURL } from "../../config/variables";
import Spinner from "../Alert/Spinner/Spinner";

const ListAccommodation01Edit = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const currentHostID = window.localStorage.getItem("hostID");

  const [data, setData] = useState({
    hostID: currentHostID,
  });
  const [loading, setLoading ] = useState(false)

 
  useEffect(() => {
    // scroll to top on page load

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    axios
      .get(`${BaseURL}/fetchpropertydetails/${id}`)
      .then((res) => {
        if (res.data.status === "Success") {
          setData({...data, 
            propertyName:res.data.Result[0].propertyName,
            propertyType:res.data.Result[0].propertyType,
            city:res.data.Result[0].city,
            zipCode:res.data.Result[0].zipCode,
            stateProvince:res.data.Result[0].stateProvince,
            country:res.data.Result[0].country,
            streetAddress:res.data.Result[0].streetAddress,
            aboutProperty:res.data.Result[0].aboutProperty,
            hostName:res.data.Result[0].hostName,
            aboutHost:res.data.Result[0].aboutHost,
            phoneNumber:res.data.Result[0].phoneNumber,
            aboutNeighborhood:res.data.Result[0].aboutNeighborhood,
          });
          //console.log(res.data.Result[0]);
        } else {
          console.log("No properties found"); 
        }
      })
      .catch((err) => console.log(err));
  }, []); 

  
    const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
    const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

    const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(data)
    axios
      .put(`${BaseURL}/${id}`, data)
      .then((res) => {
        if (res.data.success) {
          const { success, message } = res.data;

          if (success) {
            handleSuccess(message);
            setTimeout(() => {
              navigate("/list-accommodation");
            }, 1000);
          } else {
            handleError(message);
          }
        }
      })
      .catch((err) => console.log(err));
    }


  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <ToastContainer />

      <div className="ListAccommodation">
        <Container>
          <Row>
            <Col xs={12} md={3} lg={3}>
              <HostNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <form className="form-panel" onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <h2>Edit Your Property</h2>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <label htmlFor="propertyName">Property Name:</label>
                    <input
                      type="text"
                      name="propertyName"
                      id="propertyName"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, propertyName: e.target.value })
                      }
                      value={data.propertyName || ''}
                    />

                    <label htmlFor="propertyType">Type of Property: </label>
                    <select
                      name="propertyType"
                      id="propertyType"
                      className="form-select selectt"
                      onChange={(e) =>
                        setData({ ...data, propertyType: e.target.value })
                      }
                      value={data.propertyType || ''}
                    >
                      <option value={""}>Choose type</option>
                      <option value={"Hotel"}>Hotel</option>
                      <option value={"Villa"}>Villa</option>
                      <option value={"Apartment"}>Apartment</option>
                      <option value={"Guest House"}>Guest House</option>
                      <option value={"Hostel"}>Hostel</option>
                      <option value={"Other"}>Other</option>
                    </select>

                    <label htmlFor="city">City</label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, city: e.target.value })
                      }
                      value={data.city || ''}
                    />

                    <label htmlFor="zipCode">Zip Code</label>
                    <input
                      type="text"
                      name="zipCode"
                      id="zipCode"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, zipCode: e.target.value })
                      }
                      value={data.zipCode || ''}
                    />

                    <label htmlFor="stateProvince">State/Province</label>
                    <input
                      type="text"
                      name="stateProvince"
                      id="stateProvince"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, stateProvince: e.target.value })
                      }
                      value={data.stateProvince || ''}
                    />

                    <label htmlFor="country">Country</label>
                    <input
                      type="text"
                      name="country"
                      id="country"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, country: e.target.value })
                      }
                      value={data.country || ''}
                    />

                    <label htmlFor="streetAddress">Street Address</label>
                    <input
                      type="text"
                      name="streetAddress"
                      id="streetAddress"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, streetAddress: e.target.value })
                      }
                      value={data.streetAddress || ''}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <label htmlFor="aboutProperty">About Property</label><br />
                    <small><b>What should visitors expect?</b></small>
                    <textarea
                      name="aboutProperty"
                      id="aboutProperty"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, aboutProperty: e.target.value })
                      }
                      value={data.aboutProperty || ''}
                    ></textarea>

                    <label htmlFor="hostName">Host Name</label>
                    <input
                      type="text"
                      name="hostName"
                      id="hostName"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, hostName: e.target.value })
                      }
                      value={data.hostName || ''}
                    />

                    <label htmlFor="aboutHost">About Host</label>
                    <textarea
                      name="aboutHost"
                      id="aboutHost"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, aboutHost: e.target.value })
                      }
                      value={data.aboutHost || ''}
                    ></textarea>

                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, phoneNumber: e.target.value })
                      }
                      value={data.phoneNumber || ''}
                    />

                    <label htmlFor="aboutNeighborhood">
                      About The Neighborhood
                    </label>
                    <textarea
                      name="aboutNeighborhood"
                      id="aboutNeighborhood"
                      className="form-control"
                      onChange={(e) =>
                        setData({ ...data, aboutNeighborhood: e.target.value })
                      }
                      value={data.aboutNeighborhood  || ''}
                    ></textarea>
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <button type="submit" className="btnOutline mt-5">
                      Edit Property <FaPenAlt />
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>

        { loading && <Spinner />}

      </div>
    </>
  );
};

export default ListAccommodation01Edit;
