import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./PaymentPage.css";
import { PaymentOutlined } from "@mui/icons-material";
import { FaCheckCircle } from "react-icons/fa";
import NavbarMenu from "./Layouts/Navbar";
import {
  useParams,
  useSearchParams,
  Link,
  useNavigate,
} from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useGetUserID } from "../Hooks/useGetUserID";
import Spinner from "./Alert/Spinner/Spinner";
import {
  paystackPayment,
  verifypaystackPayment,
} from "../utils/userActions/paymentActions";
import { BaseURL, clientURL } from "../config/variables";
import { datediff } from "../utils/utilities";

const PaymentPage = () => {
  const userID = useGetUserID();
  const navigate = useNavigate();

  //   const { id } = useParams();
  const [query] = useSearchParams();
  // const itemBooked = query.get("itemBooked");
  const amount = query.get("amount");
  const id = query.get("itemID");
  const itemType = query.get("itemType");
  const duration = query.get("duration");
  const unitPrice = query.get("unitPrice");
  const checkInDate = query.get("checkInDate");
  const checkInTime = query.get("checkInTime");
  const checkOutDate = query.get("checkOutDate");
  const checkOutTime = query.get("checkOutTime");
  const bookingFor = query.get("bookingFor");
  const tickets = query.get("tickets");
  const hostID = query.get("hostID");
  const bookedDates = query.get("bookedDates");
  let ticketValue;

  if (tickets < 1) {
    ticketValue = 0;
  } else {
    ticketValue = tickets;
  }

  const [inputValue, setInputValue] = useState({
    itemID: id,
    hostID,
    userID,
    itemType,
    itemUnitPrice: unitPrice,
    amountPaid: amount,
    bookingFor,
    checkInDate,
    checkInTime,
    checkOutDate,
    checkOutTime,
    duration,
    tickets: ticketValue,
    bookedDates,
  });

  const [loading, setLoading] = useState(false);
  const [runBooking, setRunBooking] = useState("");

  // console.log(inputValue);

  // function numberWithCommas(x) {
  //   return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  // }

  // if (itemType) {
  //   alert(itemType);
  // }

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

  const handleSubmit = async () => {
    // const bookingOrder =  JSON.parse(localStorage.getItem('bookingOrder') || "{}");

    // e.preventDefault();
    try {
      if (localStorage.getItem("bookingOrder") !== null) {
        const bookingOrder = JSON.parse(localStorage.getItem("bookingOrder"));
        // console.log({ bookingOrder });

        const { data } = await axios.post(`${BaseURL}/booking`, {
          paymentStatus: "1",
          ...bookingOrder,
        });

        const { success, message } = data;
        // console.log({data});
        if (success) {
          handleSuccess(message);
          localStorage.removeItem("bookingOrder");
          localStorage.removeItem("bookingDetails");
          navigate("/booking-success");
          setLoading(false);
        } else {
          handleError(message);
          setLoading(false);
        }
      } else {
        return handleError("No booking order!");
      }
    } catch (error) {
      handleError("something went wrong with booking!");
      setLoading(false);
    }
    setInputValue({
      ...inputValue,
    });
  };

  const handlepaystackPayment = async () => {
    const transId =
      localStorage.getItem("transId") === null
        ? ""
        : localStorage.getItem("transId");

    if (localStorage.getItem("transId") === null) {
      localStorage.setItem("bookingOrder", JSON.stringify(inputValue));
      makePayment();
    } else {
      verifyPayment(transId);
    }
  };

  const makePayment = async () => {
    const emailAddress = localStorage.getItem("emailAddress");
    setLoading(true);
    // setLoading(false)

    const payload = {
      amount: inputValue.amountPaid,
      emailAddress: `${emailAddress}`,
      callbackUrl: `${clientURL}/pay-now`,
    };

    // console.log(payload)

    const response = await paystackPayment(payload);

    if (response?.status) {
      if (response?.data?.authorization_url) {
        localStorage.setItem("transId", response?.data?.reference);
        setLoading(false);
        // console.log(response)
        window.location.href = response?.data?.authorization_url;
      }
    } else {
      handleError("Could not call up Paystack");
      setLoading(false);
    }
  };

  const verifyPayment = async (transId) => {
    // console.log("Verify Payment URL Called");
    setLoading(true);

    const response = await verifypaystackPayment(transId);

    if (response?.status) {
      if (response?.data?.status === "success") {
        handleSuccess("payment successful");
        localStorage.removeItem("transId");
        await localStorage.removeItem("paymentMethod");

        await handleSubmit();
        // console.log(response)
      } else {
        await localStorage.removeItem("transId");
        await localStorage.removeItem("paymentMethod");
        handleError(response?.data?.gateway_response);
        await navigate("/");
      }
    } else {
      await localStorage.removeItem("transId");
      handleError("Could not verify payment");
      setLoading(false);
      await navigate("/");
    }
  };

  const handlePayWithWallet = async () => {
    try {
      setLoading(true);
      localStorage.setItem("bookingOrder", JSON.stringify(inputValue));

      const description = ` Payment for booking ${itemType} on Maczuby.`;

      const { data } = await axios.patch(`${BaseURL}/wallet/pay/${userID}`, {
        amount,
        description,
      });

      const { success, message } = data;

      if (success) {
        handleSuccess(message);
        await localStorage.removeItem("paymentMethod");
        await handleSubmit();
      } else {
        await localStorage.removeItem("paymentMethod");
        handleError(message);
        setLoading(false);
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }
    } catch (error) {
      handleError("something went wrong with payment!");
      setLoading(false);
    }
  };

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    const paymentMethod =
      localStorage.getItem("paymentMethod") === null
        ? ""
        : localStorage.getItem("paymentMethod");

    if (paymentMethod === "wallet") {
      handlePayWithWallet();
    } else {
      handlepaystackPayment();
    }
  }, []);

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />

      <div className="PaymentPage">
        <Container className="container">
          <ToastContainer />

          <Col xs="12" md={6} className="payment-panel">
            <Row>
              <Col xs={12} md={12}>
                <h2>
                  {/* <PaymentOutlined />  */}
                  Processing...
                </h2>
              </Col>
              {/* <Col xs={12} md={12}>
               

                <div className="paybox">
                  <p className="lead">
                    Pay 
                    to Maczuby.com
                  </p>
                </div>
              </Col>
              <Col xs={12} md={12}>
                <form >
                   
                  <input
                    type="text"
                    name="cardHolderName"
                    id="cardHolderName"
                    placeholder="Card Holder Name"
                    className="form-control mt-3"
                    required
                  />
                  <input
                    type="text"
                    name="cardNumber"
                    id="cardNumber"
                    placeholder="Card Number"
                    className="form-control mt-3"
                    required
                  />
                  <Row>
                    <Col xs={12} md={6}>
                      <input
                        type="date"
                        name="expiryDate"
                        id="expiryDate"
                        placeholder="Expiry Date"
                        className="form-control mt-3"
                        required
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <input
                        type="number"
                        name="cvvNumber"
                        id="cvvNumber"
                        placeholder="CVV Number"
                        className="form-control mt-3"
                        required
                      />
                    </Col>
                  </Row>
                  <button type="submit" className="btnMore mt-5 mb-3">
                    Pay <FaCheckCircle />
                  </button>
                </form>
              </Col> */}
            </Row>
          </Col>
        </Container>

        {loading && <Spinner />}
      </div>
    </>
  );
};

export default PaymentPage;
