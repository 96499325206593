

import axios from "axios"
import { toast } from "react-toastify"
import { BaseURL } from "../../config/variables"

const handleError = (err) =>{
    toast.error(err, {
      position: "bottom-left",
    });
  }

export const paystackPayment = async ( payload ) => {
    try {

        const response = await axios.post( `${BaseURL}/transaction/initialize`, { ...payload },

        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)

        return data
     
    } catch (error ) {

        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 

}

export const verifypaystackPayment = async ( transId ) => {
    try {

        const response = await axios.get( `${BaseURL}/transaction/verify/${transId}`,

        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)

        return data
     
    } catch (error ) {

        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 

}


export const getUserWallet = async ( userId ) => {
    try {

        const response = await axios.get( `${BaseURL}/wallet/${userId}`,
        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)

        return data
     
    } catch (error ) {

        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 

}
export const fundUserWallet = async ( userId, amount ) => {
    try {

        const response = await axios.patch( `${BaseURL}/wallet/fund/${userId}`,
        {
            amount
        },

        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)

        return data
     
    } catch (error ) {

        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 

}

export const getUserWalletTransactions = async ( userId, page, limit) => {
    try {

        const response = await axios.get( `${BaseURL}/wallet/transactions/${userId}?page=${page}&limit=${limit}`,

        {headers: {  
            'Accept': 'application/json', 'Content-Type': 'application/json',
        }}, 
        )
        
        const { data } = (response)

        return data
     
    } catch (error ) {

        if(error.response?.data?.message){
            return handleError(error.response?.data?.message) 
        } else {
            return handleError(error.message) 
        }
        
    } 

}