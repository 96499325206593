import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './calender.css';



const Calender = () => {

    const availableDates = [
        new Date('2023-03-10'),
        new Date('2023-03-15'),
        new Date('2023-03-20'),
        new Date('2023-03-25'),
      ];
    
      const [selectedDate, setSelectedDate] = useState(new Date());
      const [name, setName] = useState('');

//     const [availableDates, setAvailableDates] = useState([new Date("04/05/2024")]);
//   const [selectedDate, setSelectedDate] = useState(new Date());
//   const [name, setName] = useState('');


  useEffect(() => {
    // Fetch available dates from the server
    // const fetchAvailableDates = async () => {
    //   const response = await axios.get('http://localhost:5000/api/available-dates');
    //   setAvailableDates(response.data.availableDates.map((date) => new Date(date)));
    // };

    // fetchAvailableDates();
  }, []);


  const handleDateChange = (date) => {
    setSelectedDate(date);
  };


  const handleBooking = async () => {
    if (!name || !selectedDate) {
      alert('Please provide your name and select a date.');
      return;
    }

    // Book appointment on the server
    try {
      await axios.post('http://localhost:5000/api/book-appointment', {
        date: selectedDate.toISOString().split('T')[0],
        name,
      });
      alert('Appointment booked successfully!');
      // Optionally, you can refresh the available dates after booking
      // setAvailableDates([...]);
    } catch (error) {
      alert('Error booking appointment. Please try again.');
    }
  };


  return (
    <div className="modal fade" id="calenderModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
        <div className="modal-header">
            
        </div>
        <div className="modal-body">
       
        <h1>Calendly Clone</h1>
      <div className="calendar-container">
        <Calendar onChange={handleDateChange} value={selectedDate} tileDisabled={({ activeStartDate, date }) => !availableDates.some(availableDate => date.toISOString() === availableDate.toISOString())} />
      </div>
      <div className="booking-form">
        <h2>Book your appointment:</h2>
        <label htmlFor="name">Your Name:</label>
        <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />
        <button onClick={handleBooking}>Book Appointment</button>
      </div>
        
        
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" >Proceed</button>
        </div>
        </div>
    </div>
    </div>
  )
}

export default Calender