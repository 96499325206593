import React from 'react' 
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Login.css'
import { FaSignInAlt } from 'react-icons/fa';
import NavbarMenu from '../Layouts/Navbar'; 

const HostReg2 = () => {
  return ( 
    <>
        <NavbarMenu  style={{backgroundColor: '#003B95' }} />
        
        <div className='LoginPage'>
            <Container className='container'>
              <Row className='justify-items-center'>
                <Col xs={12} md={12}>
                  <div className="login-panel">
                    <h3>Create password</h3>
                    <p>Use a minimum of 8 characters, including uppercase, lower case letters, numbers and special characters.</p>
                    <form action='./host-dashboard'>
                      <label htmlFor="password">Password</label>
                      <input type="password" name="" id="" className='form-control mb-3' />
                      <label htmlFor="password">Confirm Password</label>
                      <input type="password" name="" id="" className='form-control mb-3' />

                      <button className='btnMore' style={{padding: "20px 30px", width: "100%"}}>Create New Account <FaSignInAlt/></button>
                    </form>
                    <div className='policy'>
                      <p>
                        By sign in or creating an account you agree with our &nbsp;
                        <Link to="/">Terms & Conditions</Link> and <Link to="/">Privacy Policy</Link>
                      </p> 
                    </div>
                  
                  </div>
                </Col>
              </Row>
            </Container>
        </div>
    </>
  )
}

export default HostReg2