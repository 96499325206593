import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./MyListings.css";
import HostNavbar from "./HostNavbar";
import { Table, Badge } from "react-bootstrap";
import { FaCheckCircle, FaEye, FaTrash } from "react-icons/fa";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { CancelOutlined } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { BaseURL } from "../../config/variables";
import { limit, totalPages } from "../../utils/ownerActions/ownerRequests";
import { getBookedAccommodation, getBookedFlights, getBookedServices, getBookedTours, getBookedVehicles } from "../../utils/hostActions/hostRequests";
import Spinner from "../Alert/Spinner/Spinner";
import { calculateNights, hostDasboardCalculateNights } from "../../utils/utilities";

const MyListings = () => {
  // Show all bookings for this host
  const hostID = window.localStorage.getItem("hostID");
  const [data, setData] = useState([]);
  const [flightData, setFlightData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [tourData, setTourData] = useState([]);
  const [msg, setMsg] = useState("");
  const [inputValue, setInputValue] = useState(0);
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  const [currentAPage, setCurrentAPage] = useState(1)
  const [currentVPage, setCurrentVPage] = useState(1)
  const [currentFPage, setCurrentFPage] = useState(1)
  const [currentTPage, setCurrentTPage] = useState(1)
  const [currentSPage, setCurrentSPage] = useState(1)

  const [startIndex, setstartIndex] = useState(0)
 const [startAIndex, setstartAIndex] = useState(0) 
  const [startVIndex, setstartVIndex] = useState(0)
  const [startFIndex, setstartFIndex] = useState(0)
  const [startTIndex, setstartTIndex] = useState(0)
  const [startSIndex, setstartSIndex] = useState(0)

  const [accomTotalAmount, setAccomTotalAmount] = useState(0)
  const [flightTotalAmount, setFlightTotalAmount] = useState(0)
  const [vehicleTotalAmount, setVehicleTotalAmount] = useState(0)
  const [tourTotalAmount, setTourTotalAmount] = useState(0)
  const [servicesTotalAmount, setServicesTotalAmount] = useState(0)
  



  const fetchAllBookedItems = async()=>{
      setLoading(true);
      setstartIndex((currentPage - 1) * limit)
      const accomm = await getBookedAccommodation(hostID, currentPage, limit)
      const flights = await getBookedFlights(hostID, currentPage, limit)
      const vehicles = await getBookedVehicles(hostID, currentPage, limit)
      const tours = await getBookedTours(hostID, currentPage, limit)
      const services = await getBookedServices(hostID, currentPage, limit)
      
      if (accomm?.status) {
        setData(accomm.accommodations)
          // setLoading(false);
        
      } else {
        handleError("Could not get accommodation lists.");
        // setLoading(false);
      }

      if (flights?.status) {
        setFlightData(flights.flights)
          // setLoading(false);
        
      } else {
        handleError("Could not get flight lists.");
        // setLoading(false);
      }

      if (vehicles?.status) {
        setVehicleData(vehicles.vehicles)
          // setLoading(false);
        
      } else {
        handleError("Could not get vehicles lists.");
        // setLoading(false);
      }

      if (tours?.status) {
        setTourData(tours.tours)
          // setLoading(false);
        
      } else {
        handleError("Could not get tours lists.");
        // setLoading(false);
      }

      if (services?.status) {
        setServiceData(services.services)
          setLoading(false);
        
      } else {
        handleError("Could not get services lists.");
        setLoading(false);
      }
  }


  const fetchAccomm = async()=>{
    setLoading(true);
    setstartAIndex((currentAPage - 1) * limit)
    const accomm = await getBookedAccommodation(hostID, currentAPage, limit)
    if (accomm?.status) {
        const data = accomm.accommodations
        setData(accomm.accommodations)
        console.log(accomm.accommodations) 

        const totalAmount = data.reduce((sum, item) => sum + Number(item.amountPaid), 0)
        setAccomTotalAmount(totalAmount)

        setLoading(false);
        // console.log({accomm})
    
    } else {
    handleError("Could not get accommodation lists.");
    setLoading(false);
    }

}

const fetchVehicles = async()=>{
    setLoading(true);
    setstartVIndex((currentAPage - 1) * limit)
    const vehicles = await await  getBookedVehicles(hostID, currentVPage, limit)
    if (vehicles?.status) {
      // console.log({ vehicles })
        const data = vehicles.vehicles
        setVehicleData(vehicles.vehicles)
        const totalAmount = data?.reduce((sum, item) => sum + Number(item.amountPaid), 0)
        setVehicleTotalAmount(totalAmount)
        setLoading(false);
        
    } else {
    handleError("Could not get vehicles lists.");
    setLoading(false);  
    }

    
}
const fetchFlights = async()=>{
    setLoading(true);        
    setstartFIndex((currentAPage - 1) * limit)
    const flights = await getBookedFlights(hostID, currentFPage, limit)
    if (flights?.status) {
      // console.log({ flights })
        const data = flights.flights
        setFlightData(flights.flights)
        const totalAmount = data?.reduce((sum, item) => sum + Number(item.amountPaid), 0)
        setFlightTotalAmount(totalAmount)
            setLoading(false);
        
        } else {
        handleError("Could not get flight lists.");
        setLoading(false);
    }


}
const fetchTours = async()=>{
    setLoading(true);
    setstartTIndex((currentAPage - 1) * limit)
    const tours = await getBookedTours(hostID, currentTPage, limit)
    if (tours?.status) {
        // console.log({ tours })
        const data = tours.tours
        setTourData(tours.tours)
        const totalAmount = data?.reduce((sum, item) => sum + Number(item.amountPaid), 0)
        setTourTotalAmount(totalAmount)
            setLoading(false);
        
        } else {
        handleError("Could not get tours lists.");
        setLoading(false);
        }


}
const fetchServices = async()=>{
    setLoading(true);
    setstartSIndex((currentAPage - 1) * limit)
    const services = await getBookedServices(hostID, currentSPage, limit)
    if (services?.status) {
      // console.log({ services })
        const data = services.services
        setServiceData(services.services)
        const totalAmount = data?.reduce((sum, item) => sum + Number(item.amountPaid), 0)
        setServicesTotalAmount(totalAmount)
            setLoading(false);
        
        } else {
        handleError("Could not get services lists.");
        setLoading(false);
        }


}




const handlePrevious = (text) =>{
  if(text === "a"){
      currentAPage > 1 ? setCurrentAPage(currentAPage - 1) : setCurrentAPage(currentAPage);
      
  } else if(text === "v"){
      currentVPage > 1 ? setCurrentVPage(currentVPage - 1) : setCurrentVPage(currentVPage);
      
  } else if(text === "f"){
      currentFPage > 1 ? setCurrentFPage(currentFPage - 1) : setCurrentFPage(currentFPage);
      
  } else if(text === "t"){
      currentTPage > 1 ? setCurrentTPage(currentTPage - 1) : setCurrentTPage(currentTPage);
      
  } else if(text === "s"){
      currentSPage > 1 ? setCurrentSPage(currentSPage - 1) : setCurrentSPage(currentSPage);

  } 
  
}

const handleNextPage = (text) =>{
  if(text === "a"){
      currentAPage < totalPages ? setCurrentAPage(currentAPage + 1) : setCurrentAPage(currentAPage);
      
  } else if(text === "v"){
      currentVPage < totalPages ? setCurrentVPage(currentVPage + 1) : setCurrentVPage(currentVPage);
      
  } else if(text === "f"){
      currentFPage < totalPages ? setCurrentFPage(currentFPage + 1) : setCurrentFPage(currentFPage);
      
  } else if(text === "t"){
      currentTPage < totalPages ? setCurrentTPage(currentTPage + 1) : setCurrentTPage(currentTPage);
      
  } else if(text === "s"){
      currentSPage < totalPages ? setCurrentSPage(currentSPage + 1) : setCurrentSPage(currentSPage);

  } 
  
}

const goToPage = (number, text) =>{
  if(text === "a"){
      setCurrentAPage(number) 
      
  } else if(text === "v"){
      setCurrentVPage(number) 
      
  } else if(text === "f"){
      setCurrentFPage(number) 
      
  } else if(text === "t"){
      setCurrentTPage(number) 
      
  } else if(text === "s"){
      setCurrentSPage(number) 

  } 
  
}





useEffect(() => {
  fetchAccomm()
}, [ currentAPage]);

useEffect(() => {
  fetchVehicles()
}, [ currentVPage]);

useEffect(() => {
  fetchFlights()
}, [ currentFPage]);

useEffect(() => {
  fetchTours()
}, [ currentTPage]);

useEffect(() => {
  fetchServices()
}, [ currentSPage]);


  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  }, []);

  //   console.log(data);

  function onTimeChange(value) {
    let timeSplit = value.split(":"),
      hours,
      minutes,
      meridian;
    hours = timeSplit[0];
    minutes = timeSplit[1];
    if (hours > 12) {
      meridian = "PM";
      hours -= 12;
      return hours + ":" + minutes + " " + meridian;
    } else if (hours < 12) {
      meridian = "AM";
      if (hours == 0) {
        hours = 12;
      }
      return hours + ":" + minutes + " " + meridian;
    } else {
      meridian = "PM";
      return hours + ":" + minutes + " " + meridian;
    }
  }

  function formatDate(inputDate) {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
      // Months use 0 index.
      return (
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
      );
    }
  }

  function datediff(first, second) {
    let start = new Date(first);
    start.setHours(0, 0, 0, 0); // Sets hours, minutes, seconds, and milliseconds
    let end = new Date(second);
    end.setHours(0, 0, 0, 0);
    let diff = Math.round((end - start) / 86400000) + 1; // See note below re `+ 1`
    return diff; // 3
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

    // console.log({ vehicleData })

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <ToastContainer />

      <div className="MyListings">
        <Container>
          <Row>
            <Col xs={12} md={3} lg={3}>
              <HostNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <Row>
                <Col xs={12} md={12} className="mb-3">
                  <h2>Customer Bookings </h2>
                  <p>Find All booking on your Listings in one place. </p>
                </Col>




                <Col xs={12} md={12} className="mb-5">
                  <div className="table-panel">
                    <h3>Accommodation(s)</h3>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th> S/N </th>
                          <th> Reservations </th>
                          <th> Customer </th>
                          <th> Room Type </th>
                          <th> Room No. </th>
                          <th> Duration </th>
                          <th> Status </th>
                          <th> Amount (₦) </th>
                          <th> Confirm</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.map((property, index) => {
                          return (
                            <tr key={index}>
                              <th>{index + 1 + startAIndex}.</th>
                              <td>{property.propertyName} </td>
                              <td>
                                <b>
                                  {property.firstName + " " + property.lastName}{" "}
                                  <br /> ({property.phoneNumber})
                                </b>
                                <br /> Comments: <br /> {property.bookingFor}
                              </td>
                              <td>{property.roomType} </td>
                              <td>{property.roomNo} </td>
                              <td>
                                From: {formatDate(property.checkInDate)} <br />(
                                {onTimeChange(property.checkInTime)})<br />
                                To: {formatDate(property.checkOutDate)} <br />(
                                {onTimeChange(property.checkOutTime)})<br />
                                <b>Total Stay: </b>
                                {/* {property.duration} */}
                                { hostDasboardCalculateNights(property.checkInDate, property.checkInTime, property.checkOutDate)}

                               

                              </td>
                              <td>
                                {property.confirmation === 0 && (
                                  <Badge bg="warning">Pending</Badge>
                                )}
                                {property.confirmation === 1 && (
                                  <Badge bg="success">Accepted</Badge>
                                )}
                                {property.confirmation === 2 && (
                                  <Badge bg="danger">Rejected</Badge>
                                )}
                              </td>
                              <td>
                                <div className="text-primary">
                                ₦ {numberWithCommas(property.amountPaid)}
                                </div>
                              </td>
                              <td width={120}>
                                {property.confirmation === 0 ? (
                                  <> 
                                    &nbsp;
                                    <Link
                                      to={`/my-listing-confirmation/?confirm=1&bookingID=${property.bookingID}&email=${property.emailAddress}&roomID=${property.roomID}`}
                                      className="btn btn-success mt-2"
                                      title="Accept"
                                    >
                                      <FaCheckCircle />{" "}
                                    </Link>
                                    &nbsp;
                                    <Link
                                      to={`/my-listing-confirmation/?confirm=2&bookingID=${property.bookingID}&email=${property.emailAddress}`}
                                      className="btn btn-danger mt-2"
                                      title="Reject"
                                    >
                                      <CancelOutlined />{" "}
                                    </Link>
                                  </>
                                ) : (
                                  <Badge bg="primary">Cleared</Badge>
                                )}
                              </td>
                            </tr>
                          );
                        })}


                        
                      </tbody>
                    </Table>


                    <ul className="pagination "
                    style={{ alignItems: "right", justifyContent: "right"}}
                    >
                        <li className="page-item" onClick={()=> handlePrevious("a")}>
                        <Link className="page-link" to="" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                        </Link>
                        </li>
                        <li className="page-item" onClick={()=> goToPage(1, "a")}><Link className="page-link" to="">1</Link></li>
                        <li className="page-item" onClick={()=> goToPage(2, "a")}><Link className="page-link" to="">2</Link></li>
                        <li className="page-item" onClick={()=> goToPage(3, "a")}><Link className="page-link" to="">3</Link></li>

                        <li className="page-item" onClick={()=>  handleNextPage("a")}>
                        <Link className="page-link" to="" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                        </Link>
                        </li>
                    </ul>
                  </div>
                </Col>

                <Col xs={12} md={12} className="mb-5">
                  <div className="table-panel">
                    

                    <h3 className="mt-5">Flight(s)</h3>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th> S/N </th>
                          <th> Reservations </th>
                          <th> Customer </th>
                          <th> Duration </th>
                          <th> Status </th>
                          <th> Amount </th>
                          <th> Confirm</th>
                        </tr>
                      </thead>
                      <tbody>
                        {flightData?.map((property, index) => {
                          return (
                            <tr key={index}>
                              <th>{index + 1 + startFIndex }.</th>
                              <td>
                                {property.fromWhere} -  
                                {property.toWhere}  
                                
                              </td>
                              <td>
                                <b>
                                  {property.firstName + " " + property.lastName}{" "}
                                  <br /> ({property.phoneNumber})
                                </b>
                                <br /> Comments: <br /> {property.bookingFor}
                              </td>
                              <td>

                                From: {formatDate(property.checkInDate)} <br />(
                                {onTimeChange(property.checkInTime)})<br />
                                To: {formatDate(property.checkOutDate)} <br />(
                                {onTimeChange(property.checkOutTime)})<br />
                                <b>Total Stay: </b>
                                {/* {property.duration} */}
                                { hostDasboardCalculateNights(property.checkInDate, property.checkInTime, property.checkOutDate)}

                                <br/>
                                <b>Passengers: </b>
                                {property.tickets} 

                              </td>
                              <td>
                                {property.confirmation === 0 && (
                                  <Badge bg="warning">Pending</Badge>
                                )}
                                {property.confirmation === 1 && (
                                  <Badge bg="success">Accepted</Badge>
                                )}
                                {property.confirmation === 2 && (
                                  <Badge bg="danger">Rejected</Badge>
                                )}
                              </td>
                              <td>
                                <div className="text-primary">
                                  NGN{numberWithCommas(property.amountPaid)}
                                </div>
                              </td>
                              <td width={120}>
                                {property.confirmation === 0 ? (
                                  <>
                                    &nbsp;
                                    <Link
                                      to={`/my-listing-confirmation/?confirm=1&bookingID=${property.bookingID}&email=${property.emailAddress}`}
                                      className="btn btn-success mt-2"
                                      title="Accept"
                                    >
                                      <FaCheckCircle />{" "}
                                    </Link>
                                    &nbsp;
                                    <Link
                                      to={`/my-listing-confirmation/?confirm=2&bookingID=${property.bookingID}&email=${property.emailAddress}`}
                                      className="btn btn-danger mt-2"
                                      title="Reject"
                                    >
                                      <CancelOutlined />{" "}
                                    </Link>
                                  </>
                                ) : (
                                  <Badge bg="primary">Cleared</Badge>
                                )}
                              </td>
                            </tr>
                          );
                        })}

                       
                      </tbody>
                    </Table>


                    <ul className="pagination "
                      style={{ alignItems: "right", justifyContent: "right"}}
                      >
                          <li className="page-item" onClick={()=> handlePrevious("f")}>
                          <Link className="page-link" to="" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                          </Link>
                          </li>
                          <li className="page-item" onClick={()=> goToPage(1, "f")}><Link className="page-link" to="">1</Link></li>
                          <li className="page-item" onClick={()=> goToPage(2, "f")}><Link className="page-link" to="">2</Link></li>
                          <li className="page-item" onClick={()=> goToPage(3, "f")}><Link className="page-link" to="">3</Link></li>

                          <li className="page-item" onClick={()=>  handleNextPage("f")}>
                          <Link className="page-link" to="" aria-label="Next">
                              <span aria-hidden="true">&raquo;</span>
                          </Link>
                          </li>
                      </ul>
                  </div>
                </Col>

                <Col xs={12} md={12} className="mb-5">
                  <div className="table-panel">
                    

                    <h3 className="mt-5">Vehicle(s)</h3>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th> S/N </th>
                          <th> Reservations </th>
                          <th> Customer </th>
                          <th> Duration </th>
                          <th> Status </th>
                          <th> Amount </th>
                          <th> Confirm</th>
                        </tr>
                      </thead>
                      <tbody>
                        {vehicleData?.map((property, index) => {
                          return (
                            <tr key={index}>
                              <th>{index + 1 + startVIndex}.</th>
                              <td>{property.vehicleName} </td>
                              <td>
                                <b>
                                  {property.firstName + " " + property.lastName}{" "}
                                  <br /> ({property.phoneNumber})
                                </b>
                                <br /> Comments: <br /> {property.bookingFor}
                              </td>
                              <td>
                                From: {formatDate(property.checkInDate)} <br />(
                                {onTimeChange(property.checkInTime)})<br />
                                To: {formatDate(property.checkOutDate)} <br />(
                                {onTimeChange(property.checkOutTime)})<br />
                                <b>Total Stay: </b>
                                {/* {property.duration} */}                                { hostDasboardCalculateNights(property.checkInDate, property.checkInTime, property.checkOutDate)}

                              </td>
                              <td>
                                {property.confirmation === 0 && (
                                  <Badge bg="warning">Pending</Badge>
                                )}
                                {property.confirmation === 1 && (
                                  <Badge bg="success">Accepted</Badge>
                                )}
                                {property.confirmation === 2 && (
                                  <Badge bg="danger">Rejected</Badge>
                                )}
                              </td>
                              <td>
                                <div className="text-primary">
                                  NGN{numberWithCommas(property.amountPaid)}
                                </div>
                              </td>
                              <td width={120}>
                                {property.confirmation === 0 ? (
                                  <>
                                    &nbsp;
                                    <Link
                                      to={`/my-listing-confirmation/?confirm=1&bookingID=${property.bookingID}&email=${property.emailAddress}&roomID=${property.roomID}&vehicleID=${property.vehicleID}`}
                                      className="btn btn-success mt-2"
                                      title="Accept"
                                    >
                                      <FaCheckCircle />{" "}
                                    </Link>
                                    &nbsp;
                                    <Link
                                      to={`/my-listing-confirmation/?confirm=2&bookingID=${property.bookingID}&email=${property.emailAddress}`}
                                      className="btn btn-danger mt-2"
                                      title="Reject"
                                    >
                                      <CancelOutlined />{" "}
                                    </Link>
                                  </>
                                ) : (
                                  <Badge bg="primary">Cleared</Badge>
                                )}
                              </td>
                            </tr>
                          );
                        })}

                       
                      </tbody>
                    </Table>


                    <ul className="pagination "
                    style={{ alignItems: "right", justifyContent: "right"}}
                    >
                        <li className="page-item" onClick={()=> handlePrevious("v")}>
                        <Link className="page-link" to="" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                        </Link>
                        </li>
                        <li className="page-item" onClick={()=> goToPage(1, "v")}><Link className="page-link" to="">1</Link></li>
                        <li className="page-item" onClick={()=> goToPage(2, "v")}><Link className="page-link" to="">2</Link></li>
                        <li className="page-item" onClick={()=> goToPage(3, "v")}><Link className="page-link" to="">3</Link></li>

                        <li className="page-item" onClick={()=>  handleNextPage("v")}>
                        <Link className="page-link" to="" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                        </Link>
                        </li>
                    </ul>
                  </div>
                </Col>

                <Col xs={12} md={12} className="mb-5">
                  <div className="table-panel">
                    

                    <h3 className="mt-5">Tour(s)</h3>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th> S/N </th>
                          <th> Reservations </th>
                          <th> Customer </th>
                          <th> Duration </th>
                          <th> Status </th>
                          <th> Amount </th>
                          <th> Confirm</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tourData?.map((property, index) => {
                          return (
                            <tr key={index}>
                              <th>{index + 1 + startTIndex }.</th>
                              <td>{property.tourName} </td>
                              <td>
                                <b>
                                  {property.firstName + " " + property.lastName}{" "}
                                  <br /> ({property.phoneNumber})
                                </b>
                                <br /> Comments: <br /> {property.bookingFor}
                              </td>
                              <td>
                                From: {formatDate(property.checkInDate)} <br />(
                                {onTimeChange(property.checkInTime)})<br />
                                To: {formatDate(property.checkOutDate)} <br />(
                                {onTimeChange(property.checkOutTime)})<br />
                                <b>Total Stay: </b>
                                {/* {property.duration} */}
                                { hostDasboardCalculateNights(property.checkInDate, property.checkInTime, property.checkOutDate)}

                              </td>
                              <td>
                                {property.confirmation === 0 && (
                                  <Badge bg="warning">Pending</Badge>
                                )}
                                {property.confirmation === 1 && (
                                  <Badge bg="success">Accepted</Badge>
                                )}
                                {property.confirmation === 2 && (
                                  <Badge bg="danger">Rejected</Badge>
                                )}
                              </td>
                              <td>
                                <div className="text-primary">
                                  NGN{numberWithCommas(property.amountPaid)}
                                </div>
                              </td>
                              <td width={120}>
                                {property.confirmation === 0 ? (
                                  <>
                                    &nbsp;
                                    <Link
                                      to={`/my-listing-confirmation/?confirm=1&bookingID=${property.bookingID}&email=${property.emailAddress}`}
                                      className="btn btn-success mt-2"
                                      title="Accept"
                                    >
                                      <FaCheckCircle />{" "}
                                    </Link>
                                    &nbsp;
                                    <Link
                                      to={`/my-listing-confirmation/?confirm=2&bookingID=${property.bookingID}&email=${property.emailAddress}`}
                                      className="btn btn-danger mt-2"
                                      title="Reject"
                                    >
                                      <CancelOutlined />{" "}
                                    </Link>
                                  </>
                                ) : (
                                  <Badge bg="primary">Cleared</Badge>
                                )}
                              </td>
                            </tr>
                          );
                        })}

                        
                      </tbody>
                    </Table>


                    <ul className="pagination "
                    style={{ alignItems: "right", justifyContent: "right"}}
                    >
                        <li className="page-item" onClick={()=> handlePrevious("t")}>
                        <Link className="page-link" to="" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                        </Link>
                        </li>
                        <li className="page-item" onClick={()=> goToPage(1, "t")}><Link className="page-link" to="">1</Link></li>
                        <li className="page-item" onClick={()=> goToPage(2, "t")}><Link className="page-link" to="">2</Link></li>
                        <li className="page-item" onClick={()=> goToPage(3, "t")}><Link className="page-link" to="">3</Link></li>

                        <li className="page-item" onClick={()=>  handleNextPage("t")}>
                        <Link className="page-link" to="" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                        </Link>
                        </li>
                    </ul>
                  </div>
                </Col>


                <Col xs={12} md={12} className="mb-5">
                  <div className="table-panel">
                   

                    <h3 className="mt-5">Service(s)</h3>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th> S/N </th>
                          <th> Reservations </th>
                          <th> Customer </th>
                          <th> Duration </th>
                          <th> Status </th>
                          <th> Amount </th>
                          <th> Confirm</th>
                        </tr>
                      </thead>
                      <tbody>
                        {serviceData?.map((property, index) => {
                          return (
                            <tr key={index}>
                              <th>{index + 1 + startSIndex }.</th>
                              <td>{property.serviceName} </td>
                              <td>
                                <b>
                                  {property.firstName + " " + property.lastName}{" "}
                                  <br /> ({property.phoneNumber})
                                </b>{" "}
                                <br /> Comments: <br /> {property.bookingFor}
                              </td>
                              <td>
                                From: {formatDate(property.checkInDate)} <br />(
                                {onTimeChange(property.checkInTime)})<br />
                                To: {formatDate(property.checkOutDate)} <br />(
                                {onTimeChange(property.checkOutTime)})<br />
                                <b>Total Stay: </b>
                                {/* {property.duration} */}
                                { hostDasboardCalculateNights(property.checkInDate, property.checkInTime, property.checkOutDate)}

                              </td>
                              <td>
                                {property.confirmation === 0 && (
                                  <Badge bg="warning">Pending</Badge>
                                )}
                                {property.confirmation === 1 && (
                                  <Badge bg="success">Accepted</Badge>
                                )}
                                {property.confirmation === 2 && (
                                  <Badge bg="danger">Rejected</Badge>
                                )}
                              </td>
                              <td>
                                <div className="text-primary">
                                  NGN{numberWithCommas(property.amountPaid)}
                                </div>
                              </td>
                              <td width={120}>
                                {property.confirmation === 0 ? (
                                  <>
                                    &nbsp;
                                    <Link
                                      to={`/my-listing-confirmation/?confirm=1&bookingID=${property.bookingID}&email=${property.emailAddress}`}
                                      className="btn btn-success mt-2"
                                      title="Accept"
                                    >
                                      <FaCheckCircle />{" "}
                                    </Link>
                                    &nbsp;
                                    <Link
                                      to={`/my-listing-confirmation/?confirm=2&bookingID=${property.bookingID}&email=${property.emailAddress}`}
                                      className="btn btn-danger mt-2"
                                      title="Reject"
                                    >
                                      <CancelOutlined />{" "}
                                    </Link>
                                  </>
                                ) : (
                                  <Badge bg="primary">Cleared</Badge>
                                )}
                              </td>
                            </tr>
                          );
                        })}

                        
                      </tbody>
                    </Table>


                    <ul className="pagination "
                      style={{ alignItems: "right", justifyContent: "right"}}
                      >
                          <li className="page-item" onClick={()=> handlePrevious("s")}>
                          <Link className="page-link" to="" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                          </Link>
                          </li>
                          <li className="page-item" onClick={()=> goToPage(1, "s")}><Link className="page-link" to="">1</Link></li>
                          <li className="page-item" onClick={()=> goToPage(2, "s")}><Link className="page-link" to="">2</Link></li>
                          <li className="page-item" onClick={()=> goToPage(3, "s")}><Link className="page-link" to="">3</Link></li>

                          <li className="page-item" onClick={()=>  handleNextPage("s")}>
                          <Link className="page-link" to="" aria-label="Next">
                              <span aria-hidden="true">&raquo;</span>
                          </Link>
                          </li>
                      </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        { loading && <Spinner />}
      </div>
    </>
  );
};

export default MyListings;
