import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HostNavbar from "./HostNavbar";
import { FaCamera, FaRegSave, FaUpload } from "react-icons/fa";
import "./ListAccommodation.css";
import dropImg from "../../Assets/illustrations/ep-picture-filled.svg";
import NavbarMenu from "../Layouts/Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BaseURL } from "../../config/variables";
import Spinner from "../Alert/Spinner/Spinner";
import { uploadImagesToCloud } from "../../utils/imageUtils/uploadImage";

const ListAccommodation04 = () => {
  const navigate = useNavigate();
  const currentPropertyID = window.localStorage.getItem("propertyID");
  // alert (currentPropertyID);
  const [data, setData] = useState({
    propertyPic1: "", 
    propertyID: currentPropertyID,
  });
  
  const [loading, setLoading ] = useState(false)

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
 

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    
    try {
      // const arrayOfImages = [data.propertyPic1, data.propertyPic2, data.propertyPic3, data.propertyPic4, data.propertyPic5 ]

      const filteredArrayOfImages = [data.propertyPic1, data.propertyPic2, data.propertyPic3, data.propertyPic4, data.propertyPic5].filter(imageUrl => imageUrl !== "" && imageUrl !== null && imageUrl !== undefined);

    const uploadedImageURLs = await uploadImagesToCloud(filteredArrayOfImages)
    const imageURLs = []
    uploadedImageURLs.forEach((each, i)=>{
      imageURLs.push(each.url)
    })

    // console.log(imageURLs)
    // setLoading(false)

    
      const response = await axios.post(
        `${BaseURL}/addpropertyimages`,
        {propertyID: data.propertyID, imageURLs}
      );
      const { success, message } = response.data; 
        
      // console.log({response})
      
      if (success) {
        handleSuccess(message);
          navigate("/list-accommodation");
       
        setLoading(false)
        window.localStorage.removeItem("propertyID");
      } else {
        setLoading(false)
        handleError(message);
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
 
  };

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <ToastContainer />

      <div className="ListAccommodation">
        <Container>
          <Row>
            <Col xs={12} md={3} lg={3}>
              <HostNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <form
                className="form-panel"
                encType="multipart/form-data"
                onSubmit={handleSubmit}
              >
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <h2>Property Photos:</h2>
                  </Col>
                  <Col xs={12} md={12} lg={12}>
                    <div className="dropbox mt-5">
                      <p>
                        <strong>
                          Upload at least 5 photos of your property.{" "}
                        </strong>
                        The more you upload, the more likely you are to get
                        bookings. <b>You can change them later.</b>{" "}
                      </p>
                      <div className="dropphotos">
                        <div className="picbox mt-5">
                          <img src={dropImg} alt="property pic" />
                        </div>
                        <p>Click below to select your photos</p>
                        <div className="col-12 mb-3">
                          <label className="form-label" for="propertyPic1">
                            Main Photo
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="propertyPic1"
                            id="propertyPic1"
                            // value={propertyPic1}
                            onChange={e => setData({...data, propertyPic1: e.target.files[0]})}
                          />
                          <label className="form-label" for="propertyPic2">
                            Photo 2
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="propertyPic2"
                            id="propertyPic2"
                            // value={propertyPic2}
                            onChange={e => setData({...data, propertyPic2: e.target.files[0]})}
                          />
                          <label className="form-label" for="propertyPic3">
                            Photo 3
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="propertyPic3"
                            id="propertyPic3"
                            // value={propertyPic3}
                            onChange={e => setData({...data, propertyPic3: e.target.files[0]})}
                          />
                          <label className="form-label" for="propertyPic4">
                            Photo 4
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="propertyPic4"
                            id="propertyPic4"
                            // value={propertyPic4}
                            onChange={e => setData({...data, propertyPic4: e.target.files[0]})}
                          />
                          <label className="form-label" for="propertyPic5">
                            Photo 5
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="propertyPic5"
                            id="propertyPic5"
                            // value={propertyPic5}
                            onChange={e => setData({...data, propertyPic5: e.target.files[0]})}
                          />
                        </div> 
                        {/* <button className="btn btn-outline mb-3">
                                                <FaCamera /> Upload photos
                                            </button> */}
                      </div>
                    </div>
                  </Col>

                  <Col xs={12} md={12} lg={12} className="mt-5">
                    <button type="submit" className="btnOutline mt-5">
                      Publish <FaCamera />
                    </button>
                  </Col>
                  {/* <Col xs={12} md={12} lg={4} className='mt-5'>
                                    <button className='btnOutline mt-5'>Save Draft <FaRegSave /></button>
                                    
                                </Col> */}
                </Row>
              </form>
            </Col>
          </Row>
        </Container>

        { loading && <Spinner />}
      </div>
    </>
  );
};

export default ListAccommodation04;
