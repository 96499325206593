import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Login.css";
import { FaSignInAlt } from "react-icons/fa";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import NavbarMenu from "../Layouts/Navbar";
import { Cookies, useCookies } from "react-cookie";
import { useGetHostID } from "../../Hooks/useGetHostID";
import { BaseURL } from "../../config/variables";
import Spinner from "../Alert/Spinner/Spinner";
//import { useGetUserID } from "../../Hooks/useGetUserID";

const Login = () => {
  const navigate = useNavigate();

  const [cookies, removeCookie] = useCookies([]);
  //const userID = useGetUserID();
  const hostID = useGetHostID();


  if (hostID) {
    window.localStorage.removeItem("hostID");
    window.sessionStorage.removeItem("session");
    //navigate("/host-login");
    removeCookie("token", "");
    window.location.reload();
  }

  //   if(userID){
  //     navigate("/login");
  //   window.location.reload();
  //  }
  const [query] = useSearchParams();
  const q = query.get("q");
  const id = query.get("id");
  const pv = query.get("verify");
  const urlBack = "/" + q + "/" + id;
  const verify = pv;

  const [inputValue, setInputValue] = useState({
    emailAddress: "",
    password: "",
  });
  const [loading, setLoading] = useState(false)

  const { emailAddress, password } = inputValue;

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    if(verify == null) return

    if (verify !== "") {
      axios
        .put(`${BaseURL}/login/${verify}`)
        .then((res) => {
          if (res.data.success) {
            const { success, message } = res.data;
            // verify = '';
          }
        })
        .catch((err) => console.log(err));
    }

    
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const handleError = (err) =>{
    toast.error(err, {
      position: "bottom-left",
    });
    setLoading(false)
  }
  const handleSuccess = (msg) =>{
    toast.success(msg, {
      position: "bottom-right",
    });
    setLoading(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true)
      const { data } = await axios.post(
        BaseURL +"/login",
        {
          ...inputValue,
        },
        // { withCredentials: true }
        );
        // console.log(data);
        const { success, message, userID, accessToken, user} = data;

        if (success) {
          handleSuccess(message);
          window.localStorage.setItem("userID", userID);
          window.localStorage.setItem("user", user?.username);
          window.localStorage.setItem("emailAddress", user?.emailAddress);
          sessionStorage.setItem("session", accessToken)
          // check if user left a booking ongoing?
          
          setLoading(false)
        if (q) { 
            navigate(urlBack); 
        } else { 
            navigate("/user-dashboard"); 
        }
      } else {
        handleError(message);
      }
    } catch (error) {
      if(error.response?.data?.message){
        return handleError(error.response?.data?.message) 
    } else {
        return handleError(error.message) 
    }
    }
    setInputValue({
      ...inputValue,
      emailAddress: "",
      password: "",
    });
  };

  localStorage.clear()

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />

      <div className="LoginPage">
        <Container className="container">
          <ToastContainer />
          <Row className="justify-items-center">
            <Col xs={12} md={12}>
              <div className="login-panel">
                {verify && (
                  <div className="alert alert-success">
                    Email address verified successfully!
                  </div>
                )}
                <h3>Sign In or Create Account</h3>
                <form onSubmit={handleSubmit}>
                  <p>Provide your personal details</p>
                  <label htmlFor="emailAddress">Email Address</label>
                  <input
                    type="email"
                    name="emailAddress"
                    id="emailAddress"
                    className="form-control mb-3"
                    value={emailAddress}
                    onChange={handleOnChange}
                  />

                  <label htmlFor="Password">Password</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="form-control mb-3"
                    value={password}
                    onChange={handleOnChange}
                  />

                  <button
                    type="submit"
                    className="btnMore"
                    style={{ padding: "20px 30px", width: "100%" }}
                  >
                    Sign In <FaSignInAlt />
                  </button>
                </form>
                <hr />
                <p>
                  Forgot Password?{" "}
                  <Link to={"/user-forgot-pass"}>Get reset link</Link>
                </p>
                <div className="policy">
                  <p>
                    New User?{" "}
                    <Link to={"/register"}>
                      Sign Up <FaSignInAlt />
                    </Link>
                  </p>
                  <hr />
                  <p>
                    By sign in or creating an account you agree with our &nbsp;
                    <Link to="/">Terms & Conditions</Link> and{" "}
                    <Link to="/">Privacy Policy</Link>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        { loading && <Spinner />}
      </div>
    </>
  );
};

export default Login;
