import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { DashboardOutlined, RoomServiceTwoTone } from '@mui/icons-material'; 
import { HiUser } from 'react-icons/hi2';
import { FaCarSide, FaClipboardCheck, FaCreditCard, FaHome, FaListUl, FaMapSigns, FaPlaneDeparture, FaSignOutAlt, FaComment } from 'react-icons/fa';
import { useGetHostID } from '../../Hooks/useGetHostID';

const HostNavbar = () => {
  const navigate = useNavigate();  
  const hostID = useGetHostID();
  if(!hostID){
    navigate("/host-login");
  }
  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
    <div>
         
        <div className='list-group'>
            <Link to={'/host-dashboard'} className="list-group-item list-group-item-action" aria-current="true"><DashboardOutlined/> Dashboard</Link>
            <Link to={'/host-account-history'} className="list-group-item list-group-item-action"><FaClipboardCheck /> Account History </Link>
            <Link to={'/my-listings'} className="list-group-item list-group-item-action"><FaListUl /> Booking History</Link>
            <Link to={'/list-accommodation'} className="list-group-item list-group-item-action"><FaHome /> List Accommodation</Link>
            <Link to={'/list-vehicle'} className="list-group-item list-group-item-action"><FaCarSide /> List Vehicle</Link>
            <Link to={'/list-tour'} className="list-group-item list-group-item-action"><FaMapSigns /> List Tour Center</Link>
            <Link to={'/list-flight'} className="list-group-item list-group-item-action"><FaPlaneDeparture /> List Flight Ticket</Link>
            <Link to={'/list-service'} className="list-group-item list-group-item-action"><RoomServiceTwoTone /> List Services</Link> 
            {/* <Link to={''} className="list-group-item list-group-item-action"><FaCreditCard /> View Payments</Link> */}
            <Link to={'/host-profile'} className="list-group-item list-group-item-action"><HiUser /> Manage Profile</Link>
            <Link to={'/host-feedback'} className="list-group-item list-group-item-action"><FaComment /> Host Feedback</Link>
            {/* <Link to={'#logout'} className="list-group-item list-group-item-action"><FaSignOutAlt /> Logout</Link> */}
        </div>
    </div>
  )
}

export default HostNavbar