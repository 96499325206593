import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./UserDashboard.css";
import { PaymentOutlined, Payments } from "@mui/icons-material";
import NavbarMenu from "../Layouts/Navbar";
import UserNavbar from "./UserNavbar";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useGetUserID } from "../../Hooks/useGetUserID";
import {
  FaArrowCircleRight,
  FaBriefcase,
  FaCreditCard,
  FaKey,
  FaReceipt,
  FaUserCheck,
} from "react-icons/fa";
import axios from "axios";
import { BaseURL } from "../../config/variables";

const BookedFlight = () => {
  const userID = useGetUserID();
  const { id } = useParams();
  // alert(userID);

  const [data, setData] = useState({
    userID: userID,
  });

  useEffect(() => {
    // scroll to top on page load

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    axios
      .get(`${BaseURL}/fetchbookedflights/${userID}/${id}`)
      .then((res) => {
        if (res.data.status === "Success") {
          setData({
            ...data,
            hostID: res.data.Result[0].hostID,
            fromWhere: res.data.Result[0].fromWhere,
            toWhere: res.data.Result[0].toWhere,
            ticketType: res.data.Result[0].ticketType,
            hostName: res.data.Result[0].hostName,
            phoneNumber: res.data.Result[0].phoneNumber,
            userID: res.data.Result[0].userID,
            itemType: res.data.Result[0].itemType,
            itemUnitPrice: res.data.Result[0].itemUnitPrice,
            amountPaid: res.data.Result[0].amountPaid,
            bookingFor: res.data.Result[0].bookingFor,
            checkInDate: res.data.Result[0].checkInDate,
            checkInTime: res.data.Result[0].checkInTime,
            checkOutDate: res.data.Result[0].checkOutDate,
            checkOutTime: res.data.Result[0].checkOutTime,
            duration: res.data.Result[0].duration,
            tickets: res.data.Result[0].tickets,
          });
          console.log(res.data.Result[0]);
        } else {
          console.log("No such booking found");
        }
      })
      .catch((err) => console.log(err));
  }, []);

  function onTimeChange(value) {
    let timeSplit = value.split(":"),
      hours,
      minutes,
      meridian;
    hours = timeSplit[0];
    minutes = timeSplit[1];
    if (hours > 12) {
      meridian = "PM";
      hours -= 12;
      return hours + ":" + minutes + " " + meridian;
    } else if (hours < 12) {
      meridian = "AM";
      if (hours == 0) {
        hours = 12;
      }
      return hours + ":" + minutes + " " + meridian;
    } else {
      meridian = "PM";
      return hours + ":" + minutes + " " + meridian;
    }
  }
  function formatDate(inputDate) {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
      // Months use 0 index.
      return (
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
      );
    }
  }

  function datediff(first, second) {
    let start = new Date(first);
    start.setHours(0, 0, 0, 0); // Sets hours, minutes, seconds, and milliseconds
    let end = new Date(second);
    end.setHours(0, 0, 0, 0);
    let diff = Math.round((end - start) / 86400000) + 1; // See note below re `+ 1`
    return diff; // 3
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />
      <div className="UserDashboard">
        {/* Do a mini navbar just below the main navbar */}
        <Container className="container">
          <Row>
            <Col xs={12} md={3} lg={3}>
              <UserNavbar />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <Row>
                <Col xs={12} md={12} className="mt-5">
                  <h2 className="mb-3">Booking Details</h2>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} lg={12} className="">
                  <p>Here's your booking details below: </p>
                </Col>
                <Col xs={12} md={12} lg={6} className="mb-5">
                  <div className="profile-bg">
                    <div className="icon-list">
                      <FaReceipt className="icon-big" />
                    </div>
                    <h4>From: <b>{data.fromWhere}</b> - To:  <b>{data.toWhere}</b></h4>
                    <p>
                      Ticket Type: <b>{data.ticketType}</b>
                      <br/>                     
                      Booking For: <b>{data.bookingFor}</b>
                      <br/>
                      Duration: <b>{data.duration} night(s)</b>
                      <br/>
                      Passengers: <b>{data.tickets}</b>
                      <br />
                      <b>Host Name:</b> {data.hostName}
                      <br />
                      <b>Phone Number:</b> {data.phoneNumber}
                    </p>
                  </div>
                </Col>
                <Col xs={12} md={12} lg={6} className="mb-5">
                  <div className="profile-bg">
                    <div className="icon-list">
                      <FaCreditCard className="icon-big" />
                    </div>
                    <h4>Payment Details</h4>
                    <p>
                      Amount Paid: <b>NGN{numberWithCommas(Number(data.amountPaid))}.00</b>
                    </p>
                    <p> 
                      <b>Check In Date: </b>
                      {formatDate(data.checkInDate)}
                      <br />
                      <b>Check In Time: </b>
                      {onTimeChange(String(data.checkInTime))}
                      <br />
                      <b>Check Out Date: </b>
                      {formatDate(data.checkOutDate)}
                      <br />
                      <b>Check Out Time: </b>
                      {onTimeChange(String(data.checkOutTime))}
                      <br />
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </>
  );
};

export default BookedFlight;
