import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Login.css";
import { FaArrowRight, FaSignInAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import NavbarMenu from "../Layouts/Navbar";
import { BaseURL } from "../../config/variables";
import Spinner from "../Alert/Spinner/Spinner";

const HostReg1 = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)

  const [inputValue, setInputValue] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    password: "",
    username: "",
  });
  const {
    firstName,
    lastName,
    emailAddress,
    phoneNumber,
    password,
    cfPassword,
    username,
  } = inputValue;

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });
  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-right",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const { data } = await axios.post(`${BaseURL}/hostsignup`, {
        ...inputValue,
      });
      const { success, message } = data;
      if (success) {
        handleSuccess(message);
        setTimeout(() => {
          navigate("/host-login");
        }, 1000);
        setLoading(false)
      } else {
        handleError(message);
        setLoading(false)
      }
    } catch (error) {
      console.log(error);
    }
    setInputValue({
      ...inputValue,
      firstName: "",
      lastName: "",
      emailAddress: "",
      phoneNumber: "",
      password: "",
      username: "",
    });
  };

  return (
    <>
      <NavbarMenu style={{ backgroundColor: "#003B95" }} />

      <div className="LoginPage">
        <ToastContainer />
        <Container className="container">
          <Row className="justify-items-center">
            <Col xs={12} md={12}>
              <div className="login-panel">
                <h3>Sign Up Host Account </h3>
                <p>
                  Your full name and phone number are needed to ensure security
                  of your account.{" "}
                </p>
                <form onSubmit={handleSubmit}>
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    className="form-control mb-3"
                    value={firstName}
                    onChange={handleOnChange}
                  />
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    className="form-control mb-3"
                    value={lastName}
                    onChange={handleOnChange}
                  />
                  <label htmlFor="emailAddress">Email Address</label>
                  <input
                    type="email"
                    name="emailAddress"
                    id="emailAddress"
                    className="form-control mb-3"
                    value={emailAddress}
                    onChange={handleOnChange}
                  />
                  <label htmlFor="phoneNumber">Phone Number</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    id="phoneNumber"
                    className="form-control mb-3"
                    value={phoneNumber}
                    onChange={handleOnChange}
                  />
                  <label htmlFor="Username">Username</label>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    className="form-control mb-3"
                    value={username}
                    onChange={handleOnChange}
                  />

                  <h3>Create password</h3>
                  <p>
                    Use a minimum of 8 characters, including uppercase, lower
                    case letters, numbers and special characters.
                  </p>

                  <label htmlFor="Password">Password</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="form-control mb-3"
                    value={password}
                    onChange={handleOnChange}
                  />
                  <label htmlFor="cfpassword">Confirm Password</label>
                  <input
                    type="password"
                    name="cfPassword"
                    id="cfPassword"
                    className="form-control mb-3"
                    value={cfPassword}
                    onChange={handleOnChange}
                  />
                  <button type="submit" className="btn btn-primary">
                    Create Account <FaSignInAlt />
                  </button>
                </form>
                <div className="policy">
                  <p>
                    Already a Host?{" "}
                    <Link style={{ textDecoration: "none" }} to={"/host-login"}>
                      Sign In <FaSignInAlt />
                    </Link>
                  </p>
                  <hr />
                  <p>
                    By sign in or creating an account you agree with our &nbsp;
                    <Link to="/">Terms & Conditions</Link> and{" "}
                    <Link to="/">Privacy Policy</Link>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        { loading && <Spinner />}
      </div>
    </>
  );
};

export default HostReg1;
